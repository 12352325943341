import {useMemo} from "react";
import {EnumDropdownElement, EnumRadioElement} from "@ib-tehnologije/react-widgets";
import {WorkOrderPriority} from "../graphql-types";
import {reorderEnum} from "../Helpers/reorderEnum";

export const useWorkOrderPriorityPicker = () => {
    return useMemo(() => ({
        type: new EnumRadioElement({
            enumData: reorderEnum(WorkOrderPriority, [
                WorkOrderPriority.Low,
                WorkOrderPriority.Medium,
                WorkOrderPriority.High
            ]),
        })
    }), []);
}