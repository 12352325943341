import React, {FC, useCallback, useContext, useMemo} from "react";
import {
    DropdownElement,
    FormOrientation,
    GroupElement,
    ITenantUpdating,
    RenderAntdGraphQLForm,
    TextFieldElement,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {AuthContext} from "../Contexts/AuthContext";
import {
    AccountChangePasswordDtoInput,
    AccountUpdateDtoInput, ChangePasswordMutation, ChangePasswordMutationVariables,
    UpdateProfileMutation, UpdateProfileMutationHookResult, useChangePasswordMutation,
    useGetMyUserQuery,
    useUpdateProfileMutation
} from "../graphql-types";
import {useTranslation} from "react-i18next";
import i18n, {locales} from "../i18n";
import {notification, Spin} from "antd";
import i18next from "i18next";
import {hr} from "../i18n/hr";
import {en} from "../i18n/en";
import {de} from "../i18n/de";
import {it} from "../i18n/it";

const UserSettingsPage: FC = () => {
    const {auth: {userId}, refreshAndSetToken} = useContext(AuthContext);
    const me = useGetMyUserQuery();
    const [update] = useUpdateProfileMutation();
    const languages = useMemo(() => Object.keys(i18n.options.resources!), []);
    const {t} = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [changePassword] = useChangePasswordMutation();

    const updating = useMemo<ITenantUpdating<UpdateProfileMutation, {
        id: string;
        email?: string;
        firstName?: string;
        lastName?: string;
        phoneNumber?: string;
        preferredLanguage?: string;
    }, AccountUpdateDtoInput, "dto">>(() => ({
        modelKey: "dto",
        fn: update,
        selectionToBaseFormData: (s) => ({
            id: userId,
            email: s?.email,
            firstName: s?.firstName,
            lastName: s?.lastName,
            phoneNumber: s?.phoneNumber,
            preferredLanguage: s?.preferredLanguage,
        }),
        formDataToDto: (f) => ({
            id: userId,
            email: f.email,
            firstName: f.firstName,
            lastName: f.lastName,
            phoneNumber: f.phoneNumber,
            preferredLanguage: f.preferredLanguage,
        }),
        formDefinition: {
            nameAndSurname: {
                type: new GroupElement({
                    firstName: {
                        type: new TextFieldElement({})
                    },
                    lastName: {
                        type: new TextFieldElement({})
                    },
                }),
            },
            email: {
                type: new TextFieldElement({})
            },
            phoneNumber: {
                type: new TextFieldElement({})
            },
            preferredLanguage: {
                type: new DropdownElement({
                    values: languages.map(l => ({
                        key: locales[l], value: {
                            "hr": hr[`languages-full:${l}` as keyof typeof hr],
                            "en": en[`languages-full:${l}` as keyof typeof en],
                            "de": de[`languages-full:${l}` as keyof typeof de],
                            "it": it[`languages-full:${l}` as keyof typeof it],
                        }[l]
                    })),
                    valueKey: "key",
                    titleKey: "value",
                })
            },
        }
    }), [t, userId]);

    const onProfileUpdated = useCallback((response: (UpdateProfileMutation | null | undefined)) => {
        refreshAndSetToken();
        api.success({
            message: t("profile-updated"),
        });
    }, [api, refreshAndSetToken]);

    const changingPassword = useMemo<ITenantUpdating<ChangePasswordMutation, any, AccountChangePasswordDtoInput, "model">>(() => ({
        modelKey: "model",
        fn: changePassword,
        formDataToDto: (f) => ({
            username: me.data?.user?.userName ?? "",
            oldPassword: f.oldPassword ?? "",
            newPassword: f.newPassword ?? "",
        }),
        formDefinition: {
            oldPassword: {
                type: new TextFieldElement({
                    inputProps: {
                        type: "password"
                    }
                })
            },
            newPassword: {
                type: new TextFieldElement({
                    inputProps: {
                        type: "password"
                    }
                })
            },
        },
        selectionToBaseFormData: (s) => ({}),
    }), [me.data?.user?.userName]);

    const onPasswordChanged = useCallback((response: (ChangePasswordMutation | null | undefined)) => {
        api.success({
            message: t("password-changed"),
        });
    }, []);

    return <TitleAndContentLayout title={"settings"} pad>
        {contextHolder}
        <Spin spinning={me.loading}>
            <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                <div style={{maxWidth: 500}}>
                    <RenderAntdGraphQLForm
                        getId={t => t.id}
                        editingTitle={""}
                        addingTitle={""}
                        loading={me.loading}
                        formSettings={{
                            inline: true,
                            orientation: FormOrientation.VERTICAL,
                        }}
                        onSuccessfulSubmission={onProfileUpdated}
                        selectedItem={me.data?.user}
                        updating={updating}
                        doNotResetAfterSuccessfulSubmission={true}
                    />
                </div>
                <div style={{maxWidth: 200}}>
                    <RenderAntdGraphQLForm
                        getId={t => t.id}
                        editingTitle={""}
                        addingTitle={""}
                        loading={me.loading}
                        formSettings={{
                            inline: true,
                            orientation: FormOrientation.VERTICAL,
                        }}
                        onSuccessfulSubmission={onPasswordChanged}
                        updating={changingPassword}
                    />
                </div>
            </div>
        </Spin>
    </TitleAndContentLayout>
}

export default UserSettingsPage;