import React, {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import dayjs, {Dayjs} from "dayjs";
import {ReportsPageContext} from "../Contexts/ReportsPageContext";
import {
    useWorkOrderCountPerDayLazyQuery,
    useWorkOrderCountPerDayQuery,
    WorkOrderCountPerDayDtoInput
} from "../graphql-types";
import {useVehiclePicker} from "../Pickers/useVehiclePicker";
import {useCompanyPicker} from "../Pickers/useCompanyPicker";
import {useCompanyLocationPicker} from "../Pickers/useCompanyLocationPicker";
import {useUserPicker} from "../Pickers/useUserPicker";
import {Badge, Button, Calendar, CalendarProps} from "antd";
import {DateElement, FormOrientation, RenderAntdStandaloneForm} from "@ib-tehnologije/react-widgets";
import {useExport} from "../Hooks/useExport";
import {FileExcelOutlined, FilePdfOutlined, FileTextOutlined} from "@ant-design/icons";

export const TotalWorkOrderCountReportView: FC = () => {
    const {t} = useTranslation();
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [selectedMode, setSelectedMode] = useState<"month" | "year">("month");
    const {setElement} = useContext(ReportsPageContext);
    const baseStartDate = useMemo(() => selectedMode === "month" ? new Date(selectedDate.toDate().getFullYear(), selectedDate.toDate().getMonth(), 1) : new Date(selectedDate.toDate().getFullYear(), 0, 1), [selectedDate, selectedMode]);
    const baseEndDate = useMemo(() => selectedMode === "month" ? new Date(selectedDate.toDate().getFullYear(), selectedDate.toDate().getMonth() + 1, 0) : new Date(selectedDate.toDate().getFullYear(), 11, 31), [selectedDate, selectedMode]);

    const [dto, setDto] = useState<WorkOrderCountPerDayDtoInput>({
        startDate: baseStartDate,
        endDate: baseEndDate,
    });

    useEffect(() => {
        setDto(dto => ({
            ...dto,
            startDate: baseStartDate,
            endDate: baseEndDate
        }));
    }, [selectedDate, selectedMode]);

    const vehicleIdPicker = useVehiclePicker();
    const companyIdPicker = useCompanyPicker();
    const companyLocationIdPicker = useCompanyLocationPicker();
    const userIdPicker = useUserPicker("assignee");
    const [exportsLoading, setExportsLoading] = useState<{ [key: string]: boolean }>({});

    const [query, data] = useWorkOrderCountPerDayLazyQuery({});

    useEffect(() => {
        if (dto) {
            query({
                variables: {
                    dto: {
                        vehicleId: dto.vehicleId,
                        companyId: dto.companyId,
                        companyLocationId: dto.companyLocationId,
                        assigneeId: dto.assigneeId,
                        endDate: dto.endDate,
                        startDate: dto.startDate
                    }
                }
            }).then(r => {
            }).catch(e => {});
        }
    }, [dto]);

    const monthCellRender = (value: Dayjs) => {
        const monthSum = data?.data?.workOrderCountAndValuePerDay.reduce((acc, item) => {
            if (value.month() === dayjs(item.key).month()) {
                return acc + item.value.count;
            }
            return acc;
        }, 0);
        return monthSum ? (
            <div className="notes-month">
                <section><b>{monthSum}</b></section>
                <span>{t("work_order_count")}</span>
            </div>
        ) : null;
    };

    const dateCellRender = (value: Dayjs) => {
        const listData = data?.data?.workOrderCountAndValuePerDay.filter((item) => dayjs(item.key).isSame(value, 'day')) || [];
        return (
            <ul className="events">
                {listData.map((item) => (
                    <li key={item.key}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <Badge status={"error"} text={<span>
                                {t("work_order_count_short")}: <b>{item.value.count}</b>
                            </span>}/>
                            <Badge status={"success"} text={<span>
                                {t("value")}: <b>{item.value.value}</b>
                            </span>}/>
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
        if (info.type === 'date') return dateCellRender(current);
        if (info.type === 'month') return monthCellRender(current);
        return info.originNode;
    };

    const searchFormDefinition = useMemo(() => ({
        startDate: {
            type: new DateElement({})
        },
        endDate: {
            type: new DateElement({})
        },
        vehicleId: vehicleIdPicker,
        companyId: companyIdPicker,
        companyLocationId: companyLocationIdPicker,
        assigneeId: userIdPicker
    }), []);

    const onPanelChange = useCallback((value: Dayjs, mode: "month" | "year") => {
        setSelectedDate(value);
        setSelectedMode(mode);
    }, []);

    const {singleSheetXLSX, csv, pdf} = useExport();

    const xlsxExport = useCallback(async () => {
        setExportsLoading(loading => ({...loading, xlsx: true}));
        try {
            if (data) {
                const sortedData = [...data.data?.workOrderCountAndValuePerDay ?? []].sort((a, b) => dayjs(a.key).isBefore(dayjs(b.key)) ? -1 : 1);

                await singleSheetXLSX([
                        [t("date"), t("count"), t("value")],
                        ...sortedData.map(item => [dayjs.utc(item.key).format("L"), item.value.count, item.value.value])],
                    t("work_order_count_per_day"));
            } else {
                console.error("No data to export");
                window.alert("No data to export");
            }
        } finally {
            setExportsLoading(loading => ({...loading, xlsx: false}));
        }
    }, [data, t]);

    const csvExport = useCallback(async () => {
        setExportsLoading(loading => ({...loading, csv: true}));
        try {
            if (data) {
                const sortedData = [...data.data?.workOrderCountAndValuePerDay ?? []].sort((a, b) => dayjs(a.key).isBefore(dayjs(b.key)) ? -1 : 1);

                await csv([
                        [t("date"), t("count"), t("value")],
                        ...sortedData.map(item => [dayjs.utc(item.key).format("L"), item.value.count, item.value.value])],
                    t("work_order_count_per_day"));
            } else {
                console.error("No data to export");
                window.alert("No data to export");
            }
        } finally {
            setExportsLoading(loading => ({...loading, csv: false}));
        }
    }, [data, t]);

    const pdfExport = useCallback(async () => {
        setExportsLoading(loading => ({...loading, pdf: true}));
        try {
            if (data) {
                const sortedData = [...data.data?.workOrderCountAndValuePerDay ?? []].sort((a, b) => dayjs(a.key).isBefore(dayjs(b.key)) ? -1 : 1);

                await pdf({
                    html: `<h1>${t("work_order_count_per_day")}</h1>
                <table>
                    <thead>
                        <tr>
                            <th>${t("date")}</th>
                            <th>${t("count")}</th>
                            <th>${t("value")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${sortedData.map(item => `<tr>
                            <td>${dayjs.utc(item.key).format("L")}</td>
                            <td>${item.value.count}</td>
                            <td>${item.value.value}</td>
                        </tr>`).join("")}
                    </tbody>
                </table>`,
                    fileName: t("work_order_count_per_day"),
                    footerCenter: `"${t("current-page")} [page] ${t("page-of")} [topage]"`,
                });
            }
        } finally {
            setExportsLoading(loading => ({...loading, pdf: false}));
        }
    }, [data, t]);

    useEffect(() => {
        setElement(<div style={{display: "flex", flexDirection: "row", gap: 10}}>
            <Button onClick={xlsxExport} loading={exportsLoading["xlsx"]} icon={<FileExcelOutlined/>}>
                {t("xlsx_export")}
            </Button>
            <Button onClick={csvExport} loading={exportsLoading["csv"]} icon={<FileTextOutlined/>}>
                {t("csv_export")}
            </Button>
            <Button onClick={pdfExport} loading={exportsLoading["pdf"]} icon={<FilePdfOutlined/>}>
                {t("pdf_export")}
            </Button>
        </div>);
    }, [xlsxExport, csvExport, setElement, t, pdfExport, exportsLoading]);

    return <div style={{display: "flex", flexDirection: "row", gap: 10, background: "white"}}>
        <div style={{minWidth: 300, padding: 10}}>
            <RenderAntdStandaloneForm loading={false}
                                      title={"search"}
                                      baseFormData={dto}
                                      formSettings={{inline: true, orientation: FormOrientation.VERTICAL}}
                                      submission={{
                                          title: "search",
                                          onSubmit: (values) => {
                                              setDto({
                                                  ...values,
                                                  startDate: values.startDate || baseStartDate,
                                                  endDate: values.endDate || baseEndDate
                                              });
                                          },
                                      }} formDefinition={searchFormDefinition}/>
        </div>
        <Calendar
            style={{paddingRight: 10}}
            value={selectedDate}
            onPanelChange={onPanelChange}
            mode={selectedMode}
            onSelect={setSelectedDate}
            cellRender={cellRender}
        />
    </div>;
}