import React, {useMemo} from "react";
import {EnumDropdownElement} from "@ib-tehnologije/react-widgets";
import {WorkOrderStatus} from "../graphql-types";
import {reorderEnum} from "../Helpers/reorderEnum";
import {WorkOrderStatusIcon} from "../Components/WorkOrderStatusIcon";

export const useWorkOrderStatusPicker = () => {
    return useMemo(() => ({
        type: new EnumDropdownElement({
            enumData: reorderEnum(WorkOrderStatus, [
                WorkOrderStatus.WithoutAssignment,
                WorkOrderStatus.WaitingForAcceptanceByFieldWorker,
                WorkOrderStatus.AcceptedByFieldWorker,
                WorkOrderStatus.DeclinedByFieldWorker,
                WorkOrderStatus.CompletedByFieldWorker,
                WorkOrderStatus.ConfirmedByManager,
                WorkOrderStatus.ConfirmedByAccounting,
                WorkOrderStatus.SentToErp,
                WorkOrderStatus.SendingToErpFailed
            ]),
            iconFromValue: (value) => {
                return <WorkOrderStatusIcon size={20} status={value}/>;
            }
        })
    }), []);
}