import React, {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {ReportsPageContext} from "../Contexts/ReportsPageContext";
import {useVehiclePicker} from "../Pickers/useVehiclePicker";
import {useCompanyPicker} from "../Pickers/useCompanyPicker";
import {useCompanyLocationPicker} from "../Pickers/useCompanyLocationPicker";
import {useUserPicker} from "../Pickers/useUserPicker";
import {useGetWorkOrdersForReportQuery, WorkOrderFilterDtoInput} from "../graphql-types";
import {PivotSheet} from "@antv/s2";
import {DateElement, FormOrientation, RenderAntdStandaloneForm} from "@ib-tehnologije/react-widgets";
import {Select} from "antd";

export const ReportTableView: FC = () => {
    const {t} = useTranslation();
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [selectedMode, setSelectedMode] = useState<"month" | "year">("month");
    const {setElement} = useContext(ReportsPageContext);
    const baseStartDate = useMemo(() => selectedMode === "month" ? new Date(selectedDate.toDate().getFullYear(), selectedDate.toDate().getMonth(), 1) : new Date(selectedDate.toDate().getFullYear(), 0, 1), [selectedDate, selectedMode]);
    const baseEndDate = useMemo(() => selectedMode === "month" ? new Date(selectedDate.toDate().getFullYear(), selectedDate.toDate().getMonth() + 1, 0) : new Date(selectedDate.toDate().getFullYear(), 11, 31), [selectedDate, selectedMode]);

    useEffect(() => {
        setDto(dto => ({
            ...dto,
            scheduledDateFrom: baseStartDate,
            scheduledDateTo: baseEndDate
        }));
    }, [selectedDate, selectedMode]);

    const vehicleIdPicker = useVehiclePicker();
    const companyIdPicker = useCompanyPicker();
    const companyLocationIdPicker = useCompanyLocationPicker();
    const userIdPicker = useUserPicker();

    const [dto, setDto] = useState<WorkOrderFilterDtoInput>({
        scheduledDateFrom: baseStartDate,
        scheduledDateTo: baseEndDate,
    });
    const {data} = useGetWorkOrdersForReportQuery({
        variables: {
            dto
        }
    });

    const allColumnsPrefix = "reportWorkOrder";

    const [columns, setColumns] = useState<string[]>([]);
    const [rows, setRows] = useState<string[]>([]);
    const [values, setValues] = useState<string[]>([]);

    useEffect(() => {
        const renderOutlet = document.getElementById("render-outlet");

        if (renderOutlet) {
            renderOutlet.innerHTML = "";
            const pivotSheet = new PivotSheet(renderOutlet, {
                data: data?.workOrdersForReport?.map(item => {
                    const ret: { [key: string]: any } = {};
                    for (const key in item) {
                        if (item.hasOwnProperty(key)) {
                            ret[t(allColumnsPrefix + key)] = item[key as keyof typeof item];
                        }
                    }
                    return ret;
                }) ?? [],
                fields: {
                    rows: rows,
                    columns: columns,
                    values: values,
                },
            }, {
                width: 1200,
                height: 600,
            });
            pivotSheet.render();
        }
    }, [data, columns, rows, values, t]);


    const searchFormDefinition = useMemo(() => ({
        scheduledDateFrom: {
            type: new DateElement({})
        },
        scheduledDateTo: {
            type: new DateElement({})
        },
        vehicleId: vehicleIdPicker,
        companyId: companyIdPicker,
        companyLocationId: companyLocationIdPicker,
        assignedUserId: userIdPicker
    }), []);

    const dataColumns = useMemo(() => Object.keys(data?.workOrdersForReport[0] ?? {})
        .filter(k => k !== "__typename")
        .map(k => `${allColumnsPrefix}${k}`), [data]);

    const handleRowsChanged = useCallback((value: string[]) => {
        setRows(value);
    }, []);

    const handleColumnsChanged = useCallback((value: string[]) => {
        setColumns(value);
    }, []);

    const handleValuesChanged = useCallback((value: string[]) => {
        setValues(value);
    }, []);

    return <div style={{display: "flex", flexDirection: "row"}}>
        <div style={{minWidth: 300, padding: 10}}>
            <RenderAntdStandaloneForm loading={false}
                                      title={"search"}
                                      baseFormData={{
                                          scheduledDateFrom: dto.scheduledDateFrom,
                                          scheduledDateTo: dto.scheduledDateTo,
                                      }}
                                      formSettings={{inline: true, orientation: FormOrientation.VERTICAL}}
                                      submission={{
                                          title: "search",
                                          onSubmit: (values) => {
                                              setDto({
                                                  scheduledDateFrom: values.startDate || baseStartDate,
                                                  scheduledDateTo: values.endDate || baseEndDate,
                                                  vehicleId: values.vehicleId,
                                                  companyId: values.companyId,
                                                  companyLocationId: values.companyLocationId,
                                                  assignedUserId: values.assignedUserId
                                              });
                                          },
                                      }} formDefinition={searchFormDefinition}/>
        </div>
        <div style={{flex: 1}}>
            <Select
                mode="multiple"
                allowClear
                style={{width: '100%'}}
                placeholder="Redovi"
                onChange={handleRowsChanged}
                options={dataColumns.map(item => ({label: t(item), value: t(item)}))}
            />
            <Select
                mode="multiple"
                allowClear
                style={{width: '100%'}}
                placeholder="Stupci"
                onChange={handleColumnsChanged}
                options={dataColumns.map(item => ({label: t(item), value: t(item)}))}
            />
            <Select
                mode="multiple"
                allowClear
                style={{width: '100%'}}
                placeholder="Vrijednosti"
                onChange={handleValuesChanged}
                options={dataColumns.map(item => ({label: t(item), value: t(item)}))}
            />

            <div id={"render-outlet"} style={{flex: 1, minWidth: "100%"}}/>
        </div>
    </div>;
}