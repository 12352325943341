import React from 'react';
import {createRoot} from "react-dom/client";
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n';
import {I18nContext, I18nextProvider, useTranslation} from 'react-i18next';
import "./Styles/index.css";
import {ConfigProvider, App as AntdApp} from "antd";
import hrHR from "antd/lib/locale/hr_HR";
import enUS from "antd/lib/locale/en_US";
import deDE from "antd/lib/locale/de_DE";
import itIT from "antd/lib/locale/it_IT";
import loc from "dayjs/locale/hr";
import dayjs from "dayjs";
import {blue, generate} from "@ant-design/colors";
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat'; // Import if you need to parse custom formats
import localizedFormat from 'dayjs/plugin/localizedFormat'; // Import for localized formatting
import utc from 'dayjs/plugin/utc'; // Import the UTC plugin
import weekday from 'dayjs/plugin/weekday';
import {MapConfigurationContext} from "@ib-tehnologije/react-widgets"; // Import the weekday plugin

dayjs.extend(customParseFormat); // Use this if you are parsing custom formats
dayjs.extend(localizedFormat); // Use this for localized formatting
dayjs.extend(utc); // Use the UTC plugin
dayjs.extend(relativeTime);
dayjs.extend(weekday);

dayjs.locale(loc);

export const ncFormat = new Intl.NumberFormat("hr-HR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const nFormat = new Intl.NumberFormat("hr-HR", {
    useGrouping: false
});

export const colors = generate("#50B849");


const MainChildren = () => {
    const {i18n} = useTranslation();

    return <ConfigProvider theme={{
        token: {
            colorPrimary: colors[5],
            // fontFamily: "Scada",
        },
        components: {
            Menu: {
                fontSizeIcon: 30,
                // darkItemBg: colors[9],
                // darkSubMenuItemBg: colors[9],
            },
            Layout: {
                // siderBg: colors[9],
            },
            Form: {
                marginLG: 10,
            },
            Upload: {}
        },
    }} locale={{
        "hr-HR": hrHR,
        "en-US": enUS,
        "de-DE": deDE,
        "it-IT": itIT,
    }[i18n.language ?? "hr-HR"]}>
        <AntdApp style={{height: "100%"}}>
            <App/>
        </AntdApp>
    </ConfigProvider>;
}

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <MainChildren/>
        </I18nextProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
