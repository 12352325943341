import React, {FC, useCallback, useEffect, useState} from "react";
import {
    ApplicationUserFilterDtoInput,
    useDeleteUserMutation,
    useGetUsersQuery,
    useResendConfirmationEmailMutation,
    UserRole
} from "../graphql-types";
import {useUserUpdating} from "../Hooks/useUserUpdating";
import {useNavigate} from "react-router-dom";
import {useUserCreating} from "../Hooks/useUserCreating";
import {
    EnumDropdownElement,
    FormOrientation,
    RenderAntdGraphQLList,
    RenderAntdStandaloneForm, TextFieldElement,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {t} from "i18next";
import {useCompanyPicker} from "../Pickers/useCompanyPicker";
import {Button, theme, Typography} from "antd";
import {
    CheckmarkCircle20Regular,
    CheckmarkCircleRegular,
    ErrorCircle20Regular, ErrorCircleFilled,
    ErrorCircleRegular
} from "@fluentui/react-icons";
import {RightOutlined, SearchOutlined} from "@ant-design/icons";
import {useUserProfileUpdating} from "../Hooks/useUserProfileUpdating";

interface IUsersPageComponentProps {
    role?: UserRole;
}

export const UsersPageComponent: FC<IUsersPageComponentProps> = ({role}) => {
    const [deleteUser] = useDeleteUserMutation();
    const [resendConfirmationEmail] = useResendConfirmationEmailMutation();
    const updatingUserConfig = useUserUpdating();
    const navigate = useNavigate();
    const createUserConfig = useUserCreating(role);
    const [isOpen, setIsOpen] = useState(true);
    const [filter, setFilter] = useState<ApplicationUserFilterDtoInput & { search?: string }>(role != undefined ? {
        role: role
    } : {});
    const q = useGetUsersQuery({
        variables: {
            filters: filter ? {
                role: filter.role,
                companyId: filter.companyId
            } : {},
            ...(filter.search ? {
                where: {
                    or: [{
                        userName: {
                            contains: filter.search
                        }
                    }, {
                        email: {
                            contains: filter.search
                        }
                    }, {
                        firstName: {
                            contains: filter.search
                        }
                    }, {
                        lastName: {
                            contains: filter.search
                        }
                    }, {
                        phoneNumber: {
                            contains: filter.search
                        }
                    }, {
                        externalId: {
                            contains: filter.search
                        }
                    }]
                }
            } : {})
        }
    });
    const {
        token: {
            colorError,
            colorSuccess
        }
    } = theme.useToken();
    const companyIdPicker = useCompanyPicker();
    const updateFilter = useCallback((newFilter: Partial<ApplicationUserFilterDtoInput & { search?: string }>) => {
        setFilter({
            ...filter,
            ...newFilter
        });
    }, [filter]);

    useEffect(() => {
        q.refetch({
            filters: filter ? {
                role: filter.role,
                companyId: filter.companyId
            } : {},
            ...(filter.search ? {
                where: {
                    or: [{
                        userName: {
                            contains: filter.search
                        }
                    }, {
                        email: {
                            contains: filter.search
                        }
                    }, {
                        firstName: {
                            contains: filter.search
                        }
                    }, {
                        lastName: {
                            contains: filter.search
                        }
                    }, {
                        phoneNumber: {
                            contains: filter.search
                        }
                    }, {
                        externalId: {
                            contains: filter.search
                        }
                    }]
                }
            } : {})
        });
    }, [filter]);

    const openUserPage = useCallback((id: string) => {
        navigate(`/Manager/User/${id}`);
    }, [navigate]);

    return <TitleAndContentLayout title={t(role !== undefined ? `enums:${role.toString()}_plural` : "users")}
                                  contentStyle={{display: "flex", height: "100%", width: "100%"}} pad>
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                width: "100%"
            }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: 250,
            }}>
                <RenderAntdStandaloneForm
                    formSettings={{inline: true, orientation: FormOrientation.VERTICAL}}
                    loading={q.loading}
                    baseFormData={filter}
                    formDefinition={{
                        search: {
                            type: new TextFieldElement({})
                        },
                        role: {
                            type: new EnumDropdownElement({enumData: UserRole})
                        },
                        companyId: companyIdPicker
                    }}
                    submission={{
                        title: "start-search",
                        onSubmit: (values) => {
                            updateFilter({
                                role: values.role,
                                companyId: values.companyId,
                                search: values.search
                            });
                        }
                    }}
                />
            </div>
            <div style={{flex: 1, width: "100%"}}>
                <RenderAntdGraphQLList
                    key={role}
                    tableData={q.data?.users}
                    columns={[{
                        columnId: "user",
                        renderCell: (row) => <div style={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <Typography.Text>{row.firstName} {row.lastName}</Typography.Text>
                            <Typography.Text type={"secondary"}>{row.userName}</Typography.Text>
                        </div>,
                    }, {
                        columnId: "emailAndPhone",
                        renderCell: (row) => <div style={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <a href={`mailto:${row.email}`}>{row.email ?? " - "}</a>
                            <a href={`tel:${row.phoneNumber}`}>{row.phoneNumber ?? " - "}</a>
                        </div>,
                    }, {
                        columnId: "emailConfirmed",
                        renderCell: (row) => row.emailConfirmed ?
                            <CheckmarkCircleRegular fontSize={30} color={colorSuccess}/> :
                            <ErrorCircleFilled fontSize={30} color={colorError}/>,
                    }, {
                        columnId: "company",
                        renderCell: (row) => <span>{row.company?.name}</span>,
                    }, {
                        columnId: "actions",
                        renderCell: (row) => <Button
                            type={"primary"}
                            onClick={(event) => openUserPage(row.id)}>
                            {t("details")}
                            <RightOutlined/>
                        </Button>,
                    }]}
                    creating={createUserConfig}
                    deleting={{
                        modelKey: "model",
                        fn: deleteUser,
                        selectionToDto: (selection) => ({
                            username: selection.userName!,
                        })
                    }}
                    query={q}
                    loading={q.loading}
                    getRowId={item => item.id}
                    updating={updatingUserConfig}
                />
            </div>
        </div>
    </TitleAndContentLayout>;
}