import React, {FC, useCallback, useEffect} from "react";
import {useAddUserRoleMutation, useGetUserRolesQuery, useRemoveUserRoleMutation, UserRole} from "../graphql-types";
import {AlertApiError} from "@ib-tehnologije/react-widgets";
import {useTranslation} from "react-i18next";
import {Checkbox} from "antd";
import {CheckboxChangeEvent} from "antd/es/checkbox";

interface IUserRolesProps {
    userId: string;
    onRolesChanged?: (roles: UserRole[]) => void;
}

export const UserRoles: FC<IUserRolesProps> = ({userId, onRolesChanged}) => {
    const {t} = useTranslation();
    const [addUserRole] = useAddUserRoleMutation();
    const [removeUserRole] = useRemoveUserRoleMutation();
    const [loading, setLoading] = React.useState(false);
    const q = useGetUserRolesQuery({
        variables: {
            id: userId
        }
    });

    useEffect(() => {
        onRolesChanged?.(q.data?.userRoles ?? []);
    }, [q.data?.userRoles]);

    const hasRole = useCallback((role: UserRole): boolean => {
        return q.data?.userRoles?.some(f => f == role) ?? false;
    }, [q.data?.userRoles]);

    const toggleRole = useCallback((role: UserRole) => {
        return async (e: CheckboxChangeEvent) => {
            setLoading(true);
            if (e.target.checked) {
                addUserRole({
                    variables: {
                        roleId: role,
                        id: userId,
                    },
                }).then(() => q.refetch()).catch(AlertApiError).finally(() => setLoading(false));
            } else {
                removeUserRole({
                    variables: {
                        roleId: role,
                        id: userId,
                    }
                }).then(() => q.refetch()).catch(AlertApiError).finally(() => setLoading(false));
            }
        }
    }, []);

    return <div style={{display: "flex", flexDirection: "column"}}>
        <h3>{t("roles")}</h3>
        {Object.values(UserRole).map(role => <Checkbox
            key={role}
            disabled={q.loading || loading}
            name={role}
            title={t(role)}
            onChange={toggleRole(role)}
            checked={hasRole(role as UserRole)}>
            {t(role)}
        </Checkbox>)}
    </div>
}