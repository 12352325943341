// AuthContext.tsx
import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {useLocalStorage} from "@rehooks/local-storage";
import {useRefreshTokenMutation, UserRole} from "../graphql-types";
import i18next from "i18next";
import i18n from "../i18n";
import {getI18n, I18nContext} from "react-i18next";

export type ParsedAuthToken = {
    exp: number;
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    sub: string;
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": string | string[];
    "UserId": string;
    "UserName": string;
    "PreferredLanguage": string;
}

export type AuthState = {
    token?: string;
    parsedToken?: ParsedAuthToken;
    roles: UserRole[];
    userName?: string;
    userId?: string;
    preferredLanguage?: string;
};

const initialState: AuthState = {
    token: undefined,
    roles: [],
};

export const AuthContext = createContext<{
    auth: AuthState;
    setToken: (token: string) => void;
    signOut: () => void;
    refreshAndSetToken: () => void;
}>({
    auth: initialState,
    setToken: () => {
    },
    signOut: () => {
    },
    refreshAndSetToken: () => {
    }
});

export const AuthContextEntry = ({children}: { children: React.ReactNode }) => {
    const [auth, setAuth] = useLocalStorage<AuthState>("authState", initialState);
    const [refreshToken] = useRefreshTokenMutation();
    const {i18n} = useContext(I18nContext);

    const setToken = useCallback((token: string) => {
        if (token) {
            const parsedToken = JSON.parse(atob(token.split('.')[1])) as ParsedAuthToken;
            const userName = parsedToken?.["UserName"];
            const userId = parsedToken?.["UserId"];
            const preferredLanguage = parsedToken?.["PreferredLanguage"];
            let roleOrRoles = parsedToken?.["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            if (!Array.isArray(roleOrRoles)) {
                roleOrRoles = [roleOrRoles];
            }
            const roles = roleOrRoles
                .map((role: string) => UserRole[role as keyof typeof UserRole])
            setAuth({token, parsedToken, roles, userName, userId, preferredLanguage});
        }
    }, []);

    const signOut = useCallback(() => {
        setAuth(initialState);
    }, []);

    useEffect(() => {
        if (auth?.preferredLanguage) {
            i18n.changeLanguage(auth.preferredLanguage);
        }
    }, [auth.preferredLanguage, i18n]);

    const refreshAndSetToken = useCallback(() => {
        refreshToken().then(response => {
            setToken(response.data?.refreshToken?.token ?? "");
        }).catch(error => {
            console.error(error);
        });
    }, [refreshToken, setToken]);

    useEffect(() => {
        const authStateFromStorage = localStorage.getItem("authState");
        if (authStateFromStorage) {
            const tokenFromStorage = JSON.parse(authStateFromStorage).token;
            if (tokenFromStorage) {
                const parsedToken = JSON.parse(atob(tokenFromStorage.split('.')[1]));
                const issueTime = new Date(parsedToken.iat * 1000);
                const expirationTime = new Date(parsedToken.exp * 1000);

                // Calculate total validity duration in milliseconds
                const totalValidityDuration = expirationTime.getTime() - issueTime.getTime();

                // Calculate the 75% threshold
                const refreshThreshold = new Date(issueTime.getTime() + (totalValidityDuration * 0.75));

                // If current time is beyond the 75% threshold, refresh the token
                if (new Date() > refreshThreshold) {
                    refreshAndSetToken();
                }else if (new Date() > expirationTime) {
                    signOut();
                }
            }
        }
    }, [refreshAndSetToken]);

    return <AuthContext.Provider value={{auth, setToken, signOut, refreshAndSetToken}}>
        {children}
    </AuthContext.Provider>;
}