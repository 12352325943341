export const hr = {
    "login": "Prijava",
    "register": "Registracija",
    "username": "Korisničko ime",
    "password": "Lozinka",
    "sign-up": "Registriraj se",
    "email": "Email",
    "go-back": "Nazad",
    "EmailConfirmed_PleaseWait": "Email je potvrđen. Molimo pričekajte...",
    "EmailConfirmationErrorPage_Title": "Greška kod potvrde emaila",
    "EmailConfirmationErrorPage_Text": "Došlo je do greške kod potvrde emaila. Molimo pokušajte ponovno.",
    "EmailConfirmed_Success": "Email je uspješno potvrđen.",
    "The Username field is required.": "Korisničko ime je obavezno.",
    "The Email field is required.": "Email je obavezan.",
    "The Password field is required.": "Lozinka je obavezna.",
    "EmailConfirmationErrorPage_BackButton": "Nazad",
    "redirector.unknownRoleForUsingApp": "Nemate još dodjeljen pristup aplikaciji.",
    "NotFoundPage_Title": "Stranica nije pronađena",
    "NotFoundPage_BackButton": "Nazad",
    "NotFoundPage_Text": "Stranica koju tražite nije pronađena.",
    "ConfirmYourEmail": "Na vaš email je poslan link za potvrdu. Molimo potvrdite svoj email.",
    "User email not confirmed": "Email nije potvrđen",
    "EmailNotConfirmed": "Email nije potvrđen",
    "ResendConfirmationEmail": "Ponovno pošalji link za potvrdu",
    "ResendConfirmationEmail_Success": "Link za potvrdu je uspješno poslan.",
    "PasswordTooShort": "Lozinka je prekratka.",
    "User not found": "Korisnik nije pronađen.",
    "back": "Nazad",
    "home": "Početna",
    "logout": "Odjava",
    "work-orders": "Radni nalozi",
    "work-order": "Radni nalog",
    "work-orders-description": "Pregled radnih naloga",
    "users": "Korisnici",
    "users-description": "Pregled korisnika",
    "companies": "Partneri",
    "companies-description": "Pregled partnera",
    "administration": "Administracija",
    "error-loading-items": "Greška kod učitavanja stavki",
    "retry": "Pokušaj ponovno",
    "search": "Pretraživanje",
    "Previous": "Prethodna",
    "Next": "Sljedeća",
    "create": "Dodaj",
    "cancel": "Odustani",
    "save": "Spremi",
    "id": "Ključ",
    "emailConfirmed": "Email potvrđen",
    "are-you-sure-you-want-to-delete-this-item": "Jeste li sigurni da želite obrisati ovu stavku?",
    "delete": "Obriši",
    "page": "Strana",
    "InvalidEmail": "Upišite ispravan email",
    "additional": "Dodatno",
    "resendConfirmationEmail": "Ponovno pošalji link za potvrdu emaila",
    "pickup-requests": "Zahtjevi za prikup",
    "no-items": "Nema stavaka",
    "pickup-requests-description": "Pregled zahtjeva za prikup",
    "status": "Status",
    "date": "Datum",
    "roles": "Uloge",
    "Admin": "Administrator",
    "Customer": "Korisnik",
    "company-name": "Naziv partnera",
    "adding-companies": "Dodavanje partnera",
    "taxId": "OIB",
    "actions": "Akcije",
    "locations": "Lokacije",
    "company-locations": "Lokacije partnera",
    "address": "Adresa",
    "adding-companyLocations": "Dodavanje lokacije partnera",
    "company-location-name": "Naziv lokacije",
    "location-name": "Naziv lokacije",
    "geodecode-address": "Potraži adresu na mapi",
    "editing-companyLocations": "Uređivanje lokacije partnera",
    "adding-users": "Dodavanje korisnika",
    "adding-pickupRequests": "Dodavanje zahtjeva za prikup",
    "notification": "Obavijest",
    "successfully-deleted-item": "Uspješno obrisana stavka",
    "successfully-deleted-item-with-id": "Uspješno obrisana stavka sa šifrom",
    "error-deleting-item-with-id": "Greška kod brisanja stavke sa šifrom",
    "successfully-duplicated-item-with-id": "Uspješno duplicirana stavka sa šifrom",
    "error-duplicating-item-with-id": "Greška kod dupliciranja stavke sa šifrom",
    "company": "Partner",
    "company-location": "Lokacija partnera",
    "InvalidUserName": "Upišite ispravno korisničko ime",
    "editing-users": "Uređivanje korisnika",
    "edit": "Uredi",
    "show-only-unassigned": "Prikaži samo nedodijeljene",
    "settings": "Postavke",
    "start-search": "Pokreni pretraživanje",
    "adding-pickupRequestsFiltered": "Dodavanje zahtjeva za prikup",
    "editing-pickupRequestsFiltered": "Uređivanje zahtjeva za prikup",
    "isMainLocation": "Glavna lokacija",
    "assign": "Dodijeli",
    "assigned-user": "Dodijeljeno korisniku",
    "details": "Detalji",
    "user": "Korisnik",
    "user-overview": "Pregled korisnika",
    "MANAGER": "Upravitelj",
    "PARTNER_CONTACT": "Partner kontakt",
    "FIELD_WORKER": "Terenski radnik",
    "user-details": "Detalji korisnika",
    "confirmationEmailSent": "Email za potvrdu je poslan",
    "userName": "Korisničko ime",
    "firstName": "Ime",
    "lastName": "Prezime",
    "system": "Sustav",
    "field-worker": "Terenski radnik",
    "field-workers": "Terenski radnici",
    "field-workers-description": "Pregled terenskih radnika",
    "reports": "Izvještaji",
    "reports-description": "Detaljni izvještaji",
    "partner-contacts": "Partner kontakti",
    "units-of-measure": "Jedinice mjere",
    "vehicles": "Vozila",
    "organizational-entities": "Organizacijske jedinice",
    "work-order-status-changes": "Promjene statusa radnih naloga",
    "work-order-templates": "Predlošci radnih naloga",
    "basic-data": "Osnovni podaci",
    "role": "Uloga",
    "editing-companies": "Uređivanje partnera",
    "adding-partnerPickupRequests": "Dodavanje zahtjeva za prikup",
    "languages-full:hr": "Hrvatski",
    "languages-full:en": "Engleski",
    "languages-full:de": "Njemački",
    "languages-full:it": "Talijanski",
    "items": "Usluge i materijali",
    "cost-locations": "Mjesta troška",
    "execution-categories": "Kategorije izvršenja",
    "duplicate": "Dupliciraj",
    "editing-executionCategories": "Uređivanje kategorije izvršenja",
    "adding-executionCategories": "Dodavanje kategorije izvršenja",
    "name": "Naziv",
    "description": "Opis",
    "unitOfMeasureId": "Jedinica mjere",
    "unitOfMeasure": "Jedinica mjere",
    "unitPrice": "Cijena",
    "unitsContained": "Broj jedinica",
    "nameAndDescription": "Naziv i opis",
    "NoResults": "Nema rezultata",
    "adding-items": "Dodavanje usluga i materijala",
    "editing-items": "Uređivanje usluga i materijala",
    "symbol": "Simbol",
    "adding-unitsOfMeasure": "Dodavanje jedinice mjere",
    "editing-unitsOfMeasure": "Uređivanje jedinice mjere",
    "workOrderId": "Radni nalog",
    "dateTime": "Datum i vrijeme",
    "initiatingUser": "Inicijator",
    "companyLocation": "Lokacija partnera",
    "organizationalEntity": "Organizacijska jedinica",
    "executionCategory": "Kategorija izvršenja",
    "vehicle": "Vozilo",
    "recurrence": "Ponavljanje",
    "deactivated": "Deaktivirano",
    "executionDay": "Dan izvršenja",
    "executionDayOfMonth": "Dan u mjesecu",
    "executionCategoryId": "Kategorija izvršenja",
    "organizationalEntityId": "Organizacijska jedinica",
    "adding-workOrderTemplates": "Dodavanje predloška radnog naloga",
    "editing-workOrderTemplates": "Uređivanje predloška radnog naloga",
    "adding-vehicles": "Dodavanje vozila",
    "editing-vehicles": "Uređivanje vozila",
    "registrationNumber": "Registarska oznaka",
    "costLocation": "Mjesto troška",
    "costLocationId": "Mjesto troška",
    "adding-organizationalEntities": "Dodavanje organizacijske jedinice",
    "editing-organizationalEntities": "Uređivanje organizacijske jedinice",
    "parent": "Nadređena",
    "parentId": "Nadređena",
    "Email already exists": "Email već postoji",
    "unassigned": "Nedodijeljeno",
    "pickup-date": "Datum prikupa",
    "create-work-order": "Kreiraj radni nalog",
    "work-order-from-pickup-request": "Radni nalog iz zahtjeva za prikup",
    "pickup-request-id": "Šifra zahtjeva za prikup",
    "pickup-request-pickup-date": "Traženi datum prikupa",
    "note": "Napomena",
    "reporting-user": "Prijavljeno od",
    "company-details": "Detalji partnera",
    "vehicleId": "Vozilo",
    "work-order-settings": "Postavke radnog naloga",
    "first-page": "Prva stranica",
    "next-page": "Sljedeća stranica",
    "previous-page": "Prethodna stranica",
    "last-page": "Zadnja stranica",
    "pickupDate": "Datum prikupa",
    "drop-pin": "Označi lokaciju",
    "company-tax-id": "OIB partnera",
    "company-location-map-preview": "Pregled lokacije na karti",
    "yes": "Da",
    "no": "Ne",
    "warning": "Upozorenje",
    "managerNote": "Napomena upravitelja",
    "company-location-change": "Promjena lokacije partnera",
    "phoneNumber": "Broj telefona",
    "preferredLanguage": "Preferirani jezik",
    "company-and-location": "Partner i lokacija",
    "work-order-overview": "Pregled radnog naloga",
    "itemId": "Usluga ili materijal",
    "quantity": "Količina",
    "adding-workOrders": "Dodavanje radnog naloga",
    "item": "Usluga ili materijal",
    "add-row": "Dodaj redak",
    "header": "Zaglavlje",
    "create-work-order-template": "Kreiraj predložak radnog naloga",
    "known-location": "Poznata lokacija",
    "unknown-location": "Nepoznata lokacija",
    "every_week_at": "Svaki tjedan u",
    "freeformLocation": "Proizvoljna lokacija",
    "basics": "Osnovno",
    "executionFrequency": "Učestalost izvršenja",
    "companyAndLocation": "Partner i lokacija",
    "executionResources": "Sredstva izvršenja",
    "executionResource": "Sredstvo izvršenja",
    "itemsTitle": "Usluge i materijali",
    "every_month": "Svaki mjesec",
    "th-extension": "og",
    "every": "Svaki",
    "genitive_every": "Svakog",
    "day_of_month": "dana u mjesecu",
    "priority": "Prioritet",
    "Company location is required when freeform location is empty": "Lokacija partnera je obavezna kada je proizvoljna lokacija prazna",
    "Either Company location or freeform location is required, but not both": "Lokacija partnera ili proizvoljna lokacija su obavezne, ali ne i obje",
    "Execution day or execution day of month is required": "Dan izvršenja ili dan u mjesecu su obavezni",
    "Company or Location not found, or not associated with company.": "Partner ili lokacija nisu pronađeni, ili nisu povezani.",
    "every_monday": "Svaki ponedjeljak",
    "every_tuesday": "Svaki utorak",
    "every_wednesday": "Svaku srijedu",
    "every_thursday": "Svakog četvrtka",
    "every_friday": "Svakog petka",
    "every_saturday": "Svake subote",
    "every_sunday": "Svake nedjelje",
    "updating-work-order-template": "Ažuriranje predloška radnog naloga",
    "Company location or freeform location is required": "Lokacija partnera ili proizvoljna lokacija su obavezne",
    "freeformCompany": "Proizvoljni partner",
    "Either Company or freeform company is required": "Partner ili proizvoljni partner su obavezni",
    "scheduledDate": "Zakazani datum",
    "Freeform location is required when company is empty": "Proizvoljna lokacija je obavezna kada je partner prazan",
    "Freeform company is required when company is empty": "Proizvoljni partner je obavezan kada je partner prazan",
    "locationNote": "Napomena lokacije",
    "serviceNote": "Napomena usluge",
    "ACCOUNTING": "Računovodstvo",
    "BackGoToLogin": "Nazad na prijavu",
    "close": "Zatvori",
    "companyName": "Naziv partnera",
    "companyLocationName": "Naziv lokacije partnera",
    "workOrderCurrentStatusStatus": "Status radnog naloga",
    "notes": "Napomene",
    "noteForAccounting": "Napomena za računovodstvo",
    "organizational-entities-structure": "Struktura organizacijskih jedinica",
    "files": "Datoteke",
    "filesTitle": "Datoteke",
    "file-selection": "Odabir datoteke",
    "pickupRequestId": "Zahtjev za prikup",
    "pickupLocationNote": "Napomena lokacije prikupa",
    "pickupRequestServiceNote": "Napomena usluge prikupa",
    "fillFromPickupRequest": "Popuni iz zahtjeva za prikup",
    "fillFromPickupRequestContent": "Želite li popuniti radni nalog iz zahtjeva za prikup?",
    "work-order-updated": "Radni nalog je ažuriran",
    "createdAt": "Datum i vrijeme kreiranja",
    "customActionsTitle": "Prilagođene akcije",
    "Cannot set status to a lower value than the current one!": "Nije moguće postaviti status na nižu vrijednost od trenutne!",
    "Only accountants can set status to this value!": "Samo računovodstvo može postaviti status na ovu vrijednost!",
    "Only managers can set status to this value!": "Samo upravitelji mogu postaviti status na ovu vrijednost!",
    "Only managers or field workers can set status to this value!": "Samo upravitelji ili terenski radnici mogu postaviti status na ovu vrijednost!",
    "Work order must be assigned to a vehicle or organizational entity to set status to this value!": "Radni nalog mora biti dodijeljen vozilu ili organizacijskoj jedinici da bi se postavio status na ovu vrijednost!",
    "Only field workers can set status to this value!": "Samo terenski radnici mogu postaviti status na ovu vrijednost!",
    "work-order-items": "Usluge i materijali radnog naloga",
    "adding-workOrderTemplateItems": "Dodavanje usluga i materijala predloška radnog naloga",
    "editing-workOrderTemplateItems": "Uređivanje usluga i materijala predloška radnog naloga",
    "adding-workOrderItems": "Dodavanje usluga i materijala radnog naloga",
    "editing-workOrderItems": "Uređivanje usluga i materijala radnog naloga",
    "form.uploadFailed": "Greška",
    "form.uploadFailedDescription": "Postoje datoteke koje nisu uspješno prenesene. Molimo provjerite.",
    "form.uploading": "Upozorenje",
    "form.uploadingDescription": "Prijenos datoteka je u tijeku. Molimo pričekajte.",
    "cancel-work-order": "Otkaži radni nalog",
    "are-you-sure-you-want-to-cancel-this-work-order": "Jeste li sigurni da želite otkazati ovaj radni nalog?",
    "create-pdf": "Kreiraj PDF",
    "error-occurred": "Došlo je do greške",
    "reload": "Ponovno učitaj",
    "Cannot cancel work order that has been sent to ERP!": "Nije moguće otkazati radni nalog koji je poslan u ERP!",
    "detailed-work-order-status-info": "Detalji promjene statusa",
    "consent-details": "Detalji suglasnosti",
    "isEmailConsentGiven": "Email suglasnost",
    "emailConsentSource": "Izvor suglasnosti",
    "emailConsentGivenOn": "Suglasnost dana",
    "consent-not-given": "Suglasnost nije dana",
    "user-updated-successfully": "Korisnik je uspješno ažuriran",
    "consent-updated-successfully": "Suglasnost je uspješno ažurirana",
    "profile-updated": "Profil je ažuriran",
    "userRole": "Uloga korisnika",
    "CompanyLocationId or LocationNote is required": "Lokacija partnera ili napomena lokacije su obavezni",
    "location": "Lokacija",
    "requested-service": "Tražena usluga",
    "requested-by": "Traženo od",
    "view-work-order": "Pregled radnog naloga",
    "assignedUserId": "Dodijeljeno korisniku",
    "scheduledDateFrom": "Zakazani datum od",
    "scheduledDateTo": "Zakazani datum do",
    "no-linked-work-order": "Bez radnog naloga",
    "accountants": "Računovođe",
    "accounting": "Računovodstvo",
    "work_order_count_short": "Broj naloga",
    "work_order_count": "Broj radnih naloga",
    "startDate": "Početni datum",
    "endDate": "Završni datum",
    "assigneeId": "Izvoditelj",
    "count": "Broj",
    "xlsx_export": "Izvoz u Excel",
    "csv_export": "Izvoz u CSV",
    "pdf_export": "Izvoz u PDF",
    "current-page": "Stranica",
    "page-of": "od",
    "work_order_count_per_day": "Broj radnih naloga po danu",
    "reportWorkOrderid": "Ključ radnog naloga",
    "reportWorkOrderpickupRequestId": "Šifra zahtjeva za prikup",
    "reportWorkOrderpickupRequestPickupDate": "Traženi datum prikupa",
    "reportWorkOrderpickupRequestReportingUserUserName": "Prijavljeno od",
    "reportWorkOrderpickupRequestCompanyName": "Naziv partnera",
    "reportWorkOrderpickupRequestCompanyTaxId": "OIB partnera",
    "reportWorkOrderpickupRequestCompanyLocationName": "Naziv lokacije partnera",
    "reportWorkOrderpickupRequestCompanyLocationAddress": "Adresa lokacije partnera",
    "reportWorkOrderscheduledDate": "Zakazani datum",
    "reportWorkOrderstartDateTime": "Početno vrijeme",
    "reportWorkOrderendDateTime": "Završno vrijeme",
    "reportWorkOrderduration": "Trajanje",
    "reportWorkOrderassignedByManagerUserUserName": "Dodijeljeno od upravitelja",
    "reportWorkOrderassignedUserUserName": "Dodijeljeno korisniku",
    "reportWorkOrdervehicleName": "Naziv vozila",
    "reportWorkOrdervehicleRegistrationNumber": "Registarska oznaka vozila",
    "reportWorkOrdervehicleCostLocationName": "Mjesto troška vozila",
    "reportWorkOrdervehicleCostLocationCode": "Kod mjesta troška vozila",
    "reportWorkOrderorganizationalEntityName": "Naziv organizacijske jedinice",
    "reportWorkOrderexecutionCategoryName": "Naziv kategorije izvršenja",
    "reportWorkOrdercurrentStatusStatus": "Status radnog naloga",
    "reportWorkOrderitemsTotal": "Ukupno usluga i materijala",
    "currentVehicleId": "Vozilo",
    "currentOrganizationalEntityId": "Organizacijska jedinica",
    "Cannot reopen a completed work order!": "Nije moguće ponovno otvoriti završeni radni nalog!",
    "open-pdf": "Otvori PDF",
    "no-pdf-available": "Nema dostupnog PDF-a",
    "send-pdf": "Pošalji PDF",
    "send-pdf-to": "Pošalji PDF na",
    "contactId": "Kontakt",
    "directToEmail": "Izravno na email",
    "ContactId or directToEmail must be provided": "Kontakt ili email moraju biti navedeni",
    "pdf-sent": "PDF je poslan",
    "User has not given consent to use email.": "Korisnik nije dao suglasnost za korištenje emaila.",
    "value": "Vrijednost",
    "Login": "Prijava",
    "generate-work-orders": "Generiraj radne naloge",
    "Work orders generated successfully": "Radni nalozi uspješno generirani",
    "partner-portal-notes": "Napomene partner portala",

    "adding-partnerPortalNotes": "Dodavanje napomene partner portala",
    "editing-partnerPortalNotes": "Uređivanje napomene partner portala",
    "Work order must be assigned to a vehicle to set status to this value!": "Radni nalog mora biti dodijeljen vozilu da bi se postavio status na ovu vrijednost!",
    "dactivated": "Deaktivirano",
    "active": "Aktivno",
    "dateFrom": "Datum od",
    "dateTo": "Datum do",
    "currentDriverId": "Trenutni vozač",
    "currentDriver": "Trenutni vozač",
    "itemType": "Vrsta",
    "rentalThresholdDays": "Prag za najam (dani)",
    "rentalServiceItemId": "Usluga najma",

    "enums:FieldWorker": "Terenski radnik",
    "enums:PartnerContact": "Partner kontakt",
    "enums:Manager": "Upravitelj",
    "enums:WAITING_FOR_ACCEPTANCE": "Čeka prihvaćanje",
    "enums:ACCEPTED": "Prihvaćeno",
    "enums:DECLINED": "Odbijeno",
    "enums:COMPLETED": "Odrađeno",
    "enums:CANCELLED": "Otkazano",
    "enums:INVOICED": "Fakturirano",
    "enums:FRIDAY": "Petak",
    "enums:MONDAY": "Ponedjeljak",
    "enums:SATURDAY": "Subota",
    "enums:SUNDAY": "Nedjelja",
    "enums:THURSDAY": "Četvrtak",
    "enums:TUESDAY": "Utorak",
    "enums:WEDNESDAY": "Srijeda",
    "enums:High": "Visoko",
    "enums:Low": "Nisko",
    "enums:Medium": "Srednje",
    "enums:WAITING_FOR_ACCEPTANCE_BY_FIELD_WORKER": "Čeka prihvaćanje",
    "enums:ACCEPTED_BY_FIELD_WORKER": "Prihvaćeno",
    "enums:DECLINED_BY_FIELD_WORKER": "Odbijeno",
    "enums:COMPLETED_BY_FIELD_WORKER": "Odrađeno",
    "enums:CONFIRMED_BY_MANAGER": "Potvrđeno od upravitelja",
    "enums:WITHOUT_ASSIGNMENT": "Nije dodijeljeno",
    "enums:CONFIRMED_BY_ACCOUNTING": "Potvrđeno od računovodstva",
    "enums:SENT_TO_ERP": "Poslano u ERP",
    "enums:SENDING_TO_ERP_FAILED": "Greška kod slanja u ERP",
    "enums:Accounting": "Računovodstvo",
    "enums:AcceptedByFieldWorker": "Prihvaćeno",
    "enums:DeclinedByFieldWorker": "Odbijeno",
    "enums:CompletedByFieldWorker": "Odrađeno",
    "enums:Cancelled": "Otkazano",
    "enums:ConfirmedByManager": "Potvrđeno od upravitelja",
    "enums:ConfirmedByAccounting": "Potvrđeno od računovodstva",
    "enums:SentToErp": "Poslano u ERP",
    "enums:SendingToErpFailed": "Greška kod slanja u ERP",
    "enums:WaitingForAcceptanceByFieldWorker": "Čeka prihvaćanje od terenskog radnika",
    "enums:WithoutAssignment": "Nije dodijeljeno",
    "enums:reportViews.total_work_order_count": "Ukupan broj radnih naloga",
    "enums:reportViews.total_work_order_count_calendar": "Ukupan broj radnih naloga: kalendar",
    "enums:FIELD_WORKER_plural": "Terenski radnici",
    "enums:PARTNER_CONTACT_plural": "Partner kontakti",
    "enums:MANAGER_plural": "Upravitelji",
    "enums:ACCOUNTING_plural": "Računovodstvo",
    "enums:PAUSED_FOR_CONTAINER_PICKUP": "Čeka prikup",
    "enums:PAUSED_UNTIL_CONTAINER_PICKUP": "Čeka prikup",
    "enums:RESUMED_CONTAINER_PICKUP": "Nastavljen prikup",
    "enums:Container": "Kontejner",
    "enums:Material": "Materijal",
    "enums:Service": "Usluga",
    "enums:CONTAINER": "Kontejner",
    "enums:MATERIAL": "Materijal",
    "enums:SERVICE": "Usluga",
    "enums:STARTED_BY_FIELD_WORKER": "Započeto",
    "PasswordRequiresNonAlphanumeric": "Lozinka mora sadržavati barem jedan znak koji nije slovo ili broj",
    "enums:OFFLINE_INITIATED_AWAITING_REVIEW": "Proizvoljni radni nalog",
    "enums:reportViews.total_work_order_count_grid": "Ukupan broj radnih naloga: pivot",
    "workOrderNotSigned": "Nije potpisan",
    "signed": "Potpis",
    "no-signed-pdf": "Nema potpisanog PDF-a",
    "scheduledAndFinishedDate": "Zakazano\nIzvršeno",
    "vehicleOrganizationalEntityAndExecutionCategory": "Vozilo, organizacijska jedinica i kategorija izvršenja",
    "change-password": "Promjena lozinke",
    "oldPassword": "Stara lozinka",
    "newPassword": "Nova lozinka",
    "password-changed": "Lozinka je promijenjena",
    "Signed PDFs not found!": "Potpisani PDF-ovi nisu pronađeni!",
    "Some work orders do not have signed PDFs!": "Neki radni nalozi nemaju potpisane PDF-ove!",
    "emailAndPhone": "Email i telefon",
    "managers": "Upravitelji",
    "costLocations": "Mjesta troška",
    "editing-costLocations": "Uređivanje mjesta troška",
    "adding-costLocations": "Dodavanje mjesta troška",
    "code": "Šifra",
    "password-reset": "Reset lozinke",
    "password-reset-successful": "Lozinka je uspješno resetirana",
    "Cannot delete pickup request with associated work order.": "Nije moguće obrisati zahtjev za prikup s povezanim radnim nalogom.",
    "Cannot update pickup request with associated work order." : "Nije moguće ažurirati zahtjev za prikup s povezanim radnim nalogom.",
    "editing-partnerPickupRequests": "Uređivanje zahtjeva za prikup",
    "requestedService": "Tražena usluga",
    "service-note": "Napomena usluge",
    "pickupServiceNote": "Napomena usluge prikupa",
    "No results": "Nema rezultata",
    "Only Container type can have RentalServiceItemId and RentalThresholdDays": "Samo kontejner može imati najam i prag za najam",
    "Pickup Date must not be on a Sunday.": "Datum prikupa ne smije biti nedjelja.",
    "pdf-created": "PDF je kreiran",
    "open-signed-pdf": "Otvori potpisani PDF",
    "send-signed-pdf": "Pošalji potpisani PDF",
    "pdf": "PDF",
    "sendingSignedPDF": "Slanje potpisanog PDF-a",
    "signed-pdf": "Potpisani PDF",
    "unsigned-pdf": "Nepotpisani PDF",
    "unsigned-pdf-list": "Nepotpisani PDF-ovi",
    "are-you-sure-you-want-to-delete-this-pdf": "Jeste li sigurni da želite obrisati ovaj PDF?",
    "pdf-deleted": "PDF je obrisan",
    "send-to": "Pošalji na",
    "open": "Otvori",
    "not_reviewed": "Nije pregledano",
    "vat_base": "Osnovica PDV-a",
    "vat": "PDV",
    "total": "Ukupno",
    "hasSignedPDF": "Ima potpisani PDF",
    "files-that-will-be-attached-to-the-pdf": "Datoteke koje će biti priložene PDF-u",
    "attachments": "Prilozi",
    "fileName": "Naziv datoteke",
    "fileType": "Vrsta datoteke",
    "contentType": "Vrsta sadržaja",
    "includeInPDF": "Uključi u PDF",
    "enums:FILE_TYPE_GENERIC": "Ostalo",
    "enums:FILE_TYPE_SIGNED_WORK_ORDER": "Potpisani radni nalog",
    "preview": "Pregled",
    "Download": "Preuzmi",
    "workOrderHasASignature": "Radni nalog ima potpis",
    "fieldWorkerNote": "Napomena terenskog radnika",
    "vat-types": "Vrste PDV-a",
    "financial-accounts": "Konta",
    "adding-vatTypes": "Dodavanje vrste PDV-a",
    "editing-vatTypes": "Uređivanje vrste PDV-a",
    "rate": "Stopa",
    "adding-financialAccounts": "Dodavanje konta",
    "editing-financialAccounts": "Uređivanje konta",
    "number": "Broj",
    "externalId": "Vanjski ID",
    "vatTypeId": "Vrsta PDV-a",
    "vatType": "Vrsta PDV-a",
    "financialAccountId": "Konto",
    "financialAccount": "Konto",
    "isDeleted": "Obrisano",
    "restore": "Vrati",
    "work-order-restored": "Radni nalog je vraćen",
    "no-data": "Nema podataka",
    "currentStatusId": "Status",
    "companyId": "Partner",
    "currentStatusStatus": "Status",
    "price": "Cijena",
    "workOrderItems": "Stavke",
    "isDefault": "Zadano",
    "price/vatBase": "Cijena / osnovica",
    "xls_export": "Izvoz u Excel",
    "mark-as-invoiced": "Označi kao fakturirano",
    "Work order is already in this status!": "Radni nalog je već u ovom statusu!",
    "work-orders-marked-as-invoiced": "Radni nalozi su označeni kao fakturirani",
    "item-order-restored": "Usluga ili materijal je vraćen",
    "Item not found": "Usluga ili materijal nije pronađen",
    "Item is not deleted": "Usluga ili materijal nije obrisana",
    "work-order-duplicated": "Radni nalog je dupliciran",
    "vatRate/vat": "Stopa PDV / PDV",
    "confirmManually": "Potvrdi ručno",
    "Some work orders do not have a saved PDF! {{WorkOrderIds}}": "Neki radni nalozi nemaju spremljen PDF! {{WorkOrderIds}}",
    "itemUnitPrice": "Cijena",
    "prints": "Ispisi",
    "pdf_export_calculation": "Izvoz u obračun",
    "calculation-pdf-settings": "Postavke obračuna",
    "create-calculation-pdf": "Kreiraj PDF obračuna",
    "calculationDate": "Datum obračuna",
    "The current user is not authorized to access this resource.": "Trenutni korisnik nije ovlašten za pristup ovom resursu.",
};