import {FC, PropsWithChildren, useState} from "react";
import {ILoginFormContextState, LoginFormProvider} from "@ib-tehnologije/react-widgets";

export const LoginFormContextProvider: FC<PropsWithChildren> = ({children}) => {
    const [state, setState] = useState<ILoginFormContextState>({
        username: "",
        password: "",
        loading: false
    });
    
    return (
        <LoginFormProvider value={{state, setState}}>
            {children}
        </LoginFormProvider>
    );
}