import React, {FC, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {
    CreatePartnerPortalNoteMutation, DeletePartnerPortalNoteMutation, PartnerPortalNoteCreateDtoInput, PartnerPortalNoteDeleteDtoInput,
    PartnerPortalNoteUpdateDtoInput,
    UpdatePartnerPortalNoteMutation,
    useCreatePartnerPortalNoteMutation, useDeletePartnerPortalNoteMutation,
    useGetPartnerPortalNotesQuery,
    useUpdatePartnerPortalNoteMutation
} from "../graphql-types";
import {
    ITenantCreating,
    ITenantDeleting,
    ITenantUpdating, MultilineTextElement, RenderAntdGraphQLList, RichTextElement, TextFieldElement,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {ActualPartnerPortalNoteReturnDto} from "../ReturnTypes/ActualPartnerPortalNoteReturnDto";

const ManagerPartnerPortalNotesPage: FC = () => {
    const {t} = useTranslation();
    const q = useGetPartnerPortalNotesQuery();
    const [updatePartnerPortalNote] = useUpdatePartnerPortalNoteMutation();
    const [createPartnerPortalNote] = useCreatePartnerPortalNoteMutation();
    const [deletePartnerPortalNote] = useDeletePartnerPortalNoteMutation();
    
    const creating = useMemo<ITenantCreating<CreatePartnerPortalNoteMutation, ActualPartnerPortalNoteReturnDto, PartnerPortalNoteCreateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            note: {
                type: new MultilineTextElement({rows: 10})
            },
        },
        selectionToDuplicateDto: (selection) => ({
            note: selection?.note
        }),
        formDataToDto: (formData) => ({
            note: formData.note
        }),
        baseFormData: {},
        fn: createPartnerPortalNote
    }), []);
    
    const updating = useMemo<ITenantUpdating<UpdatePartnerPortalNoteMutation, ActualPartnerPortalNoteReturnDto, PartnerPortalNoteUpdateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            note: {
                type: new RichTextElement({})
            },
        },
        selectionToBaseFormData: (selection) => ({
            id: selection?.id,
            note: selection?.note
        }),
        formDataToDto: (formData) => ({
            id: formData.id,
            note: formData.note
        }),
        fn: updatePartnerPortalNote
    }), []);
    
    const deleting = useMemo<ITenantDeleting<DeletePartnerPortalNoteMutation, ActualPartnerPortalNoteReturnDto, PartnerPortalNoteDeleteDtoInput, "data">>(() => ({
        modelKey: "data",
        selectionToDto: (selection) => ({
            id: selection?.id,
        }),
        fn: deletePartnerPortalNote
    }), []);

    return <TitleAndContentLayout title={"partner-portal-notes"} pad>
        <RenderAntdGraphQLList
            deleting={deleting}
            tableData={q.data?.partnerPortalNotes}
            columns={[{
                columnId: "id",
                renderCell: (row) => row.id,
            }, {
                columnId: "note",
                renderCell: (row) => <div dangerouslySetInnerHTML={{__html: row.note ?? ""}}/>,
            }]}
            query={q}
            loading={q.loading}
            creating={creating}
            getRowId={g => g.id}
            updating={updating}
        />
    </TitleAndContentLayout>
}

export default ManagerPartnerPortalNotesPage;