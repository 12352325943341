// Redirector.tsx
import React, {FC, useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from '../Contexts/AuthContext';
import {useTranslation} from "react-i18next";
import {UserRole} from "../graphql-types";

export const Redirector: FC = () => {
    const navigate = useNavigate();
    const {auth} = useContext(AuthContext);
    const {t} = useTranslation();

    useEffect(() => {
        if (auth?.roles?.includes(UserRole.Manager) || auth?.roles?.includes(UserRole.Accounting)) {
            navigate('/Manager');
        } else if (auth?.roles?.includes(UserRole.PartnerContact)) {
            navigate('/Partner');
        } else if (auth?.roles?.includes(UserRole.FieldWorker)) {
            navigate('/FieldWorker');
        } else if (!!auth?.parsedToken) {
            navigate('/Login', {state: {appEntryError: t("redirector.unknownRoleForUsingApp")}});
        } else {
            navigate('/Login');
        }
    }, [auth, navigate]);

    return null;
}
