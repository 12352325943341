import {RenderAntdGraphQLForm, TitleAndContentLayout} from "@ib-tehnologije/react-widgets";
import React, {FC} from "react";
import {useWorkOrderTemplateCreating} from "../Hooks/useWorkOrderTemplateCreating";
import {useNavigate} from "react-router-dom";

const ManagerWorkOrderTemplateCreatePage: FC = () => {
    const creating = useWorkOrderTemplateCreating();
    const navigate = useNavigate();

    return <TitleAndContentLayout title="create-work-order-template" pad>
        <div style={{width: 600}}>
            <RenderAntdGraphQLForm
                creating={creating}
                addingTitle=""
                editingTitle=""
                formSettings={{inline: true}}
                onSuccessfulSubmission={(submitted: any) => {
                    const id = submitted.createWorkOrderTemplate?.[0]?.id;
                    if (id) {
                        navigate(`/Manager/WorkOrderTemplate/${id}`)
                    } else {
                        navigate(-1);
                    }
                }}
                doNotResetAfterSuccessfulSubmission
                getId={data => data.id}
                loading={false}
            />
        </div>
    </TitleAndContentLayout>;
}

export default ManagerWorkOrderTemplateCreatePage;