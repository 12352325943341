export const en = {
    "login": "Login",
    "register": "Register",
    "username": "Username",
    "password": "Password",
    "sign-up": "Sign Up",
    "email": "Email",
    "go-back": "Go Back",
    "EmailConfirmed_PleaseWait": "Email confirmed. Please wait...",
    "EmailConfirmationErrorPage_Title": "Email Confirmation Error",
    "EmailConfirmationErrorPage_Text": "There was an error during email confirmation. Please try again.",
    "EmailConfirmed_Success": "Email successfully confirmed.",
    "The Username field is required.": "The Username field is required.",
    "The Email field is required.": "The Email field is required.",
    "The Password field is required.": "The Password field is required.",
    "EmailConfirmationErrorPage_BackButton": "Go Back",
    "redirector.unknownRoleForUsingApp": "You do not yet have access assigned to the application.",
    "NotFoundPage_Title": "Page Not Found",
    "NotFoundPage_BackButton": "Go Back",
    "NotFoundPage_Text": "The page you are looking for was not found.",
    "ConfirmYourEmail": "A confirmation link has been sent to your email. Please confirm your email.",
    "User email not confirmed": "Email not confirmed",
    "EmailNotConfirmed": "Email not confirmed",
    "ResendConfirmationEmail": "Resend confirmation link",
    "ResendConfirmationEmail_Success": "Confirmation link successfully sent.",
    "PasswordTooShort": "Password is too short.",
    "User not found": "User not found.",
    "back": "Back",
    "home": "Home",
    "logout": "Logout",
    "work-orders": "Work Orders",
    "work-order": "Work Order",
    "work-orders-description": "Overview of Work Orders",
    "users": "Users",
    "users-description": "Overview of Users",
    "companies": "Partners",
    "companies-description": "Overview of Partners",
    "administration": "Administration",
    "error-loading-items": "Error loading items",
    "retry": "Try Again",
    "search": "Search",
    "Previous": "Previous",
    "Next": "Next",
    "create": "Add",
    "cancel": "Cancel",
    "save": "Save",
    "id": "ID",
    "emailConfirmed": "Email Confirmed",
    "are-you-sure-you-want-to-delete-this-item": "Are you sure you want to delete this item?",
    "delete": "Delete",
    "page": "Page",
    "InvalidEmail": "Enter a valid email",
    "additional": "Additional",
    "resendConfirmationEmail": "Resend email confirmation link",
    "pickup-requests": "Pickup Requests",
    "no-items": "No items",
    "pickup-requests-description": "Overview of Pickup Requests",
    "status": "Status",
    "date": "Date",
    "roles": "Roles",
    "Admin": "Admin",
    "Customer": "Customer",
    "company-name": "Partner Name",
    "adding-companies": "Adding Partners",
    "taxId": "Tax ID",
    "actions": "Actions",
    "locations": "Locations",
    "company-locations": "Partner Locations",
    "address": "Address",
    "adding-companyLocations": "Adding Partner Location",
    "company-location-name": "Location Name",
    "location-name": "Location Name",
    "geodecode-address": "Search Address on Map",
    "editing-companyLocations": "Editing Partner Location",
    "adding-users": "Adding Users",
    "adding-pickupRequests": "Adding Pickup Requests",
    "notification": "Notification",
    "successfully-deleted-item": "Item successfully deleted",
    "successfully-deleted-item-with-id": "Item successfully deleted with ID",
    "error-deleting-item-with-id": "Error deleting item with ID",
    "successfully-duplicated-item-with-id": "Item successfully duplicated with ID",
    "error-duplicating-item-with-id": "Error duplicating item with ID",
    "company": "Partner",
    "company-location": "Partner Location",
    "InvalidUserName": "Enter a valid username",
    "editing-users": "Editing Users",
    "edit": "Edit",
    "show-only-unassigned": "Show only unassigned",
    "settings": "Settings",
    "start-search": "Start Search",
    "adding-pickupRequestsFiltered": "Adding Pickup Requests",
    "editing-pickupRequestsFiltered": "Editing Pickup Requests",
    "isMainLocation": "Main Location",
    "assign": "Assign",
    "assigned-user": "Assigned to User",
    "details": "Details",
    "user": "User",
    "user-overview": "User Overview",
    "MANAGER": "Manager",
    "PARTNER_CONTACT": "Partner Contact",
    "FIELD_WORKER": "Field Worker",
    "user-details": "User Details",
    "confirmationEmailSent": "Confirmation Email Sent",
    "userName": "Username",
    "firstName": "First Name",
    "lastName": "Last Name",
    "system": "System",
    "field-worker": "Field Worker",
    "field-workers": "Field Workers",
    "field-workers-description": "Overview of Field Workers",
    "reports": "Reports",
    "reports-description": "Detailed Reports",
    "partner-contacts": "Partner Contacts",
    "units-of-measure": "Units of Measure",
    "vehicles": "Vehicles",
    "organizational-entities": "Organizational Entities",
    "work-order-status-changes": "Work Order Status Changes",
    "work-order-templates": "Work Order Templates",
    "basic-data": "Basic Data",
    "role": "Role",
    "enums:FieldWorker": "Field Worker",
    "enums:PartnerContact": "Partner Contact",
    "enums:Manager": "Manager",
    "editing-companies": "Editing Partners",
    "adding-partnerPickupRequests": "Adding Partner Pickup Request",
    "languages-full:hr": "Croatian",
    "languages-full:en": "English",
    "languages-full:de": "German",
    "languages-full:it": "Italian",
    "items": "Services and Materials",
    "cost-locations": "Cost Locations",
    "execution-categories": "Execution Categories",
    "duplicate": "Duplicate",
    "editing-executionCategories": "Editing Execution Categories",
    "adding-executionCategories": "Adding Execution Categories",
    "name": "Name",
    "description": "Description",
    "unitOfMeasureId": "Unit of Measure ID",
    "unitOfMeasure": "Unit of Measure",
    "unitPrice": "Unit Price",
    "unitsContained": "Units Contained",
    "nameAndDescription": "Name and Description",
    "NoResults": "No Results",
    "adding-items": "Adding Services and Materials",
    "editing-items": "Editing Services and Materials",
    "symbol": "Symbol",
    "adding-unitsOfMeasure": "Adding Units of Measure",
    "editing-unitsOfMeasure": "Editing Units of Measure",
    "workOrderId": "Work Order ID",
    "dateTime": "Date and Time",
    "initiatingUser": "Initiator",
    "companyLocation": "Partner Location",
    "organizationalEntity": "Organizational Entity",
    "executionCategory": "Execution Category",
    "vehicle": "Vehicle",
    "recurrence": "Recurrence",
    "deactivated": "Deactivated",
    "executionDay": "Execution Day",
    "executionDayOfMonth": "Day of the Month",
    "executionCategoryId": "Execution Category ID",
    "organizationalEntityId": "Organizational Entity ID",
    "adding-workOrderTemplates": "Adding Work Order Templates",
    "editing-workOrderTemplates": "Editing Work Order Templates",
    "adding-vehicles": "Adding Vehicles",
    "editing-vehicles": "Editing Vehicles",
    "registrationNumber": "Registration Number",
    "costLocation": "Cost Location",
    "costLocationId": "Cost Location ID",
    "adding-organizationalEntities": "Adding Organizational Units",
    "editing-organizationalEntities": "Editing Organizational Units",
    "parent": "Parent",
    "parentId": "Parent",
    "Email already exists": "Email already exists",
    "unassigned": "Unassigned",
    "pickup-date": "Pickup Date",
    "create-work-order": "Create Work Order",
    "work-order-from-pickup-request": "Work Order from Pickup Request",
    "pickup-request-id": "Pickup Request ID",
    "pickup-request-pickup-date": "Requested Pickup Date",
    "note": "Note",
    "reporting-user": "Reported By",
    "company-details": "Company Details",
    "vehicleId": "Vehicle",
    "work-order-settings": "Work Order Settings",
    "first-page": "First Page",
    "next-page": "Next Page",
    "previous-page": "Previous Page",
    "last-page": "Last Page",
    "pickupDate": "Pickup Date",
    "drop-pin": "Drop Pin",
    "company-tax-id": "Company Tax ID",
    "company-location-map-preview": "Company Location Map Preview",
    "yes": "Yes",
    "no": "No",
    "warning": "Warning",
    "managerNote": "Manager Note",
    "company-location-change": "Company Location Change",
    "phoneNumber": "Phone Number",
    "preferredLanguage": "Preferred Language",
    "enums:WAITING_FOR_ACCEPTANCE": "Waiting for Acceptance",
    "enums:ACCEPTED": "Accepted",
    "enums:DECLINED": "Declined",
    "enums:COMPLETED": "Completed",
    "company-and-location": "Company and Location",
    "work-order-overview": "Work Order Overview",
    "enums:CANCELLED": "Cancelled",
    "enums:INVOICED": "Invoiced",
    "itemId": "Service or Material",
    "quantity": "Quantity",
    "adding-workOrders": "Adding Work Orders",
    "item": "Service or Material",
    "add-row": "Add Row",
    "header": "Header",
    "create-work-order-template": "Create Work Order Template",
    "enums:FRIDAY": "Friday",
    "enums:MONDAY": "Monday",
    "enums:SATURDAY": "Saturday",
    "enums:SUNDAY": "Sunday",
    "enums:THURSDAY": "Thursday",
    "enums:TUESDAY": "Tuesday",
    "enums:WEDNESDAY": "Wednesday",
    "known-location": "Known Location",
    "unknown-location": "Unknown Location",
    "every_week_at": "Every Week At",
    "freeformLocation": "Arbitrary Location",
    "basics": "Basics",
    "executionFrequency": "Execution Frequency",
    "companyAndLocation": "Partner and Location",
    "executionResources": "Execution Resources",
    "itemsTitle": "Services and Materials",
    "every_month": "Every Month",
    "th-extension": "th",
    "every": "Every",
    "genitive_every": "Every",
    "day_of_month": "day of the month",
    "enums:High": "High",
    "enums:Low": "Low",
    "enums:Medium": "Medium",
    "priority": "Priority",
    "Company location is required when freeform location is empty": "Partner location is required when arbitrary location is empty",
    "Either Company location or freeform location is required, but not both": "Either partner location or arbitrary location is required, but not both",
    "Execution day or execution day of month is required": "Execution day or day of the month is required",
    "Company or Location not found, or not associated with company.": "Partner or location not found, or not associated with partner.",
    "every_monday": "Every Monday",
    "every_tuesday": "Every Tuesday",
    "every_wednesday": "Every Wednesday",
    "every_thursday": "Every Thursday",
    "every_friday": "Every Friday",
    "every_saturday": "Every Saturday",
    "every_sunday": "Every Sunday",
    "updating-work-order-template": "Updating Work Order Template",
    "Company location or freeform location is required": "Partner location or arbitrary location is required",
    "freeformCompany": "Arbitrary Partner",
    "Either Company or freeform company is required": "Either partner or arbitrary partner is required",
    "scheduledDate": "Scheduled Date",
    "Freeform location is required when company is empty": "Arbitrary location is required when partner is empty",
    "Freeform company is required when company is empty": "Arbitrary partner is required when partner is empty",
    "enums:WAITING_FOR_ACCEPTANCE_BY_FIELD_WORKER": "Waiting for acceptance by field worker",
    "enums:ACCEPTED_BY_FIELD_WORKER": "Accepted by field worker",
    "enums:DECLINED_BY_FIELD_WORKER": "Declined by field worker",
    "enums:COMPLETED_BY_FIELD_WORKER": "Completed by field worker",
    "enums:CONFIRMED_BY_MANAGER": "Confirmed by manager",
    "enums:WITHOUT_ASSIGNMENT": "Without assignment",
    "locationNote": "Location Note",
    "serviceNote": "Service Note",
    "ACCOUNTING": "Accounting",
    "BackGoToLogin": "Back to Login",
    "close": "Close",
    "enums:Accounting": "Accounting",
    "companyName": "Partner Name",
    "companyLocationName": "Partner Location Name",
    "workOrderCurrentStatusStatus": "Work Order Current Status",
    "notes": "Notes",
    "noteForAccounting": "Note for Accounting",
    "organizational-entities-structure": "Organizational Entities Structure",
    "files": "Files",
    "filesTitle": "Files",
    "file-selection": "File Selection",
    "pickupRequestId": "Pickup Request ID",
    "pickupLocationNote": "Pickup Location Note",
    "pickupRequestServiceNote": "Pickup Request Service Note",
    "fillFromPickupRequest": "Fill from Pickup Request",
    "fillFromPickupRequestContent": "Do you want to fill the work order from the pickup request?",
    "work-order-updated": "Work order updated",
    "createdAt": "Creation Date and Time",
    "customActionsTitle": "Custom Actions",
    "Cannot set status to a lower value than the current one!": "Cannot set status to a lower value than the current one!",
    "Only accountants can set status to this value!": "Only accountants can set status to this value!",
    "Only managers can set status to this value!": "Only managers can set status to this value!",
    "Only managers or field workers can set status to this value!": "Only managers or field workers can set status to this value!",
    "Work order must be assigned to a vehicle or organizational entity to set status to this value!": "Work order must be assigned to a vehicle or organizational entity to set status to this value!",
    "Only field workers can set status to this value!": "Only field workers can set status to this value!",
    "work-order-items": "Work Order Items",
    "adding-workOrderTemplateItems": "Adding Work Order Template Items",
    "editing-workOrderTemplateItems": "Editing Work Order Template Items",
    "adding-workOrderItems": "Adding Work Order Items",
    "editing-workOrderItems": "Editing Work Order Services and Materials",
    "form.uploadFailed": "Error",
    "form.uploadFailedDescription": "There are files that have not been successfully uploaded. Please check.",
    "form.uploading": "Warning",
    "form.uploadingDescription": "File upload is in progress. Please wait.",
    "cancel-work-order": "Cancel Work Order",
    "are-you-sure-you-want-to-cancel-this-work-order": "Are you sure you want to cancel this work order?",
    "create-pdf": "Create PDF",
    "enums:CONFIRMED_BY_ACCOUNTING": "Confirmed by Accounting",
    "enums:SENT_TO_ERP": "Sent to ERP",
    "error-occurred": "An error occurred",
    "reload": "Reload",
    "enums:SENDING_TO_ERP_FAILED": "Error sending to ERP",
    "Cannot cancel work order that has been sent to ERP!": "Cannot cancel work order that has been sent to ERP!",
    "detailed-work-order-status-info": "Details of Status Change",
    "consent-details": "Consent Details",
    "isEmailConsentGiven": "Email Consent Given",
    "emailConsentSource": "Source of Consent",
    "emailConsentGivenOn": "Consent Given On",
    "consent-not-given": "Consent Not Given",
    "user-updated-successfully": "User Successfully Updated",
    "consent-updated-successfully": "Consent Successfully Updated",
    "profile-updated": "Profile Updated",
    "userRole": "User Role",
    "CompanyLocationId or LocationNote is required": "Partner Location or Location Note is Required",
    "location": "Location",
    "requested-service": "Requested Service",
    "requested-by": "Requested By",
    "view-work-order": "View Work Order",
    "enums:AcceptedByFieldWorker": "Accepted by Field Worker",
    "enums:DeclinedByFieldWorker": "Declined by Field Worker",
    "enums:CompletedByFieldWorker": "Completed by Field Worker",
    "enums:Cancelled": "Cancelled",
    "enums:ConfirmedByManager": "Confirmed by Manager",
    "enums:ConfirmedByAccounting": "Confirmed by Accounting",
    "enums:SentToErp": "Sent to ERP",
    "enums:SendingToErpFailed": "Sending to ERP Failed",
    "enums:WaitingForAcceptanceByFieldWorker": "Waiting for Acceptance by Field Worker",
    "enums:WithoutAssignment": "Without Assignment",
    "assignedUserId": "Assigned to User",
    "scheduledDateFrom": "Scheduled Date From",
    "scheduledDateTo": "Scheduled Date To",
    "no-linked-work-order": "No Linked Work Order",
    "accountants": "Accountants",
    "accounting": "Accounting",
    "enums:reportViews.total_work_order_count": "Total Number of Work Orders",
    "work_order_count_short": "Order Count",
    "work_order_count": "Number of Work Orders",
    "startDate": "Start Date",
    "endDate": "End Date",
    "assigneeId": "Assignee",
    "count": "Count",
    "xlsx_export": "Export to Excel",
    "csv_export": "Export to CSV",
    "pdf_export": "Export to PDF",
    "current-page": "Page",
    "page-of": "of",
    "work_order_count_per_day": "Number of Work Orders Per Day",
    "enums:reportViews.total_work_order_count_calendar": "Total Number of Work Orders: Calendar",
    "reportWorkOrderid": "Work Order Key",
    "reportWorkOrderpickupRequestId": "Pickup Request Code",
    "reportWorkOrderpickupRequestPickupDate": "Requested Pickup Date",
    "reportWorkOrderpickupRequestReportingUserUserName": "Reported By",
    "reportWorkOrderpickupRequestCompanyName": "Partner Name",
    "reportWorkOrderpickupRequestCompanyTaxId": "Partner Tax ID",
    "reportWorkOrderpickupRequestCompanyLocationName": "Partner Location Name",
    "reportWorkOrderpickupRequestCompanyLocationAddress": "Partner Location Address",
    "reportWorkOrderscheduledDate": "Scheduled Date",
    "reportWorkOrderstartDateTime": "Start Time",
    "reportWorkOrderendDateTime": "End Time",
    "reportWorkOrderduration": "Duration",
    "reportWorkOrderassignedByManagerUserUserName": "Assigned by Manager",
    "reportWorkOrderassignedUserUserName": "Assigned to User",
    "reportWorkOrdervehicleName": "Vehicle Name",
    "reportWorkOrdervehicleRegistrationNumber": "Vehicle Registration Number",
    "reportWorkOrdervehicleCostLocationName": "Vehicle Cost Location Name",
    "reportWorkOrdervehicleCostLocationCode": "Vehicle Cost Location Code",
    "reportWorkOrderorganizationalEntityName": "Organizational Entity Name",
    "reportWorkOrderexecutionCategoryName": "Execution Category Name",
    "reportWorkOrdercurrentStatusStatus": "Work Order Current Status",
    "reportWorkOrderitemsTotal": "Total Services and Materials",
    "currentVehicleId": "Vehicle",
    "currentOrganizationalEntityId": "Organizational Entity",
    "Cannot reopen a completed work order!": "Cannot reopen a completed work order!",
    "open-pdf": "Open PDF",
    "no-pdf-available": "No PDF Available",
    "send-pdf": "Send PDF",
    "send-pdf-to": "Send PDF to",
    "contactId": "Contact",
    "directToEmail": "Direct to Email",
    "ContactId or directToEmail must be provided": "Contact or Email must be provided",
    "pdf-sent": "PDF Sent",
    "User has not given consent to use email.": "User has not given consent to use email.",
    "value": "Value",
    "Login": "Login",
    "generate-work-orders": "Generate Work Orders",
    "Work orders generated successfully": "Work orders generated successfully",
    "partner-portal-notes": "Partner Portal Notes",
    "Work order must be assigned to a vehicle to set status to this value!": "Work order must be assigned to a vehicle to set status to this value!",
    "change-password": "Change Password",
    "oldPassword": "Old Password",
    "newPassword": "New Password",
    "password-changed": "Password Changed",
    "password-reset": "Password Reset",
    "password-reset-successful": "Password Reset Successful",
    "Cannot delete pickup request with associated work order.": "Cannot delete pickup request with associated work order.",
    "Cannot update pickup request with associated work order." : "Cannot update pickup request with associated work order.",
    "editing-partnerPickupRequests": "Editing Partner Pickup Request",
    "requestedService": "Requested Service",
    "service-note": "Service Note",
    "pickupServiceNote": "Pickup Service Note",
    "not_reviewed": "Not Reviewed",
    "no-data": "No Data",
}