import {useMemo} from "react";
import {
    CustomRenderElement,
    CustomSearchBoxElement,
    DateElement,
    ITenantDeleting,
    MultilineTextElement
} from "@ib-tehnologije/react-widgets";
import {
    DeletePartnerPickupRequestMutation,
    PartnerPickupRequestDeleteDtoInput, useDeletePartnerPickupRequestMutation,
    useGetCompanyLocationsQuery
} from "../graphql-types";
import {ActualPartnerPickupRequestsReturnDto} from "../ReturnTypes/ActualPartnerPickupRequestReturnDto";
import {useCompanyLocationPicker} from "../Pickers/useCompanyLocationPicker";
import dayjs from "dayjs";

export const usePartnerPickupRequestDeleting = ({note}: { note?: string }) => {
    const [deletePickupRequest] = useDeletePartnerPickupRequestMutation();
    const companyLocationPicker = useCompanyLocationPicker();

    const firstAvailableDate = useMemo(() => {
        // min two days from now and skip sunday
        const minDays = dayjs().add(2, "day").startOf("day").toDate();
        const skipSunday = dayjs(minDays).weekday() === 6 ? dayjs(minDays).add(1, "day").startOf("day").toDate() : minDays;
        return skipSunday;
    }, []);

    return useMemo<ITenantDeleting<DeletePartnerPickupRequestMutation, ActualPartnerPickupRequestsReturnDto, PartnerPickupRequestDeleteDtoInput, "data">>(() => ({
        modelKey: "data",
        fn: deletePickupRequest,
        selectionToDto: (selection) => ({
            id: selection.id
        }),
    }), [note, firstAvailableDate]);
}