import {useMemo} from "react";
import {CustomSearchBoxElement} from "@ib-tehnologije/react-widgets";
import {
    useGetUnfilteredUsersLazyQuery,
    useGetWorkOrdersLazyQuery,
    useGetWorkOrdersPlainLazyQuery
} from "../graphql-types";
import dayjs from "dayjs";

export const useWorkOrderIdPicker = () => {
    return useMemo(() => ({
        type: new CustomSearchBoxElement({
            query: useGetWorkOrdersPlainLazyQuery,
            getQueryData: (data) => data.data?.workOrders,
            valueKey: "workOrder",
            getKey: (workOrder) => workOrder.id,
            modifyQueryOptions: (options, values, search) => {
                return {
                    ...options,
                    variables: {
                        ...options.variables,
                        search: search,
                    }
                };
            },
            makeTitle: (workOrder) => {
                const woNumber = workOrder.formattedId;
                let title = woNumber;
                if (workOrder.company) {
                    title += ` - ${workOrder.company.name}`;
                } else if (workOrder.freeformCompany) {
                    title += ` - ${workOrder.freeformCompany}`;
                }
                return title;
            },
            makeSubtitle: (workOrder) => {
                let subtitle = "";
                if (workOrder.scheduledDate) {
                    subtitle += `${dayjs(workOrder.scheduledDate).format("DD/MM/YYYY")}`;
                }
                if (workOrder?.vehicle) {
                    if (workOrder.scheduledDate) {
                        subtitle += ` - ${workOrder?.vehicle?.name} `;
                    } else {
                        subtitle += `${workOrder?.vehicle?.name} `;
                    }
                }
                return subtitle;
            },
            makeWhere: (values, search) => {
                return ({});
            }
        })
    }), []);
}