import {useMemo} from "react";
import {ITenantDeleting} from "@ib-tehnologije/react-widgets";
import {
    DeletePickupRequestMutation,
    PickupRequestDeleteDtoInput,
    useDeletePickupRequestMutation
} from "../graphql-types";

export const usePickupRequestDeleting = () => {
    const [deletePickupRequest] = useDeletePickupRequestMutation();

    return useMemo<ITenantDeleting<DeletePickupRequestMutation, PickupRequestDeleteDtoInput, {
        id: number
    }, "data">>(() => ({
        modelKey: "data",
        fn: deletePickupRequest,
        selectionToDto: (selection) => ({
            id: selection?.id
        })
    }), []);
}