import React, {FC, useState} from "react";
import {
    CheckboxElement,
    FormOrientation,
    RenderAntdGraphQLList, RenderAntdStandaloneForm, TextFieldElement,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {useTranslation} from "react-i18next";
import {
    useGetCompanyLocationsQuery, useGetCompanyQuery
} from "../graphql-types";
import {useParams} from "react-router-dom";
import {CheckboxChecked20Filled} from "@fluentui/react-icons";
import {useCompanyLocationCreating} from "../Hooks/useCompanyLocationCreating";
import {useCompanyLocationUpdating} from "../Hooks/useCompanyLocationUpdating";
import {useCompanyLocationDeleting} from "../Hooks/useCompanyLocationDeleting";
import {CheckOutlined} from "@ant-design/icons";

const ManagerCompanyCompanyLocationsPage: FC = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const creating = useCompanyLocationCreating(+id!);
    const updating = useCompanyLocationUpdating(+id!);
    const deleting = useCompanyLocationDeleting();
    const [filterLoading, setFilterLoading] = useState<boolean>(false);

    const companyQuery = useGetCompanyQuery({
        variables: {
            where: {
                id: {
                    eq: +id!
                }
            }
        }
    });

    const locationsQuery = useGetCompanyLocationsQuery({
        variables: {
            where: {
                companyId: {
                    eq: +id!
                }
            }
        }
    });

    return (
        <TitleAndContentLayout title={t("company-locations")} titleAddon={companyQuery?.data?.company?.name} pad>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                    width: "100%"
                }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 250,
                }}>
                    <RenderAntdStandaloneForm
                        formSettings={{inline: true, orientation: FormOrientation.VERTICAL}}
                        loading={locationsQuery.loading}
                        formDefinition={{
                            search: {
                                type: new TextFieldElement({})
                            },
                            isMainLocation: {
                                type: new CheckboxElement({})
                            }
                        }}
                        submission={{
                            title: "start-search",
                            onSubmit: (values) => {
                                locationsQuery.refetch({
                                    where: {
                                        and: [values.search ? {
                                            or: [{
                                                name: {
                                                    contains: values.search
                                                },
                                            }, {
                                                description: {
                                                    contains: values.search
                                                },
                                            }, {
                                                address: {
                                                    contains: values.search
                                                }
                                            }, {
                                                company: {
                                                    name: {
                                                        contains: values.search
                                                    }
                                                }
                                            }, {
                                                company: {
                                                    description: {
                                                        contains: values.search
                                                    }
                                                }
                                            }, {
                                                company: {
                                                    taxId: {
                                                        contains: values.search
                                                    }
                                                }
                                            }]
                                        } : {},
                                            {
                                                companyId: {
                                                    eq: +id!
                                                }
                                            },
                                            values.isMainLocation ? {
                                                isMainLocation: {
                                                    eq: values.isMainLocation
                                                }
                                            } : {}]
                                    }
                                });
                            }
                        }}
                    />
                </div>
                <div style={{flex: 1, width: "100%"}}>
                    <RenderAntdGraphQLList
                        deleting={deleting}
                        tableData={locationsQuery.data?.companyLocations}
                        columns={[{
                            columnId: "id",
                            renderCell: (row) => row.id,
                        }, {
                            columnId: "isMainLocation",
                            renderCell: (row) => row.isMainLocation ? <CheckOutlined/> : null,
                        }, {
                            columnId: "company",
                            renderCell: (row) => <span>{row.company?.name}</span>,
                        }, {
                            columnId: "company-location-name",
                            renderCell: (row) => <span>{row.name}</span>,
                        }, {
                            columnId: "address",
                            renderCell: (row) => <span>{row.address}</span>,
                        }]}
                        query={locationsQuery}
                        loading={locationsQuery.loading}
                        creating={creating}
                        getRowId={g => g.id}
                        updating={updating}
                    />
                </div>
            </div>
        </TitleAndContentLayout>
    );
}

export default ManagerCompanyCompanyLocationsPage;