import {useMemo} from "react";
import {
    CustomSearchBoxElement,
    EnumDropdownElement,
    GroupElement,
    ITenantCreating,
    TextFieldElement
} from "@ib-tehnologije/react-widgets";
import {
    AccountRegisterExtendedDtoInput,
    RegisterMeMutation,
    useGetCompaniesQuery,
    useRegisterMeMutation, UserRole
} from "../graphql-types";
import {ActualApplicationUsersReturnDto} from "../ReturnTypes/ActualApplicationUserReturnDto";
import {useCompanyPicker} from "../Pickers/useCompanyPicker";
import {FormikProps} from "formik";

export const useUserCreating = (withUserRole?: UserRole | undefined): ITenantCreating<RegisterMeMutation, ActualApplicationUsersReturnDto, AccountRegisterExtendedDtoInput, "model"> => {
    const [register] = useRegisterMeMutation();
    const companyPickerDeps = useMemo(() => ({
        disabledIf: (formikProps: FormikProps<any>) => formikProps.values.userRole !== undefined && formikProps.values.userRole !== UserRole.PartnerContact
    }), []);
    const companyPicker = useCompanyPicker(companyPickerDeps);

    return useMemo<ITenantCreating<RegisterMeMutation, ActualApplicationUsersReturnDto, AccountRegisterExtendedDtoInput, "model">>(() => ({
        modelKey: "model",
        baseFormData: {
            userName: "",
            password: "",
            email: "",
            firstName: "",
            lastName: "",
            companyId: undefined,
            userRole: withUserRole
        },
        formDataToDto: (formData) => {
            console.log(formData);
            return ({
                userName: formData.userName,
                password: formData.password,
                email: formData.email,
                companyId: formData.companyId,
                firstName: formData.firstName,
                lastName: formData.lastName,
                userRole: formData.userRole,
                phoneNumber: formData.phoneNumber,
                externalId: formData.externalId
            });
        },
        formDefinition: {
            userName: {
                type: new TextFieldElement({})
            },
            userRole: {
                type: new EnumDropdownElement({
                    enumData: UserRole,
                }),
            },
            nameAndSurname: {
                type: new GroupElement({
                    firstName: {
                        type: new TextFieldElement({})
                    },
                    lastName: {
                        type: new TextFieldElement({})
                    },
                }),
            },
            password: {
                type: new TextFieldElement({})
            },
            phoneAndEmail: {
                type: new GroupElement({
                    phoneNumber: {
                        type: new TextFieldElement({})
                    },
                    email: {
                        type: new TextFieldElement({})
                    },
                }),
            },
            companyId: companyPicker,
            externalId: {
                type: new TextFieldElement({})
            }
        },
        fn: register,
    }), [withUserRole, companyPicker]);
}