import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {
    PickupRequestsFilterDtoInput,
    useGetCompaniesQuery, useGetPickupRequestsFilteredLazyQuery,
    useGetPickupRequestsFilteredQuery
} from "../graphql-types";
import {useTranslation} from "react-i18next";
import {usePickupRequestCreating} from "../Hooks/usePickupRequestCreating";
import {usePickupRequestUpdating} from "../Hooks/usePickupRequestUpdating";
import {usePickupRequestDeleting} from "../Hooks/usePickupRequestDeleting";
import {ActualPickupRequestFilteredReturnDto} from "../ReturnTypes/ActualPickupRequestFilteredReturnDto";
import {useCompanyPicker} from "../Pickers/useCompanyPicker";
import {
    CheckboxElement,
    FormOrientation,
    RenderAntdGraphQLList,
    RenderAntdStandaloneForm, TextFieldElement,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {Badge, ButtonProps, Layout, Tag, Typography} from "antd";
import {useCompanyLocationPicker} from "../Pickers/useCompanyLocationPicker";
import {FileAddOutlined, PlusOutlined, ExportOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {WorkOrderStatusIcon} from "../Components/WorkOrderStatusIcon";
import dayjs from "dayjs";
import {useLocalStorage} from "usehooks-ts";

const ManagerPickupRequestsPage: FC = () => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(true);
    const [filter, setFilter] = useLocalStorage<{
        values: any,
        filter: PickupRequestsFilterDtoInput
    }>("pickupreqeustfilter", {
        filter: {
            unassignedOnly: true
        },
        values: {}
    });
    const [a, q] = useGetPickupRequestsFilteredLazyQuery();
    const [addingWorkOrderFrom, setAddingWorkOrderFrom] = useState<ActualPickupRequestFilteredReturnDto | undefined>(undefined);
    const companyIdPicker = useCompanyPicker();
    const companyLocationIdPicker = useCompanyLocationPicker();
    const navigate = useNavigate();
    const [loadingFilter, setLoadingFilter] = useState(false);

    useEffect(() => {
        if(!loadingFilter){
            setLoadingFilter(true);
            a({
                variables: {
                    filters: filter.filter
                }
            }).finally(() => {
                setLoadingFilter(false);
            });
        }
    }, [filter]);

    const creating = usePickupRequestCreating();
    const updating = usePickupRequestUpdating();
    const deleting = usePickupRequestDeleting();

    const updateFilter = useCallback((newFilter: Partial<{
        values: any,
        filter: PickupRequestsFilterDtoInput
    }>) => {
        setFilter({
            ...filter,
            ...newFilter
        });
    }, [filter]);

    const selectionButtons = useCallback((selectedItems: ActualPickupRequestFilteredReturnDto[] | undefined): ButtonProps[] => {
        const selectedItem = selectedItems?.[0];

        const buttons: ButtonProps[] = [];

        if (selectedItem && selectedItems?.length === 1 && selectedItem.isAssigned) {
            buttons.push({
                content: t("view-work-order"),
                icon: <ExportOutlined/>,
                title: t("view-work-order"),
                children: t("view-work-order"),
                onClick: () => {
                    navigate(`/Manager/WorkOrder/${selectedItem.workOrder?.id}`);
                }
            });
        }

        if (selectedItem && selectedItems?.length === 1 && !selectedItem.isAssigned) {
            buttons.push({
                content: t("create-work-order"),
                icon: <PlusOutlined/>,
                title: t("create-work-order"),
                children: t("create-work-order"),
                onClick: () => {
                    navigate(`/Manager/WorkOrders/Create`, {
                        state: {
                            pickupRequest: selectedItem,
                        },
                    });
                }
            });
        }

        return buttons;
    }, [navigate]);

    return <TitleAndContentLayout title={"pickup-requests"} contentStyle={{display: "flex", height: "100%"}} pad>
        <div style={{
            display: "flex",
            flexDirection: "row",
            gap: 10
        }}>
            <div style={{minWidth: 250, maxWidth: 250}}>
                <RenderAntdStandaloneForm
                    formSettings={{inline: true, orientation: FormOrientation.VERTICAL}}
                    loading={q.loading}
                    baseFormData={filter.values}
                    formDefinition={{
                        unassignedOnly: {
                            type: new CheckboxElement({}),
                            translationKey: "show-only-unassigned",
                        },
                        search: {
                            type: new TextFieldElement({})
                        },
                        companyId: companyIdPicker,
                        companyLocationId: companyLocationIdPicker
                    }}
                    title={t("filter")}
                    submission={{
                        title: "start-search",
                        onSubmit: (values) => {
                            updateFilter({
                                values: values,
                                filter: {
                                    search: values.search,
                                    unassignedOnly: values.unassignedOnly,
                                    companyId: values.companyId,
                                    companyLocationId: values.companyLocationId
                                }
                            });
                        }
                    }}
                />
            </div>
            <div style={{flex: 1}}>
                <RenderAntdGraphQLList
                    updating={updating}
                    creating={creating}
                    tableData={q.data?.pickupRequestsFiltered}
                    deleting={deleting}
                    inlineSelectionButtons={selectionButtons}
                    columns={[{
                        columnId: "id",
                        renderCell: (row) => <div style={{}}>
                            {row.id}
                        </div>,
                    }, {
                        columnId: "pickupDate",
                        renderCell: (row) => {
                            const dateLocal = dayjs(row.pickupDate);
                            
                            if(!row.pickupDate) {
                                return null;
                            }

                            return <div style={{display: "flex", flexDirection: "column"}}>
                                <span>{dateLocal.format('L')}</span>
                                <Typography.Text type={"secondary"}>{dateLocal.fromNow()}</Typography.Text>
                            </div>;
                        },
                    }, {
                        columnId: "companyName",
                        renderCell: (row) => row.company?.name,
                    }, {
                        columnId: "location",
                        renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                            <span>{row.companyLocation?.name}</span>
                            <Typography.Text type={"secondary"}>{row.companyLocation?.address}</Typography.Text>
                            <span>{row.locationNote}</span>
                        </div>,
                    }, {
                        columnId: "requestedService",
                        renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                            <span>{row.serviceNote}</span>
                        </div>,
                    }, {
                        columnId: "workOrderCurrentStatusStatus",
                        renderCell: (row) => {
                            if (!row.isAssigned) {
                                return <Tag color={"error"}>{t("no-linked-work-order")}</Tag>;
                            }
                            return <div style={{display: "flex", flexDirection: "column"}}>
                                <WorkOrderStatusIcon status={row.workOrder?.currentStatus?.status}/>
                                <Typography.Text
                                    type={"secondary"}>{t(`enums:${row.workOrder?.currentStatus?.status}`)}</Typography.Text>
                            </div>;
                        },
                    }]}
                    query={q}
                    loading={q.loading}
                    getRowId={g => g.id}/>
            </div>
        </div>
    </TitleAndContentLayout>;
}

export default ManagerPickupRequestsPage;