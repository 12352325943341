import {useMemo} from "react";
import {
    DateElement,
    ITenantCreating, MultilineTextElement
} from "@ib-tehnologije/react-widgets";
import {
    CreatePickupRequestMutation,
    PickupRequestCreateDtoInput,
    useCreatePickupRequestMutation
} from "../graphql-types";
import {ActualPickupRequestFilteredReturnDto} from "../ReturnTypes/ActualPickupRequestFilteredReturnDto";
import {useCompanyPicker} from "../Pickers/useCompanyPicker";
import {useCompanyLocationPicker} from "../Pickers/useCompanyLocationPicker";
import dayjs from "dayjs";

export const usePickupRequestCreating = () => {
    const [createPickupRequest] = useCreatePickupRequestMutation();
    const companyPicker = useCompanyPicker();
    const companyLocationPicker = useCompanyLocationPicker();

    return useMemo<ITenantCreating<CreatePickupRequestMutation, ActualPickupRequestFilteredReturnDto, PickupRequestCreateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            companyId: companyPicker,
            companyLocationId: companyLocationPicker,
            locationNote: {
                type: new MultilineTextElement({rows: 3}),
            },
            pickupDate: {
                type: new DateElement({}),
            },
            serviceNote: {
                type: new MultilineTextElement({rows: 3}),
            },
        },
        baseFormData: {
            pickupDate: dayjs().add(1, "day").startOf("day").toDate(),
        },
        formDataToDto: (formData) => ({
            pickupDate: formData.pickupDate,
            companyId: formData.companyId,
            companyLocationId: formData.companyLocationId,
            locationNote: formData.locationNote,
            serviceNote: formData.serviceNote,
        }),
        fn: createPickupRequest
    }), []);
}
