import {DropdownElement} from "@ib-tehnologije/react-widgets";
import {orderedDays} from "../Helpers/orderedDaysOfWeek";
import {useMemo} from "react";
import {useTranslation} from "react-i18next";

export const useExecutionDayPicker = () => {
    const {t} = useTranslation();
    
    return useMemo(() => ({
        type: new DropdownElement({
            values: orderedDays.map(day => ({
                key: day,
                value: t(`enums:${day}`)
            })),
            titleKey: "value",
            valueKey: "key",
        })
    }), []);
}