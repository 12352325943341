import React, {FC, PropsWithChildren, useContext, useEffect, useMemo} from "react";
import "../Styles/DefaultLayout.css";
import {LeftMainMenuDrawer} from "./LeftMainMenuDrawer";
import {UnassignedPickupRequestCountProvider} from "../Contexts/UnassignedPickupRequestCountContext";
import {AuthContext} from "../Contexts/AuthContext";
import {UserRole} from "../graphql-types";

export const DefaultLayout: FC<PropsWithChildren> = ({children}) => {
    const {auth} = useContext(AuthContext);

    return <div style={{height: "100% !important", minHeight: "100% !important", display: "flex", flexDirection: "row"}}
                className={"default-layout"}>
        {auth.roles.includes(UserRole.Manager) ? <UnassignedPickupRequestCountProvider>
                <LeftMainMenuDrawer>
                    {children}
                </LeftMainMenuDrawer>
            </UnassignedPickupRequestCountProvider>
            : <LeftMainMenuDrawer>
                {children}
            </LeftMainMenuDrawer>}
    </div>;
}
