import {ITenantUpdating, MultilineTextElement, NumericFieldElement} from "@ib-tehnologije/react-widgets";
import {
    CreateWorkOrderTemplateItemMutation, UpdateWorkOrderTemplateItemMutation,
    useCreateWorkOrderTemplateItemMutation, useUpdateWorkOrderTemplateItemMutation,
    WorkOrderTemplateItemCreateDtoInput, WorkOrderTemplateItemUpdateDtoInput
} from "../graphql-types";
import {ActualWorkOrderTemplateItemReturnDto} from "../ReturnTypes/ActualWorkOrderTemplateItemReturnDto";
import {useItemPicker} from "../Pickers/useItemPicker";
import {useFilePicker} from "../Pickers/useFilePicker";
import {useMemo} from "react";

export const useWorkOrderTemplateItemUpdating = (forWorkOrderId: number): ITenantUpdating<UpdateWorkOrderTemplateItemMutation, ActualWorkOrderTemplateItemReturnDto, WorkOrderTemplateItemUpdateDtoInput, "data"> => {
    const [update] = useUpdateWorkOrderTemplateItemMutation();
    const itemIdPicker = useItemPicker({
        showDeleted: false,
    });
    const filePicker = useFilePicker();

    return useMemo<ITenantUpdating<UpdateWorkOrderTemplateItemMutation, ActualWorkOrderTemplateItemReturnDto, WorkOrderTemplateItemUpdateDtoInput, "data">>(() => ({
        modelKey: "data",
        baseFormData: {
            workOrderTemplateId: forWorkOrderId
        },
        formDataToDto: formData => ({
            id: formData.id,
            itemId: formData.itemId,
            note: formData.note,
            quantity: formData.quantity,
            workOrderTemplateId: forWorkOrderId
        }),
        formDefinition: {
            itemId: itemIdPicker,
            note: {
                type: new MultilineTextElement({rows: 3}),
            },
            quantity: {
                type: new NumericFieldElement({}),
            },
        },
        selectionToBaseFormData: selection => ({
            id: selection?.id,
            itemId: selection?.itemId,
            item: selection?.item,
            note: selection?.note,
            quantity: selection?.quantity,
            workOrderTemplateId: forWorkOrderId,
        }),
        fn: update
    }), [update, forWorkOrderId]);
}