// AuthContext.tsx
import React, {createContext, useCallback, useState} from 'react';

export type LoginState = {
    justConfirmedEmail?: boolean;
    appEntryError?: string;
    signUpSuccess?: "ConfirmYourEmail";
    resendConfirmationEmailSuccess?: boolean;
};

export type LoginContextState = {
    loginState: LoginState;
    setLoginState: (loginState: LoginState) => void;
};

const initialState: LoginContextState = {
    loginState: {},
    setLoginState: () => {
    }
};

export const LoginContext = createContext<LoginContextState>(initialState);

export const LoginContextEntry = ({children}: { children: React.ReactNode }) => {
    const [loginState, setLoginState] = useState<LoginState>(initialState.loginState);

    return <LoginContext.Provider value={{loginState, setLoginState}}>
        {children}
    </LoginContext.Provider>;
}