import {useMemo} from "react";
import {
    CustomSearchBoxElement,
    ICustomSearchBoxElementProps,
    IElement,
    ITenantFormLayoutDefinition, RecursivePartial
} from "@ib-tehnologije/react-widgets";
import {
    GetCompaniesLazyQueryHookResult,
    GetCompanyQueryResult,
    useGetCompaniesLazyQuery,
    useGetCompaniesQuery, UserRole
} from "../graphql-types";
import {ActualCompanyReturnDto} from "../ReturnTypes/ActualCompanyReturnDto";
import {FormikProps} from "formik";

export function useCompanyPicker<TDto>(overrides?: RecursivePartial<ITenantFormLayoutDefinition<TDto, CustomSearchBoxElement<any, any, any, any>>>, deps: any[] = []) {
    return useMemo(() => ({
        ...(overrides as any),
        type: new CustomSearchBoxElement<any, TDto, any, any>({
            query: useGetCompaniesLazyQuery,
            getQueryData: ({data}) => data?.companies,
            getKey: (f) => f.id,
            makeTitle: (f) => f.name,
            makeWhere: (values, search) => search ? ({
                or: [
                    {
                        name: {
                            contains: search
                        },
                    },
                    {
                        taxId: {
                            contains: search
                        }
                    }
                ]
            }) : undefined,
            makeSubtitle: (f) => f.taxId,
            valueKey: "company",
            ...(overrides?.type as any)
        }),
        translationKey: "company"
    }), [overrides, ...deps]);
}