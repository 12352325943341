import {useMemo} from "react";
import {CustomSearchBoxElement} from "@ib-tehnologije/react-widgets";
import {useGetUnfilteredUsersLazyQuery, useGetUsersLazyQuery} from "../graphql-types";

export const useUserPicker = (valueKey: string = "user") => {
    return useMemo(() => ({
        type: new CustomSearchBoxElement({
            query: useGetUnfilteredUsersLazyQuery,
            getQueryData: (data) => data.data?.usersUnfiltered,
            valueKey: valueKey,
            getKey: (user) => user.id,
            makeTitle: (user) => user.userName,
            makeSubtitle: (user) => `${user.firstName} ${user.lastName}`,
            makeWhere: (values, search) => ({
                or: [{
                    userName: {
                        contains: search
                    },
                }, {
                    email: {
                        contains: search
                    },
                }, {
                    firstName: {
                        contains: search
                    },
                }, {
                    lastName: {
                        contains: search
                    },
                }]
            }),
        })
    }), [valueKey]);
}