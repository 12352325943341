import React, { FC } from "react";
import {DashboardItems} from "../Components/DashboardItems";
import {TitleAndContentLayout} from "@ib-tehnologije/react-widgets";

const ManagerDashboardPage: FC = () => {
    return <TitleAndContentLayout title="home">
        <DashboardItems/>
    </TitleAndContentLayout>;
}

export default ManagerDashboardPage;