import {useMemo} from "react";
import {CustomSearchBoxElement} from "@ib-tehnologije/react-widgets";
import {
    useGetPickupRequestsLazyQuery,
} from "../graphql-types";
import dayjs from "dayjs";

export const usePickupRequestIdPicker = () => {
    return useMemo(() => ({
        type: new CustomSearchBoxElement({
            query: useGetPickupRequestsLazyQuery,
            getQueryData: ({data}) => data?.pickupRequests,
            getKey: (f) => f.id,
            makeTitle: (f) => f ? `${f?.company?.name} - ${f?.companyLocation?.name ?? f?.locationNote} (${dayjs(f?.pickupDate).format('L')})` : undefined,
            makeWhere: (values, search) => ({
                or: [{
                    company: {
                        name: {
                            contains: search
                        },
                    }
                }, {
                    companyLocation: {
                        name: {
                            contains: search
                        }
                    }
                }],
                isAssigned: {
                    eq: false
                }
            }),
            makeSubtitle: (f) => f.serviceNote,
            valueKey: "pickupRequest",
        })
    }), []);
}