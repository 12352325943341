import {WorkOrderStatus} from "../graphql-types";
import {
    ArrowRotateClockwiseRegular,
    ArrowSyncCheckmarkRegular,
    ClockToolboxRegular,
    CloudCheckmarkRegular,
    CloudDismissRegular,
    DocumentCheckmarkRegular,
    PauseCircleRegular,
    PeopleErrorRegular,
    PlayCircleRegular, PlugDisconnectedRegular,
    SubtractCircleRegular,
    TaskListSquareLtrRegular,
    ThumbDislikeRegular,
    ThumbLikeRegular
} from "@fluentui/react-icons";
import React, {FC, ReactElement, useMemo} from "react";
import {theme, Tooltip} from "antd";
import {useTranslation} from "react-i18next";

interface IWorkOrderStatusIconProps {
    status?: WorkOrderStatus;
    size?: number;
}

export const WorkOrderStatusIcon: FC<IWorkOrderStatusIconProps> = ({status, size}): ReactElement | null => {
    const endSize = useMemo(() => size ?? 30, [size]);
    const {t} = useTranslation();

    return <Tooltip style={{minWidth: 20}} title={t(`enums:${status}`)}>
        <span>
            {_makeStatusIcon(status, endSize)}
        </span>
    </Tooltip>;
}

export const PlainWorkOrderStatusIcon: FC<IWorkOrderStatusIconProps> = ({status, size}): ReactElement | null => {
    const endSize = useMemo(() => size ?? 30, [size]);

    return <span className="anticon">
        {_makeStatusIcon(status, endSize)}
    </span>;
}

const _makeStatusIcon = (status: WorkOrderStatus | undefined, endSize: number,): ReactElement | null => {
    const {
        token: {
            colorErrorText,
            colorTextDisabled,
            colorSuccessText,
            colorWarning,
            blue,
            purple,
            cyan,
            yellow6,
            green7
        },
    } = theme.useToken();

    switch (status) {
        case WorkOrderStatus.WithoutAssignment:
            return <PeopleErrorRegular fontSize={endSize} color={colorErrorText}/>;
        case WorkOrderStatus.WaitingForAcceptanceByFieldWorker:
            return <ClockToolboxRegular fontSize={endSize} color={colorWarning}/>;
        case WorkOrderStatus.AcceptedByFieldWorker:
            return <ThumbLikeRegular fontSize={endSize} color={colorSuccessText}/>;
        case WorkOrderStatus.DeclinedByFieldWorker:
            return <ThumbDislikeRegular fontSize={endSize} color={colorErrorText}/>;
        case WorkOrderStatus.Cancelled:
            return <SubtractCircleRegular fontSize={endSize} color={colorTextDisabled}/>;
        case WorkOrderStatus.CompletedByFieldWorker:
            return <TaskListSquareLtrRegular fontSize={endSize} color={green7}/>;
        case WorkOrderStatus.ConfirmedByManager:
            return <DocumentCheckmarkRegular fontSize={endSize} color={blue}/>;
        case WorkOrderStatus.ConfirmedByAccounting:
            return <ArrowSyncCheckmarkRegular fontSize={endSize} color={purple}/>;
        case WorkOrderStatus.SentToErp:
            return <CloudCheckmarkRegular fontSize={endSize} color={blue}/>;
        case WorkOrderStatus.SendingToErpFailed:
            return <CloudDismissRegular fontSize={endSize} color={colorErrorText}/>;
        case WorkOrderStatus.PausedUntilContainerPickup:
            return <PauseCircleRegular fontSize={endSize} color={yellow6}/>;
        case WorkOrderStatus.ResumedContainerPickup:
            return <PlayCircleRegular fontSize={endSize} color={colorSuccessText}/>;
        case WorkOrderStatus.StartedByFieldWorker:
            return <ArrowRotateClockwiseRegular fontSize={endSize} color={cyan}/>;
        case WorkOrderStatus.OfflineInitiatedAwaitingReview:
            return <PlugDisconnectedRegular fontSize={endSize} color={colorWarning}/>
    }

    return null;
}