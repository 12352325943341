import React, {FC, useCallback, useContext} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Button, Result} from "antd";

const EmailConfirmationErrorPage: FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    
    const onBackButtonClicked = useCallback(() => {
        navigate("/Login");
    }, [navigate]);

    return (
        <Result
            status="error"
            title={t("error")}
            subTitle={t("EmailConfirmationErrorPage_Text")}
            extra={<Button type="primary" onClick={onBackButtonClicked}>{t("EmailConfirmationErrorPage_BackButton")}</Button>}
        />
    );
}

export default EmailConfirmationErrorPage;