import React, {FC, useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {LoginContext} from "../Contexts/LoginContext";
import {Spin} from "antd";

const EmailConfirmedPage: FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {setLoginState} = useContext(LoginContext);

    useEffect(() => {
        setTimeout(() => {
            setLoginState({
                justConfirmedEmail: true,
            });
            navigate("/Login", {replace: true});
        });
    }, [navigate]);

    return (
        <center>
            <h3>{t("EmailConfirmed_PleaseWait")}</h3>
            <Spin/>
        </center>
    );
}

export default EmailConfirmedPage;
    