import {useMemo} from "react";
import {
    DeleteWorkOrderMutation,
    useDeleteWorkOrderMutation, WorkOrderDeleteDtoInput
} from "../graphql-types";
import {ITenantDeleting} from "@ib-tehnologije/react-widgets";
import {ActualWorkOrderReturnDto} from "../ReturnTypes/ActualWorkOrderReturnDto";

export const useWorkOrderDeleting = (): ITenantDeleting<DeleteWorkOrderMutation, ActualWorkOrderReturnDto, WorkOrderDeleteDtoInput, "data"> => {
    const [deleting] = useDeleteWorkOrderMutation();

    return useMemo<ITenantDeleting<DeleteWorkOrderMutation, ActualWorkOrderReturnDto, WorkOrderDeleteDtoInput, "data">>(() => ({
        modelKey: "data",
        selectionToDto: (selection) => ({id: selection.id}),
        fn: deleting,
    }), [deleting]);
}