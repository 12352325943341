import {useMemo} from "react";
import {
    CheckboxElement,
    CustomRenderElement, FormCustomElement,
    FormTextElement,
    ITenantUpdating,
    TextFieldElement
} from "@ib-tehnologije/react-widgets";
import {
    AccountConsentUpdateDtoInput,
    ApplicationUserUpdateDtoInput,
    SetConsentMutation,
    UpdateApplicationUserMutation, useSetConsentMutation
} from "../graphql-types";
import {ActualApplicationSingleUserReturnDto} from "../ReturnTypes/ActualApplicationUserReturnDto";
import {ActualAccountConsentSingleUserReturnDto} from "../ReturnTypes/ActualAccountConsentReturnDto";
import {useTranslation} from "react-i18next";
import {Tag, Typography} from "antd";
import dayjs from "dayjs";

export const useUserConsentUpdating = (): ITenantUpdating<SetConsentMutation, ActualAccountConsentSingleUserReturnDto, AccountConsentUpdateDtoInput, "dto"> => {
    const [setConsent] = useSetConsentMutation();
    const {t} = useTranslation();

    return useMemo<ITenantUpdating<SetConsentMutation, ActualAccountConsentSingleUserReturnDto, AccountConsentUpdateDtoInput, "dto">>(() => ({
        modelKey: "dto",
        selectionToBaseFormData: (data) => ({
            id: data?.id,
            emailConsentGivenOn: data?.emailConsentGivenOn,
            isEmailConsentGiven: data?.isEmailConsentGiven,
            emailConsentSource: data?.emailConsentSource,
        }),
        formDefinition: {
            hidden: {
                type: new CustomRenderElement({
                    renderer: () => <div style={{width: 450}}/>
                })
            },
            isEmailConsentGiven: {
                type: new CheckboxElement({})
            },
            emailConsentSource: {
                type: new TextFieldElement({})
            },
            emailConsentGivenOn: {
                type: new FormCustomElement({
                    render: (data: any) => {
                        const consentGiven = data?.emailConsentGivenOn;
                        if (consentGiven) {
                            return <Typography.Text>{dayjs(consentGiven).format('LL')}</Typography.Text>;
                        }
                        return <Tag color={"error"} style={{width: "100%"}}>{t("consent-not-given")}</Tag>;
                    },
                })
            }
        },
        formDataToDto: (data) => ({
            id: data.id,
            emailConsentSource: data.emailConsentSource,
            isEmailConsentGiven: data.isEmailConsentGiven
        }),
        fn: setConsent
    }), []);
}