const mimesMapping: {
    [key: string]: string;
} = {
    "application/pdf": "PDF",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "Word",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "Excel",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": "PowerPoint",
    "image/jpeg": "JPG",
    "image/png": "PNG",
    "image/gif": "GIF",
    "image/bmp": "BMP",
    "image/tiff": "TIFF",
    "text/plain": "Text",
    "application/zip": "ZIP",
    "application/x-rar-compressed": "RAR",
    "application/x-7z-compressed": "7z",
    "application/x-tar": "TAR",
    "application/x-gzip": "GZIP",
    "application/x-bzip2": "BZIP2",
    "application/x-xz": "XZ",
    "application/x-lzip": "LZIP",
    "application/x-lzma": "LZMA",
    "application/x-lzop": "LZOP",
    "application/x-lz4": "LZ4",
    "application/x-snappy-framed": "SNAPPY",
    "application/x-zstd": "ZSTD",
    "application/x-compress": "COMPRESS",
    "application/x-ace": "ACE",
    "application/x-arj": "ARJ",
    "application/x-b1": "B1",
    "application/x-bat": "BAT",
    "application/x-cfs-compressed": "CFS",
    "application/x-dar": "DAR",
    "application/x-dgc-compressed": "DGC",
    "application/x-apple-diskimage": "DMG",
    "application/x-gca-compressed": "GCA",
    "application/x-lzh": "LZH",
    "application/x-lzx": "LZX",
    "application/x-rpm": "RPM",
    "application/x-sit": "SIT",
    "application/x-stuffit": "STUFFIT",
    "application/x-tzo": "TZO",
    "application/x-cd-image": "CD",
    "application/x-nrg": "NRG",
}

const mimeTypeTextAfterSlash = (mimeType: string | undefined): string | undefined => {
    if (mimeType) {
        const slashIndex = mimeType.indexOf("/");
        return slashIndex >= 0 ? mimeType.substring(slashIndex + 1) : mimeType;
    } else {
        return undefined;
    }
}

export const mimeTypeToText = (mimeType: string | undefined): string | undefined => {
    if (mimeType) {
        if (mimesMapping[mimeType]) {
            return mimesMapping[mimeType];
        }

        const textAfterSlash = mimeTypeTextAfterSlash(mimeType);
        if (textAfterSlash) {
            return textAfterSlash;
        }
    }
    return undefined;
}