import {
    CustomRenderElement,
    FormEvent,
    ITenantUpdating,
    MultilineTextElement,
    NumericFieldElement
} from "@ib-tehnologije/react-widgets";
import {
    CreateWorkOrderItemMutation, GetWorkOrderTotalsDocument, UpdateWorkOrderItemMutation,
    useCreateWorkOrderItemMutation, useUpdateWorkOrderItemMutation,
    WorkOrderItemCreateDtoInput, WorkOrderItemUpdateDtoInput
} from "../graphql-types";
import {ActualWorkOrderItemReturnDto} from "../ReturnTypes/ActualWorkOrderItemReturnDto";
import {useItemPicker} from "../Pickers/useItemPicker";
import {useFilePicker} from "../Pickers/useFilePicker";
import React, {useMemo} from "react";
import {Descriptions} from "antd";
import {ncFormat} from "../index";
import {useTranslation} from "react-i18next";

export const useWorkOrderItemUpdating = (forWorkOrderId: number): ITenantUpdating<UpdateWorkOrderItemMutation, ActualWorkOrderItemReturnDto, WorkOrderItemUpdateDtoInput, "data"> => {
    const [update] = useUpdateWorkOrderItemMutation({
        refetchQueries: [{
            query: GetWorkOrderTotalsDocument,
            variables: {workOrderId: forWorkOrderId}
        }]
    });
    const itemIdPicker = useItemPicker({
        showDeleted: false,
    });
    const filePicker = useFilePicker();
    const {t} = useTranslation();

    return useMemo<ITenantUpdating<UpdateWorkOrderItemMutation, ActualWorkOrderItemReturnDto, WorkOrderItemUpdateDtoInput, "data">>(() => ({
        modelKey: "data",
        baseFormData: {
            workOrderId: forWorkOrderId
        },
        formDataToDto: formData => ({
            id: formData.id,
            itemId: formData.itemId,
            files: (formData.files ?? []).map((file: any) => ({id: file.response.id})),
            note: formData.note,
            quantity: formData.quantity,
            workOrderId: forWorkOrderId,
            itemUnitPrice: formData.itemUnitPrice
        }),
        formDefinition: {
            itemId: itemIdPicker,
            note: {
                type: new MultilineTextElement({rows: 3}),
            },
            quantity: {
                type: new NumericFieldElement({}),
            },
            itemUnitPrice: {
                type: new NumericFieldElement({}),
                dependsOn: [{
                    key: "itemId",
                    onChanged: async (data, value, initialized) => {
                        if(data?.item?.unitPrice){
                            return [{
                                eventType: FormEvent.UpdateValueAndStorage,
                                payload: {
                                    formKey: "itemUnitPrice",
                                    value: data?.item?.unitPrice,
                                    storage: data?.item?.unitPrice
                                }
                            }];
                        }
                    }
                }]
            },
            files: filePicker,
            totals: {
                type: new CustomRenderElement({
                    renderer: ({values}) => {
                        return <div
                            style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                            <Descriptions bordered size={"small"} column={1}>
                                <Descriptions.Item
                                    style={{textAlign: "end"}}
                                    label={t("vat_base")}>{ncFormat.format(values.vatBase ?? 0)}</Descriptions.Item>
                                <Descriptions.Item
                                    style={{textAlign: "end"}}
                                    label={t("vat")}>{ncFormat.format(values.vat ?? 0)}</Descriptions.Item>
                                <Descriptions.Item
                                    contentStyle={{fontWeight: "bold"}}
                                    style={{textAlign: "end"}}
                                    label={t("total")}>{ncFormat.format(values.total ?? 0)}</Descriptions.Item>
                            </Descriptions>
                        </div>;
                    }
                })
            }
        },
        selectionToBaseFormData: selection => ({
            id: selection?.id,
            itemId: selection?.itemId,
            note: selection?.note,
            quantity: selection?.quantity,
            workOrderId: forWorkOrderId,
            itemUnitPrice: selection?.itemUnitPrice,
            item: selection?.itemId ? {
                id: selection.itemId,
                name: selection.itemName,
                description: selection.itemDescription,
                unitsContained: selection.itemUnitsContained,
                unitPrice: selection.itemUnitPrice
            } : undefined,
            files: (selection?.files ?? [])?.map((file: any) => ({
                fileName: file.fileName,
                name: file.fileName,
                status: "done",
                response: file,
                uid: file.id,
            })),
            vatBase: selection?.vatBase,
            vat: selection?.vat,
            total: selection?.total,
        }),
        fn: update
    }), [update, forWorkOrderId, t]);
}