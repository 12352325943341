import React, {createContext, FC, PropsWithChildren, useMemo, useState} from "react";

interface IReportsPageContextProps {
    element: React.ReactNode | undefined;
    setElement: (elements: React.ReactNode) => void;
}

export const ReportsPageContext = createContext<IReportsPageContextProps>({
    setElement: () => {
    },
    element: undefined
});

export const ReportsPageProvider: FC<PropsWithChildren> = ({children}) => {
    const [element, setElement] = useState<React.ReactNode | undefined>();

    const providerValues = useMemo(() => ({
        element, setElement
    }), [element, setElement]);

    return <ReportsPageContext.Provider value={providerValues}>{children}</ReportsPageContext.Provider>;
}