import React, {FC, useMemo} from "react";
import {
    ITenantCreating,
    ITenantDeleting,
    ITenantUpdating, MultilineTextElement, NumericFieldElement, RenderAntdGraphQLList, TextFieldElement,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {useTranslation} from "react-i18next";
import {
    CreateFinancialAccountMutation,
    DeleteFinancialAccountMutation,
    UpdateFinancialAccountMutation,
    useCreateFinancialAccountMutation,
    useDeleteFinancialAccountMutation,
    useGetFinancialAccountsQuery,
    useUpdateFinancialAccountMutation,
    FinancialAccountCreateDtoInput,
    FinancialAccountDeleteDtoInput,
    FinancialAccountUpdateDtoInput
} from "../graphql-types";
import {ActualFinancialAccountReturnDto} from "../ReturnTypes/ActualFinancialAccountReturnDto";
import {CheckboxChecked20Filled} from "@fluentui/react-icons";
import {Typography} from "antd";

const ManagerFinancialAccountsPage: FC = () => {
    const {t} = useTranslation();
    const q = useGetFinancialAccountsQuery();
    const [updateFinancialAccount] = useUpdateFinancialAccountMutation();
    const [createFinancialAccount] = useCreateFinancialAccountMutation();
    const [deleteFinancialAccount] = useDeleteFinancialAccountMutation();

    const creating = useMemo<ITenantCreating<CreateFinancialAccountMutation, ActualFinancialAccountReturnDto, FinancialAccountCreateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            name: {
                type: new TextFieldElement({})
            },
            description: {
                type: new MultilineTextElement({
                    rows: 3
                })
            },
            number: {
                type: new TextFieldElement({})
            },
            externalId: {
                type: new TextFieldElement({})
            }
        },
        selectionToDuplicateDto: (selection) => ({
            name: selection?.name,
            description: selection?.description,
            number: selection?.number,
            externalId: selection?.externalId
        }),
        formDataToDto: (formData) => ({
            name: formData.name,
            description: formData.description,
            number: formData.number,
            externalId: formData.externalId
        }),
        baseFormData: {
            name: "",
            description: "",
            number: "",
            externalId: ""
        },
        fn: createFinancialAccount
    }), []);


    const updating = useMemo<ITenantUpdating<UpdateFinancialAccountMutation, ActualFinancialAccountReturnDto, FinancialAccountUpdateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            name: {
                type: new TextFieldElement({})
            },
            description: {
                type: new MultilineTextElement({
                    rows: 3
                })
            },
            number: {
                type: new TextFieldElement({})
            },
            externalId: {
                type: new TextFieldElement({})
            }
        },
        selectionToBaseFormData: (selection) => ({
            id: selection?.id,
            name: selection?.name,
            description: selection?.description,
            number: selection?.number,
            externalId: selection?.externalId
        }),
        formDataToDto: (formData) => ({
            id: formData.id,
            name: formData.name,
            description: formData.description,
            number: formData.number,
            externalId: formData.externalId
        }),
        fn: updateFinancialAccount
    }), []);

    const deleting = useMemo<ITenantDeleting<DeleteFinancialAccountMutation, ActualFinancialAccountReturnDto, FinancialAccountDeleteDtoInput, "data">>(() => ({
        modelKey: "data",
        selectionToDto: (selection) => ({
            id: selection?.id
        }),
        fn: deleteFinancialAccount
    }), []);

    return <TitleAndContentLayout title={"financial-accounts"} pad>
        <RenderAntdGraphQLList
            deleting={deleting}
            tableData={q.data?.financialAccounts}
            columns={[{
                columnId: "id",
                renderCell: (row) => <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <Typography.Text>{row.id}</Typography.Text>
                    <Typography.Text type={"secondary"}>{row.externalId}</Typography.Text>
                </div>,
            }, {
                columnId: "name",
                renderCell: (row) => row.name,
            }, {
                columnId: "description",
                renderCell: (row) => row.description,
            }, {
                columnId: "number",
                renderCell: (row) => row.number,
            }]}
            query={q}
            loading={q.loading}
            creating={creating}
            getRowId={g => g.id}
            updating={updating}
        />
    </TitleAndContentLayout>
}

export default ManagerFinancialAccountsPage;