import React, {FC, PropsWithChildren, useContext, useMemo} from "react";
import {UserRole} from "../graphql-types";
import {AuthContext} from "../Contexts/AuthContext";
import {useTranslation} from "react-i18next";
import LeftMainMenuContext from "../Contexts/LeftMainMenuContext";
import "../Styles/LeftMainMenuDrawer.css";
import {MenuDefinition, MenuItemType} from "../Static/MenuDefinition";
import {useLocation, useNavigate} from "react-router-dom";
import {RenderAntdTopBar} from "@ib-tehnologije/react-widgets";
import {SettingOutlined} from "@ant-design/icons";
import {Badge} from "antd";
import {UnassignedPickupRequestCountContext} from "../Contexts/UnassignedPickupRequestCountContext";

const logo = require('../Images/logo192.png');

export const LeftMainMenuDrawer: FC<PropsWithChildren> = ({children}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {openMenu} = useContext(LeftMainMenuContext);
    const {auth: {userName, roles,}, signOut} = useContext(AuthContext);
    const menu = useMemo(() => MenuDefinition(roles), [roles]);
    const loc = useLocation()
    const count = useContext(UnassignedPickupRequestCountContext)

    const homeRoute = useMemo(() => {
        if (roles.includes(UserRole.Manager) || roles.includes(UserRole.Accounting)) {
            return "/Manager";
        } else if (roles.includes(UserRole.PartnerContact)) {
            return "/Partner";
        } else {
            return "/";
        }
    }, [roles]);

    return <RenderAntdTopBar
        onBackClick={() => navigate(-1)}
        onLogoClick={() => navigate(homeRoute)}
        logoIcon={<div
            style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                padding: "10px 0"
            }}>
            <img src={logo} style={{
                flex: 1,
                objectFit: "contain",
                width: "100%",
                maxHeight: 48,
                cursor: "pointer"
            }} alt={"logo"}/>
        </div>}
        menu={{
            theme: "dark",
            mode: "inline",
            defaultSelectedKeys: [],
            selectedKeys: loc.pathname ? [loc.pathname] : [],
            items: menu.map((item) => {
                return ({
                    key: item.route,
                    icon: <Badge dot={!!(item.type === MenuItemType.Administration && count)} size={"small"}>
                        {item.smallIcon ?? item.icon}
                    </Badge>,
                    label: t(item.translationKey),
                    children: item.children?.map((child) => {
                        return ({
                            key: child.route,
                            icon: <Badge dot={!!(child.type === MenuItemType.PickupRequest && count)} size={"small"}>
                                {child.smallIcon ?? child.icon}
                            </Badge>,
                            label: t(child.translationKey),
                            onClick: () => navigate(child.route),
                        });
                    }) ?? [],
                });
            }),
        }}
        userFullName={userName ?? " - "}
        onSignOut={signOut}
        rightEndStartContactSubItems={[{
            label: t("settings"),
            icon: <SettingOutlined/>,
            onClick: () => navigate("/UserSettings"),
            key: "settings"
        }]}>
        {children}
    </RenderAntdTopBar>;
}