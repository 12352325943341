import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {
    AlertApiError,
    CheckboxElement,
    DateElement,
    ExtractLocalisedApiError,
    FormOrientation,
    RenderAntdGraphQLList,
    RenderAntdStandaloneForm,
    TextFieldElement,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {useTranslation} from "react-i18next";
import {
    SortEnumType,
    useDuplicateWorkOrderMutation,
    useGetWorkOrdersLazyQuery,
    useMarkWorkOrdersAsInvoicedMutation,
    useRestoreDeletedWorkOrderMutation,
    useSendWorkOrderPdfToPartnerMutation,
    WorkOrderStatus
} from "../graphql-types";
import {useNavigate} from "react-router-dom";
import {PlainWorkOrderStatusIcon, WorkOrderStatusIcon} from "../Components/WorkOrderStatusIcon";
import {Button, ButtonProps, MenuProps, notification, theme, Tooltip, Typography} from "antd";
import {FilePdfOutlined, RightOutlined, ToolOutlined, UndoOutlined} from "@ant-design/icons";
import {useWorkOrderDeleting} from "../Hooks/useWorkOrderDeleting";
import {useWorkOrderStatusPicker} from "../Hooks/useWorkOrderStatusPicker";
import {useCompanyPicker} from "../Pickers/useCompanyPicker";
import {useCompanyLocationPicker} from "../Pickers/useCompanyLocationPicker";
import {useWorkOrderPriorityPicker} from "../Pickers/useWorkOrderPriorityPicker";
import {useVehiclePicker} from "../Pickers/useVehiclePicker";
import {useUserPicker} from "../Pickers/useUserPicker";
import {useOrganizationalEntityPicker} from "../Pickers/useOrganizationalEntityPicker";
import dayjs, {Dayjs} from "dayjs";
import {ActualWorkOrderReturnDto} from "../ReturnTypes/ActualWorkOrderReturnDto";
import {SignatureRegular} from "@fluentui/react-icons";
import {signableStatuses} from "../Helpers/signableStatuses";
import {nFormat} from "../index";
import {useLocalStorage} from "usehooks-ts";

const ManagerWorkOrdersPage: FC = () => {
    const {t} = useTranslation();
    const [baseSearchData, setBaseSearchData] = useLocalStorage<any>("workordersfilter", {isDeleted: false});
    const [filterLoading, setFilterLoading] = useState(false);
    const [a, q] = useGetWorkOrdersLazyQuery();
    const [duplicateWorkOrder, duplicationData] = useDuplicateWorkOrderMutation();
    const [restoreWorkOrder, restorationData] = useRestoreDeletedWorkOrderMutation();
    const [isRestoring, setIsRestoring] = useState(false);
    const deleting = useWorkOrderDeleting();
    const navigate = useNavigate();
    const statusPicker = useWorkOrderStatusPicker();
    const companyIdPicker = useCompanyPicker();
    const companyLocationIdPicker = useCompanyLocationPicker();
    const priorityPicker = useWorkOrderPriorityPicker();
    const vehicleIdPicker = useVehiclePicker();
    const userIdPicker = useUserPicker();
    const organizationalEntityIdPicker = useOrganizationalEntityPicker();
    const [sendPDFWorkOrders, setSendPDFWorkOrders] = useState<ActualWorkOrderReturnDto[] | undefined>(undefined);
    const [sendPDFToEmail] = useSendWorkOrderPdfToPartnerMutation({});
    const [api, contextHolder] = notification.useNotification();
    const [loadingPDFExport, setLoadingPDFExport] = useState(false);
    const [markingAsInvoiced, setMarkingAsInvoiced] = useState(false);
    const [executeMarkAsInvoiced] = useMarkWorkOrdersAsInvoicedMutation();
    const [sendPDFCalculationWorkOrders, setSendPDFCalculationWorkOrders] = useState<ActualWorkOrderReturnDto[] | undefined>(undefined);
    const {
        token: {
            colorErrorText,
            colorTextDisabled,
            colorSuccessText,
            colorWarning,
            blue,
            purple,
            cyan,
            yellow6,
            green7
        },
    } = theme.useToken();

    useEffect(() => {
        if (!filterLoading) {
            setFilterLoading(true);
            a({
                variables: {
                    order: {
                        id: SortEnumType.Desc
                    },
                    dto: baseSearchData ? {
                        isDeleted: baseSearchData.isDeleted,
                        search: baseSearchData.search,
                        status: baseSearchData.status,
                        companyId: baseSearchData.companyId,
                        companyLocationId: baseSearchData.companyLocationId,
                        assignedUserId: baseSearchData.assignedUserId,
                        vehicleId: baseSearchData.vehicleId,
                        priority: baseSearchData.priority,
                        organizationalEntityId: baseSearchData.organizationalEntityId,
                        hasSignedPDF: baseSearchData.hasSignedPDF,
                        scheduledDateFrom: baseSearchData.scheduledDateFrom ? dayjs(baseSearchData.scheduledDateFrom).startOf('day') : undefined,
                        scheduledDateTo: baseSearchData.scheduledDateTo ? dayjs(baseSearchData.scheduledDateTo).endOf('day') : undefined,
                    } : {}
                }
            }).finally(() => setFilterLoading(false));
        }
    }, [baseSearchData]);

    const searchFormDefinition = useMemo(() => ({
        search: {
            type: new TextFieldElement({})
        },
        status: statusPicker,
        hasSignedPDF: {
            type: new CheckboxElement({
                tristate: true,
            })
        },
        isDeleted: {
            type: new CheckboxElement({
                tristate: true,
            })
        },
        companyId: companyIdPicker,
        companyLocationId: companyLocationIdPicker,
        assignedUserId: userIdPicker,
        vehicleId: vehicleIdPicker,
        priority: priorityPicker,
        organizationalEntityId: organizationalEntityIdPicker,
        scheduledDateFrom: {
            type: new DateElement({})
        },
        scheduledDateTo: {
            type: new DateElement({})
        },
    }), [statusPicker]);

    const inlineSelectionButtons = useCallback((selectedItems: ActualWorkOrderReturnDto[] | undefined) => {
        const buttons: ButtonProps[] = [];

        if (selectedItems?.length) {
            buttons.push({
                onClick: () => {
                    setSendPDFWorkOrders(selectedItems);
                },
                children: t("send-pdf"),
                icon: <FilePdfOutlined/>
            });
        }

        if (selectedItems?.length === 1 && selectedItems[0].isDeleted) {
            buttons.push({
                onClick: async () => {
                    setIsRestoring(true);
                    try {
                        await restoreWorkOrder({
                            variables: {
                                workOrderId: selectedItems[0].id
                            }
                        });

                        api.success({message: t("work-order-restored")});

                        q.refetch();
                    } catch (e: any) {
                        AlertApiError(e);
                    } finally {
                        setIsRestoring(false);
                    }
                },
                loading: isRestoring,
                children: t("restore"),
                icon: <UndoOutlined/>
            });
        }

        return buttons;
    }, [isRestoring, t, restoreWorkOrder, q, api]);

    const menuSelectionButtons = useCallback((selectedItems: ActualWorkOrderReturnDto[] | undefined): MenuProps["items"] => {
        const ret: MenuProps["items"] = [{
            title: "prints",
            key: "prints",
            label: t("prints"),
            icon: <FilePdfOutlined/>,
            children: [{
                onClick: () => {
                    setSendPDFWorkOrders(selectedItems);
                },
                title: t("send-pdf"),
                icon: <FilePdfOutlined/>,
                key: "send-pdf",
                label: t("send-pdf"),
            }, {
                onClick: async () => {
                    setLoadingPDFExport(true);
                    try {
                        const ids = selectedItems?.map(w => w.id);
                        const url = `/api/Exports/PDFWorkOrders?ids=${ids?.join(",")}&access_token=${JSON.parse(localStorage.getItem("authState")!).token}`;
                        console.log(window.location.host + url);
                        const blob = await fetch(url).then(r => r.blob());
                        const urlBlob = URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = urlBlob;
                        a.download = 'radni-nalozi.pdf';
                        a.click();
                        URL.revokeObjectURL(urlBlob);
                    } catch (e) {
                        console.error(e);
                        AlertApiError(e as any);
                    } finally {
                        setLoadingPDFExport(false);
                    }
                },
                title: t("pdf_export"),
                icon: <FilePdfOutlined/>,
                key: "pdf-export",
                label: t("pdf_export"),
            }, {
                onClick: async () => {
                    setSendPDFCalculationWorkOrders(selectedItems);
                },
                title: t("pdf_export_calculation"),
                icon: <FilePdfOutlined/>,
                key: "pdf-export-calculation",
                label: t("pdf_export_calculation"),
            }]
        }];

        if (selectedItems?.length) {
            ret.push({
                title: "actions",
                key: "actions",
                label: t("actions"),
                icon: <ToolOutlined/>,
                children: [{
                    onClick: async () => {
                        setMarkingAsInvoiced(true);
                        const ids = selectedItems?.map(w => w.id);
                        await executeMarkAsInvoiced({
                            variables: {
                                workOrderIds: ids
                            }
                        }).then(() => {
                            api.success({message: t("work-orders-marked-as-invoiced")});
                            q.refetch();
                        }).catch((e) => {
                            api.error({message: ExtractLocalisedApiError(e)});
                        }).finally(() => setMarkingAsInvoiced(false));
                    },
                    title: t("mark-as-invoiced"),
                    icon: <div style={{display: "flex", flexDirection: "row", gap: 5}}>
                        <PlainWorkOrderStatusIcon status={WorkOrderStatus.SentToErp} size={22}/>
                        <Typography.Text>{t("mark-as-invoiced")}</Typography.Text>
                    </div>,
                    key: "mark-as-invoiced",
                }]
            });
        }

        return ret;
    }, [t, executeMarkAsInvoiced, api, q]);

    const sendPDFWorkOrdersTo = useCallback((data: any) => {
        if (sendPDFWorkOrders?.length) {
            sendPDFToEmail({
                variables: {
                    dto: {
                        workOrderIds: sendPDFWorkOrders.map(w => w.id),
                        directToEmail: data.directToEmail,
                        contactId: data.contactId
                    }
                }
            }).then(() => {
                api.success({message: t("pdf-sent")});

                setSendPDFWorkOrders(undefined);
            }).catch((e) => {
                api.error({message: ExtractLocalisedApiError(e)});
            });
        } else {
            api.error({message: t("work-order-not-found")});
        }

    }, [sendPDFWorkOrders]);

    const onDuplicateClicked = useCallback(async (item: ActualWorkOrderReturnDto) => {
        try {
            await duplicateWorkOrder({
                variables: {
                    workOrderId: item.id
                }
            });
        } catch (e: any) {
            AlertApiError(e);
        } finally {
            q.refetch();
        }
    }, [duplicateWorkOrder]);

    const sendPDFCalculationWorkOrdersTo = useCallback(async (date: Dayjs) => {
        try {
            const ids = sendPDFCalculationWorkOrders?.map(w => w.id);
            const url = `/api/Exports/PDFCalculationWorkOrders?forDate=${date.toISOString()}&ids=${ids?.join(",")}&access_token=${JSON.parse(localStorage.getItem("authState")!).token}`;
            console.log(window.location.host + url);
            const blob = await fetch(url).then(r => r.blob());
            const urlBlob = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = urlBlob;
            a.download = 'radni-nalozi.pdf';
            a.click();
            URL.revokeObjectURL(urlBlob);
            setSendPDFCalculationWorkOrders(undefined);
        } catch (e) {
            console.error(e);
            AlertApiError(e as any);
        }
    }, [sendPDFCalculationWorkOrders]);

    return <TitleAndContentLayout title={"work-orders"} pad>
        {contextHolder}
        {sendPDFWorkOrders?.length ? <RenderAntdStandaloneForm
            loading={false}
            formSettings={{
                isOpen: !!sendPDFWorkOrders?.length,
                setIsOpen: isOpen => !isOpen ? setSendPDFWorkOrders(undefined) : undefined,
                orientation: FormOrientation.VERTICAL,
                trigger: (c) => <div/>
            }}
            title={t("send-pdf-to")}
            formDefinition={{
                contactId: companyIdPicker,
                directToEmail: {
                    type: new TextFieldElement({})
                }
            }}
            submission={{title: t("send-pdf"), onSubmit: (data) => sendPDFWorkOrdersTo(data)}}
            baseFormData={baseSearchData}
        /> : null}
        {sendPDFCalculationWorkOrders?.length ? <RenderAntdStandaloneForm
            loading={false}
            formSettings={{
                isOpen: !!sendPDFCalculationWorkOrders?.length,
                setIsOpen: isOpen => !isOpen ? setSendPDFCalculationWorkOrders(undefined) : undefined,
                orientation: FormOrientation.VERTICAL,
                trigger: (c) => <div/>
            }}
            title={t("calculation-pdf-settings")}
            formDefinition={{
                calculationDate: {
                    type: new DateElement({})
                }
            }}
            submission={{title: t("create-calculation-pdf"), onSubmit: (data) => sendPDFCalculationWorkOrdersTo(data.calculationDate)}}
            baseFormData={{
                calculationDate: dayjs().startOf('day')
            }}
        /> : null}
        <div style={{display: "flex", flexDirection: "row", gap: 10}}>
            <div style={{width: 250}}>
                <RenderAntdStandaloneForm
                    loading={q.loading}
                    baseFormData={baseSearchData}
                    formSettings={{inline: true, orientation: FormOrientation.VERTICAL}}
                    submission={{
                        title: "search", onSubmit: (values) => {
                            setBaseSearchData({
                                ...values,
                                isDeleted: values.isDeleted,
                                search: values.search,
                                status: values.status,
                                companyId: values.companyId,
                                companyLocationId: values.companyLocationId,
                                assignedUserId: values.assignedUserId,
                                vehicleId: values.vehicleId,
                                priority: values.priority,
                                organizationalEntityId: values.organizationalEntityId,
                                hasSignedPDF: values.hasSignedPDF,
                                scheduledDateFrom: values.scheduledDateFrom ? dayjs(values.scheduledDateFrom).startOf('day') : undefined,
                                scheduledDateTo: values.scheduledDateTo ? dayjs(values.scheduledDateTo).endOf('day') : undefined,
                            });
                            // q.refetch({
                            //     dto: {
                            //         isDeleted: values.isDeleted,
                            //         search: values.search,
                            //         status: values.status,
                            //         companyId: values.companyId,
                            //         companyLocationId: values.companyLocationId,
                            //         assignedUserId: values.assignedUserId,
                            //         vehicleId: values.vehicleId,
                            //         priority: values.priority,
                            //         organizationalEntityId: values.organizationalEntityId,
                            //         hasSignedPDF: values.hasSignedPDF,
                            //         scheduledDateFrom: values.scheduledDateFrom ? dayjs(values.scheduledDateFrom).startOf('day') : undefined,
                            //         scheduledDateTo: values.scheduledDateTo ? dayjs(values.scheduledDateTo).endOf('day') : undefined,
                            //     }
                            // });
                        }
                    }}
                    formDefinition={searchFormDefinition}/>
            </div>
            <div style={{flex: 1}}>
                <RenderAntdGraphQLList
                    onAddClicked={() => navigate("/Manager/WorkOrders/Create")}
                    onEditClicked={(item) => navigate(`/Manager/WorkOrder/${item.id}`)}
                    deleting={deleting}
                    selectionButtons={menuSelectionButtons}
                    onDuplicateClicked={onDuplicateClicked}
                    inlineSelectionButtons={inlineSelectionButtons}
                    tableData={q.data?.workOrders}
                    columns={[{
                        columnId: "id",
                        sorter: true,
                        width: 30,
                        renderCell: (row) => row.formattedId,
                    }, {
                        columnId: "currentStatusStatus",
                        width: 80,
                        sorter: true,
                        renderCell: (row) =>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                verticalAlign: "center",
                                alignItems: "start",
                                justifyContent: "space-between"
                            }}>
                                <WorkOrderStatusIcon status={row.currentStatus?.status}/>
                                {signableStatuses.includes(row.currentStatus?.status) ? !row.signedPDFs.length ?
                                    <Tooltip title={t("workOrderNotSigned")}>
                                        <span>
                                            <SignatureRegular fontSize={30} color={colorErrorText}/>
                                        </span>
                                    </Tooltip> : <Tooltip title={t("workOrderHasASignature")}>
                                        <span>
                                            <SignatureRegular fontSize={30} color={colorSuccessText}/>
                                        </span>
                                    </Tooltip> : null}
                            </div>,
                    }, {
                        columnId: "companyName",
                        sorter: true,
                        width: 200,
                        renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                            <Typography.Text>{row.company?.name ?? row.freeformCompany}</Typography.Text>
                            <Typography.Text
                                type={"secondary"}>{row.companyLocation?.address ?? row.freeformLocation}</Typography.Text>
                        </div>,
                    }, {
                        columnId: "workOrderItems",
                        sorter: true,
                        width: 400,
                        renderCell: (row) => {
                            const [item1, item2, item3] = row.items;

                            return <div style={{display: "flex", flexDirection: "column"}}>
                                {item1 ? <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        borderBottom: "1px solid lightgray"
                                    }}>
                                    <div>
                                        <Typography.Text type={"secondary"}>1. </Typography.Text>
                                        <Typography.Text>{item1.itemName ?? item1.itemId} </Typography.Text>
                                    </div>
                                    <Typography.Text
                                        style={{whiteSpace: "pre"}}>{nFormat.format(item1.quantity)} x {nFormat.format(item1.itemUnitsContained)} {item1.unitOfMeasureSymbol ?? ""}</Typography.Text>
                                </div> : null}
                                {item2 ? <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        borderBottom: "1px solid lightgray"
                                    }}>
                                    <div>
                                        <Typography.Text type={"secondary"}>2. </Typography.Text>
                                        <Typography.Text>{item2.itemName ?? item2.itemId} </Typography.Text>
                                    </div>
                                    <Typography.Text
                                        style={{whiteSpace: "pre"}}>{nFormat.format(item2.quantity)} x {nFormat.format(item2.itemUnitsContained)} {item2.unitOfMeasureSymbol ?? ""}</Typography.Text>
                                </div> : null}
                                {item3 ? <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        borderBottom: "1px solid lightgray"
                                    }}>
                                    <div>
                                        <Typography.Text type={"secondary"}>3. </Typography.Text>
                                        <Typography.Text>{item3.itemName ?? item3.itemId} </Typography.Text>
                                    </div>
                                    <Typography.Text
                                        style={{whiteSpace: "pre"}}>{nFormat.format(item3.quantity)} x {nFormat.format(item3.itemUnitsContained)} {item3.unitOfMeasureSymbol ?? ""}</Typography.Text>
                                </div> : null}
                            </div>;
                        },
                    }, {
                        columnId: "executionResource",
                        width: 200,
                        renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                            <Typography.Text>{row.vehicle?.name ?? " - "}</Typography.Text>
                            <Typography.Text>{row.organizationalEntity?.name ?? " - "}</Typography.Text>
                        </div>,
                    }, {
                        columnId: "scheduledDate",
                        title: "scheduledAndFinishedDate",
                        sorter: true,
                        width: 110,
                        renderCell: (row) => {
                            const scheduledDateLocal = dayjs(row.scheduledDate);
                            const endDateLocal = dayjs(row.endDateTime);

                            return <div style={{display: "flex", flexDirection: "column"}}>
                                <Typography.Text>{row.scheduledDate ? scheduledDateLocal.format('L') : " - "}</Typography.Text>
                                <Typography.Text>{row.endDateTime ? endDateLocal.format('L') : " - "}</Typography.Text>
                                {/*<Typography.Text type={"secondary"}>{scheduledDateLocal.fromNow()}</Typography.Text>*/}
                            </div>;
                        },
                    }, {
                        columnId: "actions",
                        renderCell: (row) => <Button
                            type={"primary"}
                            onClick={() => navigate(`/Manager/WorkOrder/${row.id}`, {state: row})}>
                            {t("details")}
                            <RightOutlined/>
                        </Button>,
                    }]}
                    query={q}
                    loading={q.loading}
                    getRowId={g => g.id}/>
            </div>
        </div>
    </TitleAndContentLayout>;
}

export default ManagerWorkOrdersPage;

// TODO Omogućiti grupno printanje radnih naloga, npr označi se kvačicom sve naloge koje je potrebno isprintati ili spremiti na PC (batch proces).