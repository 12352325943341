import {
    AlertApiError, AntdLoginForm,
    AnyError, ILoginFormState,
    LoginFormContext
} from "@ib-tehnologije/react-widgets";
import React, {FC, useCallback, useContext, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../Contexts/AuthContext";
import {LoginContext} from "../Contexts/LoginContext";
import {useLogMeInMutation, useResendConfirmationEmailMutation} from "../graphql-types";
import {ApolloError} from "@apollo/client";
import {Button, Result, Typography} from "antd";

const logo = require('../Images/1maj.png');

const LoginPage: FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {setToken} = useContext(AuthContext);
    const {loginState, setLoginState} = useContext(LoginContext);
    const {state: loginFormState, setState} = useContext(LoginFormContext);
    const [login, {data, loading, error}] = useLogMeInMutation();
    const [resendConfirmationEmail, {
        data: resendConfirmationEmailData,
        loading: resendConfirmationEmailLoading,
        error: resendConfirmationEmailError
    }] = useResendConfirmationEmailMutation();
    const [resendingConfirmationEmail, setResendingConfirmationEmail] = useState(false);

    const [loginError, setLoginError] = useState<AnyError<any> | undefined>(undefined);

    const userEmailNotConfirmed = useMemo(() => {
        if (loginError) {
            if (loginError instanceof ApolloError) {
                const apolloError = loginError as ApolloError;
                if (apolloError.networkError) {
                    const resultData = (apolloError.networkError as any).result;
                    if (resultData) {
                        const firstNetworkError = resultData?.errors[0];
                        if (firstNetworkError.extensions) {
                            const extension = firstNetworkError.extensions;
                            if (extension.message === "User email not confirmed" || extension?.INNER_EXCEPTION === "User email not confirmed") {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    }, [loginError]);

    const submitted = useCallback(async (data: ILoginFormState) => {
        setLoginState({});
        setLoginError(undefined);

        try {
            const loginResponse = await login({
                variables: {
                    username: data.username,
                    password: data.password
                }
            });

            if (loginResponse.data) {
                setToken(loginResponse.data.login.token);
                navigate('/');
            }
        } catch (e) {
            setLoginError(e as AnyError<any>);
            throw e;
        }
    }, [navigate, login]);

    const onSignUpClick = useCallback(() => {
        navigate('/SignUp');
    }, [navigate]);

    const resendConfirmationEmailClicked = useCallback(async () => {
        setResendingConfirmationEmail(true);
        try {
            await resendConfirmationEmail({
                variables: {
                    model: {
                        username: loginFormState.username
                    }
                }
            });
            setLoginState({resendConfirmationEmailSuccess: true});
            setLoginError(undefined);
        } catch (e) {
            setLoginState({});
            setLoginError(undefined);
            AlertApiError(e as any);
        }finally {
            setResendingConfirmationEmail(false);
        }
    }, [loginFormState, resendConfirmationEmail]);

    const showingSomeState = useMemo(() => {
        return loginState.justConfirmedEmail || loginState.appEntryError || loginState.signUpSuccess || loginState.resendConfirmationEmailSuccess || userEmailNotConfirmed;
    }, [loginState, userEmailNotConfirmed]);
    
    const clearAllState = useCallback(() => {
        setLoginState({});
        setLoginError(undefined);
    }, []);

    return <div>
        {showingSomeState ? null :
            <AntdLoginForm submitted={submitted} logoSrc={logo} logoFullWidth
                           // onSignUpClick={onSignUpClick} // no signup
            />}
        {loginState.justConfirmedEmail &&
            <Result
                style={{
                    verticalAlign: "middle",
                    margin: "auto",
                }}
                status="success"
                title={t("EmailConfirmed_Success")}
                extra={[
                    <Button type="primary" key="gotologin" onClick={() => setLoginState({})}>
                        {t("BackGoToLogin")}
                    </Button>,
                ]}
            />}
        {loginState.appEntryError && <Result
            status="error"
            title={t(loginState.appEntryError)}
            extra={[
                <Button type="primary" key="gotologin" onClick={clearAllState}>
                    {t("BackGoToLogin")}
                </Button>,
            ]}
        />}
        {loginState.signUpSuccess && <Result
            status="success"
            title={t("SignUp_Success")}
            extra={[
                <Button type="primary" key="goToLogin" onClick={() => setLoginState({})}>
                    {t("BackGoToLogin")}
                </Button>,
            ]}/>}
        {userEmailNotConfirmed && <Result
            status="error"
            title={t("EmailNotConfirmed")}
            extra={[
                <Button type="primary" key="goToLogin" onClick={() => setLoginError(undefined)}>
                    {t("BackGoToLogin")}
                </Button>,
                <Button key="resendConfirmationEmail" loading={resendingConfirmationEmail} onClick={resendConfirmationEmailClicked}>
                    {t("ResendConfirmationEmail")}
                </Button>,
            ]}/>}
        {loginState.resendConfirmationEmailSuccess && <Result
            status="success"
            title={t("ResendConfirmationEmail_Success")}
            extra={[
                <Button type="primary" key="goToLogin" onClick={() => setLoginState({})}>
                    {t("BackGoToLogin")}
                </Button>,
            ]}/>}
    </div>;
}

export default LoginPage;