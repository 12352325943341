import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A coordinate is an array of positions. */
  Coordinates: { input: any; output: any; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any; }
  /** The built-in `Decimal` scalar type. */
  Decimal: { input: any; output: any; }
  Geometry: { input: any; output: any; }
  /**
   * A position is an array of numbers. There MUST be two or more elements. The first
   * two elements are longitude and latitude, or easting and northing, precisely in
   * that order and using decimal numbers. Altitude or elevation MAY be included as
   * an optional third element.
   */
  Position: { input: any; output: any; }
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: { input: any; output: any; }
};

export type AccountChangePasswordDtoInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type AccountClaimDto = {
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AccountConsentReturnDto = {
  emailConsentGivenOn?: Maybe<Scalars['DateTime']['output']>;
  emailConsentSource?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isEmailConsentGiven?: Maybe<Scalars['Boolean']['output']>;
};

export type AccountConsentReturnDtoFilterInput = {
  and?: InputMaybe<Array<AccountConsentReturnDtoFilterInput>>;
  emailConsentGivenOn?: InputMaybe<DateTimeOperationFilterInput>;
  emailConsentSource?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  isEmailConsentGiven?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<AccountConsentReturnDtoFilterInput>>;
};

export type AccountConsentUpdateDtoInput = {
  emailConsentSource?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isEmailConsentGiven?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountDeleteDtoInput = {
  username: Scalars['String']['input'];
};

export type AccountLoginDtoInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type AccountLoginResponseDto = {
  token: Scalars['String']['output'];
};

export type AccountMobileLoginDtoInput = {
  notificationsRegistrationToken?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type AccountMobileRefreshTokenDtoInput = {
  notificationsRegistrationToken?: InputMaybe<Scalars['String']['input']>;
};

export type AccountRegisterExtendedDtoInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  userName: Scalars['String']['input'];
  userRole?: InputMaybe<UserRole>;
};

export type AccountResendConfirmationEmailDtoInput = {
  username: Scalars['String']['input'];
};

export type AccountResetPasswordDtoInput = {
  newPassword: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type AccountUpdateDtoInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicationRole = {
  concurrencyStamp?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  normalizedName?: Maybe<Scalars['String']['output']>;
  userRoles: Array<ApplicationUserRole>;
};

export type ApplicationRoleFilterInput = {
  and?: InputMaybe<Array<ApplicationRoleFilterInput>>;
  concurrencyStamp?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  normalizedName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ApplicationRoleFilterInput>>;
  userRoles?: InputMaybe<ListFilterInputTypeOfApplicationUserRoleFilterInput>;
};

export type ApplicationUser = {
  accessFailedCount: Scalars['Int']['output'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']['output']>;
  companyPrimaryContact?: Maybe<Scalars['Boolean']['output']>;
  concurrencyStamp?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentOrganizationalEntity?: Maybe<OrganizationalEntity>;
  currentOrganizationalEntityId?: Maybe<Scalars['Int']['output']>;
  currentVehicle?: Maybe<Vehicle>;
  currentVehicleId?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailConfirmed: Scalars['Boolean']['output'];
  emailConsentGivenOn?: Maybe<Scalars['DateTime']['output']>;
  emailConsentSource?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isEmailConsentGiven?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lockoutEnabled: Scalars['Boolean']['output'];
  lockoutEnd?: Maybe<Scalars['DateTime']['output']>;
  normalizedEmail?: Maybe<Scalars['String']['output']>;
  normalizedUserName?: Maybe<Scalars['String']['output']>;
  notificationsRegistrationToken?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberConfirmed: Scalars['Boolean']['output'];
  preferredLanguage: Scalars['String']['output'];
  securityStamp?: Maybe<Scalars['String']['output']>;
  twoFactorEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userName?: Maybe<Scalars['String']['output']>;
  userRoles: Array<ApplicationUserRole>;
};

export type ApplicationUserFilterDtoInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<UserRole>;
};

export type ApplicationUserFilterInput = {
  accessFailedCount?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<ApplicationUserFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  companyPrimaryContact?: InputMaybe<BooleanOperationFilterInput>;
  concurrencyStamp?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  currentOrganizationalEntity?: InputMaybe<OrganizationalEntityFilterInput>;
  currentOrganizationalEntityId?: InputMaybe<IntOperationFilterInput>;
  currentVehicle?: InputMaybe<VehicleFilterInput>;
  currentVehicleId?: InputMaybe<IntOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  emailConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  emailConsentGivenOn?: InputMaybe<DateTimeOperationFilterInput>;
  emailConsentSource?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isEmailConsentGiven?: InputMaybe<BooleanOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  lockoutEnabled?: InputMaybe<BooleanOperationFilterInput>;
  lockoutEnd?: InputMaybe<DateTimeOperationFilterInput>;
  normalizedEmail?: InputMaybe<StringOperationFilterInput>;
  normalizedUserName?: InputMaybe<StringOperationFilterInput>;
  notificationsRegistrationToken?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ApplicationUserFilterInput>>;
  passwordHash?: InputMaybe<StringOperationFilterInput>;
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  phoneNumberConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  preferredLanguage?: InputMaybe<StringOperationFilterInput>;
  securityStamp?: InputMaybe<StringOperationFilterInput>;
  twoFactorEnabled?: InputMaybe<BooleanOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  userName?: InputMaybe<StringOperationFilterInput>;
  userRoles?: InputMaybe<ListFilterInputTypeOfApplicationUserRoleFilterInput>;
};

export type ApplicationUserReturnDto = {
  company?: Maybe<CompanyReturnDto>;
  companyId?: Maybe<Scalars['Int']['output']>;
  currentOrganizationalEntity?: Maybe<OrganizationalEntityReturnDto>;
  currentOrganizationalEntityId?: Maybe<Scalars['Int']['output']>;
  currentVehicle?: Maybe<VehicleReturnDto>;
  currentVehicleId?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailConfirmed: Scalars['Boolean']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  preferredLanguage?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type ApplicationUserReturnDtoFilterInput = {
  and?: InputMaybe<Array<ApplicationUserReturnDtoFilterInput>>;
  company?: InputMaybe<CompanyReturnDtoFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  currentOrganizationalEntity?: InputMaybe<OrganizationalEntityReturnDtoFilterInput>;
  currentOrganizationalEntityId?: InputMaybe<IntOperationFilterInput>;
  currentVehicle?: InputMaybe<VehicleReturnDtoFilterInput>;
  currentVehicleId?: InputMaybe<IntOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  emailConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ApplicationUserReturnDtoFilterInput>>;
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  preferredLanguage?: InputMaybe<StringOperationFilterInput>;
  userName?: InputMaybe<StringOperationFilterInput>;
};

export type ApplicationUserReturnDtoSortInput = {
  company?: InputMaybe<CompanyReturnDtoSortInput>;
  companyId?: InputMaybe<SortEnumType>;
  currentOrganizationalEntity?: InputMaybe<OrganizationalEntityReturnDtoSortInput>;
  currentOrganizationalEntityId?: InputMaybe<SortEnumType>;
  currentVehicle?: InputMaybe<VehicleReturnDtoSortInput>;
  currentVehicleId?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  emailConfirmed?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  preferredLanguage?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

export type ApplicationUserRole = {
  role: ApplicationRole;
  roleId: Scalars['String']['output'];
  user: ApplicationUser;
  userId: Scalars['String']['output'];
};

export type ApplicationUserRoleFilterInput = {
  and?: InputMaybe<Array<ApplicationUserRoleFilterInput>>;
  or?: InputMaybe<Array<ApplicationUserRoleFilterInput>>;
  role?: InputMaybe<ApplicationRoleFilterInput>;
  roleId?: InputMaybe<StringOperationFilterInput>;
  user?: InputMaybe<ApplicationUserFilterInput>;
  userId?: InputMaybe<StringOperationFilterInput>;
};

export type ApplicationUserSortInput = {
  accessFailedCount?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  companyPrimaryContact?: InputMaybe<SortEnumType>;
  concurrencyStamp?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  currentOrganizationalEntity?: InputMaybe<OrganizationalEntitySortInput>;
  currentOrganizationalEntityId?: InputMaybe<SortEnumType>;
  currentVehicle?: InputMaybe<VehicleSortInput>;
  currentVehicleId?: InputMaybe<SortEnumType>;
  deletedAt?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  emailConfirmed?: InputMaybe<SortEnumType>;
  emailConsentGivenOn?: InputMaybe<SortEnumType>;
  emailConsentSource?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  fullName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isEmailConsentGiven?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  lockoutEnabled?: InputMaybe<SortEnumType>;
  lockoutEnd?: InputMaybe<SortEnumType>;
  normalizedEmail?: InputMaybe<SortEnumType>;
  normalizedUserName?: InputMaybe<SortEnumType>;
  notificationsRegistrationToken?: InputMaybe<SortEnumType>;
  passwordHash?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  phoneNumberConfirmed?: InputMaybe<SortEnumType>;
  preferredLanguage?: InputMaybe<SortEnumType>;
  securityStamp?: InputMaybe<SortEnumType>;
  twoFactorEnabled?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

export type ApplicationUserUpdateDtoInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  currentOrganizationalEntityId?: InputMaybe<Scalars['Int']['input']>;
  currentVehicleId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type BaseMutation = {
  addToRoleById: Array<UserRole>;
  batchChangeWorkOrdersStatus: Scalars['Boolean']['output'];
  changePassword: Scalars['Boolean']['output'];
  confirmEmail: Scalars['Boolean']['output'];
  confirmEmailManually: Scalars['Boolean']['output'];
  createCompany: Array<CompanyReturnDto>;
  createCompanyLocation: Array<CompanyLocationReturnDto>;
  createCostLocation: Array<CostLocationReturnDto>;
  createFinancialAccount: Array<FinancialAccountReturnDto>;
  createItem: Array<ItemReturnDto>;
  createOrganizationalEntity: Array<OrganizationalEntityReturnDto>;
  createPartnerPickupRequest: Array<PartnerPickupRequestReturnDto>;
  createPartnerPortalNote: Array<PartnerPortalNoteReturnDto>;
  createPickupRequest: Array<PickupRequestFilteredReturnDto>;
  createPicture: Array<FileReturnDto>;
  createUnitOfMeasure: Array<UnitOfMeasureReturnDto>;
  createVATType: Array<VatTypeReturnDto>;
  createVehicle: Array<VehicleReturnDto>;
  createWorkOrder: Array<WorkOrderReturnDto>;
  createWorkOrderItem: Array<WorkOrderItemReturnDto>;
  createWorkOrderPDF: Scalars['Int']['output'];
  createWorkOrderStatusChange: Array<WorkOrderStatusChangeReturnDto>;
  createWorkOrderTemplate: Array<WorkOrderTemplateReturnDto>;
  createWorkOrderTemplateItem: Array<WorkOrderTemplateItemReturnDto>;
  delete: Scalars['Boolean']['output'];
  deleteCompany: Scalars['Boolean']['output'];
  deleteCompanyLocation: Scalars['Boolean']['output'];
  deleteCostLocation: Scalars['Boolean']['output'];
  deleteFile: Scalars['Boolean']['output'];
  deleteFinancialAccount: Scalars['Boolean']['output'];
  deleteItem: Scalars['Boolean']['output'];
  deleteOrganizationalEntity: Scalars['Boolean']['output'];
  deletePartnerPickupRequest: Scalars['Boolean']['output'];
  deletePartnerPortalNote: Scalars['Boolean']['output'];
  deletePickupRequest: Scalars['Boolean']['output'];
  deletePicture: Scalars['Boolean']['output'];
  deleteUnitOfMeasure: Scalars['Boolean']['output'];
  deleteVATType: Scalars['Boolean']['output'];
  deleteVehicle: Scalars['Boolean']['output'];
  deleteWorkOrder: Scalars['Boolean']['output'];
  deleteWorkOrderItem: Scalars['Boolean']['output'];
  deleteWorkOrderStatusChange: Scalars['Boolean']['output'];
  deleteWorkOrderTemplate: Scalars['Boolean']['output'];
  deleteWorkOrderTemplateItem: Scalars['Boolean']['output'];
  duplicateWorkOrder: Array<WorkOrderReturnDto>;
  fieldWorkerAcceptsWorkOrder: Scalars['Boolean']['output'];
  fieldWorkerDeclinesWorkOrder: Scalars['Boolean']['output'];
  fieldWorkerPicksAVehicle: Scalars['Boolean']['output'];
  fieldWorkerPicksOrganizationalEntity: Scalars['Boolean']['output'];
  fieldWorkerSubmitsCompletedWorkOrder: Scalars['Boolean']['output'];
  generateWorkOrdersFromTemplates: Scalars['Boolean']['output'];
  login: AccountLoginResponseDto;
  mobileLogin: AccountLoginResponseDto;
  mobileRefreshToken: AccountLoginResponseDto;
  refreshToken: AccountLoginResponseDto;
  register: Scalars['Boolean']['output'];
  removeFromRoleById: Array<UserRole>;
  resendConfirmationEmail: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  restoreDeletedItem: Array<ItemReturnDto>;
  restoreDeletedWorkOrder: Array<WorkOrderReturnDto>;
  sendPDFFile: Scalars['Boolean']['output'];
  sendWorkOrderPDFToPartner: Scalars['Boolean']['output'];
  setConsent: Array<AccountConsentReturnDto>;
  setWorkOrderStatus: Scalars['Boolean']['output'];
  toggleFileIncludeInPDF: Array<FileReturnDto>;
  updateApplicationUser: Array<ApplicationUserReturnDto>;
  updateCompany: Array<CompanyReturnDto>;
  updateCompanyLocation: Array<CompanyLocationReturnDto>;
  updateCostLocation: Array<CostLocationReturnDto>;
  updateFile: Array<FileReturnDto>;
  updateFinancialAccount: Array<FinancialAccountReturnDto>;
  updateItem: Array<ItemReturnDto>;
  updateOrganizationalEntity: Array<OrganizationalEntityReturnDto>;
  updatePartnerPickupRequest: Array<PartnerPickupRequestReturnDto>;
  updatePartnerPortalNote: Array<PartnerPortalNoteReturnDto>;
  updatePickupRequest: Array<PickupRequestFilteredReturnDto>;
  updatePicture: Array<FileReturnDto>;
  updateProfile: Array<ApplicationUser>;
  updateUnitOfMeasure: Array<UnitOfMeasureReturnDto>;
  updateVATType: Array<VatTypeReturnDto>;
  updateVehicle: Array<VehicleReturnDto>;
  updateWorkOrder: Array<WorkOrderReturnDto>;
  updateWorkOrderItem: Array<WorkOrderItemReturnDto>;
  updateWorkOrderStatusChange: Array<WorkOrderStatusChangeReturnDto>;
  updateWorkOrderTemplate: Array<WorkOrderTemplateReturnDto>;
  updateWorkOrderTemplateItem: Array<WorkOrderTemplateItemReturnDto>;
};


export type BaseMutationAddToRoleByIdArgs = {
  id: Scalars['String']['input'];
  role: UserRole;
};


export type BaseMutationBatchChangeWorkOrdersStatusArgs = {
  status: WorkOrderStatus;
  workOrderIds: Array<Scalars['Int']['input']>;
};


export type BaseMutationChangePasswordArgs = {
  model: AccountChangePasswordDtoInput;
};


export type BaseMutationConfirmEmailArgs = {
  code: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type BaseMutationConfirmEmailManuallyArgs = {
  userId: Scalars['String']['input'];
};


export type BaseMutationCreateCompanyArgs = {
  data: CompanyCreateDtoInput;
};


export type BaseMutationCreateCompanyLocationArgs = {
  data: CompanyLocationCreateDtoInput;
};


export type BaseMutationCreateCostLocationArgs = {
  data: CostLocationCreateDtoInput;
};


export type BaseMutationCreateFinancialAccountArgs = {
  data: FinancialAccountCreateDtoInput;
};


export type BaseMutationCreateItemArgs = {
  data: ItemCreateDtoInput;
};


export type BaseMutationCreateOrganizationalEntityArgs = {
  data: OrganizationalEntityCreateDtoInput;
};


export type BaseMutationCreatePartnerPickupRequestArgs = {
  data: PartnerPickupRequestCreateDtoInput;
};


export type BaseMutationCreatePartnerPortalNoteArgs = {
  data: PartnerPortalNoteCreateDtoInput;
};


export type BaseMutationCreatePickupRequestArgs = {
  data: PickupRequestCreateDtoInput;
};


export type BaseMutationCreatePictureArgs = {
  data: FileCreateDtoInput;
};


export type BaseMutationCreateUnitOfMeasureArgs = {
  data: UnitOfMeasureCreateDtoInput;
};


export type BaseMutationCreateVatTypeArgs = {
  data: VatTypeCreateDtoInput;
};


export type BaseMutationCreateVehicleArgs = {
  data: VehicleCreateDtoInput;
};


export type BaseMutationCreateWorkOrderArgs = {
  data: WorkOrderCreateDtoInput;
};


export type BaseMutationCreateWorkOrderItemArgs = {
  data: WorkOrderItemCreateDtoInput;
};


export type BaseMutationCreateWorkOrderPdfArgs = {
  workOrderId: Scalars['Int']['input'];
};


export type BaseMutationCreateWorkOrderStatusChangeArgs = {
  data: WorkOrderStatusChangeCreateDtoInput;
};


export type BaseMutationCreateWorkOrderTemplateArgs = {
  data: WorkOrderTemplateCreateDtoInput;
};


export type BaseMutationCreateWorkOrderTemplateItemArgs = {
  data: WorkOrderTemplateItemCreateDtoInput;
};


export type BaseMutationDeleteArgs = {
  model: AccountDeleteDtoInput;
};


export type BaseMutationDeleteCompanyArgs = {
  data: CompanyDeleteDtoInput;
};


export type BaseMutationDeleteCompanyLocationArgs = {
  data: CompanyLocationDeleteDtoInput;
};


export type BaseMutationDeleteCostLocationArgs = {
  data: CostLocationDeleteDtoInput;
};


export type BaseMutationDeleteFileArgs = {
  data: FileDeleteDtoInput;
};


export type BaseMutationDeleteFinancialAccountArgs = {
  data: FinancialAccountDeleteDtoInput;
};


export type BaseMutationDeleteItemArgs = {
  data: ItemDeleteDtoInput;
};


export type BaseMutationDeleteOrganizationalEntityArgs = {
  data: OrganizationalEntityDeleteDtoInput;
};


export type BaseMutationDeletePartnerPickupRequestArgs = {
  data: PartnerPickupRequestDeleteDtoInput;
};


export type BaseMutationDeletePartnerPortalNoteArgs = {
  data: PartnerPortalNoteDeleteDtoInput;
};


export type BaseMutationDeletePickupRequestArgs = {
  data: PickupRequestDeleteDtoInput;
};


export type BaseMutationDeletePictureArgs = {
  data: FileDeleteDtoInput;
};


export type BaseMutationDeleteUnitOfMeasureArgs = {
  data: UnitOfMeasureDeleteDtoInput;
};


export type BaseMutationDeleteVatTypeArgs = {
  data: VatTypeDeleteDtoInput;
};


export type BaseMutationDeleteVehicleArgs = {
  data: VehicleDeleteDtoInput;
};


export type BaseMutationDeleteWorkOrderArgs = {
  data: WorkOrderDeleteDtoInput;
};


export type BaseMutationDeleteWorkOrderItemArgs = {
  data: WorkOrderItemDeleteDtoInput;
};


export type BaseMutationDeleteWorkOrderStatusChangeArgs = {
  data: WorkOrderStatusChangeDeleteDtoInput;
};


export type BaseMutationDeleteWorkOrderTemplateArgs = {
  data: WorkOrderTemplateDeleteDtoInput;
};


export type BaseMutationDeleteWorkOrderTemplateItemArgs = {
  data: WorkOrderTemplateItemDeleteDtoInput;
};


export type BaseMutationDuplicateWorkOrderArgs = {
  dto: DuplicateWorkOrderDtoInput;
};


export type BaseMutationFieldWorkerAcceptsWorkOrderArgs = {
  dto: FieldWorkerWorkOrderAcceptanceDtoInput;
};


export type BaseMutationFieldWorkerDeclinesWorkOrderArgs = {
  dto: FieldWorkerWorkOrderDeclineDtoInput;
};


export type BaseMutationFieldWorkerPicksAVehicleArgs = {
  dto: FieldWorkerVehiclePickDtoInput;
};


export type BaseMutationFieldWorkerPicksOrganizationalEntityArgs = {
  dto: FieldWorkerOrganizationalEntityPickDtoInput;
};


export type BaseMutationFieldWorkerSubmitsCompletedWorkOrderArgs = {
  dto: FieldWorkerCompletedWorkOrderDtoInput;
};


export type BaseMutationLoginArgs = {
  model: AccountLoginDtoInput;
};


export type BaseMutationMobileLoginArgs = {
  dto: AccountMobileLoginDtoInput;
};


export type BaseMutationMobileRefreshTokenArgs = {
  dto: AccountMobileRefreshTokenDtoInput;
};


export type BaseMutationRegisterArgs = {
  model: AccountRegisterExtendedDtoInput;
};


export type BaseMutationRemoveFromRoleByIdArgs = {
  id: Scalars['String']['input'];
  role: UserRole;
};


export type BaseMutationResendConfirmationEmailArgs = {
  model: AccountResendConfirmationEmailDtoInput;
};


export type BaseMutationResetPasswordArgs = {
  model: AccountResetPasswordDtoInput;
};


export type BaseMutationRestoreDeletedItemArgs = {
  dto: RestoreDeletedItemDtoInput;
};


export type BaseMutationRestoreDeletedWorkOrderArgs = {
  dto: RestoreDeletedWorkOrderDtoInput;
};


export type BaseMutationSendPdfFileArgs = {
  dto: SendPdfFilesDtoInput;
};


export type BaseMutationSendWorkOrderPdfToPartnerArgs = {
  dto: SendWorkOrderPdfDtoInput;
};


export type BaseMutationSetConsentArgs = {
  dto: AccountConsentUpdateDtoInput;
};


export type BaseMutationSetWorkOrderStatusArgs = {
  status: WorkOrderStatus;
  workOrderId: Scalars['Int']['input'];
};


export type BaseMutationToggleFileIncludeInPdfArgs = {
  fileId: Scalars['Int']['input'];
};


export type BaseMutationUpdateApplicationUserArgs = {
  data: ApplicationUserUpdateDtoInput;
};


export type BaseMutationUpdateCompanyArgs = {
  data: CompanyUpdateDtoInput;
};


export type BaseMutationUpdateCompanyLocationArgs = {
  data: CompanyLocationUpdateDtoInput;
};


export type BaseMutationUpdateCostLocationArgs = {
  data: CostLocationUpdateDtoInput;
};


export type BaseMutationUpdateFileArgs = {
  data: FileUpdateDtoInput;
};


export type BaseMutationUpdateFinancialAccountArgs = {
  data: FinancialAccountUpdateDtoInput;
};


export type BaseMutationUpdateItemArgs = {
  data: ItemUpdateDtoInput;
};


export type BaseMutationUpdateOrganizationalEntityArgs = {
  data: OrganizationalEntityUpdateDtoInput;
};


export type BaseMutationUpdatePartnerPickupRequestArgs = {
  data: PartnerPickupRequestUpdateDtoInput;
};


export type BaseMutationUpdatePartnerPortalNoteArgs = {
  data: PartnerPortalNoteUpdateDtoInput;
};


export type BaseMutationUpdatePickupRequestArgs = {
  data: PickupRequestUpdateDtoInput;
};


export type BaseMutationUpdatePictureArgs = {
  data: FileUpdateDtoInput;
};


export type BaseMutationUpdateProfileArgs = {
  dto: AccountUpdateDtoInput;
};


export type BaseMutationUpdateUnitOfMeasureArgs = {
  data: UnitOfMeasureUpdateDtoInput;
};


export type BaseMutationUpdateVatTypeArgs = {
  data: VatTypeUpdateDtoInput;
};


export type BaseMutationUpdateVehicleArgs = {
  data: VehicleUpdateDtoInput;
};


export type BaseMutationUpdateWorkOrderArgs = {
  data: WorkOrderUpdateDtoInput;
};


export type BaseMutationUpdateWorkOrderItemArgs = {
  data: WorkOrderItemUpdateDtoInput;
};


export type BaseMutationUpdateWorkOrderStatusChangeArgs = {
  data: WorkOrderStatusChangeUpdateDtoInput;
};


export type BaseMutationUpdateWorkOrderTemplateArgs = {
  data: WorkOrderTemplateUpdateDtoInput;
};


export type BaseMutationUpdateWorkOrderTemplateItemArgs = {
  data: WorkOrderTemplateItemUpdateDtoInput;
};

export type BaseQuery = {
  accountConsent?: Maybe<AccountConsentReturnDto>;
  claims: Array<AccountClaimDto>;
  companies?: Maybe<CompaniesCollectionSegment>;
  company?: Maybe<CompanyReturnDto>;
  companyLocation?: Maybe<CompanyLocationReturnDto>;
  companyLocations?: Maybe<CompanyLocationsCollectionSegment>;
  costLocation?: Maybe<CostLocationReturnDto>;
  costLocations?: Maybe<CostLocationsCollectionSegment>;
  defaultOrganizationalEntity?: Maybe<OrganizationalEntityReturnDto>;
  fieldWorkers?: Maybe<FieldWorkersCollectionSegment>;
  file?: Maybe<FileReturnDto>;
  files?: Maybe<FilesCollectionSegment>;
  financialAccount?: Maybe<FinancialAccountReturnDto>;
  financialAccounts?: Maybe<FinancialAccountsCollectionSegment>;
  geocodeAddress: GeocodingResponseDto;
  item?: Maybe<ItemReturnDto>;
  items?: Maybe<ItemsCollectionSegment>;
  organizationalEntities?: Maybe<OrganizationalEntitiesCollectionSegment>;
  organizationalEntity?: Maybe<OrganizationalEntityReturnDto>;
  partnerPickupRequests?: Maybe<PartnerPickupRequestsCollectionSegment>;
  partnerPickupRequestsFiltered?: Maybe<PartnerPickupRequestsFilteredCollectionSegment>;
  partnerPortalNote?: Maybe<PartnerPortalNoteReturnDto>;
  partnerPortalNotes?: Maybe<PartnerPortalNotesCollectionSegment>;
  pickupRequest?: Maybe<PickupRequestFilteredReturnDto>;
  pickupRequests?: Maybe<PickupRequestsCollectionSegment>;
  pickupRequestsFiltered?: Maybe<PickupRequestsFilteredCollectionSegment>;
  roles: Array<UserRole>;
  shouldSyncData: Scalars['Boolean']['output'];
  singleUser?: Maybe<ApplicationUserReturnDto>;
  singleWorkOrderAllStatusChanges: Array<WorkOrderStatusChangeReturnDto>;
  syncData: SyncDataDto;
  token: AccountLoginResponseDto;
  unassignedPickupRequestCount: Scalars['Int']['output'];
  unitOfMeasure?: Maybe<UnitOfMeasureReturnDto>;
  unitsOfMeasure?: Maybe<UnitsOfMeasureCollectionSegment>;
  unseenNotifications: Array<UserNotificationReturnDto>;
  unseenNotificationsCount: Scalars['Int']['output'];
  user: ApplicationUser;
  userByEmail: ApplicationUser;
  userById: ApplicationUser;
  userByName: ApplicationUser;
  userRoles: Array<UserRole>;
  users?: Maybe<UsersCollectionSegment>;
  usersUnfiltered?: Maybe<UsersUnfilteredCollectionSegment>;
  vatType?: Maybe<VatTypeReturnDto>;
  vatTypes?: Maybe<VatTypesCollectionSegment>;
  vehicle?: Maybe<VehicleReturnDto>;
  vehicles?: Maybe<VehiclesCollectionSegment>;
  wholeOrganizationalEntitiesTree: Array<OrganizationalEntityReturnDto>;
  workOrder: WorkOrderReturnDto;
  workOrderCountAndValuePerDay: Array<KeyValuePairOfDateTimeAndWorkOrderCountAndValuePerDayValueDto>;
  workOrderIds: WorkOrderReturnDto;
  workOrderItems?: Maybe<WorkOrderItemsCollectionSegment>;
  workOrderRelatedFiles?: Maybe<WorkOrderRelatedFilesCollectionSegment>;
  workOrderStatusChange?: Maybe<WorkOrderStatusChangeReturnDto>;
  workOrderStatusChanges?: Maybe<WorkOrderStatusChangesCollectionSegment>;
  workOrderTemplate?: Maybe<WorkOrderTemplateReturnDto>;
  workOrderTemplateItem?: Maybe<WorkOrderTemplateItemReturnDto>;
  workOrderTemplateItems?: Maybe<WorkOrderTemplateItemsCollectionSegment>;
  workOrderTemplates?: Maybe<WorkOrderTemplatesCollectionSegment>;
  workOrderTotals: WorkOrderReturnDto;
  workOrders?: Maybe<WorkOrdersCollectionSegment>;
  workOrdersForReport: Array<WorkOrderFlattenedReturnDto>;
};


export type BaseQueryAccountConsentArgs = {
  userId: Scalars['String']['input'];
  where?: InputMaybe<AccountConsentReturnDtoFilterInput>;
};


export type BaseQueryCompaniesArgs = {
  order?: InputMaybe<Array<CompanyReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CompanyReturnDtoFilterInput>;
};


export type BaseQueryCompanyArgs = {
  where?: InputMaybe<CompanyReturnDtoFilterInput>;
};


export type BaseQueryCompanyLocationArgs = {
  where?: InputMaybe<CompanyLocationReturnDtoFilterInput>;
};


export type BaseQueryCompanyLocationsArgs = {
  order?: InputMaybe<Array<CompanyLocationReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CompanyLocationReturnDtoFilterInput>;
};


export type BaseQueryCostLocationArgs = {
  where?: InputMaybe<CostLocationReturnDtoFilterInput>;
};


export type BaseQueryCostLocationsArgs = {
  order?: InputMaybe<Array<CostLocationReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CostLocationReturnDtoFilterInput>;
};


export type BaseQueryDefaultOrganizationalEntityArgs = {
  where?: InputMaybe<OrganizationalEntityReturnDtoFilterInput>;
};


export type BaseQueryFieldWorkersArgs = {
  order?: InputMaybe<Array<FieldWorkerReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FieldWorkerReturnDtoFilterInput>;
};


export type BaseQueryFileArgs = {
  where?: InputMaybe<FileReturnDtoFilterInput>;
};


export type BaseQueryFilesArgs = {
  order?: InputMaybe<Array<FileReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileReturnDtoFilterInput>;
};


export type BaseQueryFinancialAccountArgs = {
  where?: InputMaybe<FinancialAccountReturnDtoFilterInput>;
};


export type BaseQueryFinancialAccountsArgs = {
  order?: InputMaybe<Array<FinancialAccountReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FinancialAccountReturnDtoFilterInput>;
};


export type BaseQueryGeocodeAddressArgs = {
  address: Scalars['String']['input'];
};


export type BaseQueryItemArgs = {
  where?: InputMaybe<ItemReturnDtoFilterInput>;
};


export type BaseQueryItemsArgs = {
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Array<ItemReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ItemReturnDtoFilterInput>;
};


export type BaseQueryOrganizationalEntitiesArgs = {
  order?: InputMaybe<Array<OrganizationalEntityReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganizationalEntityReturnDtoFilterInput>;
};


export type BaseQueryOrganizationalEntityArgs = {
  where?: InputMaybe<OrganizationalEntityReturnDtoFilterInput>;
};


export type BaseQueryPartnerPickupRequestsArgs = {
  order?: InputMaybe<Array<PartnerPickupRequestReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PartnerPickupRequestReturnDtoFilterInput>;
};


export type BaseQueryPartnerPickupRequestsFilteredArgs = {
  filters: PartnerPickupRequestsFilterDtoInput;
  order?: InputMaybe<Array<PartnerPickupRequestFilteredReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PartnerPickupRequestFilteredReturnDtoFilterInput>;
};


export type BaseQueryPartnerPortalNoteArgs = {
  where?: InputMaybe<PartnerPortalNoteReturnDtoFilterInput>;
};


export type BaseQueryPartnerPortalNotesArgs = {
  order?: InputMaybe<Array<PartnerPortalNoteReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PartnerPortalNoteReturnDtoFilterInput>;
};


export type BaseQueryPickupRequestArgs = {
  where?: InputMaybe<PickupRequestFilteredReturnDtoFilterInput>;
};


export type BaseQueryPickupRequestsArgs = {
  order?: InputMaybe<Array<PickupRequestFilteredReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PickupRequestFilteredReturnDtoFilterInput>;
};


export type BaseQueryPickupRequestsFilteredArgs = {
  filters: PickupRequestsFilterDtoInput;
  order?: InputMaybe<Array<PickupRequestFilteredReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PickupRequestFilteredReturnDtoFilterInput>;
};


export type BaseQueryShouldSyncDataArgs = {
  lastSync?: InputMaybe<Scalars['DateTime']['input']>;
};


export type BaseQuerySingleUserArgs = {
  where?: InputMaybe<ApplicationUserReturnDtoFilterInput>;
};


export type BaseQuerySingleWorkOrderAllStatusChangesArgs = {
  order?: InputMaybe<Array<WorkOrderStatusChangeReturnDtoSortInput>>;
  where?: InputMaybe<WorkOrderStatusChangeReturnDtoFilterInput>;
  workOrderId: Scalars['Int']['input'];
};


export type BaseQuerySyncDataArgs = {
  lastSync?: InputMaybe<Scalars['DateTime']['input']>;
};


export type BaseQueryUnitOfMeasureArgs = {
  where?: InputMaybe<UnitOfMeasureReturnDtoFilterInput>;
};


export type BaseQueryUnitsOfMeasureArgs = {
  order?: InputMaybe<Array<UnitOfMeasureReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UnitOfMeasureReturnDtoFilterInput>;
};


export type BaseQueryUnseenNotificationsArgs = {
  order?: InputMaybe<Array<UserNotificationReturnDtoSortInput>>;
};


export type BaseQueryUserByEmailArgs = {
  email: Scalars['String']['input'];
};


export type BaseQueryUserByIdArgs = {
  id: Scalars['String']['input'];
};


export type BaseQueryUserByNameArgs = {
  name: Scalars['String']['input'];
};


export type BaseQueryUserRolesArgs = {
  id: Scalars['String']['input'];
};


export type BaseQueryUsersArgs = {
  filters: ApplicationUserFilterDtoInput;
  order?: InputMaybe<Array<ApplicationUserSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ApplicationUserFilterInput>;
};


export type BaseQueryUsersUnfilteredArgs = {
  order?: InputMaybe<Array<ApplicationUserReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ApplicationUserReturnDtoFilterInput>;
};


export type BaseQueryVatTypeArgs = {
  where?: InputMaybe<VatTypeReturnDtoFilterInput>;
};


export type BaseQueryVatTypesArgs = {
  order?: InputMaybe<Array<VatTypeReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VatTypeReturnDtoFilterInput>;
};


export type BaseQueryVehicleArgs = {
  where?: InputMaybe<VehicleReturnDtoFilterInput>;
};


export type BaseQueryVehiclesArgs = {
  order?: InputMaybe<Array<VehicleReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleReturnDtoFilterInput>;
};


export type BaseQueryWorkOrderArgs = {
  workOrderId: Scalars['Int']['input'];
};


export type BaseQueryWorkOrderCountAndValuePerDayArgs = {
  dto: WorkOrderCountPerDayDtoInput;
};


export type BaseQueryWorkOrderIdsArgs = {
  workOrderId: Scalars['Int']['input'];
};


export type BaseQueryWorkOrderItemsArgs = {
  order?: InputMaybe<Array<WorkOrderItemReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkOrderItemReturnDtoFilterInput>;
};


export type BaseQueryWorkOrderRelatedFilesArgs = {
  order?: InputMaybe<Array<FileReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileReturnDtoFilterInput>;
  workOrderId: Scalars['Int']['input'];
};


export type BaseQueryWorkOrderStatusChangeArgs = {
  where?: InputMaybe<WorkOrderStatusChangeReturnDtoFilterInput>;
};


export type BaseQueryWorkOrderStatusChangesArgs = {
  order?: InputMaybe<Array<WorkOrderStatusChangeReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkOrderStatusChangeReturnDtoFilterInput>;
};


export type BaseQueryWorkOrderTemplateArgs = {
  where?: InputMaybe<WorkOrderTemplateReturnDtoFilterInput>;
};


export type BaseQueryWorkOrderTemplateItemArgs = {
  where?: InputMaybe<WorkOrderTemplateItemReturnDtoFilterInput>;
};


export type BaseQueryWorkOrderTemplateItemsArgs = {
  order?: InputMaybe<Array<WorkOrderTemplateItemReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkOrderTemplateItemReturnDtoFilterInput>;
};


export type BaseQueryWorkOrderTemplatesArgs = {
  order?: InputMaybe<Array<WorkOrderTemplateReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkOrderTemplateReturnDtoFilterInput>;
};


export type BaseQueryWorkOrderTotalsArgs = {
  workOrderId: Scalars['Int']['input'];
};


export type BaseQueryWorkOrdersArgs = {
  dto: WorkOrderFilterDtoInput;
  order?: InputMaybe<Array<WorkOrderReturnDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkOrderReturnDtoFilterInput>;
};


export type BaseQueryWorkOrdersForReportArgs = {
  dto: WorkOrderFilterDtoInput;
  order?: InputMaybe<Array<WorkOrderFlattenedReturnDtoSortInput>>;
  where?: InputMaybe<WorkOrderFlattenedReturnDtoFilterInput>;
};

export type BaseSubscription = {
  unassignedPickupRequestCountChanged: Scalars['Int']['output'];
  unseenUserNotificationsCountChanged: Scalars['Int']['output'];
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

/** A segment of a collection. */
export type CompaniesCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<CompanyReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Company = {
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  employees: Array<ApplicationUser>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  locations: Array<CompanyLocation>;
  name?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyCreateDtoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyDeleteDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyFilterInput = {
  and?: InputMaybe<Array<CompanyFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfApplicationUserFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  locations?: InputMaybe<ListFilterInputTypeOfCompanyLocationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CompanyFilterInput>>;
  taxId?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type CompanyLocation = {
  address?: Maybe<Scalars['String']['output']>;
  company: Company;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isMainLocation: Scalars['Boolean']['output'];
  location?: Maybe<GeoJsonPointType>;
  name?: Maybe<Scalars['String']['output']>;
  pickupRequests: Array<PickupRequest>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyLocationCreateDtoInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  isMainLocation?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<PointDtoInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyLocationDeleteDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyLocationFilterInput = {
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<CompanyLocationFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isMainLocation?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<PointFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CompanyLocationFilterInput>>;
  pickupRequests?: InputMaybe<ListFilterInputTypeOfPickupRequestFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type CompanyLocationReturnDto = {
  address?: Maybe<Scalars['String']['output']>;
  company?: Maybe<CompanyReturnDto>;
  companyId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isMainLocation?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<PointDto>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CompanyLocationReturnDtoFilterInput = {
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<CompanyLocationReturnDtoFilterInput>>;
  company?: InputMaybe<CompanyReturnDtoFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isMainLocation?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<PointDtoFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CompanyLocationReturnDtoFilterInput>>;
};

export type CompanyLocationReturnDtoSortInput = {
  address?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanyReturnDtoSortInput>;
  companyId?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isMainLocation?: InputMaybe<SortEnumType>;
  location?: InputMaybe<PointDtoSortInput>;
  name?: InputMaybe<SortEnumType>;
};

export type CompanyLocationUpdateDtoInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isMainLocation?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<PointDtoInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** A segment of a collection. */
export type CompanyLocationsCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<CompanyLocationReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type CompanyReturnDto = {
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
};

export type CompanyReturnDtoFilterInput = {
  and?: InputMaybe<Array<CompanyReturnDtoFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CompanyReturnDtoFilterInput>>;
  taxId?: InputMaybe<StringOperationFilterInput>;
};

export type CompanyReturnDtoSortInput = {
  description?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  taxId?: InputMaybe<SortEnumType>;
};

export type CompanySortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  deletedAt?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  taxId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type CompanyUpdateDtoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
};

export type CoordinateSortInput = {
  coordinateValue?: InputMaybe<CoordinateSortInput>;
  x?: InputMaybe<SortEnumType>;
  y?: InputMaybe<SortEnumType>;
  z?: InputMaybe<SortEnumType>;
};

export type CostLocation = {
  code?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CostLocationCreateDtoInput = {
  code?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CostLocationDeleteDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CostLocationFilterInput = {
  and?: InputMaybe<Array<CostLocationFilterInput>>;
  code?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CostLocationFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type CostLocationReturnDto = {
  code?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type CostLocationReturnDtoFilterInput = {
  and?: InputMaybe<Array<CostLocationReturnDtoFilterInput>>;
  code?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CostLocationReturnDtoFilterInput>>;
};

export type CostLocationReturnDtoSortInput = {
  code?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type CostLocationSortInput = {
  code?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  deletedAt?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type CostLocationUpdateDtoInput = {
  code?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** A segment of a collection. */
export type CostLocationsCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<CostLocationReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  neq?: InputMaybe<Scalars['Decimal']['input']>;
  ngt?: InputMaybe<Scalars['Decimal']['input']>;
  ngte?: InputMaybe<Scalars['Decimal']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  nlt?: InputMaybe<Scalars['Decimal']['input']>;
  nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

export enum Dimension {
  Collapse = 'COLLAPSE',
  Curve = 'CURVE',
  Dontcare = 'DONTCARE',
  False = 'FALSE',
  Point = 'POINT',
  Surface = 'SURFACE',
  True = 'TRUE'
}

export type DimensionOperationFilterInput = {
  eq?: InputMaybe<Dimension>;
  in?: InputMaybe<Array<Dimension>>;
  neq?: InputMaybe<Dimension>;
  nin?: InputMaybe<Array<Dimension>>;
};

export type DuplicateWorkOrderDtoInput = {
  workOrderId: Scalars['Int']['input'];
};

export type FieldWorkerCompletedWorkOrderDtoInput = {
  files: Array<FieldWorkerWorkOrderSubmitFileDtoInput>;
  workOrder: FieldWorkerWorkOrderSubmitDtoInput;
  workOrderItemFiles: Array<FieldWorkerWorkOrderSubmitFileDtoInput>;
  workOrderItems: Array<FieldWorkerWorkOrderSubmitItemDtoInput>;
};

export type FieldWorkerOrganizationalEntityPickDtoInput = {
  organizationalEntityId?: InputMaybe<Scalars['Int']['input']>;
};

export type FieldWorkerProfileReturnDto = {
  currentOrganizationalEntityId?: Maybe<Scalars['Int']['output']>;
  currentVehicleId?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  preferredLanguage?: Maybe<Scalars['String']['output']>;
  userName: Scalars['String']['output'];
};

export type FieldWorkerReturnDto = {
  accessFailedCount: Scalars['Int']['output'];
  company?: Maybe<CompanyReturnDto>;
  companyId?: Maybe<Scalars['Int']['output']>;
  concurrencyStamp?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailConfirmed: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  lockoutEnabled: Scalars['Boolean']['output'];
  lockoutEnd?: Maybe<Scalars['DateTime']['output']>;
  normalizedEmail?: Maybe<Scalars['String']['output']>;
  normalizedUserName?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberConfirmed: Scalars['Boolean']['output'];
  securityStamp?: Maybe<Scalars['String']['output']>;
  twoFactorEnabled: Scalars['Boolean']['output'];
  userName?: Maybe<Scalars['String']['output']>;
  userRoles: Array<ApplicationUserRole>;
};

export type FieldWorkerReturnDtoFilterInput = {
  accessFailedCount?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<FieldWorkerReturnDtoFilterInput>>;
  company?: InputMaybe<CompanyReturnDtoFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  concurrencyStamp?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  emailConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  lockoutEnabled?: InputMaybe<BooleanOperationFilterInput>;
  lockoutEnd?: InputMaybe<DateTimeOperationFilterInput>;
  normalizedEmail?: InputMaybe<StringOperationFilterInput>;
  normalizedUserName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FieldWorkerReturnDtoFilterInput>>;
  passwordHash?: InputMaybe<StringOperationFilterInput>;
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  phoneNumberConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  securityStamp?: InputMaybe<StringOperationFilterInput>;
  twoFactorEnabled?: InputMaybe<BooleanOperationFilterInput>;
  userName?: InputMaybe<StringOperationFilterInput>;
  userRoles?: InputMaybe<ListFilterInputTypeOfApplicationUserRoleFilterInput>;
};

export type FieldWorkerReturnDtoSortInput = {
  accessFailedCount?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanyReturnDtoSortInput>;
  companyId?: InputMaybe<SortEnumType>;
  concurrencyStamp?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  emailConfirmed?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lockoutEnabled?: InputMaybe<SortEnumType>;
  lockoutEnd?: InputMaybe<SortEnumType>;
  normalizedEmail?: InputMaybe<SortEnumType>;
  normalizedUserName?: InputMaybe<SortEnumType>;
  passwordHash?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  phoneNumberConfirmed?: InputMaybe<SortEnumType>;
  securityStamp?: InputMaybe<SortEnumType>;
  twoFactorEnabled?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

export type FieldWorkerVehiclePickDtoInput = {
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

export type FieldWorkerWorkOrderAcceptanceDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type FieldWorkerWorkOrderDeclineDtoInput = {
  reason?: InputMaybe<Scalars['String']['input']>;
  workOrderId?: InputMaybe<Scalars['Int']['input']>;
};

export type FieldWorkerWorkOrderSubmitDtoInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  companyLocationId?: InputMaybe<Scalars['Int']['input']>;
  endDateTime: Scalars['DateTime']['input'];
  endLatitude: Scalars['Float']['input'];
  endLongitude: Scalars['Float']['input'];
  fieldWorkerNote?: InputMaybe<Scalars['String']['input']>;
  freeformCompany?: InputMaybe<Scalars['String']['input']>;
  freeformLocation?: InputMaybe<Scalars['String']['input']>;
  offlineWorkOrderId: Scalars['String']['input'];
  onlineWorkOrderId?: InputMaybe<Scalars['Int']['input']>;
  organizationalEntityId?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<WorkOrderPriority>;
  startDateTime: Scalars['DateTime']['input'];
  startLatitude: Scalars['Float']['input'];
  startLongitude: Scalars['Float']['input'];
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

export type FieldWorkerWorkOrderSubmitFileDtoInput = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  offlineFileId: Scalars['String']['input'];
  offlineWorkOrderId?: InputMaybe<Scalars['String']['input']>;
  offlineWorkOrderItemId?: InputMaybe<Scalars['String']['input']>;
  onlineFileId?: InputMaybe<Scalars['Int']['input']>;
  onlineWorkOrderItemId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type FieldWorkerWorkOrderSubmitItemDtoInput = {
  itemId: Scalars['Int']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  offlineWorkOrderId: Scalars['String']['input'];
  offlineWorkOrderItemId: Scalars['String']['input'];
  onlineWorkOrderItemId?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Decimal']['input'];
};

/** A segment of a collection. */
export type FieldWorkersCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<FieldWorkerReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type File = {
  contentType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileType: FileType;
  id: Scalars['Int']['output'];
  includeInPDF: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  location?: Maybe<GeoJsonPointType>;
  notes?: Maybe<Scalars['String']['output']>;
  offlineFileId?: Maybe<Scalars['String']['output']>;
  offlineWorkOrderId?: Maybe<Scalars['String']['output']>;
  offlineWorkOrderItemId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: ApplicationUser;
  userId: Scalars['String']['output'];
  workOrder?: Maybe<WorkOrder>;
  workOrderId?: Maybe<Scalars['Int']['output']>;
  workOrderItem?: Maybe<WorkOrderItem>;
  workOrderItemId?: Maybe<Scalars['Int']['output']>;
};

export type FileCreateDtoInput = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  createdAt: Scalars['DateTime']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileType: FileType;
  includeInPDF?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  offlineFileId?: InputMaybe<Scalars['String']['input']>;
  offlineWorkOrderId?: InputMaybe<Scalars['String']['input']>;
  offlineWorkOrderItemId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  workOrderId?: InputMaybe<Scalars['Int']['input']>;
  workOrderItemId?: InputMaybe<Scalars['Int']['input']>;
};

export type FileDeleteDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type FileFilterInput = {
  and?: InputMaybe<Array<FileFilterInput>>;
  contentType?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  fileType?: InputMaybe<FileTypeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  includeInPDF?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<PointFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  offlineFileId?: InputMaybe<StringOperationFilterInput>;
  offlineWorkOrderId?: InputMaybe<StringOperationFilterInput>;
  offlineWorkOrderItemId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FileFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  user?: InputMaybe<ApplicationUserFilterInput>;
  userId?: InputMaybe<StringOperationFilterInput>;
  workOrder?: InputMaybe<WorkOrderFilterInput>;
  workOrderId?: InputMaybe<IntOperationFilterInput>;
  workOrderItem?: InputMaybe<WorkOrderItemFilterInput>;
  workOrderItemId?: InputMaybe<IntOperationFilterInput>;
};

export type FileReturnDto = {
  contentType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
  fileType: FileType;
  id: Scalars['Int']['output'];
  includeInPDF?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  offlineFileId?: Maybe<Scalars['String']['output']>;
  offlineWorkOrderId?: Maybe<Scalars['String']['output']>;
  offlineWorkOrderItemId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  workOrderId?: Maybe<Scalars['Int']['output']>;
  workOrderItemId?: Maybe<Scalars['Int']['output']>;
};

export type FileReturnDtoFilterInput = {
  and?: InputMaybe<Array<FileReturnDtoFilterInput>>;
  contentType?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  fileType?: InputMaybe<FileTypeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  includeInPDF?: InputMaybe<BooleanOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  offlineFileId?: InputMaybe<StringOperationFilterInput>;
  offlineWorkOrderId?: InputMaybe<StringOperationFilterInput>;
  offlineWorkOrderItemId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FileReturnDtoFilterInput>>;
  userId?: InputMaybe<StringOperationFilterInput>;
  workOrderId?: InputMaybe<IntOperationFilterInput>;
  workOrderItemId?: InputMaybe<IntOperationFilterInput>;
};

export type FileReturnDtoSortInput = {
  contentType?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  fileName?: InputMaybe<SortEnumType>;
  fileType?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  includeInPDF?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  offlineFileId?: InputMaybe<SortEnumType>;
  offlineWorkOrderId?: InputMaybe<SortEnumType>;
  offlineWorkOrderItemId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
  workOrderId?: InputMaybe<SortEnumType>;
  workOrderItemId?: InputMaybe<SortEnumType>;
};

export enum FileType {
  Generic = 'GENERIC',
  SignedWorkOrder = 'SIGNED_WORK_ORDER',
  WorkOrder = 'WORK_ORDER'
}

export type FileTypeOperationFilterInput = {
  eq?: InputMaybe<FileType>;
  in?: InputMaybe<Array<FileType>>;
  neq?: InputMaybe<FileType>;
  nin?: InputMaybe<Array<FileType>>;
};

export type FileUpdateDtoInput = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  createdAt: Scalars['DateTime']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileType: FileType;
  id?: InputMaybe<Scalars['Int']['input']>;
  includeInPDF?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  offlineFileId?: InputMaybe<Scalars['String']['input']>;
  offlineWorkOrderId?: InputMaybe<Scalars['String']['input']>;
  offlineWorkOrderItemId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  workOrderId?: InputMaybe<Scalars['Int']['input']>;
  workOrderItemId?: InputMaybe<Scalars['Int']['input']>;
};

/** A segment of a collection. */
export type FilesCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<FileReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type FinancialAccount = {
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FinancialAccountCreateDtoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
};

export type FinancialAccountDeleteDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type FinancialAccountFilterInput = {
  and?: InputMaybe<Array<FinancialAccountFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FinancialAccountFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type FinancialAccountReturnDto = {
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
};

export type FinancialAccountReturnDtoFilterInput = {
  and?: InputMaybe<Array<FinancialAccountReturnDtoFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FinancialAccountReturnDtoFilterInput>>;
};

export type FinancialAccountReturnDtoSortInput = {
  description?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
};

export type FinancialAccountUpdateDtoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
};

/** A segment of a collection. */
export type FinancialAccountsCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<FinancialAccountReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

export enum GeoJsonGeometryType {
  GeometryCollection = 'GeometryCollection',
  LineString = 'LineString',
  MultiLineString = 'MultiLineString',
  MultiPoint = 'MultiPoint',
  MultiPolygon = 'MultiPolygon',
  Point = 'Point',
  Polygon = 'Polygon'
}

export type GeoJsonInterface = {
  /** The minimum bounding box around the geometry object */
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  /** The coordinate reference system integer identifier */
  crs?: Maybe<Scalars['Int']['output']>;
  /** The geometry type of the GeoJson object */
  type: GeoJsonGeometryType;
};

export type GeoJsonLineStringInput = {
  /** The "coordinates" field is an array of two or more positions. */
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Position']['input']>>>;
  /** The coordinate reference system integer identifier */
  crs?: InputMaybe<Scalars['Int']['input']>;
  /** The geometry type of the GeoJson object */
  type?: InputMaybe<GeoJsonGeometryType>;
};

export type GeoJsonLineStringType = GeoJsonInterface & {
  /** The minimum bounding box around the geometry object */
  bbox: Array<Scalars['Float']['output']>;
  /** The "coordinates" field is an array of two or more positions. */
  coordinates?: Maybe<Array<Maybe<Scalars['Position']['output']>>>;
  /** The coordinate reference system integer identifier */
  crs: Scalars['Int']['output'];
  /** The geometry type of the GeoJson object */
  type: GeoJsonGeometryType;
};

export type GeoJsonMultiLineStringInput = {
  /** The "coordinates" field is an array of LineString coordinate arrays. */
  coordinates?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Position']['input']>>>>>;
  /** The coordinate reference system integer identifier */
  crs?: InputMaybe<Scalars['Int']['input']>;
  /** The geometry type of the GeoJson object */
  type?: InputMaybe<GeoJsonGeometryType>;
};

export type GeoJsonMultiLineStringType = GeoJsonInterface & {
  /** The minimum bounding box around the geometry object */
  bbox: Array<Scalars['Float']['output']>;
  /** The "coordinates" field is an array of LineString coordinate arrays. */
  coordinates?: Maybe<Array<Maybe<Scalars['Position']['output']>>>;
  /** The coordinate reference system integer identifier */
  crs: Scalars['Int']['output'];
  /** The geometry type of the GeoJson object */
  type: GeoJsonGeometryType;
};

export type GeoJsonMultiPointInput = {
  /** The "coordinates" field is an array of positions. */
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Position']['input']>>>;
  /** The coordinate reference system integer identifier */
  crs?: InputMaybe<Scalars['Int']['input']>;
  /** The geometry type of the GeoJson object */
  type?: InputMaybe<GeoJsonGeometryType>;
};

export type GeoJsonMultiPointType = GeoJsonInterface & {
  /** The minimum bounding box around the geometry object */
  bbox: Array<Scalars['Float']['output']>;
  /** The "coordinates" field is an array of positions. */
  coordinates?: Maybe<Array<Maybe<Scalars['Position']['output']>>>;
  /** The coordinate reference system integer identifier */
  crs: Scalars['Int']['output'];
  /** The geometry type of the GeoJson object */
  type: GeoJsonGeometryType;
};

export type GeoJsonMultiPolygonInput = {
  /** The "coordinates" field is an array of Polygon coordinate arrays. */
  coordinates?: InputMaybe<Scalars['Coordinates']['input']>;
  /** The coordinate reference system integer identifier */
  crs?: InputMaybe<Scalars['Int']['input']>;
  /** The geometry type of the GeoJson object */
  type?: InputMaybe<GeoJsonGeometryType>;
};

export type GeoJsonMultiPolygonType = GeoJsonInterface & {
  /** The minimum bounding box around the geometry object */
  bbox: Array<Scalars['Float']['output']>;
  /** The "coordinates" field is an array of Polygon coordinate arrays. */
  coordinates?: Maybe<Scalars['Coordinates']['output']>;
  /** The coordinate reference system integer identifier */
  crs: Scalars['Int']['output'];
  /** The geometry type of the GeoJson object */
  type: GeoJsonGeometryType;
};

export type GeoJsonPointInput = {
  /** The "coordinates" field is a single position. */
  coordinates?: InputMaybe<Scalars['Position']['input']>;
  /** The coordinate reference system integer identifier */
  crs?: InputMaybe<Scalars['Int']['input']>;
  /** The geometry type of the GeoJson object */
  type?: InputMaybe<GeoJsonGeometryType>;
};

export type GeoJsonPointType = GeoJsonInterface & {
  /** The minimum bounding box around the geometry object */
  bbox: Array<Scalars['Float']['output']>;
  /** The "coordinates" field is a single position. */
  coordinates?: Maybe<Scalars['Position']['output']>;
  /** The coordinate reference system integer identifier */
  crs: Scalars['Int']['output'];
  /** The geometry type of the GeoJson object */
  type: GeoJsonGeometryType;
};

export type GeoJsonPolygonInput = {
  /**
   * The "coordinates" field MUST be an array of linear ring coordinate arrays. For
   * Polygons with more than one of these rings, the first MUST be the exterior
   * ring, and any others MUST be interior rings. The exterior ring bounds the
   * surface, and the interior rings (if present) bound holes within the surface.
   */
  coordinates?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Position']['input']>>>>>;
  /** The coordinate reference system integer identifier */
  crs?: InputMaybe<Scalars['Int']['input']>;
  /** The geometry type of the GeoJson object */
  type?: InputMaybe<GeoJsonGeometryType>;
};

export type GeoJsonPolygonType = GeoJsonInterface & {
  /** The minimum bounding box around the geometry object */
  bbox: Array<Scalars['Float']['output']>;
  /**
   * The "coordinates" field MUST be an array of linear ring coordinate arrays. For
   * Polygons with more than one of these rings, the first MUST be the exterior
   * ring, and any others MUST be interior rings. The exterior ring bounds the
   * surface, and the interior rings (if present) bound holes within the surface.
   */
  coordinates?: Maybe<Array<Maybe<Array<Maybe<Scalars['Position']['output']>>>>>;
  /** The coordinate reference system integer identifier */
  crs: Scalars['Int']['output'];
  /** The geometry type of the GeoJson object */
  type: GeoJsonGeometryType;
};

export type GeocodingResponseDto = {
  lat: Scalars['Decimal']['output'];
  lng: Scalars['Decimal']['output'];
};

export type GeometryContainsOperationFilterInput = {
  buffer?: InputMaybe<Scalars['Float']['input']>;
  geometry: Scalars['Geometry']['input'];
};

export type GeometryDistanceOperationFilterInput = {
  buffer?: InputMaybe<Scalars['Float']['input']>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  geometry: Scalars['Geometry']['input'];
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<Scalars['Float']['input']>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

export type GeometryFilterInput = {
  and?: InputMaybe<Array<GeometryFilterInput>>;
  area?: InputMaybe<FloatOperationFilterInput>;
  boundary?: InputMaybe<GeometryFilterInput>;
  centroid?: InputMaybe<PointFilterInput>;
  contains?: InputMaybe<GeometryContainsOperationFilterInput>;
  dimension?: InputMaybe<DimensionOperationFilterInput>;
  distance?: InputMaybe<GeometryDistanceOperationFilterInput>;
  envelope?: InputMaybe<GeometryFilterInput>;
  geometryType?: InputMaybe<StringOperationFilterInput>;
  interiorPoint?: InputMaybe<PointFilterInput>;
  intersects?: InputMaybe<GeometryIntersectsOperationFilterInput>;
  isSimple?: InputMaybe<BooleanOperationFilterInput>;
  isValid?: InputMaybe<BooleanOperationFilterInput>;
  length?: InputMaybe<FloatOperationFilterInput>;
  ncontains?: InputMaybe<GeometryContainsOperationFilterInput>;
  nintersects?: InputMaybe<GeometryIntersectsOperationFilterInput>;
  noverlaps?: InputMaybe<GeometryOverlapsOperationFilterInput>;
  ntouches?: InputMaybe<GeometryTouchesOperationFilterInput>;
  numPoints?: InputMaybe<IntOperationFilterInput>;
  nwithin?: InputMaybe<GeometryWithinOperationFilterInput>;
  ogcGeometryType?: InputMaybe<OgcGeometryTypeOperationFilterInput>;
  or?: InputMaybe<Array<GeometryFilterInput>>;
  overlaps?: InputMaybe<GeometryOverlapsOperationFilterInput>;
  pointOnSurface?: InputMaybe<PointFilterInput>;
  srid?: InputMaybe<IntOperationFilterInput>;
  touches?: InputMaybe<GeometryTouchesOperationFilterInput>;
  within?: InputMaybe<GeometryWithinOperationFilterInput>;
};

export type GeometryIntersectsOperationFilterInput = {
  buffer?: InputMaybe<Scalars['Float']['input']>;
  geometry: Scalars['Geometry']['input'];
};

export type GeometryOverlapsOperationFilterInput = {
  buffer?: InputMaybe<Scalars['Float']['input']>;
  geometry: Scalars['Geometry']['input'];
};

export type GeometrySortInput = {
  coordinate?: InputMaybe<CoordinateSortInput>;
};

export type GeometryTouchesOperationFilterInput = {
  buffer?: InputMaybe<Scalars['Float']['input']>;
  geometry: Scalars['Geometry']['input'];
};

export type GeometryWithinOperationFilterInput = {
  buffer?: InputMaybe<Scalars['Float']['input']>;
  geometry: Scalars['Geometry']['input'];
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type Item = {
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  financialAccount?: Maybe<FinancialAccount>;
  financialAccountId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  itemType: ItemType;
  name?: Maybe<Scalars['String']['output']>;
  rentalServiceItem?: Maybe<Item>;
  rentalServiceItemId?: Maybe<Scalars['Int']['output']>;
  rentalThresholdDays: Scalars['Int']['output'];
  unitOfMeasure?: Maybe<UnitOfMeasure>;
  unitOfMeasureId?: Maybe<Scalars['Int']['output']>;
  unitPrice?: Maybe<Scalars['Decimal']['output']>;
  unitsContained?: Maybe<Scalars['Decimal']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vatType?: Maybe<VatType>;
  vatTypeId?: Maybe<Scalars['Int']['output']>;
};

export type ItemCreateDtoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  financialAccountId?: InputMaybe<Scalars['Int']['input']>;
  itemType?: InputMaybe<ItemType>;
  name?: InputMaybe<Scalars['String']['input']>;
  rentalServiceItemId?: InputMaybe<Scalars['Int']['input']>;
  rentalThresholdDays: Scalars['Int']['input'];
  unitOfMeasureId?: InputMaybe<Scalars['Int']['input']>;
  unitPrice?: InputMaybe<Scalars['Decimal']['input']>;
  unitsContained?: InputMaybe<Scalars['Decimal']['input']>;
  vatTypeId?: InputMaybe<Scalars['Int']['input']>;
};

export type ItemDeleteDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ItemFilterInput = {
  and?: InputMaybe<Array<ItemFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  financialAccount?: InputMaybe<FinancialAccountFilterInput>;
  financialAccountId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  itemType?: InputMaybe<ItemTypeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ItemFilterInput>>;
  rentalServiceItem?: InputMaybe<ItemFilterInput>;
  rentalServiceItemId?: InputMaybe<IntOperationFilterInput>;
  rentalThresholdDays?: InputMaybe<IntOperationFilterInput>;
  unitOfMeasure?: InputMaybe<UnitOfMeasureFilterInput>;
  unitOfMeasureId?: InputMaybe<IntOperationFilterInput>;
  unitPrice?: InputMaybe<DecimalOperationFilterInput>;
  unitsContained?: InputMaybe<DecimalOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  vatType?: InputMaybe<VatTypeFilterInput>;
  vatTypeId?: InputMaybe<IntOperationFilterInput>;
};

export type ItemRentalServiceItemDto = {
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  unitOfMeasureId?: Maybe<Scalars['Int']['output']>;
  unitPrice?: Maybe<Scalars['Decimal']['output']>;
  unitsContained?: Maybe<Scalars['Decimal']['output']>;
};

export type ItemRentalServiceItemDtoFilterInput = {
  and?: InputMaybe<Array<ItemRentalServiceItemDtoFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ItemRentalServiceItemDtoFilterInput>>;
  unitOfMeasureId?: InputMaybe<IntOperationFilterInput>;
  unitPrice?: InputMaybe<DecimalOperationFilterInput>;
  unitsContained?: InputMaybe<DecimalOperationFilterInput>;
};

export type ItemRentalServiceItemDtoSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  unitOfMeasureId?: InputMaybe<SortEnumType>;
  unitPrice?: InputMaybe<SortEnumType>;
  unitsContained?: InputMaybe<SortEnumType>;
};

export type ItemReturnDto = {
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  financialAccount?: Maybe<ItemReturnFinancialAccountDto>;
  financialAccountId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  itemType?: Maybe<ItemType>;
  name?: Maybe<Scalars['String']['output']>;
  rentalServiceItem?: Maybe<ItemRentalServiceItemDto>;
  rentalServiceItemId?: Maybe<Scalars['Int']['output']>;
  rentalThresholdDays: Scalars['Int']['output'];
  unitOfMeasure?: Maybe<ItemReturnUnitOfMeasureDto>;
  unitOfMeasureId?: Maybe<Scalars['Int']['output']>;
  unitPrice?: Maybe<Scalars['Decimal']['output']>;
  unitsContained?: Maybe<Scalars['Decimal']['output']>;
  vatType?: Maybe<ItemReturnVatTypeDto>;
  vatTypeId?: Maybe<Scalars['Int']['output']>;
};

export type ItemReturnDtoFilterInput = {
  and?: InputMaybe<Array<ItemReturnDtoFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  financialAccount?: InputMaybe<ItemReturnFinancialAccountDtoFilterInput>;
  financialAccountId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  itemType?: InputMaybe<NullableOfItemTypeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ItemReturnDtoFilterInput>>;
  rentalServiceItem?: InputMaybe<ItemRentalServiceItemDtoFilterInput>;
  rentalServiceItemId?: InputMaybe<IntOperationFilterInput>;
  rentalThresholdDays?: InputMaybe<IntOperationFilterInput>;
  unitOfMeasure?: InputMaybe<ItemReturnUnitOfMeasureDtoFilterInput>;
  unitOfMeasureId?: InputMaybe<IntOperationFilterInput>;
  unitPrice?: InputMaybe<DecimalOperationFilterInput>;
  unitsContained?: InputMaybe<DecimalOperationFilterInput>;
  vatType?: InputMaybe<ItemReturnVatTypeDtoFilterInput>;
  vatTypeId?: InputMaybe<IntOperationFilterInput>;
};

export type ItemReturnDtoSortInput = {
  description?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  financialAccount?: InputMaybe<ItemReturnFinancialAccountDtoSortInput>;
  financialAccountId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  itemType?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  rentalServiceItem?: InputMaybe<ItemRentalServiceItemDtoSortInput>;
  rentalServiceItemId?: InputMaybe<SortEnumType>;
  rentalThresholdDays?: InputMaybe<SortEnumType>;
  unitOfMeasure?: InputMaybe<ItemReturnUnitOfMeasureDtoSortInput>;
  unitOfMeasureId?: InputMaybe<SortEnumType>;
  unitPrice?: InputMaybe<SortEnumType>;
  unitsContained?: InputMaybe<SortEnumType>;
  vatType?: InputMaybe<ItemReturnVatTypeDtoSortInput>;
  vatTypeId?: InputMaybe<SortEnumType>;
};

export type ItemReturnFinancialAccountDto = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
};

export type ItemReturnFinancialAccountDtoFilterInput = {
  and?: InputMaybe<Array<ItemReturnFinancialAccountDtoFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ItemReturnFinancialAccountDtoFilterInput>>;
};

export type ItemReturnFinancialAccountDtoSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
};

export type ItemReturnUnitOfMeasureDto = {
  name?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
};

export type ItemReturnUnitOfMeasureDtoFilterInput = {
  and?: InputMaybe<Array<ItemReturnUnitOfMeasureDtoFilterInput>>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ItemReturnUnitOfMeasureDtoFilterInput>>;
  symbol?: InputMaybe<StringOperationFilterInput>;
};

export type ItemReturnUnitOfMeasureDtoSortInput = {
  name?: InputMaybe<SortEnumType>;
  symbol?: InputMaybe<SortEnumType>;
};

export type ItemReturnVatTypeDto = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['Decimal']['output']>;
};

export type ItemReturnVatTypeDtoFilterInput = {
  and?: InputMaybe<Array<ItemReturnVatTypeDtoFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ItemReturnVatTypeDtoFilterInput>>;
  rate?: InputMaybe<DecimalOperationFilterInput>;
};

export type ItemReturnVatTypeDtoSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  rate?: InputMaybe<SortEnumType>;
};

export enum ItemType {
  Container = 'CONTAINER',
  Material = 'MATERIAL',
  Service = 'SERVICE'
}

export type ItemTypeOperationFilterInput = {
  eq?: InputMaybe<ItemType>;
  in?: InputMaybe<Array<ItemType>>;
  neq?: InputMaybe<ItemType>;
  nin?: InputMaybe<Array<ItemType>>;
};

export type ItemUpdateDtoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  financialAccountId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemType?: InputMaybe<ItemType>;
  name?: InputMaybe<Scalars['String']['input']>;
  rentalServiceItemId?: InputMaybe<Scalars['Int']['input']>;
  rentalThresholdDays: Scalars['Int']['input'];
  unitOfMeasureId?: InputMaybe<Scalars['Int']['input']>;
  unitPrice?: InputMaybe<Scalars['Decimal']['input']>;
  unitsContained?: InputMaybe<Scalars['Decimal']['input']>;
  vatTypeId?: InputMaybe<Scalars['Int']['input']>;
};

/** A segment of a collection. */
export type ItemsCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<ItemReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type KeyValuePairOfDateTimeAndWorkOrderCountAndValuePerDayValueDto = {
  key: Scalars['DateTime']['output'];
  value: WorkOrderCountAndValuePerDayValueDto;
};

export type ListFilterInputTypeOfApplicationUserFilterInput = {
  all?: InputMaybe<ApplicationUserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ApplicationUserFilterInput>;
  some?: InputMaybe<ApplicationUserFilterInput>;
};

export type ListFilterInputTypeOfApplicationUserRoleFilterInput = {
  all?: InputMaybe<ApplicationUserRoleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ApplicationUserRoleFilterInput>;
  some?: InputMaybe<ApplicationUserRoleFilterInput>;
};

export type ListFilterInputTypeOfCompanyLocationFilterInput = {
  all?: InputMaybe<CompanyLocationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CompanyLocationFilterInput>;
  some?: InputMaybe<CompanyLocationFilterInput>;
};

export type ListFilterInputTypeOfFileFilterInput = {
  all?: InputMaybe<FileFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FileFilterInput>;
  some?: InputMaybe<FileFilterInput>;
};

export type ListFilterInputTypeOfFileReturnDtoFilterInput = {
  all?: InputMaybe<FileReturnDtoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FileReturnDtoFilterInput>;
  some?: InputMaybe<FileReturnDtoFilterInput>;
};

export type ListFilterInputTypeOfOrganizationalEntityFilterInput = {
  all?: InputMaybe<OrganizationalEntityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganizationalEntityFilterInput>;
  some?: InputMaybe<OrganizationalEntityFilterInput>;
};

export type ListFilterInputTypeOfPickupRequestFilterInput = {
  all?: InputMaybe<PickupRequestFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PickupRequestFilterInput>;
  some?: InputMaybe<PickupRequestFilterInput>;
};

export type ListFilterInputTypeOfWorkOrderItemFilterInput = {
  all?: InputMaybe<WorkOrderItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<WorkOrderItemFilterInput>;
  some?: InputMaybe<WorkOrderItemFilterInput>;
};

export type ListFilterInputTypeOfWorkOrderItemReturnDtoFilterInput = {
  all?: InputMaybe<WorkOrderItemReturnDtoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<WorkOrderItemReturnDtoFilterInput>;
  some?: InputMaybe<WorkOrderItemReturnDtoFilterInput>;
};

export type ListFilterInputTypeOfWorkOrderStatusChangeFilterInput = {
  all?: InputMaybe<WorkOrderStatusChangeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<WorkOrderStatusChangeFilterInput>;
  some?: InputMaybe<WorkOrderStatusChangeFilterInput>;
};

export type ListFilterInputTypeOfWorkOrderTemplateItemFilterInput = {
  all?: InputMaybe<WorkOrderTemplateItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<WorkOrderTemplateItemFilterInput>;
  some?: InputMaybe<WorkOrderTemplateItemFilterInput>;
};

export type ListFilterInputTypeOfWorkOrderTemplateItemReturnDtoFilterInput = {
  all?: InputMaybe<WorkOrderTemplateItemReturnDtoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<WorkOrderTemplateItemReturnDtoFilterInput>;
  some?: InputMaybe<WorkOrderTemplateItemReturnDtoFilterInput>;
};

export enum NotificationType {
  NewWorkOrder = 'NEW_WORK_ORDER'
}

export type NullableOfDayOfWeekOperationFilterInput = {
  eq?: InputMaybe<DayOfWeek>;
  in?: InputMaybe<Array<InputMaybe<DayOfWeek>>>;
  neq?: InputMaybe<DayOfWeek>;
  nin?: InputMaybe<Array<InputMaybe<DayOfWeek>>>;
};

export type NullableOfItemTypeOperationFilterInput = {
  eq?: InputMaybe<ItemType>;
  in?: InputMaybe<Array<InputMaybe<ItemType>>>;
  neq?: InputMaybe<ItemType>;
  nin?: InputMaybe<Array<InputMaybe<ItemType>>>;
};

export type NullableOfWorkOrderPriorityOperationFilterInput = {
  eq?: InputMaybe<WorkOrderPriority>;
  in?: InputMaybe<Array<InputMaybe<WorkOrderPriority>>>;
  neq?: InputMaybe<WorkOrderPriority>;
  nin?: InputMaybe<Array<InputMaybe<WorkOrderPriority>>>;
};

export type NullableOfWorkOrderStatusOperationFilterInput = {
  eq?: InputMaybe<WorkOrderStatus>;
  in?: InputMaybe<Array<InputMaybe<WorkOrderStatus>>>;
  neq?: InputMaybe<WorkOrderStatus>;
  nin?: InputMaybe<Array<InputMaybe<WorkOrderStatus>>>;
};

export enum OgcGeometryType {
  CircularString = 'CIRCULAR_STRING',
  CompoundCurve = 'COMPOUND_CURVE',
  Curve = 'CURVE',
  CurvePolygon = 'CURVE_POLYGON',
  GeometryCollection = 'GEOMETRY_COLLECTION',
  LineString = 'LINE_STRING',
  MultiCurve = 'MULTI_CURVE',
  MultiLineString = 'MULTI_LINE_STRING',
  MultiPoint = 'MULTI_POINT',
  MultiPolygon = 'MULTI_POLYGON',
  MultiSurface = 'MULTI_SURFACE',
  Point = 'POINT',
  Polygon = 'POLYGON',
  PolyhedralSurface = 'POLYHEDRAL_SURFACE',
  Surface = 'SURFACE',
  Tin = 'TIN'
}

export type OgcGeometryTypeOperationFilterInput = {
  eq?: InputMaybe<OgcGeometryType>;
  in?: InputMaybe<Array<OgcGeometryType>>;
  neq?: InputMaybe<OgcGeometryType>;
  nin?: InputMaybe<Array<OgcGeometryType>>;
};

/** A segment of a collection. */
export type OrganizationalEntitiesCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<OrganizationalEntityReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrganizationalEntity = {
  children: Array<OrganizationalEntity>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parent: OrganizationalEntity;
  parentId?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganizationalEntityCreateDtoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationalEntityDeleteDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationalEntityFilterInput = {
  and?: InputMaybe<Array<OrganizationalEntityFilterInput>>;
  children?: InputMaybe<ListFilterInputTypeOfOrganizationalEntityFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDefault?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganizationalEntityFilterInput>>;
  parent?: InputMaybe<OrganizationalEntityFilterInput>;
  parentId?: InputMaybe<IntOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type OrganizationalEntityReturnDto = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<OrganizationalEntityReturnDto>;
  parentId?: Maybe<Scalars['Int']['output']>;
};

export type OrganizationalEntityReturnDtoFilterInput = {
  and?: InputMaybe<Array<OrganizationalEntityReturnDtoFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDefault?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganizationalEntityReturnDtoFilterInput>>;
  parent?: InputMaybe<OrganizationalEntityReturnDtoFilterInput>;
  parentId?: InputMaybe<IntOperationFilterInput>;
};

export type OrganizationalEntityReturnDtoSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDefault?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parent?: InputMaybe<OrganizationalEntityReturnDtoSortInput>;
  parentId?: InputMaybe<SortEnumType>;
};

export type OrganizationalEntitySortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  deletedAt?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDefault?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parent?: InputMaybe<OrganizationalEntitySortInput>;
  parentId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type OrganizationalEntityUpdateDtoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
};

export type PartnerContactReturnDto = {
  companyId?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  userName: Scalars['String']['output'];
};

export type PartnerPickupRequestCompanyLocationDto = {
  address?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type PartnerPickupRequestCompanyLocationDtoFilterInput = {
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<PartnerPickupRequestCompanyLocationDtoFilterInput>>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PartnerPickupRequestCompanyLocationDtoFilterInput>>;
};

export type PartnerPickupRequestCompanyLocationDtoSortInput = {
  address?: InputMaybe<SortEnumType>;
  companyId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type PartnerPickupRequestCreateDtoInput = {
  companyLocationId?: InputMaybe<Scalars['Int']['input']>;
  locationNote?: InputMaybe<Scalars['String']['input']>;
  pickupDate?: InputMaybe<Scalars['DateTime']['input']>;
  serviceNote?: InputMaybe<Scalars['String']['input']>;
};

export type PartnerPickupRequestDeleteDtoInput = {
  id: Scalars['Int']['input'];
};

export type PartnerPickupRequestFilteredReturnDto = {
  companyLocation?: Maybe<PartnerPickupRequestCompanyLocationDto>;
  companyLocationId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  locationNote?: Maybe<Scalars['String']['output']>;
  pickupDate?: Maybe<Scalars['DateTime']['output']>;
  serviceNote?: Maybe<Scalars['String']['output']>;
};

export type PartnerPickupRequestFilteredReturnDtoFilterInput = {
  and?: InputMaybe<Array<PartnerPickupRequestFilteredReturnDtoFilterInput>>;
  companyLocation?: InputMaybe<PartnerPickupRequestCompanyLocationDtoFilterInput>;
  companyLocationId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  locationNote?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PartnerPickupRequestFilteredReturnDtoFilterInput>>;
  pickupDate?: InputMaybe<DateTimeOperationFilterInput>;
  serviceNote?: InputMaybe<StringOperationFilterInput>;
};

export type PartnerPickupRequestFilteredReturnDtoSortInput = {
  companyLocation?: InputMaybe<PartnerPickupRequestCompanyLocationDtoSortInput>;
  companyLocationId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  locationNote?: InputMaybe<SortEnumType>;
  pickupDate?: InputMaybe<SortEnumType>;
  serviceNote?: InputMaybe<SortEnumType>;
};

export type PartnerPickupRequestReturnDto = {
  companyLocation?: Maybe<PickupRequestCompanyLocationDto>;
  companyLocationId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isAssigned: Scalars['Boolean']['output'];
  locationNote?: Maybe<Scalars['String']['output']>;
  pickupDate?: Maybe<Scalars['DateTime']['output']>;
  reportingUser: PickupRequestApplicationUserDto;
  reportingUserId: Scalars['String']['output'];
  serviceNote?: Maybe<Scalars['String']['output']>;
  workOrder?: Maybe<PickupRequestWorkOrderDto>;
};

export type PartnerPickupRequestReturnDtoFilterInput = {
  and?: InputMaybe<Array<PartnerPickupRequestReturnDtoFilterInput>>;
  companyLocation?: InputMaybe<PickupRequestCompanyLocationDtoFilterInput>;
  companyLocationId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isAssigned?: InputMaybe<BooleanOperationFilterInput>;
  locationNote?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PartnerPickupRequestReturnDtoFilterInput>>;
  pickupDate?: InputMaybe<DateTimeOperationFilterInput>;
  reportingUser?: InputMaybe<PickupRequestApplicationUserDtoFilterInput>;
  reportingUserId?: InputMaybe<StringOperationFilterInput>;
  serviceNote?: InputMaybe<StringOperationFilterInput>;
  workOrder?: InputMaybe<PickupRequestWorkOrderDtoFilterInput>;
};

export type PartnerPickupRequestReturnDtoSortInput = {
  companyLocation?: InputMaybe<PickupRequestCompanyLocationDtoSortInput>;
  companyLocationId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isAssigned?: InputMaybe<SortEnumType>;
  locationNote?: InputMaybe<SortEnumType>;
  pickupDate?: InputMaybe<SortEnumType>;
  reportingUser?: InputMaybe<PickupRequestApplicationUserDtoSortInput>;
  reportingUserId?: InputMaybe<SortEnumType>;
  serviceNote?: InputMaybe<SortEnumType>;
  workOrder?: InputMaybe<PickupRequestWorkOrderDtoSortInput>;
};

export type PartnerPickupRequestUpdateDtoInput = {
  companyLocationId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  locationNote?: InputMaybe<Scalars['String']['input']>;
  pickupDate?: InputMaybe<Scalars['DateTime']['input']>;
  serviceNote?: InputMaybe<Scalars['String']['input']>;
};

/** A segment of a collection. */
export type PartnerPickupRequestsCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<PartnerPickupRequestReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type PartnerPickupRequestsFilterDtoInput = {
  unassignedOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A segment of a collection. */
export type PartnerPickupRequestsFilteredCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<PartnerPickupRequestFilteredReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type PartnerPortalNoteCreateDtoInput = {
  note?: InputMaybe<Scalars['String']['input']>;
};

export type PartnerPortalNoteDeleteDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type PartnerPortalNoteReturnDto = {
  id: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type PartnerPortalNoteReturnDtoFilterInput = {
  and?: InputMaybe<Array<PartnerPortalNoteReturnDtoFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PartnerPortalNoteReturnDtoFilterInput>>;
};

export type PartnerPortalNoteReturnDtoSortInput = {
  id?: InputMaybe<SortEnumType>;
  note?: InputMaybe<SortEnumType>;
};

export type PartnerPortalNoteUpdateDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};

/** A segment of a collection. */
export type PartnerPortalNotesCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<PartnerPortalNoteReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type PickupRequest = {
  company: Company;
  companyId: Scalars['Int']['output'];
  companyLocation?: Maybe<CompanyLocation>;
  companyLocationId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  locationNote?: Maybe<Scalars['String']['output']>;
  pickupDate?: Maybe<Scalars['DateTime']['output']>;
  reportingUser: ApplicationUser;
  reportingUserId: Scalars['String']['output'];
  serviceNote?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  workOrder?: Maybe<WorkOrder>;
};

export type PickupRequestApplicationUserDto = {
  id: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export type PickupRequestApplicationUserDtoFilterInput = {
  and?: InputMaybe<Array<PickupRequestApplicationUserDtoFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PickupRequestApplicationUserDtoFilterInput>>;
  userName?: InputMaybe<StringOperationFilterInput>;
};

export type PickupRequestApplicationUserDtoSortInput = {
  id?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

export type PickupRequestCompanyDto = {
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
};

export type PickupRequestCompanyDtoFilterInput = {
  and?: InputMaybe<Array<PickupRequestCompanyDtoFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PickupRequestCompanyDtoFilterInput>>;
  taxId?: InputMaybe<StringOperationFilterInput>;
};

export type PickupRequestCompanyDtoSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  taxId?: InputMaybe<SortEnumType>;
};

export type PickupRequestCompanyLocationDto = {
  address?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isMainLocation: Scalars['Boolean']['output'];
  location?: Maybe<PointDto>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PickupRequestCompanyLocationDtoFilterInput = {
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<PickupRequestCompanyLocationDtoFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  isMainLocation?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<PointDtoFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PickupRequestCompanyLocationDtoFilterInput>>;
};

export type PickupRequestCompanyLocationDtoSortInput = {
  address?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isMainLocation?: InputMaybe<SortEnumType>;
  location?: InputMaybe<PointDtoSortInput>;
  name?: InputMaybe<SortEnumType>;
};

export type PickupRequestCreateDtoInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  companyLocationId?: InputMaybe<Scalars['Int']['input']>;
  locationNote?: InputMaybe<Scalars['String']['input']>;
  pickupDate?: InputMaybe<Scalars['DateTime']['input']>;
  serviceNote?: InputMaybe<Scalars['String']['input']>;
};

export type PickupRequestDeleteDtoInput = {
  id: Scalars['Int']['input'];
};

export type PickupRequestFilterInput = {
  and?: InputMaybe<Array<PickupRequestFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  companyLocation?: InputMaybe<CompanyLocationFilterInput>;
  companyLocationId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  locationNote?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PickupRequestFilterInput>>;
  pickupDate?: InputMaybe<DateTimeOperationFilterInput>;
  reportingUser?: InputMaybe<ApplicationUserFilterInput>;
  reportingUserId?: InputMaybe<StringOperationFilterInput>;
  serviceNote?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  workOrder?: InputMaybe<WorkOrderFilterInput>;
};

export type PickupRequestFilteredReturnDto = {
  company?: Maybe<PickupRequestCompanyDto>;
  companyId?: Maybe<Scalars['Int']['output']>;
  companyLocation?: Maybe<PickupRequestCompanyLocationDto>;
  companyLocationId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isAssigned: Scalars['Boolean']['output'];
  locationNote?: Maybe<Scalars['String']['output']>;
  pickupDate?: Maybe<Scalars['DateTime']['output']>;
  reportingUser?: Maybe<PickupRequestApplicationUserDto>;
  serviceNote?: Maybe<Scalars['String']['output']>;
  workOrder?: Maybe<PickupRequestWorkOrderDto>;
  workOrderId?: Maybe<Scalars['Int']['output']>;
};

export type PickupRequestFilteredReturnDtoFilterInput = {
  and?: InputMaybe<Array<PickupRequestFilteredReturnDtoFilterInput>>;
  company?: InputMaybe<PickupRequestCompanyDtoFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  companyLocation?: InputMaybe<PickupRequestCompanyLocationDtoFilterInput>;
  companyLocationId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isAssigned?: InputMaybe<BooleanOperationFilterInput>;
  locationNote?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PickupRequestFilteredReturnDtoFilterInput>>;
  pickupDate?: InputMaybe<DateTimeOperationFilterInput>;
  reportingUser?: InputMaybe<PickupRequestApplicationUserDtoFilterInput>;
  serviceNote?: InputMaybe<StringOperationFilterInput>;
  workOrder?: InputMaybe<PickupRequestWorkOrderDtoFilterInput>;
  workOrderId?: InputMaybe<IntOperationFilterInput>;
};

export type PickupRequestFilteredReturnDtoSortInput = {
  company?: InputMaybe<PickupRequestCompanyDtoSortInput>;
  companyId?: InputMaybe<SortEnumType>;
  companyLocation?: InputMaybe<PickupRequestCompanyLocationDtoSortInput>;
  companyLocationId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isAssigned?: InputMaybe<SortEnumType>;
  locationNote?: InputMaybe<SortEnumType>;
  pickupDate?: InputMaybe<SortEnumType>;
  reportingUser?: InputMaybe<PickupRequestApplicationUserDtoSortInput>;
  serviceNote?: InputMaybe<SortEnumType>;
  workOrder?: InputMaybe<PickupRequestWorkOrderDtoSortInput>;
  workOrderId?: InputMaybe<SortEnumType>;
};

export type PickupRequestUpdateDtoInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  companyLocationId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  locationNote?: InputMaybe<Scalars['String']['input']>;
  pickupDate?: InputMaybe<Scalars['DateTime']['input']>;
  serviceNote?: InputMaybe<Scalars['String']['input']>;
};

export type PickupRequestWorkOrderDto = {
  currentStatus?: Maybe<PickupRequestWorkOrderStatusChangeDto>;
  id: Scalars['Int']['output'];
  pickupRequestId: Scalars['Int']['output'];
};

export type PickupRequestWorkOrderDtoFilterInput = {
  and?: InputMaybe<Array<PickupRequestWorkOrderDtoFilterInput>>;
  currentStatus?: InputMaybe<PickupRequestWorkOrderStatusChangeDtoFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PickupRequestWorkOrderDtoFilterInput>>;
  pickupRequestId?: InputMaybe<IntOperationFilterInput>;
};

export type PickupRequestWorkOrderDtoSortInput = {
  currentStatus?: InputMaybe<PickupRequestWorkOrderStatusChangeDtoSortInput>;
  id?: InputMaybe<SortEnumType>;
  pickupRequestId?: InputMaybe<SortEnumType>;
};

export type PickupRequestWorkOrderStatusChangeApplicationUserDto = {
  id: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export type PickupRequestWorkOrderStatusChangeApplicationUserDtoFilterInput = {
  and?: InputMaybe<Array<PickupRequestWorkOrderStatusChangeApplicationUserDtoFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PickupRequestWorkOrderStatusChangeApplicationUserDtoFilterInput>>;
  userName?: InputMaybe<StringOperationFilterInput>;
};

export type PickupRequestWorkOrderStatusChangeApplicationUserDtoSortInput = {
  id?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

export type PickupRequestWorkOrderStatusChangeDto = {
  dateTime: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  initiatingUser: PickupRequestWorkOrderStatusChangeApplicationUserDto;
  initiatingUserId: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  status: WorkOrderStatus;
  workOrderId: Scalars['Int']['output'];
};

export type PickupRequestWorkOrderStatusChangeDtoFilterInput = {
  and?: InputMaybe<Array<PickupRequestWorkOrderStatusChangeDtoFilterInput>>;
  dateTime?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  initiatingUser?: InputMaybe<PickupRequestWorkOrderStatusChangeApplicationUserDtoFilterInput>;
  initiatingUserId?: InputMaybe<StringOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PickupRequestWorkOrderStatusChangeDtoFilterInput>>;
  status?: InputMaybe<WorkOrderStatusOperationFilterInput>;
  workOrderId?: InputMaybe<IntOperationFilterInput>;
};

export type PickupRequestWorkOrderStatusChangeDtoSortInput = {
  dateTime?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  initiatingUser?: InputMaybe<PickupRequestWorkOrderStatusChangeApplicationUserDtoSortInput>;
  initiatingUserId?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  workOrderId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type PickupRequestsCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<PickupRequestFilteredReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type PickupRequestsFilterDtoInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  companyLocationId?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  unassignedOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A segment of a collection. */
export type PickupRequestsFilteredCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<PickupRequestFilteredReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type PointDto = {
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type PointDtoFilterInput = {
  and?: InputMaybe<Array<PointDtoFilterInput>>;
  latitude?: InputMaybe<FloatOperationFilterInput>;
  longitude?: InputMaybe<FloatOperationFilterInput>;
  or?: InputMaybe<Array<PointDtoFilterInput>>;
};

export type PointDtoInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type PointDtoSortInput = {
  latitude?: InputMaybe<SortEnumType>;
  longitude?: InputMaybe<SortEnumType>;
};

export type PointFilterInput = {
  and?: InputMaybe<Array<PointFilterInput>>;
  area?: InputMaybe<FloatOperationFilterInput>;
  boundary?: InputMaybe<GeometryFilterInput>;
  centroid?: InputMaybe<PointFilterInput>;
  contains?: InputMaybe<GeometryContainsOperationFilterInput>;
  dimension?: InputMaybe<DimensionOperationFilterInput>;
  distance?: InputMaybe<GeometryDistanceOperationFilterInput>;
  envelope?: InputMaybe<GeometryFilterInput>;
  geometryType?: InputMaybe<StringOperationFilterInput>;
  interiorPoint?: InputMaybe<PointFilterInput>;
  intersects?: InputMaybe<GeometryIntersectsOperationFilterInput>;
  isSimple?: InputMaybe<BooleanOperationFilterInput>;
  isValid?: InputMaybe<BooleanOperationFilterInput>;
  length?: InputMaybe<FloatOperationFilterInput>;
  m?: InputMaybe<FloatOperationFilterInput>;
  ncontains?: InputMaybe<GeometryContainsOperationFilterInput>;
  nintersects?: InputMaybe<GeometryIntersectsOperationFilterInput>;
  noverlaps?: InputMaybe<GeometryOverlapsOperationFilterInput>;
  ntouches?: InputMaybe<GeometryTouchesOperationFilterInput>;
  numPoints?: InputMaybe<IntOperationFilterInput>;
  nwithin?: InputMaybe<GeometryWithinOperationFilterInput>;
  ogcGeometryType?: InputMaybe<OgcGeometryTypeOperationFilterInput>;
  or?: InputMaybe<Array<PointFilterInput>>;
  overlaps?: InputMaybe<GeometryOverlapsOperationFilterInput>;
  pointOnSurface?: InputMaybe<PointFilterInput>;
  srid?: InputMaybe<IntOperationFilterInput>;
  touches?: InputMaybe<GeometryTouchesOperationFilterInput>;
  within?: InputMaybe<GeometryWithinOperationFilterInput>;
  x?: InputMaybe<FloatOperationFilterInput>;
  y?: InputMaybe<FloatOperationFilterInput>;
  z?: InputMaybe<FloatOperationFilterInput>;
};

export type PointSortInput = {
  x?: InputMaybe<SortEnumType>;
  y?: InputMaybe<SortEnumType>;
  z?: InputMaybe<SortEnumType>;
};

export type RestoreDeletedItemDtoInput = {
  itemId: Scalars['Int']['input'];
};

export type RestoreDeletedWorkOrderDtoInput = {
  workOrderId: Scalars['Int']['input'];
};

export type SendPdfFilesDtoInput = {
  contactId?: InputMaybe<Scalars['String']['input']>;
  directToEmail?: InputMaybe<Scalars['String']['input']>;
  fileIds: Array<Scalars['Int']['input']>;
};

export type SendWorkOrderPdfDtoInput = {
  contactId?: InputMaybe<Scalars['String']['input']>;
  directToEmail?: InputMaybe<Scalars['String']['input']>;
  workOrderIds: Array<Scalars['Int']['input']>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type SyncDataDto = {
  companies: Array<CompanyReturnDto>;
  companyLocations: Array<CompanyLocationReturnDto>;
  costLocations: Array<CostLocationReturnDto>;
  items: Array<ItemReturnDto>;
  organizationalEntities: Array<OrganizationalEntityReturnDto>;
  partnerContacts: Array<PartnerContactReturnDto>;
  profile?: Maybe<FieldWorkerProfileReturnDto>;
  syncTime: Scalars['DateTime']['output'];
  unitsOfMeasure: Array<UnitOfMeasureReturnDto>;
  vehicles: Array<VehicleReturnDto>;
};

export type TimeSpanOperationFilterInput = {
  eq?: InputMaybe<Scalars['TimeSpan']['input']>;
  gt?: InputMaybe<Scalars['TimeSpan']['input']>;
  gte?: InputMaybe<Scalars['TimeSpan']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['TimeSpan']['input']>>>;
  lt?: InputMaybe<Scalars['TimeSpan']['input']>;
  lte?: InputMaybe<Scalars['TimeSpan']['input']>;
  neq?: InputMaybe<Scalars['TimeSpan']['input']>;
  ngt?: InputMaybe<Scalars['TimeSpan']['input']>;
  ngte?: InputMaybe<Scalars['TimeSpan']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['TimeSpan']['input']>>>;
  nlt?: InputMaybe<Scalars['TimeSpan']['input']>;
  nlte?: InputMaybe<Scalars['TimeSpan']['input']>;
};

export type UnitOfMeasure = {
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UnitOfMeasureCreateDtoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
};

export type UnitOfMeasureDeleteDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type UnitOfMeasureFilterInput = {
  and?: InputMaybe<Array<UnitOfMeasureFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UnitOfMeasureFilterInput>>;
  symbol?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type UnitOfMeasureReturnDto = {
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
};

export type UnitOfMeasureReturnDtoFilterInput = {
  and?: InputMaybe<Array<UnitOfMeasureReturnDtoFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UnitOfMeasureReturnDtoFilterInput>>;
  symbol?: InputMaybe<StringOperationFilterInput>;
};

export type UnitOfMeasureReturnDtoSortInput = {
  description?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  symbol?: InputMaybe<SortEnumType>;
};

export type UnitOfMeasureUpdateDtoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
};

/** A segment of a collection. */
export type UnitsOfMeasureCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<UnitOfMeasureReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserNotificationReturnDto = {
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isSeen: Scalars['Boolean']['output'];
  notificationRelatedWorkOrderId?: Maybe<Scalars['Int']['output']>;
  notificationType?: Maybe<NotificationType>;
  title?: Maybe<Scalars['String']['output']>;
};

export type UserNotificationReturnDtoSortInput = {
  content?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isSeen?: InputMaybe<SortEnumType>;
  notificationRelatedWorkOrderId?: InputMaybe<SortEnumType>;
  notificationType?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
};

export enum UserRole {
  Accounting = 'ACCOUNTING',
  FieldWorker = 'FIELD_WORKER',
  Manager = 'MANAGER',
  PartnerContact = 'PARTNER_CONTACT'
}

/** A segment of a collection. */
export type UsersCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<ApplicationUser>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** A segment of a collection. */
export type UsersUnfilteredCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<ApplicationUserReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type VatType = {
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  rate: Scalars['Decimal']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type VatTypeCreateDtoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['Decimal']['input']>;
};

export type VatTypeDeleteDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type VatTypeFilterInput = {
  and?: InputMaybe<Array<VatTypeFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<VatTypeFilterInput>>;
  rate?: InputMaybe<DecimalOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type VatTypeReturnDto = {
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['Decimal']['output']>;
};

export type VatTypeReturnDtoFilterInput = {
  and?: InputMaybe<Array<VatTypeReturnDtoFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<VatTypeReturnDtoFilterInput>>;
  rate?: InputMaybe<DecimalOperationFilterInput>;
};

export type VatTypeReturnDtoSortInput = {
  description?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  rate?: InputMaybe<SortEnumType>;
};

export type VatTypeUpdateDtoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['Decimal']['input']>;
};

/** A segment of a collection. */
export type VatTypesCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<VatTypeReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Vehicle = {
  assignedUser?: Maybe<ApplicationUser>;
  costLocation?: Maybe<CostLocation>;
  costLocationId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  location?: Maybe<GeoJsonPointType>;
  name?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type VehicleAssignedUserDto = {
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type VehicleAssignedUserDtoFilterInput = {
  and?: InputMaybe<Array<VehicleAssignedUserDtoFilterInput>>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<VehicleAssignedUserDtoFilterInput>>;
  userName?: InputMaybe<StringOperationFilterInput>;
};

export type VehicleAssignedUserDtoInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleAssignedUserDtoSortInput = {
  firstName?: InputMaybe<SortEnumType>;
  fullName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

export type VehicleCreateDtoInput = {
  assignedUser?: InputMaybe<VehicleAssignedUserDtoInput>;
  costLocationId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleDeleteDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleFilterInput = {
  and?: InputMaybe<Array<VehicleFilterInput>>;
  assignedUser?: InputMaybe<ApplicationUserFilterInput>;
  costLocation?: InputMaybe<CostLocationFilterInput>;
  costLocationId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<PointFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<VehicleFilterInput>>;
  registrationNumber?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type VehicleReturnCostLocationDto = {
  code?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type VehicleReturnCostLocationDtoFilterInput = {
  and?: InputMaybe<Array<VehicleReturnCostLocationDtoFilterInput>>;
  code?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<VehicleReturnCostLocationDtoFilterInput>>;
};

export type VehicleReturnCostLocationDtoSortInput = {
  code?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type VehicleReturnDto = {
  assignedUser?: Maybe<VehicleAssignedUserDto>;
  costLocation?: Maybe<VehicleReturnCostLocationDto>;
  costLocationId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  location?: Maybe<PointDto>;
  name?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
};

export type VehicleReturnDtoFilterInput = {
  and?: InputMaybe<Array<VehicleReturnDtoFilterInput>>;
  assignedUser?: InputMaybe<VehicleAssignedUserDtoFilterInput>;
  costLocation?: InputMaybe<VehicleReturnCostLocationDtoFilterInput>;
  costLocationId?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<PointDtoFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<VehicleReturnDtoFilterInput>>;
  registrationNumber?: InputMaybe<StringOperationFilterInput>;
};

export type VehicleReturnDtoSortInput = {
  assignedUser?: InputMaybe<VehicleAssignedUserDtoSortInput>;
  costLocation?: InputMaybe<VehicleReturnCostLocationDtoSortInput>;
  costLocationId?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  location?: InputMaybe<PointDtoSortInput>;
  name?: InputMaybe<SortEnumType>;
  registrationNumber?: InputMaybe<SortEnumType>;
};

export type VehicleSortInput = {
  assignedUser?: InputMaybe<ApplicationUserSortInput>;
  costLocation?: InputMaybe<CostLocationSortInput>;
  costLocationId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  deletedAt?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  externalId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  location?: InputMaybe<PointSortInput>;
  name?: InputMaybe<SortEnumType>;
  registrationNumber?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type VehicleUpdateDtoInput = {
  assignedUser?: InputMaybe<VehicleAssignedUserDtoInput>;
  costLocationId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
};

/** A segment of a collection. */
export type VehiclesCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<VehicleReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkOrder = {
  assignedByManagerUser?: Maybe<ApplicationUser>;
  assignedByManagerUserId?: Maybe<Scalars['String']['output']>;
  assignedUser?: Maybe<ApplicationUser>;
  assignedUserId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']['output']>;
  companyLocation?: Maybe<CompanyLocation>;
  companyLocationId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentStatus?: Maybe<WorkOrderStatusChange>;
  currentStatusId?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endDateTime?: Maybe<Scalars['DateTime']['output']>;
  endLocation?: Maybe<GeoJsonPointType>;
  fieldWorkerNote?: Maybe<Scalars['String']['output']>;
  files: Array<File>;
  formattedId: Scalars['String']['output'];
  freeformCompany?: Maybe<Scalars['String']['output']>;
  freeformLocation?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  items: Array<WorkOrderItem>;
  managerNote?: Maybe<Scalars['String']['output']>;
  noteForAccounting?: Maybe<Scalars['String']['output']>;
  number: Scalars['Int']['output'];
  offlineWorkOrderId?: Maybe<Scalars['String']['output']>;
  organizationalEntity?: Maybe<OrganizationalEntity>;
  organizationalEntityId?: Maybe<Scalars['Int']['output']>;
  pickupRequest?: Maybe<PickupRequest>;
  pickupRequestId?: Maybe<Scalars['Int']['output']>;
  priority: WorkOrderPriority;
  scheduledDate: Scalars['DateTime']['output'];
  startDateTime?: Maybe<Scalars['DateTime']['output']>;
  startLocation?: Maybe<GeoJsonPointType>;
  statusChanges: Array<WorkOrderStatusChange>;
  template?: Maybe<WorkOrderTemplate>;
  templateDate?: Maybe<Scalars['DateTime']['output']>;
  templateId?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Decimal']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vat?: Maybe<Scalars['Decimal']['output']>;
  vatBase?: Maybe<Scalars['Decimal']['output']>;
  vehicle?: Maybe<Vehicle>;
  vehicleId?: Maybe<Scalars['Int']['output']>;
  year: Scalars['Int']['output'];
  yearShort: Scalars['Int']['output'];
};

export type WorkOrderApplicationUserReturnDto = {
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type WorkOrderApplicationUserReturnDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderApplicationUserReturnDtoFilterInput>>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderApplicationUserReturnDtoFilterInput>>;
  userName?: InputMaybe<StringOperationFilterInput>;
};

export type WorkOrderApplicationUserReturnDtoSortInput = {
  firstName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

export type WorkOrderCostLocationReturnDto = {
  code?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type WorkOrderCostLocationReturnDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderCostLocationReturnDtoFilterInput>>;
  code?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderCostLocationReturnDtoFilterInput>>;
};

export type WorkOrderCostLocationReturnDtoSortInput = {
  code?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type WorkOrderCountAndValuePerDayValueDto = {
  count: Scalars['Int']['output'];
  value: Scalars['Decimal']['output'];
};

export type WorkOrderCountPerDayDtoInput = {
  assigneeId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  companyLocationId?: InputMaybe<Scalars['Int']['input']>;
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkOrderCreateDtoInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  companyLocationId?: InputMaybe<Scalars['Int']['input']>;
  files: Array<WorkOrderFileCreateDtoInput>;
  freeformCompany?: InputMaybe<Scalars['String']['input']>;
  freeformLocation?: InputMaybe<Scalars['String']['input']>;
  managerNote?: InputMaybe<Scalars['String']['input']>;
  noteForAccounting?: InputMaybe<Scalars['String']['input']>;
  organizationalEntityId?: InputMaybe<Scalars['Int']['input']>;
  pickupRequestId?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<WorkOrderPriority>;
  scheduledDate?: InputMaybe<Scalars['DateTime']['input']>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkOrderDeleteDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkOrderFileCreateDtoInput = {
  id: Scalars['Int']['input'];
};

export type WorkOrderFileUpdateDtoInput = {
  id: Scalars['Int']['input'];
};

export type WorkOrderFilterDtoInput = {
  assignedUserId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  companyLocationId?: InputMaybe<Scalars['Int']['input']>;
  hasSignedPDF?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  organizationalEntityId?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<WorkOrderPriority>;
  scheduledDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<WorkOrderStatus>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkOrderFilterInput = {
  and?: InputMaybe<Array<WorkOrderFilterInput>>;
  assignedByManagerUser?: InputMaybe<ApplicationUserFilterInput>;
  assignedByManagerUserId?: InputMaybe<StringOperationFilterInput>;
  assignedUser?: InputMaybe<ApplicationUserFilterInput>;
  assignedUserId?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  companyLocation?: InputMaybe<CompanyLocationFilterInput>;
  companyLocationId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  currentStatus?: InputMaybe<WorkOrderStatusChangeFilterInput>;
  currentStatusId?: InputMaybe<IntOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  endDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  endLocation?: InputMaybe<PointFilterInput>;
  fieldWorkerNote?: InputMaybe<StringOperationFilterInput>;
  files?: InputMaybe<ListFilterInputTypeOfFileFilterInput>;
  formattedId?: InputMaybe<StringOperationFilterInput>;
  freeformCompany?: InputMaybe<StringOperationFilterInput>;
  freeformLocation?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  items?: InputMaybe<ListFilterInputTypeOfWorkOrderItemFilterInput>;
  managerNote?: InputMaybe<StringOperationFilterInput>;
  noteForAccounting?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<IntOperationFilterInput>;
  offlineWorkOrderId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderFilterInput>>;
  organizationalEntity?: InputMaybe<OrganizationalEntityFilterInput>;
  organizationalEntityId?: InputMaybe<IntOperationFilterInput>;
  pickupRequest?: InputMaybe<PickupRequestFilterInput>;
  pickupRequestId?: InputMaybe<IntOperationFilterInput>;
  priority?: InputMaybe<WorkOrderPriorityOperationFilterInput>;
  scheduledDate?: InputMaybe<DateTimeOperationFilterInput>;
  startDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  startLocation?: InputMaybe<PointFilterInput>;
  statusChanges?: InputMaybe<ListFilterInputTypeOfWorkOrderStatusChangeFilterInput>;
  template?: InputMaybe<WorkOrderTemplateFilterInput>;
  templateDate?: InputMaybe<DateTimeOperationFilterInput>;
  templateId?: InputMaybe<IntOperationFilterInput>;
  total?: InputMaybe<DecimalOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  vat?: InputMaybe<DecimalOperationFilterInput>;
  vatBase?: InputMaybe<DecimalOperationFilterInput>;
  vehicle?: InputMaybe<VehicleFilterInput>;
  vehicleId?: InputMaybe<IntOperationFilterInput>;
  year?: InputMaybe<IntOperationFilterInput>;
  yearShort?: InputMaybe<IntOperationFilterInput>;
};

export type WorkOrderFlattenedReturnDto = {
  assignedByManagerUserUserName?: Maybe<Scalars['String']['output']>;
  assignedUserUserName?: Maybe<Scalars['String']['output']>;
  currentStatusStatus?: Maybe<WorkOrderStatus>;
  endDateTime?: Maybe<Scalars['DateTime']['output']>;
  executionCategoryName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  itemsTotal?: Maybe<Scalars['Decimal']['output']>;
  organizationalEntityName?: Maybe<Scalars['String']['output']>;
  pickupRequestCompanyLocationAddress?: Maybe<Scalars['String']['output']>;
  pickupRequestCompanyLocationName?: Maybe<Scalars['String']['output']>;
  pickupRequestCompanyName?: Maybe<Scalars['String']['output']>;
  pickupRequestCompanyTaxId?: Maybe<Scalars['String']['output']>;
  pickupRequestId?: Maybe<Scalars['Int']['output']>;
  pickupRequestPickupDate?: Maybe<Scalars['DateTime']['output']>;
  pickupRequestReportingUserUserName?: Maybe<Scalars['String']['output']>;
  scheduledDate?: Maybe<Scalars['DateTime']['output']>;
  startDateTime?: Maybe<Scalars['DateTime']['output']>;
  vehicleCostLocationCode?: Maybe<Scalars['Int']['output']>;
  vehicleCostLocationName?: Maybe<Scalars['String']['output']>;
  vehicleName?: Maybe<Scalars['String']['output']>;
  vehicleRegistrationNumber?: Maybe<Scalars['String']['output']>;
};

export type WorkOrderFlattenedReturnDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderFlattenedReturnDtoFilterInput>>;
  assignedByManagerUserUserName?: InputMaybe<StringOperationFilterInput>;
  assignedUserUserName?: InputMaybe<StringOperationFilterInput>;
  currentStatusStatus?: InputMaybe<NullableOfWorkOrderStatusOperationFilterInput>;
  endDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  executionCategoryName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  itemsTotal?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderFlattenedReturnDtoFilterInput>>;
  organizationalEntityName?: InputMaybe<StringOperationFilterInput>;
  pickupRequestCompanyLocationAddress?: InputMaybe<StringOperationFilterInput>;
  pickupRequestCompanyLocationName?: InputMaybe<StringOperationFilterInput>;
  pickupRequestCompanyName?: InputMaybe<StringOperationFilterInput>;
  pickupRequestCompanyTaxId?: InputMaybe<StringOperationFilterInput>;
  pickupRequestId?: InputMaybe<IntOperationFilterInput>;
  pickupRequestPickupDate?: InputMaybe<DateTimeOperationFilterInput>;
  pickupRequestReportingUserUserName?: InputMaybe<StringOperationFilterInput>;
  scheduledDate?: InputMaybe<DateTimeOperationFilterInput>;
  startDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  vehicleCostLocationCode?: InputMaybe<IntOperationFilterInput>;
  vehicleCostLocationName?: InputMaybe<StringOperationFilterInput>;
  vehicleName?: InputMaybe<StringOperationFilterInput>;
  vehicleRegistrationNumber?: InputMaybe<StringOperationFilterInput>;
};

export type WorkOrderFlattenedReturnDtoSortInput = {
  assignedByManagerUserUserName?: InputMaybe<SortEnumType>;
  assignedUserUserName?: InputMaybe<SortEnumType>;
  currentStatusStatus?: InputMaybe<SortEnumType>;
  endDateTime?: InputMaybe<SortEnumType>;
  executionCategoryName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  itemsTotal?: InputMaybe<SortEnumType>;
  organizationalEntityName?: InputMaybe<SortEnumType>;
  pickupRequestCompanyLocationAddress?: InputMaybe<SortEnumType>;
  pickupRequestCompanyLocationName?: InputMaybe<SortEnumType>;
  pickupRequestCompanyName?: InputMaybe<SortEnumType>;
  pickupRequestCompanyTaxId?: InputMaybe<SortEnumType>;
  pickupRequestId?: InputMaybe<SortEnumType>;
  pickupRequestPickupDate?: InputMaybe<SortEnumType>;
  pickupRequestReportingUserUserName?: InputMaybe<SortEnumType>;
  scheduledDate?: InputMaybe<SortEnumType>;
  startDateTime?: InputMaybe<SortEnumType>;
  vehicleCostLocationCode?: InputMaybe<SortEnumType>;
  vehicleCostLocationName?: InputMaybe<SortEnumType>;
  vehicleName?: InputMaybe<SortEnumType>;
  vehicleRegistrationNumber?: InputMaybe<SortEnumType>;
};

export type WorkOrderItem = {
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  files: Array<File>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  item: Item;
  itemDescription?: Maybe<Scalars['String']['output']>;
  itemFinancialAccountDescription?: Maybe<Scalars['String']['output']>;
  itemFinancialAccountId?: Maybe<Scalars['Int']['output']>;
  itemFinancialAccountName?: Maybe<Scalars['String']['output']>;
  itemFinancialAccountNumber?: Maybe<Scalars['String']['output']>;
  itemId: Scalars['Int']['output'];
  itemItemType: ItemType;
  itemName?: Maybe<Scalars['String']['output']>;
  itemRentalServiceItem?: Maybe<Item>;
  itemRentalServiceItemDescription?: Maybe<Scalars['String']['output']>;
  itemRentalServiceItemId?: Maybe<Scalars['Int']['output']>;
  itemRentalServiceItemName?: Maybe<Scalars['String']['output']>;
  itemRentalServiceItemType?: Maybe<ItemType>;
  itemRentalServiceItemUnitPrice?: Maybe<Scalars['Decimal']['output']>;
  itemRentalServiceItemUnitsContained?: Maybe<Scalars['Decimal']['output']>;
  itemRentalThresholdDays: Scalars['Int']['output'];
  itemUnitOfMeasure?: Maybe<UnitOfMeasure>;
  itemUnitOfMeasureDescription?: Maybe<Scalars['String']['output']>;
  itemUnitOfMeasureId?: Maybe<Scalars['Int']['output']>;
  itemUnitOfMeasureName?: Maybe<Scalars['String']['output']>;
  itemUnitOfMeasureSymbol?: Maybe<Scalars['String']['output']>;
  itemUnitPrice?: Maybe<Scalars['Decimal']['output']>;
  itemUnitsContained?: Maybe<Scalars['Decimal']['output']>;
  itemVATTypeDescription?: Maybe<Scalars['String']['output']>;
  itemVATTypeId?: Maybe<Scalars['Int']['output']>;
  itemVATTypeName?: Maybe<Scalars['String']['output']>;
  itemVATTypeRate?: Maybe<Scalars['Decimal']['output']>;
  managerNote?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  offlineWorkOrderItemId?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Decimal']['output'];
  total?: Maybe<Scalars['Decimal']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vat?: Maybe<Scalars['Decimal']['output']>;
  vatBase?: Maybe<Scalars['Decimal']['output']>;
  workOrder: WorkOrder;
  workOrderId: Scalars['Int']['output'];
};

export type WorkOrderItemCreateDtoInput = {
  files: Array<WorkOrderItemFileCreateDtoInput>;
  itemId?: InputMaybe<Scalars['Int']['input']>;
  itemUnitPrice?: InputMaybe<Scalars['Decimal']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  workOrderId?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkOrderItemDeleteDtoInput = {
  id: Scalars['Int']['input'];
};

export type WorkOrderItemFileCreateDtoInput = {
  id: Scalars['Int']['input'];
};

export type WorkOrderItemFileUpdateDtoInput = {
  id: Scalars['Int']['input'];
};

export type WorkOrderItemFilterInput = {
  and?: InputMaybe<Array<WorkOrderItemFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  files?: InputMaybe<ListFilterInputTypeOfFileFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  item?: InputMaybe<ItemFilterInput>;
  itemDescription?: InputMaybe<StringOperationFilterInput>;
  itemFinancialAccountDescription?: InputMaybe<StringOperationFilterInput>;
  itemFinancialAccountId?: InputMaybe<IntOperationFilterInput>;
  itemFinancialAccountName?: InputMaybe<StringOperationFilterInput>;
  itemFinancialAccountNumber?: InputMaybe<StringOperationFilterInput>;
  itemId?: InputMaybe<IntOperationFilterInput>;
  itemItemType?: InputMaybe<ItemTypeOperationFilterInput>;
  itemName?: InputMaybe<StringOperationFilterInput>;
  itemRentalServiceItem?: InputMaybe<ItemFilterInput>;
  itemRentalServiceItemDescription?: InputMaybe<StringOperationFilterInput>;
  itemRentalServiceItemId?: InputMaybe<IntOperationFilterInput>;
  itemRentalServiceItemName?: InputMaybe<StringOperationFilterInput>;
  itemRentalServiceItemType?: InputMaybe<NullableOfItemTypeOperationFilterInput>;
  itemRentalServiceItemUnitPrice?: InputMaybe<DecimalOperationFilterInput>;
  itemRentalServiceItemUnitsContained?: InputMaybe<DecimalOperationFilterInput>;
  itemRentalThresholdDays?: InputMaybe<IntOperationFilterInput>;
  itemUnitOfMeasure?: InputMaybe<UnitOfMeasureFilterInput>;
  itemUnitOfMeasureDescription?: InputMaybe<StringOperationFilterInput>;
  itemUnitOfMeasureId?: InputMaybe<IntOperationFilterInput>;
  itemUnitOfMeasureName?: InputMaybe<StringOperationFilterInput>;
  itemUnitOfMeasureSymbol?: InputMaybe<StringOperationFilterInput>;
  itemUnitPrice?: InputMaybe<DecimalOperationFilterInput>;
  itemUnitsContained?: InputMaybe<DecimalOperationFilterInput>;
  itemVATTypeDescription?: InputMaybe<StringOperationFilterInput>;
  itemVATTypeId?: InputMaybe<IntOperationFilterInput>;
  itemVATTypeName?: InputMaybe<StringOperationFilterInput>;
  itemVATTypeRate?: InputMaybe<DecimalOperationFilterInput>;
  managerNote?: InputMaybe<StringOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  offlineWorkOrderItemId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderItemFilterInput>>;
  quantity?: InputMaybe<DecimalOperationFilterInput>;
  total?: InputMaybe<DecimalOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  vat?: InputMaybe<DecimalOperationFilterInput>;
  vatBase?: InputMaybe<DecimalOperationFilterInput>;
  workOrder?: InputMaybe<WorkOrderFilterInput>;
  workOrderId?: InputMaybe<IntOperationFilterInput>;
};

export type WorkOrderItemItemReturnDto = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  unitOfMeasure?: Maybe<WorkOrderItemItemUnitOfMeasureReturnDto>;
  unitsContained?: Maybe<Scalars['Decimal']['output']>;
};

export type WorkOrderItemItemReturnDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderItemItemReturnDtoFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderItemItemReturnDtoFilterInput>>;
  unitOfMeasure?: InputMaybe<WorkOrderItemItemUnitOfMeasureReturnDtoFilterInput>;
  unitsContained?: InputMaybe<DecimalOperationFilterInput>;
};

export type WorkOrderItemItemReturnDtoSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  unitOfMeasure?: InputMaybe<WorkOrderItemItemUnitOfMeasureReturnDtoSortInput>;
  unitsContained?: InputMaybe<SortEnumType>;
};

export type WorkOrderItemItemUnitOfMeasureReturnDto = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
};

export type WorkOrderItemItemUnitOfMeasureReturnDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderItemItemUnitOfMeasureReturnDtoFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderItemItemUnitOfMeasureReturnDtoFilterInput>>;
  symbol?: InputMaybe<StringOperationFilterInput>;
};

export type WorkOrderItemItemUnitOfMeasureReturnDtoSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  symbol?: InputMaybe<SortEnumType>;
};

export type WorkOrderItemReturnDto = {
  files: Array<FileReturnDto>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  itemDescription?: Maybe<Scalars['String']['output']>;
  itemId: Scalars['Int']['output'];
  itemItemType: ItemType;
  itemName?: Maybe<Scalars['String']['output']>;
  itemRentalServiceItemDescription?: Maybe<Scalars['String']['output']>;
  itemRentalServiceItemId?: Maybe<Scalars['Int']['output']>;
  itemRentalServiceItemName?: Maybe<Scalars['String']['output']>;
  itemRentalServiceItemType?: Maybe<ItemType>;
  itemRentalServiceItemUnitPrice?: Maybe<Scalars['Decimal']['output']>;
  itemRentalServiceItemUnitsContained?: Maybe<Scalars['Decimal']['output']>;
  itemRentalThresholdDays: Scalars['Int']['output'];
  itemUnitPrice?: Maybe<Scalars['Decimal']['output']>;
  itemUnitsContained?: Maybe<Scalars['Decimal']['output']>;
  itemVATTypeRate?: Maybe<Scalars['Decimal']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  offlineWorkOrderItemId?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Decimal']['output'];
  total?: Maybe<Scalars['Decimal']['output']>;
  unitOfMeasureDescription?: Maybe<Scalars['String']['output']>;
  unitOfMeasureId?: Maybe<Scalars['Int']['output']>;
  unitOfMeasureName?: Maybe<Scalars['String']['output']>;
  unitOfMeasureSymbol?: Maybe<Scalars['String']['output']>;
  vat?: Maybe<Scalars['Decimal']['output']>;
  vatBase?: Maybe<Scalars['Decimal']['output']>;
  workOrderId: Scalars['Int']['output'];
};


export type WorkOrderItemReturnDtoFilesArgs = {
  where?: InputMaybe<FileReturnDtoFilterInput>;
};

export type WorkOrderItemReturnDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderItemReturnDtoFilterInput>>;
  files?: InputMaybe<ListFilterInputTypeOfFileReturnDtoFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  itemDescription?: InputMaybe<StringOperationFilterInput>;
  itemId?: InputMaybe<IntOperationFilterInput>;
  itemItemType?: InputMaybe<ItemTypeOperationFilterInput>;
  itemName?: InputMaybe<StringOperationFilterInput>;
  itemRentalServiceItemDescription?: InputMaybe<StringOperationFilterInput>;
  itemRentalServiceItemId?: InputMaybe<IntOperationFilterInput>;
  itemRentalServiceItemName?: InputMaybe<StringOperationFilterInput>;
  itemRentalServiceItemType?: InputMaybe<NullableOfItemTypeOperationFilterInput>;
  itemRentalServiceItemUnitPrice?: InputMaybe<DecimalOperationFilterInput>;
  itemRentalServiceItemUnitsContained?: InputMaybe<DecimalOperationFilterInput>;
  itemRentalThresholdDays?: InputMaybe<IntOperationFilterInput>;
  itemUnitPrice?: InputMaybe<DecimalOperationFilterInput>;
  itemUnitsContained?: InputMaybe<DecimalOperationFilterInput>;
  itemVATTypeRate?: InputMaybe<DecimalOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  offlineWorkOrderItemId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderItemReturnDtoFilterInput>>;
  quantity?: InputMaybe<DecimalOperationFilterInput>;
  total?: InputMaybe<DecimalOperationFilterInput>;
  unitOfMeasureDescription?: InputMaybe<StringOperationFilterInput>;
  unitOfMeasureId?: InputMaybe<IntOperationFilterInput>;
  unitOfMeasureName?: InputMaybe<StringOperationFilterInput>;
  unitOfMeasureSymbol?: InputMaybe<StringOperationFilterInput>;
  vat?: InputMaybe<DecimalOperationFilterInput>;
  vatBase?: InputMaybe<DecimalOperationFilterInput>;
  workOrderId?: InputMaybe<IntOperationFilterInput>;
};

export type WorkOrderItemReturnDtoSortInput = {
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  itemDescription?: InputMaybe<SortEnumType>;
  itemId?: InputMaybe<SortEnumType>;
  itemItemType?: InputMaybe<SortEnumType>;
  itemName?: InputMaybe<SortEnumType>;
  itemRentalServiceItemDescription?: InputMaybe<SortEnumType>;
  itemRentalServiceItemId?: InputMaybe<SortEnumType>;
  itemRentalServiceItemName?: InputMaybe<SortEnumType>;
  itemRentalServiceItemType?: InputMaybe<SortEnumType>;
  itemRentalServiceItemUnitPrice?: InputMaybe<SortEnumType>;
  itemRentalServiceItemUnitsContained?: InputMaybe<SortEnumType>;
  itemRentalThresholdDays?: InputMaybe<SortEnumType>;
  itemUnitPrice?: InputMaybe<SortEnumType>;
  itemUnitsContained?: InputMaybe<SortEnumType>;
  itemVATTypeRate?: InputMaybe<SortEnumType>;
  note?: InputMaybe<SortEnumType>;
  offlineWorkOrderItemId?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  total?: InputMaybe<SortEnumType>;
  unitOfMeasureDescription?: InputMaybe<SortEnumType>;
  unitOfMeasureId?: InputMaybe<SortEnumType>;
  unitOfMeasureName?: InputMaybe<SortEnumType>;
  unitOfMeasureSymbol?: InputMaybe<SortEnumType>;
  vat?: InputMaybe<SortEnumType>;
  vatBase?: InputMaybe<SortEnumType>;
  workOrderId?: InputMaybe<SortEnumType>;
};

export type WorkOrderItemUpdateDtoInput = {
  files: Array<WorkOrderItemFileUpdateDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  itemId?: InputMaybe<Scalars['Int']['input']>;
  itemUnitPrice?: InputMaybe<Scalars['Decimal']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  workOrderId?: InputMaybe<Scalars['Int']['input']>;
};

/** A segment of a collection. */
export type WorkOrderItemsCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<WorkOrderItemReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkOrderOrganizationalEntityReturnDto = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
};

export type WorkOrderOrganizationalEntityReturnDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderOrganizationalEntityReturnDtoFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderOrganizationalEntityReturnDtoFilterInput>>;
  parentId?: InputMaybe<IntOperationFilterInput>;
};

export type WorkOrderOrganizationalEntityReturnDtoSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentId?: InputMaybe<SortEnumType>;
};

export enum WorkOrderPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type WorkOrderPriorityOperationFilterInput = {
  eq?: InputMaybe<WorkOrderPriority>;
  in?: InputMaybe<Array<WorkOrderPriority>>;
  neq?: InputMaybe<WorkOrderPriority>;
  nin?: InputMaybe<Array<WorkOrderPriority>>;
};

/** A segment of a collection. */
export type WorkOrderRelatedFilesCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<FileReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkOrderReturnDto = {
  assignedByManagerUser?: Maybe<WorkOrderApplicationUserReturnDto>;
  assignedByManagerUserId?: Maybe<Scalars['String']['output']>;
  assignedUser?: Maybe<WorkOrderApplicationUserReturnDto>;
  assignedUserId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<CompanyReturnDto>;
  companyId?: Maybe<Scalars['Int']['output']>;
  companyLocation?: Maybe<CompanyLocationReturnDto>;
  companyLocationId?: Maybe<Scalars['Int']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  currentStatus?: Maybe<WorkOrderWorkOrderStatusChangeReturnDto>;
  currentStatusId?: Maybe<Scalars['Int']['output']>;
  currentStatusStatus?: Maybe<WorkOrderStatus>;
  duration?: Maybe<Scalars['TimeSpan']['output']>;
  endDateTime?: Maybe<Scalars['DateTime']['output']>;
  fieldWorkerNote?: Maybe<Scalars['String']['output']>;
  files: Array<FileReturnDto>;
  formattedId: Scalars['String']['output'];
  freeformCompany?: Maybe<Scalars['String']['output']>;
  freeformLocation?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  items: Array<WorkOrderItemReturnDto>;
  managerNote?: Maybe<Scalars['String']['output']>;
  noteForAccounting?: Maybe<Scalars['String']['output']>;
  number: Scalars['Int']['output'];
  offlineWorkOrderId?: Maybe<Scalars['String']['output']>;
  organizationalEntity?: Maybe<WorkOrderOrganizationalEntityReturnDto>;
  organizationalEntityId?: Maybe<Scalars['Int']['output']>;
  pickupRequest?: Maybe<WorkOrderReturnPickupRequestDto>;
  pickupRequestId?: Maybe<Scalars['Int']['output']>;
  priority: WorkOrderPriority;
  scheduledDate: Scalars['DateTime']['output'];
  startDateTime?: Maybe<Scalars['DateTime']['output']>;
  total?: Maybe<Scalars['Decimal']['output']>;
  vat?: Maybe<Scalars['Decimal']['output']>;
  vatBase?: Maybe<Scalars['Decimal']['output']>;
  vehicle?: Maybe<WorkOrderVehicleReturnDto>;
  vehicleId?: Maybe<Scalars['Int']['output']>;
  year: Scalars['Int']['output'];
};


export type WorkOrderReturnDtoFilesArgs = {
  where?: InputMaybe<FileReturnDtoFilterInput>;
};

export type WorkOrderReturnDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderReturnDtoFilterInput>>;
  assignedByManagerUser?: InputMaybe<WorkOrderApplicationUserReturnDtoFilterInput>;
  assignedByManagerUserId?: InputMaybe<StringOperationFilterInput>;
  assignedUser?: InputMaybe<WorkOrderApplicationUserReturnDtoFilterInput>;
  assignedUserId?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyReturnDtoFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  companyLocation?: InputMaybe<CompanyLocationReturnDtoFilterInput>;
  companyLocationId?: InputMaybe<IntOperationFilterInput>;
  companyName?: InputMaybe<StringOperationFilterInput>;
  currentStatus?: InputMaybe<WorkOrderWorkOrderStatusChangeReturnDtoFilterInput>;
  currentStatusId?: InputMaybe<IntOperationFilterInput>;
  currentStatusStatus?: InputMaybe<NullableOfWorkOrderStatusOperationFilterInput>;
  duration?: InputMaybe<TimeSpanOperationFilterInput>;
  endDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  fieldWorkerNote?: InputMaybe<StringOperationFilterInput>;
  files?: InputMaybe<ListFilterInputTypeOfFileReturnDtoFilterInput>;
  formattedId?: InputMaybe<StringOperationFilterInput>;
  freeformCompany?: InputMaybe<StringOperationFilterInput>;
  freeformLocation?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  items?: InputMaybe<ListFilterInputTypeOfWorkOrderItemReturnDtoFilterInput>;
  managerNote?: InputMaybe<StringOperationFilterInput>;
  noteForAccounting?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<IntOperationFilterInput>;
  offlineWorkOrderId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderReturnDtoFilterInput>>;
  organizationalEntity?: InputMaybe<WorkOrderOrganizationalEntityReturnDtoFilterInput>;
  organizationalEntityId?: InputMaybe<IntOperationFilterInput>;
  pickupRequest?: InputMaybe<WorkOrderReturnPickupRequestDtoFilterInput>;
  pickupRequestId?: InputMaybe<IntOperationFilterInput>;
  priority?: InputMaybe<WorkOrderPriorityOperationFilterInput>;
  scheduledDate?: InputMaybe<DateTimeOperationFilterInput>;
  startDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  total?: InputMaybe<DecimalOperationFilterInput>;
  vat?: InputMaybe<DecimalOperationFilterInput>;
  vatBase?: InputMaybe<DecimalOperationFilterInput>;
  vehicle?: InputMaybe<WorkOrderVehicleReturnDtoFilterInput>;
  vehicleId?: InputMaybe<IntOperationFilterInput>;
  year?: InputMaybe<IntOperationFilterInput>;
};

export type WorkOrderReturnDtoSortInput = {
  assignedByManagerUser?: InputMaybe<WorkOrderApplicationUserReturnDtoSortInput>;
  assignedByManagerUserId?: InputMaybe<SortEnumType>;
  assignedUser?: InputMaybe<WorkOrderApplicationUserReturnDtoSortInput>;
  assignedUserId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanyReturnDtoSortInput>;
  companyId?: InputMaybe<SortEnumType>;
  companyLocation?: InputMaybe<CompanyLocationReturnDtoSortInput>;
  companyLocationId?: InputMaybe<SortEnumType>;
  companyName?: InputMaybe<SortEnumType>;
  currentStatus?: InputMaybe<WorkOrderWorkOrderStatusChangeReturnDtoSortInput>;
  currentStatusId?: InputMaybe<SortEnumType>;
  currentStatusStatus?: InputMaybe<SortEnumType>;
  duration?: InputMaybe<SortEnumType>;
  endDateTime?: InputMaybe<SortEnumType>;
  fieldWorkerNote?: InputMaybe<SortEnumType>;
  formattedId?: InputMaybe<SortEnumType>;
  freeformCompany?: InputMaybe<SortEnumType>;
  freeformLocation?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  managerNote?: InputMaybe<SortEnumType>;
  noteForAccounting?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
  offlineWorkOrderId?: InputMaybe<SortEnumType>;
  organizationalEntity?: InputMaybe<WorkOrderOrganizationalEntityReturnDtoSortInput>;
  organizationalEntityId?: InputMaybe<SortEnumType>;
  pickupRequest?: InputMaybe<WorkOrderReturnPickupRequestDtoSortInput>;
  pickupRequestId?: InputMaybe<SortEnumType>;
  priority?: InputMaybe<SortEnumType>;
  scheduledDate?: InputMaybe<SortEnumType>;
  startDateTime?: InputMaybe<SortEnumType>;
  total?: InputMaybe<SortEnumType>;
  vat?: InputMaybe<SortEnumType>;
  vatBase?: InputMaybe<SortEnumType>;
  vehicle?: InputMaybe<WorkOrderVehicleReturnDtoSortInput>;
  vehicleId?: InputMaybe<SortEnumType>;
  year?: InputMaybe<SortEnumType>;
};

export type WorkOrderReturnPickupRequestCompanyDto = {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type WorkOrderReturnPickupRequestCompanyDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderReturnPickupRequestCompanyDtoFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderReturnPickupRequestCompanyDtoFilterInput>>;
};

export type WorkOrderReturnPickupRequestCompanyDtoSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type WorkOrderReturnPickupRequestCompanyLocationDto = {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type WorkOrderReturnPickupRequestCompanyLocationDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderReturnPickupRequestCompanyLocationDtoFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderReturnPickupRequestCompanyLocationDtoFilterInput>>;
};

export type WorkOrderReturnPickupRequestCompanyLocationDtoSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type WorkOrderReturnPickupRequestDto = {
  company?: Maybe<WorkOrderReturnPickupRequestCompanyDto>;
  companyId?: Maybe<Scalars['Int']['output']>;
  companyLocation?: Maybe<WorkOrderReturnPickupRequestCompanyLocationDto>;
  companyLocationId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  locationNote?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  pickupDate?: Maybe<Scalars['DateTime']['output']>;
  reportingUserId: Scalars['String']['output'];
  serviceNote?: Maybe<Scalars['String']['output']>;
};

export type WorkOrderReturnPickupRequestDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderReturnPickupRequestDtoFilterInput>>;
  company?: InputMaybe<WorkOrderReturnPickupRequestCompanyDtoFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  companyLocation?: InputMaybe<WorkOrderReturnPickupRequestCompanyLocationDtoFilterInput>;
  companyLocationId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  locationNote?: InputMaybe<StringOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderReturnPickupRequestDtoFilterInput>>;
  pickupDate?: InputMaybe<DateTimeOperationFilterInput>;
  reportingUserId?: InputMaybe<StringOperationFilterInput>;
  serviceNote?: InputMaybe<StringOperationFilterInput>;
};

export type WorkOrderReturnPickupRequestDtoSortInput = {
  company?: InputMaybe<WorkOrderReturnPickupRequestCompanyDtoSortInput>;
  companyId?: InputMaybe<SortEnumType>;
  companyLocation?: InputMaybe<WorkOrderReturnPickupRequestCompanyLocationDtoSortInput>;
  companyLocationId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  locationNote?: InputMaybe<SortEnumType>;
  note?: InputMaybe<SortEnumType>;
  pickupDate?: InputMaybe<SortEnumType>;
  reportingUserId?: InputMaybe<SortEnumType>;
  serviceNote?: InputMaybe<SortEnumType>;
};

export enum WorkOrderStatus {
  AcceptedByFieldWorker = 'ACCEPTED_BY_FIELD_WORKER',
  Cancelled = 'CANCELLED',
  CompletedByFieldWorker = 'COMPLETED_BY_FIELD_WORKER',
  ConfirmedByAccounting = 'CONFIRMED_BY_ACCOUNTING',
  ConfirmedByManager = 'CONFIRMED_BY_MANAGER',
  DeclinedByFieldWorker = 'DECLINED_BY_FIELD_WORKER',
  OfflineInitiatedAwaitingReview = 'OFFLINE_INITIATED_AWAITING_REVIEW',
  PausedUntilContainerPickup = 'PAUSED_UNTIL_CONTAINER_PICKUP',
  ResumedContainerPickup = 'RESUMED_CONTAINER_PICKUP',
  SendingToErpFailed = 'SENDING_TO_ERP_FAILED',
  SentToErp = 'SENT_TO_ERP',
  StartedByFieldWorker = 'STARTED_BY_FIELD_WORKER',
  WaitingForAcceptanceByFieldWorker = 'WAITING_FOR_ACCEPTANCE_BY_FIELD_WORKER',
  WithoutAssignment = 'WITHOUT_ASSIGNMENT'
}

export type WorkOrderStatusChange = {
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  initiatingUser?: Maybe<ApplicationUser>;
  initiatingUserId?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  location?: Maybe<GeoJsonPointType>;
  notes?: Maybe<Scalars['String']['output']>;
  status: WorkOrderStatus;
  updatedAt: Scalars['DateTime']['output'];
  workOrder: WorkOrder;
  workOrderId: Scalars['Int']['output'];
};

export type WorkOrderStatusChangeCreateDtoInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  initiatingUser?: InputMaybe<WorkOrderStatusChangeInitiatingUserDtoInput>;
  initiatingUserId?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<PointDtoInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<WorkOrderStatus>;
  workOrder?: InputMaybe<WorkOrderStatusChangeWorkOrderDtoInput>;
  workOrderId?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkOrderStatusChangeDeleteDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkOrderStatusChangeFilterInput = {
  and?: InputMaybe<Array<WorkOrderStatusChangeFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  initiatingUser?: InputMaybe<ApplicationUserFilterInput>;
  initiatingUserId?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<PointFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderStatusChangeFilterInput>>;
  status?: InputMaybe<WorkOrderStatusOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  workOrder?: InputMaybe<WorkOrderFilterInput>;
  workOrderId?: InputMaybe<IntOperationFilterInput>;
};

export type WorkOrderStatusChangeInitiatingUserDto = {
  id?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type WorkOrderStatusChangeInitiatingUserDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderStatusChangeInitiatingUserDtoFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderStatusChangeInitiatingUserDtoFilterInput>>;
  userName?: InputMaybe<StringOperationFilterInput>;
};

export type WorkOrderStatusChangeInitiatingUserDtoInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type WorkOrderStatusChangeInitiatingUserDtoSortInput = {
  id?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

export type WorkOrderStatusChangeReturnDto = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  initiatingUser?: Maybe<WorkOrderStatusChangeInitiatingUserDto>;
  initiatingUserId?: Maybe<Scalars['String']['output']>;
  location?: Maybe<PointDto>;
  notes?: Maybe<Scalars['String']['output']>;
  status?: Maybe<WorkOrderStatus>;
  workOrder?: Maybe<WorkOrderStatusChangeWorkOrderDto>;
  workOrderId?: Maybe<Scalars['Int']['output']>;
};

export type WorkOrderStatusChangeReturnDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderStatusChangeReturnDtoFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  initiatingUser?: InputMaybe<WorkOrderStatusChangeInitiatingUserDtoFilterInput>;
  initiatingUserId?: InputMaybe<StringOperationFilterInput>;
  location?: InputMaybe<PointDtoFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderStatusChangeReturnDtoFilterInput>>;
  status?: InputMaybe<NullableOfWorkOrderStatusOperationFilterInput>;
  workOrder?: InputMaybe<WorkOrderStatusChangeWorkOrderDtoFilterInput>;
  workOrderId?: InputMaybe<IntOperationFilterInput>;
};

export type WorkOrderStatusChangeReturnDtoSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  initiatingUser?: InputMaybe<WorkOrderStatusChangeInitiatingUserDtoSortInput>;
  initiatingUserId?: InputMaybe<SortEnumType>;
  location?: InputMaybe<PointDtoSortInput>;
  notes?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  workOrder?: InputMaybe<WorkOrderStatusChangeWorkOrderDtoSortInput>;
  workOrderId?: InputMaybe<SortEnumType>;
};

export type WorkOrderStatusChangeUpdateDtoInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  initiatingUser?: InputMaybe<WorkOrderStatusChangeInitiatingUserDtoInput>;
  initiatingUserId?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<PointDtoInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<WorkOrderStatus>;
  workOrder?: InputMaybe<WorkOrderStatusChangeWorkOrderDtoInput>;
  workOrderId?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkOrderStatusChangeWorkOrderDto = {
  formattedId: Scalars['String']['output'];
};

export type WorkOrderStatusChangeWorkOrderDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderStatusChangeWorkOrderDtoFilterInput>>;
  formattedId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderStatusChangeWorkOrderDtoFilterInput>>;
};

export type WorkOrderStatusChangeWorkOrderDtoInput = {
  formattedId: Scalars['String']['input'];
};

export type WorkOrderStatusChangeWorkOrderDtoSortInput = {
  formattedId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type WorkOrderStatusChangesCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<WorkOrderStatusChangeReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkOrderStatusOperationFilterInput = {
  eq?: InputMaybe<WorkOrderStatus>;
  in?: InputMaybe<Array<WorkOrderStatus>>;
  neq?: InputMaybe<WorkOrderStatus>;
  nin?: InputMaybe<Array<WorkOrderStatus>>;
};

export type WorkOrderTemplate = {
  company: Company;
  companyId: Scalars['Int']['output'];
  companyLocation?: Maybe<CompanyLocation>;
  companyLocationId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deactivated: Scalars['Boolean']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  executionDay?: Maybe<DayOfWeek>;
  executionDayOfMonth?: Maybe<Scalars['Int']['output']>;
  freeformLocation?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  items: Array<WorkOrderTemplateItem>;
  name?: Maybe<Scalars['String']['output']>;
  organizationalEntity?: Maybe<OrganizationalEntity>;
  organizationalEntityId?: Maybe<Scalars['Int']['output']>;
  priority: WorkOrderPriority;
  toWorkOrder: WorkOrder;
  updatedAt: Scalars['DateTime']['output'];
  vehicle?: Maybe<Vehicle>;
  vehicleId?: Maybe<Scalars['Int']['output']>;
};


export type WorkOrderTemplateToWorkOrderArgs = {
  date: Scalars['DateTime']['input'];
};

export type WorkOrderTemplateCreateDtoInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  companyLocationId?: InputMaybe<Scalars['Int']['input']>;
  deactivated?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  executionDay?: InputMaybe<DayOfWeek>;
  executionDayOfMonth?: InputMaybe<Scalars['Int']['input']>;
  freeformLocation?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationalEntityId?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<WorkOrderPriority>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkOrderTemplateDeleteDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkOrderTemplateFilterInput = {
  and?: InputMaybe<Array<WorkOrderTemplateFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  companyLocation?: InputMaybe<CompanyLocationFilterInput>;
  companyLocationId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deactivated?: InputMaybe<BooleanOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  executionDay?: InputMaybe<NullableOfDayOfWeekOperationFilterInput>;
  executionDayOfMonth?: InputMaybe<IntOperationFilterInput>;
  freeformLocation?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  items?: InputMaybe<ListFilterInputTypeOfWorkOrderTemplateItemFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderTemplateFilterInput>>;
  organizationalEntity?: InputMaybe<OrganizationalEntityFilterInput>;
  organizationalEntityId?: InputMaybe<IntOperationFilterInput>;
  priority?: InputMaybe<WorkOrderPriorityOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  vehicle?: InputMaybe<VehicleFilterInput>;
  vehicleId?: InputMaybe<IntOperationFilterInput>;
};

export type WorkOrderTemplateItem = {
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  item: Item;
  itemId: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Decimal']['output'];
  toWorkOrderItem: WorkOrderItem;
  updatedAt: Scalars['DateTime']['output'];
  workOrderTemplate: WorkOrderTemplate;
  workOrderTemplateId: Scalars['Int']['output'];
};


export type WorkOrderTemplateItemToWorkOrderItemArgs = {
  woId: Scalars['Int']['input'];
};

export type WorkOrderTemplateItemCreateDtoInput = {
  itemId?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  workOrderTemplateId?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkOrderTemplateItemDeleteDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkOrderTemplateItemFilterInput = {
  and?: InputMaybe<Array<WorkOrderTemplateItemFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  item?: InputMaybe<ItemFilterInput>;
  itemId?: InputMaybe<IntOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderTemplateItemFilterInput>>;
  quantity?: InputMaybe<DecimalOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  workOrderTemplate?: InputMaybe<WorkOrderTemplateFilterInput>;
  workOrderTemplateId?: InputMaybe<IntOperationFilterInput>;
};

export type WorkOrderTemplateItemReturnDto = {
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  item: WorkOrderItemItemReturnDto;
  itemId: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Decimal']['output'];
  workOrderTemplateId: Scalars['Int']['output'];
};

export type WorkOrderTemplateItemReturnDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderTemplateItemReturnDtoFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  item?: InputMaybe<WorkOrderItemItemReturnDtoFilterInput>;
  itemId?: InputMaybe<IntOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderTemplateItemReturnDtoFilterInput>>;
  quantity?: InputMaybe<DecimalOperationFilterInput>;
  workOrderTemplateId?: InputMaybe<IntOperationFilterInput>;
};

export type WorkOrderTemplateItemReturnDtoSortInput = {
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  item?: InputMaybe<WorkOrderItemItemReturnDtoSortInput>;
  itemId?: InputMaybe<SortEnumType>;
  note?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  workOrderTemplateId?: InputMaybe<SortEnumType>;
};

export type WorkOrderTemplateItemUpdateDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  itemId?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  workOrderTemplateId?: InputMaybe<Scalars['Int']['input']>;
};

/** A segment of a collection. */
export type WorkOrderTemplateItemsCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<WorkOrderTemplateItemReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkOrderTemplateReturnCompanyDto = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
};

export type WorkOrderTemplateReturnCompanyDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderTemplateReturnCompanyDtoFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderTemplateReturnCompanyDtoFilterInput>>;
  taxId?: InputMaybe<StringOperationFilterInput>;
};

export type WorkOrderTemplateReturnCompanyDtoSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  taxId?: InputMaybe<SortEnumType>;
};

export type WorkOrderTemplateReturnCompanyLocationDto = {
  address?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type WorkOrderTemplateReturnCompanyLocationDtoFilterInput = {
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<WorkOrderTemplateReturnCompanyLocationDtoFilterInput>>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderTemplateReturnCompanyLocationDtoFilterInput>>;
};

export type WorkOrderTemplateReturnCompanyLocationDtoSortInput = {
  address?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type WorkOrderTemplateReturnDto = {
  company?: Maybe<WorkOrderTemplateReturnCompanyDto>;
  companyId?: Maybe<Scalars['Int']['output']>;
  companyLocation?: Maybe<WorkOrderTemplateReturnCompanyLocationDto>;
  companyLocationId?: Maybe<Scalars['Int']['output']>;
  deactivated?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  executionDay?: Maybe<DayOfWeek>;
  executionDayOfMonth?: Maybe<Scalars['Int']['output']>;
  freeformLocation?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  items: Array<WorkOrderTemplateItemReturnDto>;
  name?: Maybe<Scalars['String']['output']>;
  organizationalEntity?: Maybe<WorkOrderTemplateReturnOrganizationalEntityDto>;
  organizationalEntityId?: Maybe<Scalars['Int']['output']>;
  priority?: Maybe<WorkOrderPriority>;
  vehicle?: Maybe<WorkOrderTemplateReturnVehicleDto>;
  vehicleId?: Maybe<Scalars['Int']['output']>;
};

export type WorkOrderTemplateReturnDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderTemplateReturnDtoFilterInput>>;
  company?: InputMaybe<WorkOrderTemplateReturnCompanyDtoFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  companyLocation?: InputMaybe<WorkOrderTemplateReturnCompanyLocationDtoFilterInput>;
  companyLocationId?: InputMaybe<IntOperationFilterInput>;
  deactivated?: InputMaybe<BooleanOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  executionDay?: InputMaybe<NullableOfDayOfWeekOperationFilterInput>;
  executionDayOfMonth?: InputMaybe<IntOperationFilterInput>;
  freeformLocation?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  items?: InputMaybe<ListFilterInputTypeOfWorkOrderTemplateItemReturnDtoFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderTemplateReturnDtoFilterInput>>;
  organizationalEntity?: InputMaybe<WorkOrderTemplateReturnOrganizationalEntityDtoFilterInput>;
  organizationalEntityId?: InputMaybe<IntOperationFilterInput>;
  priority?: InputMaybe<NullableOfWorkOrderPriorityOperationFilterInput>;
  vehicle?: InputMaybe<WorkOrderTemplateReturnVehicleDtoFilterInput>;
  vehicleId?: InputMaybe<IntOperationFilterInput>;
};

export type WorkOrderTemplateReturnDtoSortInput = {
  company?: InputMaybe<WorkOrderTemplateReturnCompanyDtoSortInput>;
  companyId?: InputMaybe<SortEnumType>;
  companyLocation?: InputMaybe<WorkOrderTemplateReturnCompanyLocationDtoSortInput>;
  companyLocationId?: InputMaybe<SortEnumType>;
  deactivated?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  executionDay?: InputMaybe<SortEnumType>;
  executionDayOfMonth?: InputMaybe<SortEnumType>;
  freeformLocation?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organizationalEntity?: InputMaybe<WorkOrderTemplateReturnOrganizationalEntityDtoSortInput>;
  organizationalEntityId?: InputMaybe<SortEnumType>;
  priority?: InputMaybe<SortEnumType>;
  vehicle?: InputMaybe<WorkOrderTemplateReturnVehicleDtoSortInput>;
  vehicleId?: InputMaybe<SortEnumType>;
};

export type WorkOrderTemplateReturnOrganizationalEntityDto = {
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type WorkOrderTemplateReturnOrganizationalEntityDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderTemplateReturnOrganizationalEntityDtoFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderTemplateReturnOrganizationalEntityDtoFilterInput>>;
};

export type WorkOrderTemplateReturnOrganizationalEntityDtoSortInput = {
  description?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type WorkOrderTemplateReturnVehicleCostLocationDto = {
  code?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type WorkOrderTemplateReturnVehicleCostLocationDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderTemplateReturnVehicleCostLocationDtoFilterInput>>;
  code?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderTemplateReturnVehicleCostLocationDtoFilterInput>>;
};

export type WorkOrderTemplateReturnVehicleCostLocationDtoSortInput = {
  code?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type WorkOrderTemplateReturnVehicleDto = {
  costLocation?: Maybe<WorkOrderTemplateReturnVehicleCostLocationDto>;
  name?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
};

export type WorkOrderTemplateReturnVehicleDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderTemplateReturnVehicleDtoFilterInput>>;
  costLocation?: InputMaybe<WorkOrderTemplateReturnVehicleCostLocationDtoFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderTemplateReturnVehicleDtoFilterInput>>;
  registrationNumber?: InputMaybe<StringOperationFilterInput>;
};

export type WorkOrderTemplateReturnVehicleDtoSortInput = {
  costLocation?: InputMaybe<WorkOrderTemplateReturnVehicleCostLocationDtoSortInput>;
  name?: InputMaybe<SortEnumType>;
  registrationNumber?: InputMaybe<SortEnumType>;
};

export type WorkOrderTemplateUpdateDtoInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  companyLocationId?: InputMaybe<Scalars['Int']['input']>;
  deactivated?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  executionDay?: InputMaybe<DayOfWeek>;
  executionDayOfMonth?: InputMaybe<Scalars['Int']['input']>;
  freeformLocation?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  items?: InputMaybe<Array<WorkOrderTemplateItemUpdateDtoInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationalEntityId?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<WorkOrderPriority>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

/** A segment of a collection. */
export type WorkOrderTemplatesCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<WorkOrderTemplateReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkOrderUpdateDtoInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  companyLocationId?: InputMaybe<Scalars['Int']['input']>;
  files: Array<WorkOrderFileUpdateDtoInput>;
  freeformCompany?: InputMaybe<Scalars['String']['input']>;
  freeformLocation?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  managerNote?: InputMaybe<Scalars['String']['input']>;
  noteForAccounting?: InputMaybe<Scalars['String']['input']>;
  organizationalEntityId?: InputMaybe<Scalars['Int']['input']>;
  pickupRequestId?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<WorkOrderPriority>;
  scheduledDate?: InputMaybe<Scalars['DateTime']['input']>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkOrderVehicleReturnDto = {
  costLocation?: Maybe<WorkOrderCostLocationReturnDto>;
  costLocationId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
};

export type WorkOrderVehicleReturnDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderVehicleReturnDtoFilterInput>>;
  costLocation?: InputMaybe<WorkOrderCostLocationReturnDtoFilterInput>;
  costLocationId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderVehicleReturnDtoFilterInput>>;
  registrationNumber?: InputMaybe<StringOperationFilterInput>;
};

export type WorkOrderVehicleReturnDtoSortInput = {
  costLocation?: InputMaybe<WorkOrderCostLocationReturnDtoSortInput>;
  costLocationId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  registrationNumber?: InputMaybe<SortEnumType>;
};

export type WorkOrderWorkOrderStatusChangeReturnDto = {
  dateTime?: Maybe<Scalars['DateTime']['output']>;
  initiatingUser?: Maybe<WorkOrderStatusChangeInitiatingUserDto>;
  initiatingUserId?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  status?: Maybe<WorkOrderStatus>;
  workOrderId?: Maybe<Scalars['Int']['output']>;
};

export type WorkOrderWorkOrderStatusChangeReturnDtoFilterInput = {
  and?: InputMaybe<Array<WorkOrderWorkOrderStatusChangeReturnDtoFilterInput>>;
  dateTime?: InputMaybe<DateTimeOperationFilterInput>;
  initiatingUser?: InputMaybe<WorkOrderStatusChangeInitiatingUserDtoFilterInput>;
  initiatingUserId?: InputMaybe<StringOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkOrderWorkOrderStatusChangeReturnDtoFilterInput>>;
  status?: InputMaybe<NullableOfWorkOrderStatusOperationFilterInput>;
  workOrderId?: InputMaybe<IntOperationFilterInput>;
};

export type WorkOrderWorkOrderStatusChangeReturnDtoSortInput = {
  dateTime?: InputMaybe<SortEnumType>;
  initiatingUser?: InputMaybe<WorkOrderStatusChangeInitiatingUserDtoSortInput>;
  initiatingUserId?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  workOrderId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type WorkOrdersCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<WorkOrderReturnDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ChangePasswordMutationVariables = Exact<{
  model: AccountChangePasswordDtoInput;
}>;


export type ChangePasswordMutation = { changePassword: boolean };

export type EmailConfirmMutationVariables = Exact<{
  code: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;


export type EmailConfirmMutation = { confirmEmail: boolean };

export type GetConsentQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type GetConsentQuery = { accountConsent?: { id: string, emailConsentGivenOn?: any, emailConsentSource?: string, isEmailConsentGiven?: boolean } };

export type GetMyClaimsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyClaimsQuery = { claims: Array<{ type: string, value: string }> };

export type GetMyRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyRolesQuery = { roles: Array<UserRole> };

export type GetMyTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyTokenQuery = { token: { token: string } };

export type GetMyUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyUserQuery = { user: { id: string, email?: string, userName?: string, firstName?: string, lastName?: string, preferredLanguage: string, phoneNumber?: string } };

export type LogMeInMutationVariables = Exact<{
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
}>;


export type LogMeInMutation = { login: { token: string } };

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokenMutation = { refreshToken: { token: string } };

export type RegisterMeMutationVariables = Exact<{
  model: AccountRegisterExtendedDtoInput;
}>;


export type RegisterMeMutation = { register: boolean };

export type ResetMyPasswordMutationVariables = Exact<{
  model: AccountResetPasswordDtoInput;
}>;


export type ResetMyPasswordMutation = { resetPassword: boolean };

export type SetConsentMutationVariables = Exact<{
  dto: AccountConsentUpdateDtoInput;
}>;


export type SetConsentMutation = { setConsent: Array<{ id: string }> };

export type UpdateProfileMutationVariables = Exact<{
  dto: AccountUpdateDtoInput;
}>;


export type UpdateProfileMutation = { updateProfile: Array<{ id: string, phoneNumber?: string, preferredLanguage: string, email?: string, firstName?: string, lastName?: string }> };

export type CreateCompanyMutationVariables = Exact<{
  data: CompanyCreateDtoInput;
}>;


export type CreateCompanyMutation = { createCompany: Array<{ id: number }> };

export type DeleteCompanyMutationVariables = Exact<{
  data: CompanyDeleteDtoInput;
}>;


export type DeleteCompanyMutation = { deleteCompany: boolean };

export type GetCompaniesQueryVariables = Exact<{
  where?: InputMaybe<CompanyReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CompanyReturnDtoSortInput> | CompanyReturnDtoSortInput>;
}>;


export type GetCompaniesQuery = { companies?: { totalCount: number, items?: Array<{ id: number, name?: string, taxId?: string, description?: string, externalId?: string }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetCompanyQueryVariables = Exact<{
  where?: InputMaybe<CompanyReturnDtoFilterInput>;
}>;


export type GetCompanyQuery = { company?: { id: number, name?: string, taxId?: string, description?: string, externalId?: string } };

export type UpdateCompanyMutationVariables = Exact<{
  data: CompanyUpdateDtoInput;
}>;


export type UpdateCompanyMutation = { updateCompany: Array<{ id: number, name?: string }> };

export type CreateCompanyLocationMutationVariables = Exact<{
  data: CompanyLocationCreateDtoInput;
}>;


export type CreateCompanyLocationMutation = { createCompanyLocation: Array<{ id: number }> };

export type DeleteCompanyLocationMutationVariables = Exact<{
  data: CompanyLocationDeleteDtoInput;
}>;


export type DeleteCompanyLocationMutation = { deleteCompanyLocation: boolean };

export type GetCompanyLocationsQueryVariables = Exact<{
  where?: InputMaybe<CompanyLocationReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CompanyLocationReturnDtoSortInput> | CompanyLocationReturnDtoSortInput>;
}>;


export type GetCompanyLocationsQuery = { companyLocations?: { totalCount: number, items?: Array<{ id: number, name?: string, description?: string, isMainLocation?: boolean, address?: string, companyId?: number, externalId?: string, location?: { latitude: number, longitude: number }, company?: { id: number, name?: string } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type UpdateCompanyLocationMutationVariables = Exact<{
  data: CompanyLocationUpdateDtoInput;
}>;


export type UpdateCompanyLocationMutation = { updateCompanyLocation: Array<{ id: number, name?: string, companyId?: number, address?: string, isMainLocation?: boolean, company?: { name?: string, id: number }, location?: { latitude: number, longitude: number } }> };

export type CreateCostLocationMutationVariables = Exact<{
  data: CostLocationCreateDtoInput;
}>;


export type CreateCostLocationMutation = { createCostLocation: Array<{ id: number }> };

export type DeleteCostLocationMutationVariables = Exact<{
  data: CostLocationDeleteDtoInput;
}>;


export type DeleteCostLocationMutation = { deleteCostLocation: boolean };

export type GetCostLocationsQueryVariables = Exact<{
  where?: InputMaybe<CostLocationReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CostLocationReturnDtoSortInput> | CostLocationReturnDtoSortInput>;
}>;


export type GetCostLocationsQuery = { costLocations?: { totalCount: number, items?: Array<{ id: number, name?: string, description?: string, code?: number, externalId?: string }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type UpdateCostLocationMutationVariables = Exact<{
  data: CostLocationUpdateDtoInput;
}>;


export type UpdateCostLocationMutation = { updateCostLocation: Array<{ id: number, name?: string }> };

export type DeleteFileMutationVariables = Exact<{
  data: FileDeleteDtoInput;
}>;


export type DeleteFileMutation = { deleteFile: boolean };

export type GetSignedWorkOrderPdFsQueryVariables = Exact<{
  workOrderId: Scalars['Int']['input'];
}>;


export type GetSignedWorkOrderPdFsQuery = { file?: { id: number, fileType: FileType, workOrderId?: number, userId?: string, contentType?: string, fileName?: string, notes?: string, workOrderItemId?: number } };

export type GetUnsignedWorkOrderPdFsQueryVariables = Exact<{
  workOrderId: Scalars['Int']['input'];
}>;


export type GetUnsignedWorkOrderPdFsQuery = { files?: { totalCount: number, items?: Array<{ id: number, fileType: FileType, workOrderId?: number, userId?: string, contentType?: string, fileName?: string, notes?: string, workOrderItemId?: number, createdAt: any }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetWorkOrderRelatedFilesQueryVariables = Exact<{
  workOrderId: Scalars['Int']['input'];
  where?: InputMaybe<FileReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<FileReturnDtoSortInput> | FileReturnDtoSortInput>;
}>;


export type GetWorkOrderRelatedFilesQuery = { workOrderRelatedFiles?: { totalCount: number, items?: Array<{ id: number, workOrderId?: number, fileName?: string, fileType: FileType, notes?: string, contentType?: string, includeInPDF?: boolean }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type SendPdfFileMutationVariables = Exact<{
  dto: SendPdfFilesDtoInput;
}>;


export type SendPdfFileMutation = { sendPDFFile: boolean };

export type ToggleFileIncludeInPdfMutationVariables = Exact<{
  fileId: Scalars['Int']['input'];
}>;


export type ToggleFileIncludeInPdfMutation = { toggleFileIncludeInPDF: Array<{ id: number, includeInPDF?: boolean }> };

export type UpdateFileMutationVariables = Exact<{
  data: FileUpdateDtoInput;
}>;


export type UpdateFileMutation = { updateFile: Array<{ id: number }> };

export type CreateFinancialAccountMutationVariables = Exact<{
  data: FinancialAccountCreateDtoInput;
}>;


export type CreateFinancialAccountMutation = { createFinancialAccount: Array<{ id: number }> };

export type DeleteFinancialAccountMutationVariables = Exact<{
  data: FinancialAccountDeleteDtoInput;
}>;


export type DeleteFinancialAccountMutation = { deleteFinancialAccount: boolean };

export type GetFinancialAccountsQueryVariables = Exact<{
  where?: InputMaybe<FinancialAccountReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<FinancialAccountReturnDtoSortInput> | FinancialAccountReturnDtoSortInput>;
}>;


export type GetFinancialAccountsQuery = { financialAccounts?: { totalCount: number, items?: Array<{ id: number, name?: string, description?: string, number?: string, externalId?: string }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type UpdateFinancialAccountMutationVariables = Exact<{
  data: FinancialAccountUpdateDtoInput;
}>;


export type UpdateFinancialAccountMutation = { updateFinancialAccount: Array<{ id: number, name?: string }> };

export type GeocodeAddressQueryVariables = Exact<{
  address: Scalars['String']['input'];
}>;


export type GeocodeAddressQuery = { geocodeAddress: { lat: any, lng: any } };

export type CreateItemMutationVariables = Exact<{
  data: ItemCreateDtoInput;
}>;


export type CreateItemMutation = { createItem: Array<{ id: number }> };

export type DeleteItemMutationVariables = Exact<{
  data: ItemDeleteDtoInput;
}>;


export type DeleteItemMutation = { deleteItem: boolean };

export type GetItemsQueryVariables = Exact<{
  where?: InputMaybe<ItemReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ItemReturnDtoSortInput> | ItemReturnDtoSortInput>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetItemsQuery = { items?: { totalCount: number, items?: Array<{ id: number, name?: string, description?: string, isDeleted: boolean, unitOfMeasureId?: number, unitPrice?: any, unitsContained?: any, itemType?: ItemType, rentalServiceItemId?: number, rentalThresholdDays: number, externalId?: string, vatTypeId?: number, financialAccountId?: number, unitOfMeasure?: { name?: string, symbol?: string }, rentalServiceItem?: { id?: number, name?: string, description?: string, unitsContained?: any, unitPrice?: any, unitOfMeasureId?: number }, vatType?: { id: number, name?: string, description?: string, rate?: any }, financialAccount?: { id: number, name?: string, description?: string, number?: string } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type RestoreDeletedItemMutationVariables = Exact<{
  dto: RestoreDeletedItemDtoInput;
}>;


export type RestoreDeletedItemMutation = { restoreDeletedItem: Array<{ id: number }> };

export type UpdateItemMutationVariables = Exact<{
  data: ItemUpdateDtoInput;
}>;


export type UpdateItemMutation = { updateItem: Array<{ id: number, name?: string }> };

export type CreateOrganizationalEntityMutationVariables = Exact<{
  data: OrganizationalEntityCreateDtoInput;
}>;


export type CreateOrganizationalEntityMutation = { createOrganizationalEntity: Array<{ id: number }> };

export type DeleteOrganizationalEntityMutationVariables = Exact<{
  data: OrganizationalEntityDeleteDtoInput;
}>;


export type DeleteOrganizationalEntityMutation = { deleteOrganizationalEntity: boolean };

export type GetAllOrganizationalEntitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllOrganizationalEntitiesQuery = { wholeOrganizationalEntitiesTree: Array<{ id: number, name?: string, description?: string, parentId?: number }> };

export type GetDefaultOrganizationalEntityQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefaultOrganizationalEntityQuery = { defaultOrganizationalEntity?: { id: number, name?: string, description?: string, isDefault?: boolean } };

export type GetOrganizationalEntitiesQueryVariables = Exact<{
  where?: InputMaybe<OrganizationalEntityReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<OrganizationalEntityReturnDtoSortInput> | OrganizationalEntityReturnDtoSortInput>;
}>;


export type GetOrganizationalEntitiesQuery = { organizationalEntities?: { totalCount: number, items?: Array<{ id: number, name?: string, isDefault?: boolean, description?: string, parentId?: number, parent?: { id: number, name?: string, description?: string } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type UpdateOrganizationalEntityMutationVariables = Exact<{
  data: OrganizationalEntityUpdateDtoInput;
}>;


export type UpdateOrganizationalEntityMutation = { updateOrganizationalEntity: Array<{ id: number, name?: string }> };

export type CreateWorkOrderPdfMutationVariables = Exact<{
  workOrderId: Scalars['Int']['input'];
}>;


export type CreateWorkOrderPdfMutation = { createWorkOrderPDF: number };

export type CreatePartnerPickupRequestMutationVariables = Exact<{
  data: PartnerPickupRequestCreateDtoInput;
}>;


export type CreatePartnerPickupRequestMutation = { createPartnerPickupRequest: Array<{ id: number }> };

export type DeletePartnerPickupRequestMutationVariables = Exact<{
  data: PartnerPickupRequestDeleteDtoInput;
}>;


export type DeletePartnerPickupRequestMutation = { deletePartnerPickupRequest: boolean };

export type GetPartnerPickupRequestsQueryVariables = Exact<{
  where?: InputMaybe<PartnerPickupRequestReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<PartnerPickupRequestReturnDtoSortInput> | PartnerPickupRequestReturnDtoSortInput>;
}>;


export type GetPartnerPickupRequestsQuery = { partnerPickupRequests?: { totalCount: number, pageInfo: { hasPreviousPage: boolean, hasNextPage: boolean }, items?: Array<{ id: number, isAssigned: boolean, serviceNote?: string, locationNote?: string, pickupDate?: any, companyLocationId?: number, reportingUser: { userName: string }, companyLocation?: { id: number, name?: string, address?: string, isMainLocation: boolean, location?: { longitude: number, latitude: number } }, workOrder?: { currentStatus?: { status: WorkOrderStatus } } }> } };

export type GetPartnerPickupRequestsFilteredQueryVariables = Exact<{
  where?: InputMaybe<PartnerPickupRequestFilteredReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<PartnerPickupRequestFilteredReturnDtoSortInput> | PartnerPickupRequestFilteredReturnDtoSortInput>;
  filters: PartnerPickupRequestsFilterDtoInput;
}>;


export type GetPartnerPickupRequestsFilteredQuery = { partnerPickupRequestsFiltered?: { totalCount: number, pageInfo: { hasPreviousPage: boolean, hasNextPage: boolean }, items?: Array<{ id: number, companyLocationId?: number, companyLocation?: { name?: string, address?: string } }> } };

export type UpdatePartnerPickupRequestMutationVariables = Exact<{
  data: PartnerPickupRequestUpdateDtoInput;
}>;


export type UpdatePartnerPickupRequestMutation = { updatePartnerPickupRequest: Array<{ id: number }> };

export type CreatePartnerPortalNoteMutationVariables = Exact<{
  data: PartnerPortalNoteCreateDtoInput;
}>;


export type CreatePartnerPortalNoteMutation = { createPartnerPortalNote: Array<{ id: number }> };

export type DeletePartnerPortalNoteMutationVariables = Exact<{
  data: PartnerPortalNoteDeleteDtoInput;
}>;


export type DeletePartnerPortalNoteMutation = { deletePartnerPortalNote: boolean };

export type GetPartnerPortalNotesQueryVariables = Exact<{
  where?: InputMaybe<PartnerPortalNoteReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<PartnerPortalNoteReturnDtoSortInput> | PartnerPortalNoteReturnDtoSortInput>;
}>;


export type GetPartnerPortalNotesQuery = { partnerPortalNotes?: { totalCount: number, items?: Array<{ id: number, note?: string }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type UpdatePartnerPortalNoteMutationVariables = Exact<{
  data: PartnerPortalNoteUpdateDtoInput;
}>;


export type UpdatePartnerPortalNoteMutation = { updatePartnerPortalNote: Array<{ id: number, note?: string }> };

export type CreatePickupRequestMutationVariables = Exact<{
  data: PickupRequestCreateDtoInput;
}>;


export type CreatePickupRequestMutation = { createPickupRequest: Array<{ id: number }> };

export type DeletePickupRequestMutationVariables = Exact<{
  data: PickupRequestDeleteDtoInput;
}>;


export type DeletePickupRequestMutation = { deletePickupRequest: boolean };

export type GetPickupRequestsQueryVariables = Exact<{
  where?: InputMaybe<PickupRequestFilteredReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<PickupRequestFilteredReturnDtoSortInput> | PickupRequestFilteredReturnDtoSortInput>;
}>;


export type GetPickupRequestsQuery = { pickupRequests?: { totalCount: number, pageInfo: { hasPreviousPage: boolean, hasNextPage: boolean }, items?: Array<{ id: number, locationNote?: string, serviceNote?: string, pickupDate?: any, companyLocationId?: number, companyId?: number, workOrderId?: number, reportingUser?: { id: string, userName: string }, companyLocation?: { id: number, name?: string, address?: string }, company?: { name?: string, taxId?: string }, workOrder?: { id: number, currentStatus?: { id: number, workOrderId: number, notes?: string, dateTime: any, status: WorkOrderStatus, initiatingUserId: string, initiatingUser: { id: string, userName: string } } } }> } };

export type GetPickupRequestsFilteredQueryVariables = Exact<{
  where?: InputMaybe<PickupRequestFilteredReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<PickupRequestFilteredReturnDtoSortInput> | PickupRequestFilteredReturnDtoSortInput>;
  filters: PickupRequestsFilterDtoInput;
}>;


export type GetPickupRequestsFilteredQuery = { pickupRequestsFiltered?: { totalCount: number, pageInfo: { hasPreviousPage: boolean, hasNextPage: boolean }, items?: Array<{ id: number, locationNote?: string, serviceNote?: string, pickupDate?: any, companyLocationId?: number, companyId?: number, isAssigned: boolean, workOrderId?: number, companyLocation?: { id: number, name?: string, address?: string, isMainLocation: boolean, location?: { longitude: number, latitude: number } }, company?: { id: number, name?: string, taxId?: string }, reportingUser?: { id: string, userName: string }, workOrder?: { id: number, pickupRequestId: number, currentStatus?: { id: number, dateTime: any, notes?: string, initiatingUserId: string, status: WorkOrderStatus, initiatingUser: { id: string, userName: string } } } }> } };

export type GetUnassignedPickupRequestsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnassignedPickupRequestsCountQuery = { unassignedPickupRequestCount: number };

export type UnassignedPickupRequestCountChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UnassignedPickupRequestCountChangedSubscription = { unassignedPickupRequestCountChanged: number };

export type UpdatePickupRequestMutationVariables = Exact<{
  data: PickupRequestUpdateDtoInput;
}>;


export type UpdatePickupRequestMutation = { updatePickupRequest: Array<{ id: number }> };

export type WorkOrderCountPerDayQueryVariables = Exact<{
  dto: WorkOrderCountPerDayDtoInput;
}>;


export type WorkOrderCountPerDayQuery = { workOrderCountAndValuePerDay: Array<{ key: any, value: { count: number, value: any } }> };

export type GetWorkOrdersForReportQueryVariables = Exact<{
  dto: WorkOrderFilterDtoInput;
  order?: InputMaybe<Array<WorkOrderFlattenedReturnDtoSortInput> | WorkOrderFlattenedReturnDtoSortInput>;
  where?: InputMaybe<WorkOrderFlattenedReturnDtoFilterInput>;
}>;


export type GetWorkOrdersForReportQuery = { workOrdersForReport: Array<{ id: number, pickupRequestId?: number, pickupRequestPickupDate?: any, pickupRequestReportingUserUserName?: string, pickupRequestCompanyName?: string, pickupRequestCompanyTaxId?: string, pickupRequestCompanyLocationName?: string, pickupRequestCompanyLocationAddress?: string, scheduledDate?: any, startDateTime?: any, endDateTime?: any, assignedByManagerUserUserName?: string, assignedUserUserName?: string, vehicleName?: string, vehicleRegistrationNumber?: string, vehicleCostLocationName?: string, vehicleCostLocationCode?: number, organizationalEntityName?: string, executionCategoryName?: string, currentStatusStatus?: WorkOrderStatus, itemsTotal?: any }> };

export type CreateUnitOfMeasureMutationVariables = Exact<{
  data: UnitOfMeasureCreateDtoInput;
}>;


export type CreateUnitOfMeasureMutation = { createUnitOfMeasure: Array<{ id: number }> };

export type DeleteUnitOfMeasureMutationVariables = Exact<{
  data: UnitOfMeasureDeleteDtoInput;
}>;


export type DeleteUnitOfMeasureMutation = { deleteUnitOfMeasure: boolean };

export type GetUnitsOfMeasureQueryVariables = Exact<{
  where?: InputMaybe<UnitOfMeasureReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<UnitOfMeasureReturnDtoSortInput> | UnitOfMeasureReturnDtoSortInput>;
}>;


export type GetUnitsOfMeasureQuery = { unitsOfMeasure?: { totalCount: number, items?: Array<{ id: number, name?: string, description?: string, symbol?: string, externalId?: string }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type UpdateUnitOfMeasureMutationVariables = Exact<{
  data: UnitOfMeasureUpdateDtoInput;
}>;


export type UpdateUnitOfMeasureMutation = { updateUnitOfMeasure: Array<{ id: number, name?: string }> };

export type UnseenNotificationsCountSubscriptionSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UnseenNotificationsCountSubscriptionSubscription = { unseenUserNotificationsCountChanged: number };

export type DeleteUserMutationVariables = Exact<{
  model: AccountDeleteDtoInput;
}>;


export type DeleteUserMutation = { delete: boolean };

export type GetFieldWorkersPlainQueryVariables = Exact<{
  order?: InputMaybe<Array<ApplicationUserSortInput> | ApplicationUserSortInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ApplicationUserFilterInput>;
}>;


export type GetFieldWorkersPlainQuery = { users?: { totalCount: number, items?: Array<{ id: string, userName?: string, email?: string, firstName?: string, lastName?: string, emailConfirmed: boolean, phoneNumber?: string, companyId?: number, currentVehicleId?: number, currentOrganizationalEntityId?: number, company?: { id: number, name?: string, taxId?: string }, currentVehicle?: { id: number, name?: string, description?: string, registrationNumber?: string }, currentOrganizationalEntity?: { id: number, name?: string, description?: string } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetUnfilteredUsersQueryVariables = Exact<{
  order?: InputMaybe<Array<ApplicationUserReturnDtoSortInput> | ApplicationUserReturnDtoSortInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ApplicationUserReturnDtoFilterInput>;
}>;


export type GetUnfilteredUsersQuery = { usersUnfiltered?: { totalCount: number, items?: Array<{ id: string, userName?: string, email?: string, firstName?: string, lastName?: string, emailConfirmed: boolean, phoneNumber?: string, companyId?: number, currentVehicleId?: number, currentOrganizationalEntityId?: number, externalId?: string, company?: { id: number, name?: string, taxId?: string }, currentVehicle?: { id: number, name?: string, description?: string, registrationNumber?: string }, currentOrganizationalEntity?: { id: number, name?: string, description?: string } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetUserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetUserQuery = { singleUser?: { id: string, userName?: string, companyId?: number, firstName?: string, lastName?: string, emailConfirmed: boolean, email?: string, phoneNumber?: string, preferredLanguage?: string, currentVehicleId?: number, currentOrganizationalEntityId?: number, externalId?: string, company?: { id: number, name?: string, taxId?: string }, currentVehicle?: { id: number, name?: string, description?: string, registrationNumber?: string }, currentOrganizationalEntity?: { id: number, name?: string, description?: string } } };

export type GetUserRolesQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetUserRolesQuery = { userRoles: Array<UserRole> };

export type GetUsersQueryVariables = Exact<{
  order?: InputMaybe<Array<ApplicationUserSortInput> | ApplicationUserSortInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ApplicationUserFilterInput>;
  filters: ApplicationUserFilterDtoInput;
}>;


export type GetUsersQuery = { users?: { totalCount: number, items?: Array<{ id: string, userName?: string, email?: string, firstName?: string, lastName?: string, emailConfirmed: boolean, phoneNumber?: string, companyId?: number, currentVehicleId?: number, currentOrganizationalEntityId?: number, externalId?: string, userRoles: Array<{ role: { name?: string } }>, company?: { id: number, name?: string, taxId?: string }, currentVehicle?: { id: number, name?: string, description?: string, registrationNumber?: string }, currentOrganizationalEntity?: { id: number, name?: string, description?: string } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type ManuallyConfirmUserEmailMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type ManuallyConfirmUserEmailMutation = { confirmEmailManually: boolean };

export type RegisterUserMutationVariables = Exact<{
  model: AccountRegisterExtendedDtoInput;
}>;


export type RegisterUserMutation = { register: boolean };

export type RemoveUserRoleMutationVariables = Exact<{
  id: Scalars['String']['input'];
  roleId: UserRole;
}>;


export type RemoveUserRoleMutation = { removeFromRoleById: Array<UserRole> };

export type ResendConfirmationEmailMutationVariables = Exact<{
  model: AccountResendConfirmationEmailDtoInput;
}>;


export type ResendConfirmationEmailMutation = { resendConfirmationEmail: boolean };

export type UpdateApplicationUserMutationVariables = Exact<{
  input: ApplicationUserUpdateDtoInput;
}>;


export type UpdateApplicationUserMutation = { updateApplicationUser: Array<{ id: string, companyId?: number }> };

export type AddUserRoleMutationVariables = Exact<{
  id: Scalars['String']['input'];
  roleId: UserRole;
}>;


export type AddUserRoleMutation = { addToRoleById: Array<UserRole> };

export type CreateVatTypeMutationVariables = Exact<{
  data: VatTypeCreateDtoInput;
}>;


export type CreateVatTypeMutation = { createVATType: Array<{ id: number }> };

export type DeleteVatTypeMutationVariables = Exact<{
  data: VatTypeDeleteDtoInput;
}>;


export type DeleteVatTypeMutation = { deleteVATType: boolean };

export type GetVatTypesQueryVariables = Exact<{
  where?: InputMaybe<VatTypeReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<VatTypeReturnDtoSortInput> | VatTypeReturnDtoSortInput>;
}>;


export type GetVatTypesQuery = { vatTypes?: { totalCount: number, items?: Array<{ id: number, name?: string, description?: string, rate?: any, externalId?: string }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type UpdateVatTypeMutationVariables = Exact<{
  data: VatTypeUpdateDtoInput;
}>;


export type UpdateVatTypeMutation = { updateVATType: Array<{ id: number, name?: string }> };

export type CreateVehicleMutationVariables = Exact<{
  data: VehicleCreateDtoInput;
}>;


export type CreateVehicleMutation = { createVehicle: Array<{ id: number }> };

export type DeleteVehicleMutationVariables = Exact<{
  data: VehicleDeleteDtoInput;
}>;


export type DeleteVehicleMutation = { deleteVehicle: boolean };

export type GetVehiclesQueryVariables = Exact<{
  where?: InputMaybe<VehicleReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<VehicleReturnDtoSortInput> | VehicleReturnDtoSortInput>;
}>;


export type GetVehiclesQuery = { vehicles?: { totalCount: number, items?: Array<{ id: number, name?: string, description?: string, costLocationId?: number, registrationNumber?: string, externalId?: string, costLocation?: { name?: string, code?: number }, location?: { latitude: number, longitude: number }, assignedUser?: { id: string, userName?: string, firstName?: string, lastName?: string, fullName?: string } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type UpdateVehicleMutationVariables = Exact<{
  data: VehicleUpdateDtoInput;
}>;


export type UpdateVehicleMutation = { updateVehicle: Array<{ id: number, name?: string }> };

export type CreateWorkOrderItemMutationVariables = Exact<{
  data: WorkOrderItemCreateDtoInput;
}>;


export type CreateWorkOrderItemMutation = { createWorkOrderItem: Array<{ id: number }> };

export type DeleteWorkOrderItemMutationVariables = Exact<{
  data: WorkOrderItemDeleteDtoInput;
}>;


export type DeleteWorkOrderItemMutation = { deleteWorkOrderItem: boolean };

export type GetWorkOrderItemsQueryVariables = Exact<{
  where?: InputMaybe<WorkOrderItemReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<WorkOrderItemReturnDtoSortInput> | WorkOrderItemReturnDtoSortInput>;
}>;


export type GetWorkOrderItemsQuery = { workOrderItems?: { totalCount: number, items?: Array<{ id: number, note?: string, workOrderId: number, quantity: any, itemId: number, itemName?: string, itemDescription?: string, itemUnitsContained?: any, itemUnitPrice?: any, unitOfMeasureId?: number, unitOfMeasureName?: string, unitOfMeasureSymbol?: string, vat?: any, vatBase?: any, total?: any, itemVATTypeRate?: any, files: Array<{ id: number, workOrderId?: number, notes?: string, fileName?: string, contentType?: string }> }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type UpdateWorkOrderItemMutationVariables = Exact<{
  data: WorkOrderItemUpdateDtoInput;
}>;


export type UpdateWorkOrderItemMutation = { updateWorkOrderItem: Array<{ id: number }> };

export type CreateWorkOrderStatusChangeMutationVariables = Exact<{
  data: WorkOrderStatusChangeCreateDtoInput;
}>;


export type CreateWorkOrderStatusChangeMutation = { createWorkOrderStatusChange: Array<{ id: number }> };

export type DeleteWorkOrderStatusChangeMutationVariables = Exact<{
  data: WorkOrderStatusChangeDeleteDtoInput;
}>;


export type DeleteWorkOrderStatusChangeMutation = { deleteWorkOrderStatusChange: boolean };

export type GetSingleWorkOrdersStatusChangesQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetSingleWorkOrdersStatusChangesQuery = { singleWorkOrderAllStatusChanges: Array<{ id: number, workOrderId?: number, notes?: string, status?: WorkOrderStatus, initiatingUserId?: string, createdAt?: any, initiatingUser?: { id?: string, userName?: string } }> };

export type GetWorkOrderStatusChangesQueryVariables = Exact<{
  where?: InputMaybe<WorkOrderStatusChangeReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<WorkOrderStatusChangeReturnDtoSortInput> | WorkOrderStatusChangeReturnDtoSortInput>;
}>;


export type GetWorkOrderStatusChangesQuery = { workOrderStatusChanges?: { totalCount: number, items?: Array<{ id: number, workOrderId?: number, status?: WorkOrderStatus, notes?: string, initiatingUserId?: string, createdAt?: any, workOrder?: { formattedId: string }, initiatingUser?: { userName?: string }, location?: { latitude: number, longitude: number } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type UpdateWorkOrderStatusChangeMutationVariables = Exact<{
  data: WorkOrderStatusChangeUpdateDtoInput;
}>;


export type UpdateWorkOrderStatusChangeMutation = { updateWorkOrderStatusChange: Array<{ id: number }> };

export type CreateWorkOrderTemplateItemMutationVariables = Exact<{
  data: WorkOrderTemplateItemCreateDtoInput;
}>;


export type CreateWorkOrderTemplateItemMutation = { createWorkOrderTemplateItem: Array<{ id: number }> };

export type DeleteWorkOrderTemplateItemMutationVariables = Exact<{
  data: WorkOrderTemplateItemDeleteDtoInput;
}>;


export type DeleteWorkOrderTemplateItemMutation = { deleteWorkOrderTemplateItem: boolean };

export type GetWorkOrderTemplateItemsQueryVariables = Exact<{
  where?: InputMaybe<WorkOrderTemplateItemReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<WorkOrderTemplateItemReturnDtoSortInput> | WorkOrderTemplateItemReturnDtoSortInput>;
}>;


export type GetWorkOrderTemplateItemsQuery = { workOrderTemplateItems?: { totalCount: number, items?: Array<{ id: number, note?: string, workOrderTemplateId: number, quantity: any, itemId: number, item: { id: number, name?: string, description?: string } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type UpdateWorkOrderTemplateItemMutationVariables = Exact<{
  data: WorkOrderTemplateItemUpdateDtoInput;
}>;


export type UpdateWorkOrderTemplateItemMutation = { updateWorkOrderTemplateItem: Array<{ id: number }> };

export type CreateWorkOrderTemplateMutationVariables = Exact<{
  data: WorkOrderTemplateCreateDtoInput;
}>;


export type CreateWorkOrderTemplateMutation = { createWorkOrderTemplate: Array<{ id: number }> };

export type DeleteWorkOrderTemplateMutationVariables = Exact<{
  data: WorkOrderTemplateDeleteDtoInput;
}>;


export type DeleteWorkOrderTemplateMutation = { deleteWorkOrderTemplate: boolean };

export type GenerateWorkOrdersFromTemplatesMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateWorkOrdersFromTemplatesMutation = { generateWorkOrdersFromTemplates: boolean };

export type GetWorkOrderTemplateQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetWorkOrderTemplateQuery = { workOrderTemplate?: { id: number, name?: string, description?: string, companyLocationId?: number, companyId?: number, freeformLocation?: string, deactivated?: boolean, executionDay?: DayOfWeek, executionDayOfMonth?: number, organizationalEntityId?: number, vehicleId?: number, priority?: WorkOrderPriority, companyLocation?: { name?: string, address?: string }, company?: { id: number, name?: string, description?: string, taxId?: string }, organizationalEntity?: { name?: string, description?: string }, vehicle?: { name?: string, registrationNumber?: string }, items: Array<{ id: number, itemId: number, quantity: any, note?: string, item: { id: number, name?: string, description?: string } }> } };

export type GetWorkOrderTemplatesQueryVariables = Exact<{
  where?: InputMaybe<WorkOrderTemplateReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<WorkOrderTemplateReturnDtoSortInput> | WorkOrderTemplateReturnDtoSortInput>;
}>;


export type GetWorkOrderTemplatesQuery = { workOrderTemplates?: { totalCount: number, items?: Array<{ id: number, name?: string, description?: string, companyLocationId?: number, companyId?: number, freeformLocation?: string, deactivated?: boolean, executionDay?: DayOfWeek, executionDayOfMonth?: number, organizationalEntityId?: number, vehicleId?: number, priority?: WorkOrderPriority, companyLocation?: { name?: string, address?: string }, company?: { id: number, name?: string, taxId?: string, description?: string }, organizationalEntity?: { name?: string, description?: string }, vehicle?: { name?: string, registrationNumber?: string }, items: Array<{ id: number, itemId: number, quantity: any, note?: string, item: { id: number, name?: string, description?: string } }> }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type UpdateWorkOrderTemplateMutationVariables = Exact<{
  data: WorkOrderTemplateUpdateDtoInput;
}>;


export type UpdateWorkOrderTemplateMutation = { updateWorkOrderTemplate: Array<{ id: number }> };

export type CreateWorkOrderMutationVariables = Exact<{
  data: WorkOrderCreateDtoInput;
}>;


export type CreateWorkOrderMutation = { createWorkOrder: Array<{ id: number }> };

export type DeleteWorkOrderMutationVariables = Exact<{
  data: WorkOrderDeleteDtoInput;
}>;


export type DeleteWorkOrderMutation = { deleteWorkOrder: boolean };

export type DuplicateWorkOrderMutationVariables = Exact<{
  workOrderId: Scalars['Int']['input'];
}>;


export type DuplicateWorkOrderMutation = { duplicateWorkOrder: Array<{ id: number }> };

export type GetWorkOrderQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetWorkOrderQuery = { workOrder: { id: number, formattedId: string, vat?: any, vatBase?: any, total?: any, currentStatusId?: number, scheduledDate: any, pickupRequestId?: number, companyId?: number, freeformCompany?: string, freeformLocation?: string, companyLocationId?: number, assignedUserId?: string, startDateTime?: any, endDateTime?: any, fieldWorkerNote?: string, managerNote?: string, noteForAccounting?: string, organizationalEntityId?: number, duration?: any, vehicleId?: number, priority: WorkOrderPriority, currentStatus?: { dateTime?: any, notes?: string, status?: WorkOrderStatus }, pickupRequest?: { id: number, companyLocationId?: number, note?: string, pickupDate?: any, reportingUserId: string, locationNote?: string, serviceNote?: string, company?: { id: number, name: string }, companyLocation?: { id: number, name: string } }, company?: { id: number, name?: string, description?: string, taxId?: string }, companyLocation?: { id: number, name?: string, description?: string, address?: string, isMainLocation?: boolean, location?: { latitude: number, longitude: number } }, assignedUser?: { id?: string, userName?: string }, organizationalEntity?: { id: number, description?: string, name?: string }, vehicle?: { id: number, name?: string, costLocationId?: number, registrationNumber?: string }, items: Array<{ id: number, note?: string, quantity: any, itemId: number, itemName?: string, itemDescription?: string, itemUnitPrice?: any, vat?: any, vatBase?: any, total?: any, files: Array<{ id: number, notes?: string, contentType?: string, fileName?: string }> }>, files: Array<{ id: number, notes?: string, contentType?: string, fileName?: string }>, signedPDFs: Array<{ id: number, fileType: FileType, userId?: string, workOrderId?: number, workOrderItemId?: number, notes?: string, contentType?: string, fileName?: string }> } };

export type GetWorkOrderIdsQueryVariables = Exact<{
  workOrderId: Scalars['Int']['input'];
}>;


export type GetWorkOrderIdsQuery = { workOrderIds: { id: number, formattedId: string, number: number, year: number } };

export type GetWorkOrderTotalsQueryVariables = Exact<{
  workOrderId: Scalars['Int']['input'];
}>;


export type GetWorkOrderTotalsQuery = { workOrderTotals: { vatBase?: any, vat?: any, total?: any } };

export type GetWorkOrdersQueryVariables = Exact<{
  dto: WorkOrderFilterDtoInput;
  where?: InputMaybe<WorkOrderReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<WorkOrderReturnDtoSortInput> | WorkOrderReturnDtoSortInput>;
}>;


export type GetWorkOrdersQuery = { workOrders?: { totalCount: number, items?: Array<{ id: number, isDeleted: boolean, formattedId: string, currentStatusId?: number, pickupRequestId?: number, freeformCompany?: string, companyLocationId?: number, freeformLocation?: string, scheduledDate: any, assignedUserId?: string, startDateTime?: any, endDateTime?: any, fieldWorkerNote?: string, managerNote?: string, noteForAccounting?: string, organizationalEntityId?: number, duration?: any, vehicleId?: number, currentStatus?: { dateTime?: any, notes?: string, status?: WorkOrderStatus }, pickupRequest?: { id: number, companyLocationId?: number, note?: string, pickupDate?: any, reportingUserId: string }, company?: { name?: string, description?: string, taxId?: string }, companyLocation?: { name?: string, address?: string, isMainLocation?: boolean, location?: { latitude: number, longitude: number } }, assignedUser?: { id?: string, userName?: string }, organizationalEntity?: { description?: string, name?: string }, vehicle?: { name?: string, id: number, costLocationId?: number, registrationNumber?: string }, items: Array<{ id: number, note?: string, quantity: any, itemId: number, itemName?: string, itemDescription?: string, itemUnitPrice?: any, unitOfMeasureSymbol?: string, itemUnitsContained?: any }>, signedPDFs: Array<{ id: number, fileType: FileType, userId?: string, workOrderId?: number, workOrderItemId?: number, notes?: string, contentType?: string, fileName?: string }> }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetWorkOrdersPlainQueryVariables = Exact<{
  where?: InputMaybe<WorkOrderReturnDtoFilterInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetWorkOrdersPlainQuery = { workOrders?: { totalCount: number, items?: Array<{ id: number, formattedId: string, currentStatusId?: number, pickupRequestId?: number, freeformCompany?: string, companyLocationId?: number, freeformLocation?: string, scheduledDate: any, assignedUserId?: string, startDateTime?: any, endDateTime?: any, fieldWorkerNote?: string, managerNote?: string, noteForAccounting?: string, organizationalEntityId?: number, duration?: any, vehicleId?: number, currentStatus?: { dateTime?: any, notes?: string, status?: WorkOrderStatus }, pickupRequest?: { id: number, companyLocationId?: number, note?: string, pickupDate?: any, reportingUserId: string }, company?: { name?: string, description?: string, taxId?: string }, companyLocation?: { name?: string, address?: string, isMainLocation?: boolean, location?: { latitude: number, longitude: number } }, assignedUser?: { id?: string, userName?: string }, organizationalEntity?: { description?: string, name?: string }, vehicle?: { name?: string, id: number, costLocationId?: number, registrationNumber?: string }, items: Array<{ id: number, note?: string, quantity: any, itemId: number, itemName?: string, itemDescription?: string, itemUnitPrice?: any }> }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean } } };

export type MarkWorkOrdersAsInvoicedMutationVariables = Exact<{
  workOrderIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type MarkWorkOrdersAsInvoicedMutation = { batchChangeWorkOrdersStatus: boolean };

export type RestoreDeletedWorkOrderMutationVariables = Exact<{
  workOrderId: Scalars['Int']['input'];
}>;


export type RestoreDeletedWorkOrderMutation = { restoreDeletedWorkOrder: Array<{ id: number }> };

export type SendWorkOrderPdfToPartnerMutationVariables = Exact<{
  dto: SendWorkOrderPdfDtoInput;
}>;


export type SendWorkOrderPdfToPartnerMutation = { sendWorkOrderPDFToPartner: boolean };

export type SetWorkOrderStatusMutationVariables = Exact<{
  status: WorkOrderStatus;
  workOrderId: Scalars['Int']['input'];
}>;


export type SetWorkOrderStatusMutation = { setWorkOrderStatus: boolean };

export type UpdateWorkOrderMutationVariables = Exact<{
  data: WorkOrderUpdateDtoInput;
}>;


export type UpdateWorkOrderMutation = { updateWorkOrder: Array<{ id: number }> };


export const ChangePasswordDocument = gql`
    mutation changePassword($model: AccountChangePasswordDtoInput!) {
  changePassword(model: $model)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const EmailConfirmDocument = gql`
    mutation emailConfirm($code: String!, $userId: String!) {
  confirmEmail(code: $code, userId: $userId)
}
    `;
export type EmailConfirmMutationFn = Apollo.MutationFunction<EmailConfirmMutation, EmailConfirmMutationVariables>;

/**
 * __useEmailConfirmMutation__
 *
 * To run a mutation, you first call `useEmailConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailConfirmMutation, { data, loading, error }] = useEmailConfirmMutation({
 *   variables: {
 *      code: // value for 'code'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEmailConfirmMutation(baseOptions?: Apollo.MutationHookOptions<EmailConfirmMutation, EmailConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailConfirmMutation, EmailConfirmMutationVariables>(EmailConfirmDocument, options);
      }
export type EmailConfirmMutationHookResult = ReturnType<typeof useEmailConfirmMutation>;
export type EmailConfirmMutationResult = Apollo.MutationResult<EmailConfirmMutation>;
export type EmailConfirmMutationOptions = Apollo.BaseMutationOptions<EmailConfirmMutation, EmailConfirmMutationVariables>;
export const GetConsentDocument = gql`
    query getConsent($userId: String!) {
  accountConsent(userId: $userId) {
    id
    emailConsentGivenOn
    emailConsentSource
    isEmailConsentGiven
  }
}
    `;

/**
 * __useGetConsentQuery__
 *
 * To run a query within a React component, call `useGetConsentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsentQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetConsentQuery(baseOptions: Apollo.QueryHookOptions<GetConsentQuery, GetConsentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsentQuery, GetConsentQueryVariables>(GetConsentDocument, options);
      }
export function useGetConsentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsentQuery, GetConsentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsentQuery, GetConsentQueryVariables>(GetConsentDocument, options);
        }
export type GetConsentQueryHookResult = ReturnType<typeof useGetConsentQuery>;
export type GetConsentLazyQueryHookResult = ReturnType<typeof useGetConsentLazyQuery>;
export type GetConsentQueryResult = Apollo.QueryResult<GetConsentQuery, GetConsentQueryVariables>;
export function refetchGetConsentQuery(variables: GetConsentQueryVariables) {
      return { query: GetConsentDocument, variables: variables }
    }
export const GetMyClaimsDocument = gql`
    query getMyClaims {
  claims {
    type
    value
  }
}
    `;

/**
 * __useGetMyClaimsQuery__
 *
 * To run a query within a React component, call `useGetMyClaimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyClaimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyClaimsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyClaimsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyClaimsQuery, GetMyClaimsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyClaimsQuery, GetMyClaimsQueryVariables>(GetMyClaimsDocument, options);
      }
export function useGetMyClaimsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyClaimsQuery, GetMyClaimsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyClaimsQuery, GetMyClaimsQueryVariables>(GetMyClaimsDocument, options);
        }
export type GetMyClaimsQueryHookResult = ReturnType<typeof useGetMyClaimsQuery>;
export type GetMyClaimsLazyQueryHookResult = ReturnType<typeof useGetMyClaimsLazyQuery>;
export type GetMyClaimsQueryResult = Apollo.QueryResult<GetMyClaimsQuery, GetMyClaimsQueryVariables>;
export function refetchGetMyClaimsQuery(variables?: GetMyClaimsQueryVariables) {
      return { query: GetMyClaimsDocument, variables: variables }
    }
export const GetMyRolesDocument = gql`
    query getMyRoles {
  roles
}
    `;

/**
 * __useGetMyRolesQuery__
 *
 * To run a query within a React component, call `useGetMyRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetMyRolesQuery, GetMyRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyRolesQuery, GetMyRolesQueryVariables>(GetMyRolesDocument, options);
      }
export function useGetMyRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyRolesQuery, GetMyRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyRolesQuery, GetMyRolesQueryVariables>(GetMyRolesDocument, options);
        }
export type GetMyRolesQueryHookResult = ReturnType<typeof useGetMyRolesQuery>;
export type GetMyRolesLazyQueryHookResult = ReturnType<typeof useGetMyRolesLazyQuery>;
export type GetMyRolesQueryResult = Apollo.QueryResult<GetMyRolesQuery, GetMyRolesQueryVariables>;
export function refetchGetMyRolesQuery(variables?: GetMyRolesQueryVariables) {
      return { query: GetMyRolesDocument, variables: variables }
    }
export const GetMyTokenDocument = gql`
    query getMyToken {
  token {
    token
  }
}
    `;

/**
 * __useGetMyTokenQuery__
 *
 * To run a query within a React component, call `useGetMyTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyTokenQuery(baseOptions?: Apollo.QueryHookOptions<GetMyTokenQuery, GetMyTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyTokenQuery, GetMyTokenQueryVariables>(GetMyTokenDocument, options);
      }
export function useGetMyTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyTokenQuery, GetMyTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyTokenQuery, GetMyTokenQueryVariables>(GetMyTokenDocument, options);
        }
export type GetMyTokenQueryHookResult = ReturnType<typeof useGetMyTokenQuery>;
export type GetMyTokenLazyQueryHookResult = ReturnType<typeof useGetMyTokenLazyQuery>;
export type GetMyTokenQueryResult = Apollo.QueryResult<GetMyTokenQuery, GetMyTokenQueryVariables>;
export function refetchGetMyTokenQuery(variables?: GetMyTokenQueryVariables) {
      return { query: GetMyTokenDocument, variables: variables }
    }
export const GetMyUserDocument = gql`
    query getMyUser {
  user {
    id
    email
    userName
    firstName
    lastName
    preferredLanguage
    phoneNumber
  }
}
    `;

/**
 * __useGetMyUserQuery__
 *
 * To run a query within a React component, call `useGetMyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserQuery(baseOptions?: Apollo.QueryHookOptions<GetMyUserQuery, GetMyUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyUserQuery, GetMyUserQueryVariables>(GetMyUserDocument, options);
      }
export function useGetMyUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyUserQuery, GetMyUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyUserQuery, GetMyUserQueryVariables>(GetMyUserDocument, options);
        }
export type GetMyUserQueryHookResult = ReturnType<typeof useGetMyUserQuery>;
export type GetMyUserLazyQueryHookResult = ReturnType<typeof useGetMyUserLazyQuery>;
export type GetMyUserQueryResult = Apollo.QueryResult<GetMyUserQuery, GetMyUserQueryVariables>;
export function refetchGetMyUserQuery(variables?: GetMyUserQueryVariables) {
      return { query: GetMyUserDocument, variables: variables }
    }
export const LogMeInDocument = gql`
    mutation logMeIn($password: String!, $username: String!) {
  login(model: {password: $password, username: $username}) {
    token
  }
}
    `;
export type LogMeInMutationFn = Apollo.MutationFunction<LogMeInMutation, LogMeInMutationVariables>;

/**
 * __useLogMeInMutation__
 *
 * To run a mutation, you first call `useLogMeInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogMeInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logMeInMutation, { data, loading, error }] = useLogMeInMutation({
 *   variables: {
 *      password: // value for 'password'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useLogMeInMutation(baseOptions?: Apollo.MutationHookOptions<LogMeInMutation, LogMeInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogMeInMutation, LogMeInMutationVariables>(LogMeInDocument, options);
      }
export type LogMeInMutationHookResult = ReturnType<typeof useLogMeInMutation>;
export type LogMeInMutationResult = Apollo.MutationResult<LogMeInMutation>;
export type LogMeInMutationOptions = Apollo.BaseMutationOptions<LogMeInMutation, LogMeInMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken {
  refreshToken {
    token
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const RegisterMeDocument = gql`
    mutation registerMe($model: AccountRegisterExtendedDtoInput!) {
  register(model: $model)
}
    `;
export type RegisterMeMutationFn = Apollo.MutationFunction<RegisterMeMutation, RegisterMeMutationVariables>;

/**
 * __useRegisterMeMutation__
 *
 * To run a mutation, you first call `useRegisterMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMeMutation, { data, loading, error }] = useRegisterMeMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useRegisterMeMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMeMutation, RegisterMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMeMutation, RegisterMeMutationVariables>(RegisterMeDocument, options);
      }
export type RegisterMeMutationHookResult = ReturnType<typeof useRegisterMeMutation>;
export type RegisterMeMutationResult = Apollo.MutationResult<RegisterMeMutation>;
export type RegisterMeMutationOptions = Apollo.BaseMutationOptions<RegisterMeMutation, RegisterMeMutationVariables>;
export const ResetMyPasswordDocument = gql`
    mutation resetMyPassword($model: AccountResetPasswordDtoInput!) {
  resetPassword(model: $model)
}
    `;
export type ResetMyPasswordMutationFn = Apollo.MutationFunction<ResetMyPasswordMutation, ResetMyPasswordMutationVariables>;

/**
 * __useResetMyPasswordMutation__
 *
 * To run a mutation, you first call `useResetMyPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetMyPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetMyPasswordMutation, { data, loading, error }] = useResetMyPasswordMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useResetMyPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetMyPasswordMutation, ResetMyPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetMyPasswordMutation, ResetMyPasswordMutationVariables>(ResetMyPasswordDocument, options);
      }
export type ResetMyPasswordMutationHookResult = ReturnType<typeof useResetMyPasswordMutation>;
export type ResetMyPasswordMutationResult = Apollo.MutationResult<ResetMyPasswordMutation>;
export type ResetMyPasswordMutationOptions = Apollo.BaseMutationOptions<ResetMyPasswordMutation, ResetMyPasswordMutationVariables>;
export const SetConsentDocument = gql`
    mutation setConsent($dto: AccountConsentUpdateDtoInput!) {
  setConsent(dto: $dto) {
    id
  }
}
    `;
export type SetConsentMutationFn = Apollo.MutationFunction<SetConsentMutation, SetConsentMutationVariables>;

/**
 * __useSetConsentMutation__
 *
 * To run a mutation, you first call `useSetConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setConsentMutation, { data, loading, error }] = useSetConsentMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSetConsentMutation(baseOptions?: Apollo.MutationHookOptions<SetConsentMutation, SetConsentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetConsentMutation, SetConsentMutationVariables>(SetConsentDocument, options);
      }
export type SetConsentMutationHookResult = ReturnType<typeof useSetConsentMutation>;
export type SetConsentMutationResult = Apollo.MutationResult<SetConsentMutation>;
export type SetConsentMutationOptions = Apollo.BaseMutationOptions<SetConsentMutation, SetConsentMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($dto: AccountUpdateDtoInput!) {
  updateProfile(dto: $dto) {
    id
    phoneNumber
    preferredLanguage
    email
    firstName
    lastName
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($data: CompanyCreateDtoInput!) {
  createCompany(data: $data) {
    id
  }
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($data: CompanyDeleteDtoInput!) {
  deleteCompany(data: $data)
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const GetCompaniesDocument = gql`
    query getCompanies($where: CompanyReturnDtoFilterInput, $take: Int, $skip: Int, $order: [CompanyReturnDtoSortInput!]) {
  companies(where: $where, take: $take, skip: $skip, order: $order) {
    items {
      id
      name
      taxId
      description
      externalId
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
      }
export function useGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
        }
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<GetCompaniesQuery, GetCompaniesQueryVariables>;
export function refetchGetCompaniesQuery(variables?: GetCompaniesQueryVariables) {
      return { query: GetCompaniesDocument, variables: variables }
    }
export const GetCompanyDocument = gql`
    query getCompany($where: CompanyReturnDtoFilterInput) {
  company(where: $where) {
    id
    name
    taxId
    description
    externalId
  }
}
    `;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
      }
export function useGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export function refetchGetCompanyQuery(variables?: GetCompanyQueryVariables) {
      return { query: GetCompanyDocument, variables: variables }
    }
export const UpdateCompanyDocument = gql`
    mutation updateCompany($data: CompanyUpdateDtoInput!) {
  updateCompany(data: $data) {
    id
    name
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const CreateCompanyLocationDocument = gql`
    mutation createCompanyLocation($data: CompanyLocationCreateDtoInput!) {
  createCompanyLocation(data: $data) {
    id
  }
}
    `;
export type CreateCompanyLocationMutationFn = Apollo.MutationFunction<CreateCompanyLocationMutation, CreateCompanyLocationMutationVariables>;

/**
 * __useCreateCompanyLocationMutation__
 *
 * To run a mutation, you first call `useCreateCompanyLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyLocationMutation, { data, loading, error }] = useCreateCompanyLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCompanyLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyLocationMutation, CreateCompanyLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyLocationMutation, CreateCompanyLocationMutationVariables>(CreateCompanyLocationDocument, options);
      }
export type CreateCompanyLocationMutationHookResult = ReturnType<typeof useCreateCompanyLocationMutation>;
export type CreateCompanyLocationMutationResult = Apollo.MutationResult<CreateCompanyLocationMutation>;
export type CreateCompanyLocationMutationOptions = Apollo.BaseMutationOptions<CreateCompanyLocationMutation, CreateCompanyLocationMutationVariables>;
export const DeleteCompanyLocationDocument = gql`
    mutation deleteCompanyLocation($data: CompanyLocationDeleteDtoInput!) {
  deleteCompanyLocation(data: $data)
}
    `;
export type DeleteCompanyLocationMutationFn = Apollo.MutationFunction<DeleteCompanyLocationMutation, DeleteCompanyLocationMutationVariables>;

/**
 * __useDeleteCompanyLocationMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyLocationMutation, { data, loading, error }] = useDeleteCompanyLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteCompanyLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyLocationMutation, DeleteCompanyLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyLocationMutation, DeleteCompanyLocationMutationVariables>(DeleteCompanyLocationDocument, options);
      }
export type DeleteCompanyLocationMutationHookResult = ReturnType<typeof useDeleteCompanyLocationMutation>;
export type DeleteCompanyLocationMutationResult = Apollo.MutationResult<DeleteCompanyLocationMutation>;
export type DeleteCompanyLocationMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyLocationMutation, DeleteCompanyLocationMutationVariables>;
export const GetCompanyLocationsDocument = gql`
    query getCompanyLocations($where: CompanyLocationReturnDtoFilterInput, $take: Int, $skip: Int, $order: [CompanyLocationReturnDtoSortInput!]) {
  companyLocations(where: $where, take: $take, skip: $skip, order: $order) {
    items {
      id
      name
      description
      location {
        latitude
        longitude
      }
      isMainLocation
      address
      company {
        id
        name
      }
      companyId
      externalId
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetCompanyLocationsQuery__
 *
 * To run a query within a React component, call `useGetCompanyLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyLocationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetCompanyLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyLocationsQuery, GetCompanyLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyLocationsQuery, GetCompanyLocationsQueryVariables>(GetCompanyLocationsDocument, options);
      }
export function useGetCompanyLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyLocationsQuery, GetCompanyLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyLocationsQuery, GetCompanyLocationsQueryVariables>(GetCompanyLocationsDocument, options);
        }
export type GetCompanyLocationsQueryHookResult = ReturnType<typeof useGetCompanyLocationsQuery>;
export type GetCompanyLocationsLazyQueryHookResult = ReturnType<typeof useGetCompanyLocationsLazyQuery>;
export type GetCompanyLocationsQueryResult = Apollo.QueryResult<GetCompanyLocationsQuery, GetCompanyLocationsQueryVariables>;
export function refetchGetCompanyLocationsQuery(variables?: GetCompanyLocationsQueryVariables) {
      return { query: GetCompanyLocationsDocument, variables: variables }
    }
export const UpdateCompanyLocationDocument = gql`
    mutation updateCompanyLocation($data: CompanyLocationUpdateDtoInput!) {
  updateCompanyLocation(data: $data) {
    id
    name
    companyId
    company {
      name
      id
    }
    address
    isMainLocation
    location {
      latitude
      longitude
    }
  }
}
    `;
export type UpdateCompanyLocationMutationFn = Apollo.MutationFunction<UpdateCompanyLocationMutation, UpdateCompanyLocationMutationVariables>;

/**
 * __useUpdateCompanyLocationMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyLocationMutation, { data, loading, error }] = useUpdateCompanyLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCompanyLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyLocationMutation, UpdateCompanyLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyLocationMutation, UpdateCompanyLocationMutationVariables>(UpdateCompanyLocationDocument, options);
      }
export type UpdateCompanyLocationMutationHookResult = ReturnType<typeof useUpdateCompanyLocationMutation>;
export type UpdateCompanyLocationMutationResult = Apollo.MutationResult<UpdateCompanyLocationMutation>;
export type UpdateCompanyLocationMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyLocationMutation, UpdateCompanyLocationMutationVariables>;
export const CreateCostLocationDocument = gql`
    mutation createCostLocation($data: CostLocationCreateDtoInput!) {
  createCostLocation(data: $data) {
    id
  }
}
    `;
export type CreateCostLocationMutationFn = Apollo.MutationFunction<CreateCostLocationMutation, CreateCostLocationMutationVariables>;

/**
 * __useCreateCostLocationMutation__
 *
 * To run a mutation, you first call `useCreateCostLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostLocationMutation, { data, loading, error }] = useCreateCostLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCostLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateCostLocationMutation, CreateCostLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCostLocationMutation, CreateCostLocationMutationVariables>(CreateCostLocationDocument, options);
      }
export type CreateCostLocationMutationHookResult = ReturnType<typeof useCreateCostLocationMutation>;
export type CreateCostLocationMutationResult = Apollo.MutationResult<CreateCostLocationMutation>;
export type CreateCostLocationMutationOptions = Apollo.BaseMutationOptions<CreateCostLocationMutation, CreateCostLocationMutationVariables>;
export const DeleteCostLocationDocument = gql`
    mutation deleteCostLocation($data: CostLocationDeleteDtoInput!) {
  deleteCostLocation(data: $data)
}
    `;
export type DeleteCostLocationMutationFn = Apollo.MutationFunction<DeleteCostLocationMutation, DeleteCostLocationMutationVariables>;

/**
 * __useDeleteCostLocationMutation__
 *
 * To run a mutation, you first call `useDeleteCostLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostLocationMutation, { data, loading, error }] = useDeleteCostLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteCostLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCostLocationMutation, DeleteCostLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCostLocationMutation, DeleteCostLocationMutationVariables>(DeleteCostLocationDocument, options);
      }
export type DeleteCostLocationMutationHookResult = ReturnType<typeof useDeleteCostLocationMutation>;
export type DeleteCostLocationMutationResult = Apollo.MutationResult<DeleteCostLocationMutation>;
export type DeleteCostLocationMutationOptions = Apollo.BaseMutationOptions<DeleteCostLocationMutation, DeleteCostLocationMutationVariables>;
export const GetCostLocationsDocument = gql`
    query getCostLocations($where: CostLocationReturnDtoFilterInput, $take: Int, $skip: Int, $order: [CostLocationReturnDtoSortInput!]) {
  costLocations(where: $where, take: $take, skip: $skip, order: $order) {
    items {
      id
      name
      description
      code
      externalId
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetCostLocationsQuery__
 *
 * To run a query within a React component, call `useGetCostLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostLocationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetCostLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetCostLocationsQuery, GetCostLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCostLocationsQuery, GetCostLocationsQueryVariables>(GetCostLocationsDocument, options);
      }
export function useGetCostLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCostLocationsQuery, GetCostLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCostLocationsQuery, GetCostLocationsQueryVariables>(GetCostLocationsDocument, options);
        }
export type GetCostLocationsQueryHookResult = ReturnType<typeof useGetCostLocationsQuery>;
export type GetCostLocationsLazyQueryHookResult = ReturnType<typeof useGetCostLocationsLazyQuery>;
export type GetCostLocationsQueryResult = Apollo.QueryResult<GetCostLocationsQuery, GetCostLocationsQueryVariables>;
export function refetchGetCostLocationsQuery(variables?: GetCostLocationsQueryVariables) {
      return { query: GetCostLocationsDocument, variables: variables }
    }
export const UpdateCostLocationDocument = gql`
    mutation updateCostLocation($data: CostLocationUpdateDtoInput!) {
  updateCostLocation(data: $data) {
    id
    name
  }
}
    `;
export type UpdateCostLocationMutationFn = Apollo.MutationFunction<UpdateCostLocationMutation, UpdateCostLocationMutationVariables>;

/**
 * __useUpdateCostLocationMutation__
 *
 * To run a mutation, you first call `useUpdateCostLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostLocationMutation, { data, loading, error }] = useUpdateCostLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCostLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCostLocationMutation, UpdateCostLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCostLocationMutation, UpdateCostLocationMutationVariables>(UpdateCostLocationDocument, options);
      }
export type UpdateCostLocationMutationHookResult = ReturnType<typeof useUpdateCostLocationMutation>;
export type UpdateCostLocationMutationResult = Apollo.MutationResult<UpdateCostLocationMutation>;
export type UpdateCostLocationMutationOptions = Apollo.BaseMutationOptions<UpdateCostLocationMutation, UpdateCostLocationMutationVariables>;
export const DeleteFileDocument = gql`
    mutation deleteFile($data: FileDeleteDtoInput!) {
  deleteFile(data: $data)
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;
export const GetSignedWorkOrderPdFsDocument = gql`
    query getSignedWorkOrderPDFs($workOrderId: Int!) {
  file(
    where: {workOrderId: {eq: $workOrderId}, fileType: {eq: SIGNED_WORK_ORDER}}
  ) {
    id
    fileType
    workOrderId
    userId
    contentType
    fileName
    notes
    workOrderItemId
  }
}
    `;

/**
 * __useGetSignedWorkOrderPdFsQuery__
 *
 * To run a query within a React component, call `useGetSignedWorkOrderPdFsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedWorkOrderPdFsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedWorkOrderPdFsQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *   },
 * });
 */
export function useGetSignedWorkOrderPdFsQuery(baseOptions: Apollo.QueryHookOptions<GetSignedWorkOrderPdFsQuery, GetSignedWorkOrderPdFsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignedWorkOrderPdFsQuery, GetSignedWorkOrderPdFsQueryVariables>(GetSignedWorkOrderPdFsDocument, options);
      }
export function useGetSignedWorkOrderPdFsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignedWorkOrderPdFsQuery, GetSignedWorkOrderPdFsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignedWorkOrderPdFsQuery, GetSignedWorkOrderPdFsQueryVariables>(GetSignedWorkOrderPdFsDocument, options);
        }
export type GetSignedWorkOrderPdFsQueryHookResult = ReturnType<typeof useGetSignedWorkOrderPdFsQuery>;
export type GetSignedWorkOrderPdFsLazyQueryHookResult = ReturnType<typeof useGetSignedWorkOrderPdFsLazyQuery>;
export type GetSignedWorkOrderPdFsQueryResult = Apollo.QueryResult<GetSignedWorkOrderPdFsQuery, GetSignedWorkOrderPdFsQueryVariables>;
export function refetchGetSignedWorkOrderPdFsQuery(variables: GetSignedWorkOrderPdFsQueryVariables) {
      return { query: GetSignedWorkOrderPdFsDocument, variables: variables }
    }
export const GetUnsignedWorkOrderPdFsDocument = gql`
    query getUnsignedWorkOrderPDFs($workOrderId: Int!) {
  files(
    where: {workOrderId: {eq: $workOrderId}, fileType: {eq: WORK_ORDER}}
    order: {createdAt: DESC}
  ) {
    items {
      id
      fileType
      workOrderId
      userId
      contentType
      fileName
      notes
      workOrderItemId
      createdAt
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetUnsignedWorkOrderPdFsQuery__
 *
 * To run a query within a React component, call `useGetUnsignedWorkOrderPdFsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnsignedWorkOrderPdFsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnsignedWorkOrderPdFsQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *   },
 * });
 */
export function useGetUnsignedWorkOrderPdFsQuery(baseOptions: Apollo.QueryHookOptions<GetUnsignedWorkOrderPdFsQuery, GetUnsignedWorkOrderPdFsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnsignedWorkOrderPdFsQuery, GetUnsignedWorkOrderPdFsQueryVariables>(GetUnsignedWorkOrderPdFsDocument, options);
      }
export function useGetUnsignedWorkOrderPdFsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnsignedWorkOrderPdFsQuery, GetUnsignedWorkOrderPdFsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnsignedWorkOrderPdFsQuery, GetUnsignedWorkOrderPdFsQueryVariables>(GetUnsignedWorkOrderPdFsDocument, options);
        }
export type GetUnsignedWorkOrderPdFsQueryHookResult = ReturnType<typeof useGetUnsignedWorkOrderPdFsQuery>;
export type GetUnsignedWorkOrderPdFsLazyQueryHookResult = ReturnType<typeof useGetUnsignedWorkOrderPdFsLazyQuery>;
export type GetUnsignedWorkOrderPdFsQueryResult = Apollo.QueryResult<GetUnsignedWorkOrderPdFsQuery, GetUnsignedWorkOrderPdFsQueryVariables>;
export function refetchGetUnsignedWorkOrderPdFsQuery(variables: GetUnsignedWorkOrderPdFsQueryVariables) {
      return { query: GetUnsignedWorkOrderPdFsDocument, variables: variables }
    }
export const GetWorkOrderRelatedFilesDocument = gql`
    query getWorkOrderRelatedFiles($workOrderId: Int!, $where: FileReturnDtoFilterInput, $take: Int, $skip: Int, $order: [FileReturnDtoSortInput!]) {
  workOrderRelatedFiles(
    workOrderId: $workOrderId
    where: $where
    take: $take
    skip: $skip
    order: $order
  ) {
    items {
      id
      workOrderId
      fileName
      fileType
      notes
      contentType
      includeInPDF
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetWorkOrderRelatedFilesQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderRelatedFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderRelatedFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderRelatedFilesQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetWorkOrderRelatedFilesQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrderRelatedFilesQuery, GetWorkOrderRelatedFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrderRelatedFilesQuery, GetWorkOrderRelatedFilesQueryVariables>(GetWorkOrderRelatedFilesDocument, options);
      }
export function useGetWorkOrderRelatedFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderRelatedFilesQuery, GetWorkOrderRelatedFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrderRelatedFilesQuery, GetWorkOrderRelatedFilesQueryVariables>(GetWorkOrderRelatedFilesDocument, options);
        }
export type GetWorkOrderRelatedFilesQueryHookResult = ReturnType<typeof useGetWorkOrderRelatedFilesQuery>;
export type GetWorkOrderRelatedFilesLazyQueryHookResult = ReturnType<typeof useGetWorkOrderRelatedFilesLazyQuery>;
export type GetWorkOrderRelatedFilesQueryResult = Apollo.QueryResult<GetWorkOrderRelatedFilesQuery, GetWorkOrderRelatedFilesQueryVariables>;
export function refetchGetWorkOrderRelatedFilesQuery(variables: GetWorkOrderRelatedFilesQueryVariables) {
      return { query: GetWorkOrderRelatedFilesDocument, variables: variables }
    }
export const SendPdfFileDocument = gql`
    mutation sendPDFFile($dto: SendPDFFilesDtoInput!) {
  sendPDFFile(dto: $dto)
}
    `;
export type SendPdfFileMutationFn = Apollo.MutationFunction<SendPdfFileMutation, SendPdfFileMutationVariables>;

/**
 * __useSendPdfFileMutation__
 *
 * To run a mutation, you first call `useSendPdfFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPdfFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPdfFileMutation, { data, loading, error }] = useSendPdfFileMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSendPdfFileMutation(baseOptions?: Apollo.MutationHookOptions<SendPdfFileMutation, SendPdfFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPdfFileMutation, SendPdfFileMutationVariables>(SendPdfFileDocument, options);
      }
export type SendPdfFileMutationHookResult = ReturnType<typeof useSendPdfFileMutation>;
export type SendPdfFileMutationResult = Apollo.MutationResult<SendPdfFileMutation>;
export type SendPdfFileMutationOptions = Apollo.BaseMutationOptions<SendPdfFileMutation, SendPdfFileMutationVariables>;
export const ToggleFileIncludeInPdfDocument = gql`
    mutation toggleFileIncludeInPDF($fileId: Int!) {
  toggleFileIncludeInPDF(fileId: $fileId) {
    id
    includeInPDF
  }
}
    `;
export type ToggleFileIncludeInPdfMutationFn = Apollo.MutationFunction<ToggleFileIncludeInPdfMutation, ToggleFileIncludeInPdfMutationVariables>;

/**
 * __useToggleFileIncludeInPdfMutation__
 *
 * To run a mutation, you first call `useToggleFileIncludeInPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleFileIncludeInPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleFileIncludeInPdfMutation, { data, loading, error }] = useToggleFileIncludeInPdfMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useToggleFileIncludeInPdfMutation(baseOptions?: Apollo.MutationHookOptions<ToggleFileIncludeInPdfMutation, ToggleFileIncludeInPdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleFileIncludeInPdfMutation, ToggleFileIncludeInPdfMutationVariables>(ToggleFileIncludeInPdfDocument, options);
      }
export type ToggleFileIncludeInPdfMutationHookResult = ReturnType<typeof useToggleFileIncludeInPdfMutation>;
export type ToggleFileIncludeInPdfMutationResult = Apollo.MutationResult<ToggleFileIncludeInPdfMutation>;
export type ToggleFileIncludeInPdfMutationOptions = Apollo.BaseMutationOptions<ToggleFileIncludeInPdfMutation, ToggleFileIncludeInPdfMutationVariables>;
export const UpdateFileDocument = gql`
    mutation updateFile($data: FileUpdateDtoInput!) {
  updateFile(data: $data) {
    id
  }
}
    `;
export type UpdateFileMutationFn = Apollo.MutationFunction<UpdateFileMutation, UpdateFileMutationVariables>;

/**
 * __useUpdateFileMutation__
 *
 * To run a mutation, you first call `useUpdateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileMutation, { data, loading, error }] = useUpdateFileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFileMutation, UpdateFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFileMutation, UpdateFileMutationVariables>(UpdateFileDocument, options);
      }
export type UpdateFileMutationHookResult = ReturnType<typeof useUpdateFileMutation>;
export type UpdateFileMutationResult = Apollo.MutationResult<UpdateFileMutation>;
export type UpdateFileMutationOptions = Apollo.BaseMutationOptions<UpdateFileMutation, UpdateFileMutationVariables>;
export const CreateFinancialAccountDocument = gql`
    mutation createFinancialAccount($data: FinancialAccountCreateDtoInput!) {
  createFinancialAccount(data: $data) {
    id
  }
}
    `;
export type CreateFinancialAccountMutationFn = Apollo.MutationFunction<CreateFinancialAccountMutation, CreateFinancialAccountMutationVariables>;

/**
 * __useCreateFinancialAccountMutation__
 *
 * To run a mutation, you first call `useCreateFinancialAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFinancialAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFinancialAccountMutation, { data, loading, error }] = useCreateFinancialAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFinancialAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateFinancialAccountMutation, CreateFinancialAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFinancialAccountMutation, CreateFinancialAccountMutationVariables>(CreateFinancialAccountDocument, options);
      }
export type CreateFinancialAccountMutationHookResult = ReturnType<typeof useCreateFinancialAccountMutation>;
export type CreateFinancialAccountMutationResult = Apollo.MutationResult<CreateFinancialAccountMutation>;
export type CreateFinancialAccountMutationOptions = Apollo.BaseMutationOptions<CreateFinancialAccountMutation, CreateFinancialAccountMutationVariables>;
export const DeleteFinancialAccountDocument = gql`
    mutation deleteFinancialAccount($data: FinancialAccountDeleteDtoInput!) {
  deleteFinancialAccount(data: $data)
}
    `;
export type DeleteFinancialAccountMutationFn = Apollo.MutationFunction<DeleteFinancialAccountMutation, DeleteFinancialAccountMutationVariables>;

/**
 * __useDeleteFinancialAccountMutation__
 *
 * To run a mutation, you first call `useDeleteFinancialAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFinancialAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFinancialAccountMutation, { data, loading, error }] = useDeleteFinancialAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteFinancialAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFinancialAccountMutation, DeleteFinancialAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFinancialAccountMutation, DeleteFinancialAccountMutationVariables>(DeleteFinancialAccountDocument, options);
      }
export type DeleteFinancialAccountMutationHookResult = ReturnType<typeof useDeleteFinancialAccountMutation>;
export type DeleteFinancialAccountMutationResult = Apollo.MutationResult<DeleteFinancialAccountMutation>;
export type DeleteFinancialAccountMutationOptions = Apollo.BaseMutationOptions<DeleteFinancialAccountMutation, DeleteFinancialAccountMutationVariables>;
export const GetFinancialAccountsDocument = gql`
    query getFinancialAccounts($where: FinancialAccountReturnDtoFilterInput, $take: Int, $skip: Int, $order: [FinancialAccountReturnDtoSortInput!]) {
  financialAccounts(where: $where, take: $take, skip: $skip, order: $order) {
    items {
      id
      name
      description
      number
      externalId
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetFinancialAccountsQuery__
 *
 * To run a query within a React component, call `useGetFinancialAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialAccountsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetFinancialAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetFinancialAccountsQuery, GetFinancialAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFinancialAccountsQuery, GetFinancialAccountsQueryVariables>(GetFinancialAccountsDocument, options);
      }
export function useGetFinancialAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinancialAccountsQuery, GetFinancialAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFinancialAccountsQuery, GetFinancialAccountsQueryVariables>(GetFinancialAccountsDocument, options);
        }
export type GetFinancialAccountsQueryHookResult = ReturnType<typeof useGetFinancialAccountsQuery>;
export type GetFinancialAccountsLazyQueryHookResult = ReturnType<typeof useGetFinancialAccountsLazyQuery>;
export type GetFinancialAccountsQueryResult = Apollo.QueryResult<GetFinancialAccountsQuery, GetFinancialAccountsQueryVariables>;
export function refetchGetFinancialAccountsQuery(variables?: GetFinancialAccountsQueryVariables) {
      return { query: GetFinancialAccountsDocument, variables: variables }
    }
export const UpdateFinancialAccountDocument = gql`
    mutation updateFinancialAccount($data: FinancialAccountUpdateDtoInput!) {
  updateFinancialAccount(data: $data) {
    id
    name
  }
}
    `;
export type UpdateFinancialAccountMutationFn = Apollo.MutationFunction<UpdateFinancialAccountMutation, UpdateFinancialAccountMutationVariables>;

/**
 * __useUpdateFinancialAccountMutation__
 *
 * To run a mutation, you first call `useUpdateFinancialAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFinancialAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFinancialAccountMutation, { data, loading, error }] = useUpdateFinancialAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFinancialAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFinancialAccountMutation, UpdateFinancialAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFinancialAccountMutation, UpdateFinancialAccountMutationVariables>(UpdateFinancialAccountDocument, options);
      }
export type UpdateFinancialAccountMutationHookResult = ReturnType<typeof useUpdateFinancialAccountMutation>;
export type UpdateFinancialAccountMutationResult = Apollo.MutationResult<UpdateFinancialAccountMutation>;
export type UpdateFinancialAccountMutationOptions = Apollo.BaseMutationOptions<UpdateFinancialAccountMutation, UpdateFinancialAccountMutationVariables>;
export const GeocodeAddressDocument = gql`
    query geocodeAddress($address: String!) {
  geocodeAddress(address: $address) {
    lat
    lng
  }
}
    `;

/**
 * __useGeocodeAddressQuery__
 *
 * To run a query within a React component, call `useGeocodeAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeocodeAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeocodeAddressQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useGeocodeAddressQuery(baseOptions: Apollo.QueryHookOptions<GeocodeAddressQuery, GeocodeAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GeocodeAddressQuery, GeocodeAddressQueryVariables>(GeocodeAddressDocument, options);
      }
export function useGeocodeAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeocodeAddressQuery, GeocodeAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GeocodeAddressQuery, GeocodeAddressQueryVariables>(GeocodeAddressDocument, options);
        }
export type GeocodeAddressQueryHookResult = ReturnType<typeof useGeocodeAddressQuery>;
export type GeocodeAddressLazyQueryHookResult = ReturnType<typeof useGeocodeAddressLazyQuery>;
export type GeocodeAddressQueryResult = Apollo.QueryResult<GeocodeAddressQuery, GeocodeAddressQueryVariables>;
export function refetchGeocodeAddressQuery(variables: GeocodeAddressQueryVariables) {
      return { query: GeocodeAddressDocument, variables: variables }
    }
export const CreateItemDocument = gql`
    mutation createItem($data: ItemCreateDtoInput!) {
  createItem(data: $data) {
    id
  }
}
    `;
export type CreateItemMutationFn = Apollo.MutationFunction<CreateItemMutation, CreateItemMutationVariables>;

/**
 * __useCreateItemMutation__
 *
 * To run a mutation, you first call `useCreateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemMutation, { data, loading, error }] = useCreateItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateItemMutation, CreateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateItemMutation, CreateItemMutationVariables>(CreateItemDocument, options);
      }
export type CreateItemMutationHookResult = ReturnType<typeof useCreateItemMutation>;
export type CreateItemMutationResult = Apollo.MutationResult<CreateItemMutation>;
export type CreateItemMutationOptions = Apollo.BaseMutationOptions<CreateItemMutation, CreateItemMutationVariables>;
export const DeleteItemDocument = gql`
    mutation deleteItem($data: ItemDeleteDtoInput!) {
  deleteItem(data: $data)
}
    `;
export type DeleteItemMutationFn = Apollo.MutationFunction<DeleteItemMutation, DeleteItemMutationVariables>;

/**
 * __useDeleteItemMutation__
 *
 * To run a mutation, you first call `useDeleteItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemMutation, { data, loading, error }] = useDeleteItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteItemMutation, DeleteItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteItemMutation, DeleteItemMutationVariables>(DeleteItemDocument, options);
      }
export type DeleteItemMutationHookResult = ReturnType<typeof useDeleteItemMutation>;
export type DeleteItemMutationResult = Apollo.MutationResult<DeleteItemMutation>;
export type DeleteItemMutationOptions = Apollo.BaseMutationOptions<DeleteItemMutation, DeleteItemMutationVariables>;
export const GetItemsDocument = gql`
    query getItems($where: ItemReturnDtoFilterInput, $take: Int, $skip: Int, $order: [ItemReturnDtoSortInput!], $isDeleted: Boolean) {
  items(
    where: $where
    take: $take
    skip: $skip
    order: $order
    isDeleted: $isDeleted
  ) {
    items {
      id
      name
      description
      isDeleted
      unitOfMeasureId
      unitOfMeasure {
        name
        symbol
      }
      unitPrice
      unitsContained
      itemType
      rentalServiceItemId
      rentalServiceItem {
        id
        name
        description
        unitsContained
        unitPrice
        unitOfMeasureId
      }
      rentalThresholdDays
      externalId
      vatTypeId
      vatType {
        id
        name
        description
        rate
      }
      financialAccountId
      financialAccount {
        id
        name
        description
        number
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetItemsQuery__
 *
 * To run a query within a React component, call `useGetItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      isDeleted: // value for 'isDeleted'
 *   },
 * });
 */
export function useGetItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetItemsQuery, GetItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemsQuery, GetItemsQueryVariables>(GetItemsDocument, options);
      }
export function useGetItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemsQuery, GetItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemsQuery, GetItemsQueryVariables>(GetItemsDocument, options);
        }
export type GetItemsQueryHookResult = ReturnType<typeof useGetItemsQuery>;
export type GetItemsLazyQueryHookResult = ReturnType<typeof useGetItemsLazyQuery>;
export type GetItemsQueryResult = Apollo.QueryResult<GetItemsQuery, GetItemsQueryVariables>;
export function refetchGetItemsQuery(variables?: GetItemsQueryVariables) {
      return { query: GetItemsDocument, variables: variables }
    }
export const RestoreDeletedItemDocument = gql`
    mutation restoreDeletedItem($dto: RestoreDeletedItemDtoInput!) {
  restoreDeletedItem(dto: $dto) {
    id
  }
}
    `;
export type RestoreDeletedItemMutationFn = Apollo.MutationFunction<RestoreDeletedItemMutation, RestoreDeletedItemMutationVariables>;

/**
 * __useRestoreDeletedItemMutation__
 *
 * To run a mutation, you first call `useRestoreDeletedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreDeletedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreDeletedItemMutation, { data, loading, error }] = useRestoreDeletedItemMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useRestoreDeletedItemMutation(baseOptions?: Apollo.MutationHookOptions<RestoreDeletedItemMutation, RestoreDeletedItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreDeletedItemMutation, RestoreDeletedItemMutationVariables>(RestoreDeletedItemDocument, options);
      }
export type RestoreDeletedItemMutationHookResult = ReturnType<typeof useRestoreDeletedItemMutation>;
export type RestoreDeletedItemMutationResult = Apollo.MutationResult<RestoreDeletedItemMutation>;
export type RestoreDeletedItemMutationOptions = Apollo.BaseMutationOptions<RestoreDeletedItemMutation, RestoreDeletedItemMutationVariables>;
export const UpdateItemDocument = gql`
    mutation updateItem($data: ItemUpdateDtoInput!) {
  updateItem(data: $data) {
    id
    name
  }
}
    `;
export type UpdateItemMutationFn = Apollo.MutationFunction<UpdateItemMutation, UpdateItemMutationVariables>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument, options);
      }
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = Apollo.MutationResult<UpdateItemMutation>;
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<UpdateItemMutation, UpdateItemMutationVariables>;
export const CreateOrganizationalEntityDocument = gql`
    mutation createOrganizationalEntity($data: OrganizationalEntityCreateDtoInput!) {
  createOrganizationalEntity(data: $data) {
    id
  }
}
    `;
export type CreateOrganizationalEntityMutationFn = Apollo.MutationFunction<CreateOrganizationalEntityMutation, CreateOrganizationalEntityMutationVariables>;

/**
 * __useCreateOrganizationalEntityMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationalEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationalEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationalEntityMutation, { data, loading, error }] = useCreateOrganizationalEntityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrganizationalEntityMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationalEntityMutation, CreateOrganizationalEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationalEntityMutation, CreateOrganizationalEntityMutationVariables>(CreateOrganizationalEntityDocument, options);
      }
export type CreateOrganizationalEntityMutationHookResult = ReturnType<typeof useCreateOrganizationalEntityMutation>;
export type CreateOrganizationalEntityMutationResult = Apollo.MutationResult<CreateOrganizationalEntityMutation>;
export type CreateOrganizationalEntityMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationalEntityMutation, CreateOrganizationalEntityMutationVariables>;
export const DeleteOrganizationalEntityDocument = gql`
    mutation deleteOrganizationalEntity($data: OrganizationalEntityDeleteDtoInput!) {
  deleteOrganizationalEntity(data: $data)
}
    `;
export type DeleteOrganizationalEntityMutationFn = Apollo.MutationFunction<DeleteOrganizationalEntityMutation, DeleteOrganizationalEntityMutationVariables>;

/**
 * __useDeleteOrganizationalEntityMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationalEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationalEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationalEntityMutation, { data, loading, error }] = useDeleteOrganizationalEntityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteOrganizationalEntityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationalEntityMutation, DeleteOrganizationalEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationalEntityMutation, DeleteOrganizationalEntityMutationVariables>(DeleteOrganizationalEntityDocument, options);
      }
export type DeleteOrganizationalEntityMutationHookResult = ReturnType<typeof useDeleteOrganizationalEntityMutation>;
export type DeleteOrganizationalEntityMutationResult = Apollo.MutationResult<DeleteOrganizationalEntityMutation>;
export type DeleteOrganizationalEntityMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationalEntityMutation, DeleteOrganizationalEntityMutationVariables>;
export const GetAllOrganizationalEntitiesDocument = gql`
    query getAllOrganizationalEntities {
  wholeOrganizationalEntitiesTree {
    id
    name
    description
    parentId
  }
}
    `;

/**
 * __useGetAllOrganizationalEntitiesQuery__
 *
 * To run a query within a React component, call `useGetAllOrganizationalEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrganizationalEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrganizationalEntitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllOrganizationalEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllOrganizationalEntitiesQuery, GetAllOrganizationalEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOrganizationalEntitiesQuery, GetAllOrganizationalEntitiesQueryVariables>(GetAllOrganizationalEntitiesDocument, options);
      }
export function useGetAllOrganizationalEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOrganizationalEntitiesQuery, GetAllOrganizationalEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOrganizationalEntitiesQuery, GetAllOrganizationalEntitiesQueryVariables>(GetAllOrganizationalEntitiesDocument, options);
        }
export type GetAllOrganizationalEntitiesQueryHookResult = ReturnType<typeof useGetAllOrganizationalEntitiesQuery>;
export type GetAllOrganizationalEntitiesLazyQueryHookResult = ReturnType<typeof useGetAllOrganizationalEntitiesLazyQuery>;
export type GetAllOrganizationalEntitiesQueryResult = Apollo.QueryResult<GetAllOrganizationalEntitiesQuery, GetAllOrganizationalEntitiesQueryVariables>;
export function refetchGetAllOrganizationalEntitiesQuery(variables?: GetAllOrganizationalEntitiesQueryVariables) {
      return { query: GetAllOrganizationalEntitiesDocument, variables: variables }
    }
export const GetDefaultOrganizationalEntityDocument = gql`
    query getDefaultOrganizationalEntity {
  defaultOrganizationalEntity {
    id
    name
    description
    isDefault
  }
}
    `;

/**
 * __useGetDefaultOrganizationalEntityQuery__
 *
 * To run a query within a React component, call `useGetDefaultOrganizationalEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultOrganizationalEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultOrganizationalEntityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultOrganizationalEntityQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultOrganizationalEntityQuery, GetDefaultOrganizationalEntityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultOrganizationalEntityQuery, GetDefaultOrganizationalEntityQueryVariables>(GetDefaultOrganizationalEntityDocument, options);
      }
export function useGetDefaultOrganizationalEntityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultOrganizationalEntityQuery, GetDefaultOrganizationalEntityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultOrganizationalEntityQuery, GetDefaultOrganizationalEntityQueryVariables>(GetDefaultOrganizationalEntityDocument, options);
        }
export type GetDefaultOrganizationalEntityQueryHookResult = ReturnType<typeof useGetDefaultOrganizationalEntityQuery>;
export type GetDefaultOrganizationalEntityLazyQueryHookResult = ReturnType<typeof useGetDefaultOrganizationalEntityLazyQuery>;
export type GetDefaultOrganizationalEntityQueryResult = Apollo.QueryResult<GetDefaultOrganizationalEntityQuery, GetDefaultOrganizationalEntityQueryVariables>;
export function refetchGetDefaultOrganizationalEntityQuery(variables?: GetDefaultOrganizationalEntityQueryVariables) {
      return { query: GetDefaultOrganizationalEntityDocument, variables: variables }
    }
export const GetOrganizationalEntitiesDocument = gql`
    query getOrganizationalEntities($where: OrganizationalEntityReturnDtoFilterInput, $take: Int, $skip: Int, $order: [OrganizationalEntityReturnDtoSortInput!]) {
  organizationalEntities(where: $where, take: $take, skip: $skip, order: $order) {
    items {
      id
      name
      isDefault
      description
      parentId
      parent {
        id
        name
        description
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetOrganizationalEntitiesQuery__
 *
 * To run a query within a React component, call `useGetOrganizationalEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationalEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationalEntitiesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetOrganizationalEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationalEntitiesQuery, GetOrganizationalEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationalEntitiesQuery, GetOrganizationalEntitiesQueryVariables>(GetOrganizationalEntitiesDocument, options);
      }
export function useGetOrganizationalEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationalEntitiesQuery, GetOrganizationalEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationalEntitiesQuery, GetOrganizationalEntitiesQueryVariables>(GetOrganizationalEntitiesDocument, options);
        }
export type GetOrganizationalEntitiesQueryHookResult = ReturnType<typeof useGetOrganizationalEntitiesQuery>;
export type GetOrganizationalEntitiesLazyQueryHookResult = ReturnType<typeof useGetOrganizationalEntitiesLazyQuery>;
export type GetOrganizationalEntitiesQueryResult = Apollo.QueryResult<GetOrganizationalEntitiesQuery, GetOrganizationalEntitiesQueryVariables>;
export function refetchGetOrganizationalEntitiesQuery(variables?: GetOrganizationalEntitiesQueryVariables) {
      return { query: GetOrganizationalEntitiesDocument, variables: variables }
    }
export const UpdateOrganizationalEntityDocument = gql`
    mutation updateOrganizationalEntity($data: OrganizationalEntityUpdateDtoInput!) {
  updateOrganizationalEntity(data: $data) {
    id
    name
  }
}
    `;
export type UpdateOrganizationalEntityMutationFn = Apollo.MutationFunction<UpdateOrganizationalEntityMutation, UpdateOrganizationalEntityMutationVariables>;

/**
 * __useUpdateOrganizationalEntityMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationalEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationalEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationalEntityMutation, { data, loading, error }] = useUpdateOrganizationalEntityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrganizationalEntityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationalEntityMutation, UpdateOrganizationalEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationalEntityMutation, UpdateOrganizationalEntityMutationVariables>(UpdateOrganizationalEntityDocument, options);
      }
export type UpdateOrganizationalEntityMutationHookResult = ReturnType<typeof useUpdateOrganizationalEntityMutation>;
export type UpdateOrganizationalEntityMutationResult = Apollo.MutationResult<UpdateOrganizationalEntityMutation>;
export type UpdateOrganizationalEntityMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationalEntityMutation, UpdateOrganizationalEntityMutationVariables>;
export const CreateWorkOrderPdfDocument = gql`
    mutation createWorkOrderPDF($workOrderId: Int!) {
  createWorkOrderPDF(workOrderId: $workOrderId)
}
    `;
export type CreateWorkOrderPdfMutationFn = Apollo.MutationFunction<CreateWorkOrderPdfMutation, CreateWorkOrderPdfMutationVariables>;

/**
 * __useCreateWorkOrderPdfMutation__
 *
 * To run a mutation, you first call `useCreateWorkOrderPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkOrderPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkOrderPdfMutation, { data, loading, error }] = useCreateWorkOrderPdfMutation({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *   },
 * });
 */
export function useCreateWorkOrderPdfMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkOrderPdfMutation, CreateWorkOrderPdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkOrderPdfMutation, CreateWorkOrderPdfMutationVariables>(CreateWorkOrderPdfDocument, options);
      }
export type CreateWorkOrderPdfMutationHookResult = ReturnType<typeof useCreateWorkOrderPdfMutation>;
export type CreateWorkOrderPdfMutationResult = Apollo.MutationResult<CreateWorkOrderPdfMutation>;
export type CreateWorkOrderPdfMutationOptions = Apollo.BaseMutationOptions<CreateWorkOrderPdfMutation, CreateWorkOrderPdfMutationVariables>;
export const CreatePartnerPickupRequestDocument = gql`
    mutation createPartnerPickupRequest($data: PartnerPickupRequestCreateDtoInput!) {
  createPartnerPickupRequest(data: $data) {
    id
  }
}
    `;
export type CreatePartnerPickupRequestMutationFn = Apollo.MutationFunction<CreatePartnerPickupRequestMutation, CreatePartnerPickupRequestMutationVariables>;

/**
 * __useCreatePartnerPickupRequestMutation__
 *
 * To run a mutation, you first call `useCreatePartnerPickupRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerPickupRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerPickupRequestMutation, { data, loading, error }] = useCreatePartnerPickupRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePartnerPickupRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreatePartnerPickupRequestMutation, CreatePartnerPickupRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePartnerPickupRequestMutation, CreatePartnerPickupRequestMutationVariables>(CreatePartnerPickupRequestDocument, options);
      }
export type CreatePartnerPickupRequestMutationHookResult = ReturnType<typeof useCreatePartnerPickupRequestMutation>;
export type CreatePartnerPickupRequestMutationResult = Apollo.MutationResult<CreatePartnerPickupRequestMutation>;
export type CreatePartnerPickupRequestMutationOptions = Apollo.BaseMutationOptions<CreatePartnerPickupRequestMutation, CreatePartnerPickupRequestMutationVariables>;
export const DeletePartnerPickupRequestDocument = gql`
    mutation deletePartnerPickupRequest($data: PartnerPickupRequestDeleteDtoInput!) {
  deletePartnerPickupRequest(data: $data)
}
    `;
export type DeletePartnerPickupRequestMutationFn = Apollo.MutationFunction<DeletePartnerPickupRequestMutation, DeletePartnerPickupRequestMutationVariables>;

/**
 * __useDeletePartnerPickupRequestMutation__
 *
 * To run a mutation, you first call `useDeletePartnerPickupRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartnerPickupRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnerPickupRequestMutation, { data, loading, error }] = useDeletePartnerPickupRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeletePartnerPickupRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeletePartnerPickupRequestMutation, DeletePartnerPickupRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePartnerPickupRequestMutation, DeletePartnerPickupRequestMutationVariables>(DeletePartnerPickupRequestDocument, options);
      }
export type DeletePartnerPickupRequestMutationHookResult = ReturnType<typeof useDeletePartnerPickupRequestMutation>;
export type DeletePartnerPickupRequestMutationResult = Apollo.MutationResult<DeletePartnerPickupRequestMutation>;
export type DeletePartnerPickupRequestMutationOptions = Apollo.BaseMutationOptions<DeletePartnerPickupRequestMutation, DeletePartnerPickupRequestMutationVariables>;
export const GetPartnerPickupRequestsDocument = gql`
    query getPartnerPickupRequests($where: PartnerPickupRequestReturnDtoFilterInput, $take: Int, $skip: Int, $order: [PartnerPickupRequestReturnDtoSortInput!]) {
  partnerPickupRequests(where: $where, take: $take, skip: $skip, order: $order) {
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
    items {
      id
      isAssigned
      reportingUser {
        userName
      }
      serviceNote
      locationNote
      pickupDate
      companyLocationId
      companyLocation {
        id
        name
        address
        location {
          longitude
          latitude
        }
        isMainLocation
      }
      workOrder {
        currentStatus {
          status
        }
      }
    }
  }
}
    `;

/**
 * __useGetPartnerPickupRequestsQuery__
 *
 * To run a query within a React component, call `useGetPartnerPickupRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerPickupRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerPickupRequestsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetPartnerPickupRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnerPickupRequestsQuery, GetPartnerPickupRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnerPickupRequestsQuery, GetPartnerPickupRequestsQueryVariables>(GetPartnerPickupRequestsDocument, options);
      }
export function useGetPartnerPickupRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerPickupRequestsQuery, GetPartnerPickupRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnerPickupRequestsQuery, GetPartnerPickupRequestsQueryVariables>(GetPartnerPickupRequestsDocument, options);
        }
export type GetPartnerPickupRequestsQueryHookResult = ReturnType<typeof useGetPartnerPickupRequestsQuery>;
export type GetPartnerPickupRequestsLazyQueryHookResult = ReturnType<typeof useGetPartnerPickupRequestsLazyQuery>;
export type GetPartnerPickupRequestsQueryResult = Apollo.QueryResult<GetPartnerPickupRequestsQuery, GetPartnerPickupRequestsQueryVariables>;
export function refetchGetPartnerPickupRequestsQuery(variables?: GetPartnerPickupRequestsQueryVariables) {
      return { query: GetPartnerPickupRequestsDocument, variables: variables }
    }
export const GetPartnerPickupRequestsFilteredDocument = gql`
    query getPartnerPickupRequestsFiltered($where: PartnerPickupRequestFilteredReturnDtoFilterInput, $take: Int, $skip: Int, $order: [PartnerPickupRequestFilteredReturnDtoSortInput!], $filters: PartnerPickupRequestsFilterDtoInput!) {
  partnerPickupRequestsFiltered(
    where: $where
    take: $take
    skip: $skip
    order: $order
    filters: $filters
  ) {
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
    items {
      id
      companyLocationId
      companyLocation {
        name
        address
      }
    }
  }
}
    `;

/**
 * __useGetPartnerPickupRequestsFilteredQuery__
 *
 * To run a query within a React component, call `useGetPartnerPickupRequestsFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerPickupRequestsFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerPickupRequestsFilteredQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPartnerPickupRequestsFilteredQuery(baseOptions: Apollo.QueryHookOptions<GetPartnerPickupRequestsFilteredQuery, GetPartnerPickupRequestsFilteredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnerPickupRequestsFilteredQuery, GetPartnerPickupRequestsFilteredQueryVariables>(GetPartnerPickupRequestsFilteredDocument, options);
      }
export function useGetPartnerPickupRequestsFilteredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerPickupRequestsFilteredQuery, GetPartnerPickupRequestsFilteredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnerPickupRequestsFilteredQuery, GetPartnerPickupRequestsFilteredQueryVariables>(GetPartnerPickupRequestsFilteredDocument, options);
        }
export type GetPartnerPickupRequestsFilteredQueryHookResult = ReturnType<typeof useGetPartnerPickupRequestsFilteredQuery>;
export type GetPartnerPickupRequestsFilteredLazyQueryHookResult = ReturnType<typeof useGetPartnerPickupRequestsFilteredLazyQuery>;
export type GetPartnerPickupRequestsFilteredQueryResult = Apollo.QueryResult<GetPartnerPickupRequestsFilteredQuery, GetPartnerPickupRequestsFilteredQueryVariables>;
export function refetchGetPartnerPickupRequestsFilteredQuery(variables: GetPartnerPickupRequestsFilteredQueryVariables) {
      return { query: GetPartnerPickupRequestsFilteredDocument, variables: variables }
    }
export const UpdatePartnerPickupRequestDocument = gql`
    mutation updatePartnerPickupRequest($data: PartnerPickupRequestUpdateDtoInput!) {
  updatePartnerPickupRequest(data: $data) {
    id
  }
}
    `;
export type UpdatePartnerPickupRequestMutationFn = Apollo.MutationFunction<UpdatePartnerPickupRequestMutation, UpdatePartnerPickupRequestMutationVariables>;

/**
 * __useUpdatePartnerPickupRequestMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerPickupRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerPickupRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerPickupRequestMutation, { data, loading, error }] = useUpdatePartnerPickupRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePartnerPickupRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartnerPickupRequestMutation, UpdatePartnerPickupRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartnerPickupRequestMutation, UpdatePartnerPickupRequestMutationVariables>(UpdatePartnerPickupRequestDocument, options);
      }
export type UpdatePartnerPickupRequestMutationHookResult = ReturnType<typeof useUpdatePartnerPickupRequestMutation>;
export type UpdatePartnerPickupRequestMutationResult = Apollo.MutationResult<UpdatePartnerPickupRequestMutation>;
export type UpdatePartnerPickupRequestMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerPickupRequestMutation, UpdatePartnerPickupRequestMutationVariables>;
export const CreatePartnerPortalNoteDocument = gql`
    mutation createPartnerPortalNote($data: PartnerPortalNoteCreateDtoInput!) {
  createPartnerPortalNote(data: $data) {
    id
  }
}
    `;
export type CreatePartnerPortalNoteMutationFn = Apollo.MutationFunction<CreatePartnerPortalNoteMutation, CreatePartnerPortalNoteMutationVariables>;

/**
 * __useCreatePartnerPortalNoteMutation__
 *
 * To run a mutation, you first call `useCreatePartnerPortalNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerPortalNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerPortalNoteMutation, { data, loading, error }] = useCreatePartnerPortalNoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePartnerPortalNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreatePartnerPortalNoteMutation, CreatePartnerPortalNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePartnerPortalNoteMutation, CreatePartnerPortalNoteMutationVariables>(CreatePartnerPortalNoteDocument, options);
      }
export type CreatePartnerPortalNoteMutationHookResult = ReturnType<typeof useCreatePartnerPortalNoteMutation>;
export type CreatePartnerPortalNoteMutationResult = Apollo.MutationResult<CreatePartnerPortalNoteMutation>;
export type CreatePartnerPortalNoteMutationOptions = Apollo.BaseMutationOptions<CreatePartnerPortalNoteMutation, CreatePartnerPortalNoteMutationVariables>;
export const DeletePartnerPortalNoteDocument = gql`
    mutation deletePartnerPortalNote($data: PartnerPortalNoteDeleteDtoInput!) {
  deletePartnerPortalNote(data: $data)
}
    `;
export type DeletePartnerPortalNoteMutationFn = Apollo.MutationFunction<DeletePartnerPortalNoteMutation, DeletePartnerPortalNoteMutationVariables>;

/**
 * __useDeletePartnerPortalNoteMutation__
 *
 * To run a mutation, you first call `useDeletePartnerPortalNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartnerPortalNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnerPortalNoteMutation, { data, loading, error }] = useDeletePartnerPortalNoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeletePartnerPortalNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeletePartnerPortalNoteMutation, DeletePartnerPortalNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePartnerPortalNoteMutation, DeletePartnerPortalNoteMutationVariables>(DeletePartnerPortalNoteDocument, options);
      }
export type DeletePartnerPortalNoteMutationHookResult = ReturnType<typeof useDeletePartnerPortalNoteMutation>;
export type DeletePartnerPortalNoteMutationResult = Apollo.MutationResult<DeletePartnerPortalNoteMutation>;
export type DeletePartnerPortalNoteMutationOptions = Apollo.BaseMutationOptions<DeletePartnerPortalNoteMutation, DeletePartnerPortalNoteMutationVariables>;
export const GetPartnerPortalNotesDocument = gql`
    query getPartnerPortalNotes($where: PartnerPortalNoteReturnDtoFilterInput, $take: Int, $skip: Int, $order: [PartnerPortalNoteReturnDtoSortInput!]) {
  partnerPortalNotes(where: $where, take: $take, skip: $skip, order: $order) {
    items {
      id
      note
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetPartnerPortalNotesQuery__
 *
 * To run a query within a React component, call `useGetPartnerPortalNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerPortalNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerPortalNotesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetPartnerPortalNotesQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnerPortalNotesQuery, GetPartnerPortalNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnerPortalNotesQuery, GetPartnerPortalNotesQueryVariables>(GetPartnerPortalNotesDocument, options);
      }
export function useGetPartnerPortalNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerPortalNotesQuery, GetPartnerPortalNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnerPortalNotesQuery, GetPartnerPortalNotesQueryVariables>(GetPartnerPortalNotesDocument, options);
        }
export type GetPartnerPortalNotesQueryHookResult = ReturnType<typeof useGetPartnerPortalNotesQuery>;
export type GetPartnerPortalNotesLazyQueryHookResult = ReturnType<typeof useGetPartnerPortalNotesLazyQuery>;
export type GetPartnerPortalNotesQueryResult = Apollo.QueryResult<GetPartnerPortalNotesQuery, GetPartnerPortalNotesQueryVariables>;
export function refetchGetPartnerPortalNotesQuery(variables?: GetPartnerPortalNotesQueryVariables) {
      return { query: GetPartnerPortalNotesDocument, variables: variables }
    }
export const UpdatePartnerPortalNoteDocument = gql`
    mutation updatePartnerPortalNote($data: PartnerPortalNoteUpdateDtoInput!) {
  updatePartnerPortalNote(data: $data) {
    id
    note
  }
}
    `;
export type UpdatePartnerPortalNoteMutationFn = Apollo.MutationFunction<UpdatePartnerPortalNoteMutation, UpdatePartnerPortalNoteMutationVariables>;

/**
 * __useUpdatePartnerPortalNoteMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerPortalNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerPortalNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerPortalNoteMutation, { data, loading, error }] = useUpdatePartnerPortalNoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePartnerPortalNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartnerPortalNoteMutation, UpdatePartnerPortalNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartnerPortalNoteMutation, UpdatePartnerPortalNoteMutationVariables>(UpdatePartnerPortalNoteDocument, options);
      }
export type UpdatePartnerPortalNoteMutationHookResult = ReturnType<typeof useUpdatePartnerPortalNoteMutation>;
export type UpdatePartnerPortalNoteMutationResult = Apollo.MutationResult<UpdatePartnerPortalNoteMutation>;
export type UpdatePartnerPortalNoteMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerPortalNoteMutation, UpdatePartnerPortalNoteMutationVariables>;
export const CreatePickupRequestDocument = gql`
    mutation createPickupRequest($data: PickupRequestCreateDtoInput!) {
  createPickupRequest(data: $data) {
    id
  }
}
    `;
export type CreatePickupRequestMutationFn = Apollo.MutationFunction<CreatePickupRequestMutation, CreatePickupRequestMutationVariables>;

/**
 * __useCreatePickupRequestMutation__
 *
 * To run a mutation, you first call `useCreatePickupRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePickupRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPickupRequestMutation, { data, loading, error }] = useCreatePickupRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePickupRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreatePickupRequestMutation, CreatePickupRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePickupRequestMutation, CreatePickupRequestMutationVariables>(CreatePickupRequestDocument, options);
      }
export type CreatePickupRequestMutationHookResult = ReturnType<typeof useCreatePickupRequestMutation>;
export type CreatePickupRequestMutationResult = Apollo.MutationResult<CreatePickupRequestMutation>;
export type CreatePickupRequestMutationOptions = Apollo.BaseMutationOptions<CreatePickupRequestMutation, CreatePickupRequestMutationVariables>;
export const DeletePickupRequestDocument = gql`
    mutation deletePickupRequest($data: PickupRequestDeleteDtoInput!) {
  deletePickupRequest(data: $data)
}
    `;
export type DeletePickupRequestMutationFn = Apollo.MutationFunction<DeletePickupRequestMutation, DeletePickupRequestMutationVariables>;

/**
 * __useDeletePickupRequestMutation__
 *
 * To run a mutation, you first call `useDeletePickupRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePickupRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePickupRequestMutation, { data, loading, error }] = useDeletePickupRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeletePickupRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeletePickupRequestMutation, DeletePickupRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePickupRequestMutation, DeletePickupRequestMutationVariables>(DeletePickupRequestDocument, options);
      }
export type DeletePickupRequestMutationHookResult = ReturnType<typeof useDeletePickupRequestMutation>;
export type DeletePickupRequestMutationResult = Apollo.MutationResult<DeletePickupRequestMutation>;
export type DeletePickupRequestMutationOptions = Apollo.BaseMutationOptions<DeletePickupRequestMutation, DeletePickupRequestMutationVariables>;
export const GetPickupRequestsDocument = gql`
    query getPickupRequests($where: PickupRequestFilteredReturnDtoFilterInput, $take: Int, $skip: Int, $order: [PickupRequestFilteredReturnDtoSortInput!]) {
  pickupRequests(where: $where, take: $take, skip: $skip, order: $order) {
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
    items {
      id
      locationNote
      serviceNote
      pickupDate
      reportingUser {
        id
        userName
      }
      companyLocationId
      companyLocation {
        id
        name
        address
      }
      companyId
      company {
        name
        taxId
      }
      workOrderId
      workOrder {
        id
        currentStatus {
          id
          workOrderId
          notes
          dateTime
          status
          initiatingUserId
          initiatingUser {
            id
            userName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetPickupRequestsQuery__
 *
 * To run a query within a React component, call `useGetPickupRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPickupRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPickupRequestsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetPickupRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetPickupRequestsQuery, GetPickupRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPickupRequestsQuery, GetPickupRequestsQueryVariables>(GetPickupRequestsDocument, options);
      }
export function useGetPickupRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPickupRequestsQuery, GetPickupRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPickupRequestsQuery, GetPickupRequestsQueryVariables>(GetPickupRequestsDocument, options);
        }
export type GetPickupRequestsQueryHookResult = ReturnType<typeof useGetPickupRequestsQuery>;
export type GetPickupRequestsLazyQueryHookResult = ReturnType<typeof useGetPickupRequestsLazyQuery>;
export type GetPickupRequestsQueryResult = Apollo.QueryResult<GetPickupRequestsQuery, GetPickupRequestsQueryVariables>;
export function refetchGetPickupRequestsQuery(variables?: GetPickupRequestsQueryVariables) {
      return { query: GetPickupRequestsDocument, variables: variables }
    }
export const GetPickupRequestsFilteredDocument = gql`
    query getPickupRequestsFiltered($where: PickupRequestFilteredReturnDtoFilterInput, $take: Int, $skip: Int, $order: [PickupRequestFilteredReturnDtoSortInput!], $filters: PickupRequestsFilterDtoInput!) {
  pickupRequestsFiltered(
    where: $where
    take: $take
    skip: $skip
    order: $order
    filters: $filters
  ) {
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
    items {
      id
      locationNote
      serviceNote
      pickupDate
      companyLocationId
      companyLocation {
        id
        name
        address
        location {
          longitude
          latitude
        }
        isMainLocation
      }
      companyId
      company {
        id
        name
        taxId
      }
      company {
        id
        name
        taxId
      }
      isAssigned
      reportingUser {
        id
        userName
      }
      workOrderId
      workOrder {
        id
        currentStatus {
          id
          dateTime
          notes
          initiatingUserId
          initiatingUser {
            id
            userName
          }
          status
        }
        pickupRequestId
      }
    }
  }
}
    `;

/**
 * __useGetPickupRequestsFilteredQuery__
 *
 * To run a query within a React component, call `useGetPickupRequestsFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPickupRequestsFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPickupRequestsFilteredQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPickupRequestsFilteredQuery(baseOptions: Apollo.QueryHookOptions<GetPickupRequestsFilteredQuery, GetPickupRequestsFilteredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPickupRequestsFilteredQuery, GetPickupRequestsFilteredQueryVariables>(GetPickupRequestsFilteredDocument, options);
      }
export function useGetPickupRequestsFilteredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPickupRequestsFilteredQuery, GetPickupRequestsFilteredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPickupRequestsFilteredQuery, GetPickupRequestsFilteredQueryVariables>(GetPickupRequestsFilteredDocument, options);
        }
export type GetPickupRequestsFilteredQueryHookResult = ReturnType<typeof useGetPickupRequestsFilteredQuery>;
export type GetPickupRequestsFilteredLazyQueryHookResult = ReturnType<typeof useGetPickupRequestsFilteredLazyQuery>;
export type GetPickupRequestsFilteredQueryResult = Apollo.QueryResult<GetPickupRequestsFilteredQuery, GetPickupRequestsFilteredQueryVariables>;
export function refetchGetPickupRequestsFilteredQuery(variables: GetPickupRequestsFilteredQueryVariables) {
      return { query: GetPickupRequestsFilteredDocument, variables: variables }
    }
export const GetUnassignedPickupRequestsCountDocument = gql`
    query getUnassignedPickupRequestsCount {
  unassignedPickupRequestCount
}
    `;

/**
 * __useGetUnassignedPickupRequestsCountQuery__
 *
 * To run a query within a React component, call `useGetUnassignedPickupRequestsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnassignedPickupRequestsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnassignedPickupRequestsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnassignedPickupRequestsCountQuery(baseOptions?: Apollo.QueryHookOptions<GetUnassignedPickupRequestsCountQuery, GetUnassignedPickupRequestsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnassignedPickupRequestsCountQuery, GetUnassignedPickupRequestsCountQueryVariables>(GetUnassignedPickupRequestsCountDocument, options);
      }
export function useGetUnassignedPickupRequestsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnassignedPickupRequestsCountQuery, GetUnassignedPickupRequestsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnassignedPickupRequestsCountQuery, GetUnassignedPickupRequestsCountQueryVariables>(GetUnassignedPickupRequestsCountDocument, options);
        }
export type GetUnassignedPickupRequestsCountQueryHookResult = ReturnType<typeof useGetUnassignedPickupRequestsCountQuery>;
export type GetUnassignedPickupRequestsCountLazyQueryHookResult = ReturnType<typeof useGetUnassignedPickupRequestsCountLazyQuery>;
export type GetUnassignedPickupRequestsCountQueryResult = Apollo.QueryResult<GetUnassignedPickupRequestsCountQuery, GetUnassignedPickupRequestsCountQueryVariables>;
export function refetchGetUnassignedPickupRequestsCountQuery(variables?: GetUnassignedPickupRequestsCountQueryVariables) {
      return { query: GetUnassignedPickupRequestsCountDocument, variables: variables }
    }
export const UnassignedPickupRequestCountChangedDocument = gql`
    subscription unassignedPickupRequestCountChanged {
  unassignedPickupRequestCountChanged
}
    `;

/**
 * __useUnassignedPickupRequestCountChangedSubscription__
 *
 * To run a query within a React component, call `useUnassignedPickupRequestCountChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUnassignedPickupRequestCountChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnassignedPickupRequestCountChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUnassignedPickupRequestCountChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UnassignedPickupRequestCountChangedSubscription, UnassignedPickupRequestCountChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UnassignedPickupRequestCountChangedSubscription, UnassignedPickupRequestCountChangedSubscriptionVariables>(UnassignedPickupRequestCountChangedDocument, options);
      }
export type UnassignedPickupRequestCountChangedSubscriptionHookResult = ReturnType<typeof useUnassignedPickupRequestCountChangedSubscription>;
export type UnassignedPickupRequestCountChangedSubscriptionResult = Apollo.SubscriptionResult<UnassignedPickupRequestCountChangedSubscription>;
export const UpdatePickupRequestDocument = gql`
    mutation updatePickupRequest($data: PickupRequestUpdateDtoInput!) {
  updatePickupRequest(data: $data) {
    id
  }
}
    `;
export type UpdatePickupRequestMutationFn = Apollo.MutationFunction<UpdatePickupRequestMutation, UpdatePickupRequestMutationVariables>;

/**
 * __useUpdatePickupRequestMutation__
 *
 * To run a mutation, you first call `useUpdatePickupRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePickupRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePickupRequestMutation, { data, loading, error }] = useUpdatePickupRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePickupRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePickupRequestMutation, UpdatePickupRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePickupRequestMutation, UpdatePickupRequestMutationVariables>(UpdatePickupRequestDocument, options);
      }
export type UpdatePickupRequestMutationHookResult = ReturnType<typeof useUpdatePickupRequestMutation>;
export type UpdatePickupRequestMutationResult = Apollo.MutationResult<UpdatePickupRequestMutation>;
export type UpdatePickupRequestMutationOptions = Apollo.BaseMutationOptions<UpdatePickupRequestMutation, UpdatePickupRequestMutationVariables>;
export const WorkOrderCountPerDayDocument = gql`
    query workOrderCountPerDay($dto: WorkOrderCountPerDayDtoInput!) {
  workOrderCountAndValuePerDay(dto: $dto) {
    key
    value {
      count
      value
    }
  }
}
    `;

/**
 * __useWorkOrderCountPerDayQuery__
 *
 * To run a query within a React component, call `useWorkOrderCountPerDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkOrderCountPerDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkOrderCountPerDayQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useWorkOrderCountPerDayQuery(baseOptions: Apollo.QueryHookOptions<WorkOrderCountPerDayQuery, WorkOrderCountPerDayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkOrderCountPerDayQuery, WorkOrderCountPerDayQueryVariables>(WorkOrderCountPerDayDocument, options);
      }
export function useWorkOrderCountPerDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkOrderCountPerDayQuery, WorkOrderCountPerDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkOrderCountPerDayQuery, WorkOrderCountPerDayQueryVariables>(WorkOrderCountPerDayDocument, options);
        }
export type WorkOrderCountPerDayQueryHookResult = ReturnType<typeof useWorkOrderCountPerDayQuery>;
export type WorkOrderCountPerDayLazyQueryHookResult = ReturnType<typeof useWorkOrderCountPerDayLazyQuery>;
export type WorkOrderCountPerDayQueryResult = Apollo.QueryResult<WorkOrderCountPerDayQuery, WorkOrderCountPerDayQueryVariables>;
export function refetchWorkOrderCountPerDayQuery(variables: WorkOrderCountPerDayQueryVariables) {
      return { query: WorkOrderCountPerDayDocument, variables: variables }
    }
export const GetWorkOrdersForReportDocument = gql`
    query getWorkOrdersForReport($dto: WorkOrderFilterDtoInput!, $order: [WorkOrderFlattenedReturnDtoSortInput!], $where: WorkOrderFlattenedReturnDtoFilterInput) {
  workOrdersForReport(dto: $dto, order: $order, where: $where) {
    id
    pickupRequestId
    pickupRequestPickupDate
    pickupRequestReportingUserUserName
    pickupRequestCompanyName
    pickupRequestCompanyTaxId
    pickupRequestCompanyLocationName
    pickupRequestCompanyLocationAddress
    scheduledDate
    startDateTime
    endDateTime
    assignedByManagerUserUserName
    assignedUserUserName
    vehicleName
    vehicleRegistrationNumber
    vehicleCostLocationName
    vehicleCostLocationCode
    organizationalEntityName
    executionCategoryName
    currentStatusStatus
    itemsTotal
  }
}
    `;

/**
 * __useGetWorkOrdersForReportQuery__
 *
 * To run a query within a React component, call `useGetWorkOrdersForReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrdersForReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrdersForReportQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetWorkOrdersForReportQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrdersForReportQuery, GetWorkOrdersForReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrdersForReportQuery, GetWorkOrdersForReportQueryVariables>(GetWorkOrdersForReportDocument, options);
      }
export function useGetWorkOrdersForReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrdersForReportQuery, GetWorkOrdersForReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrdersForReportQuery, GetWorkOrdersForReportQueryVariables>(GetWorkOrdersForReportDocument, options);
        }
export type GetWorkOrdersForReportQueryHookResult = ReturnType<typeof useGetWorkOrdersForReportQuery>;
export type GetWorkOrdersForReportLazyQueryHookResult = ReturnType<typeof useGetWorkOrdersForReportLazyQuery>;
export type GetWorkOrdersForReportQueryResult = Apollo.QueryResult<GetWorkOrdersForReportQuery, GetWorkOrdersForReportQueryVariables>;
export function refetchGetWorkOrdersForReportQuery(variables: GetWorkOrdersForReportQueryVariables) {
      return { query: GetWorkOrdersForReportDocument, variables: variables }
    }
export const CreateUnitOfMeasureDocument = gql`
    mutation createUnitOfMeasure($data: UnitOfMeasureCreateDtoInput!) {
  createUnitOfMeasure(data: $data) {
    id
  }
}
    `;
export type CreateUnitOfMeasureMutationFn = Apollo.MutationFunction<CreateUnitOfMeasureMutation, CreateUnitOfMeasureMutationVariables>;

/**
 * __useCreateUnitOfMeasureMutation__
 *
 * To run a mutation, you first call `useCreateUnitOfMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnitOfMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnitOfMeasureMutation, { data, loading, error }] = useCreateUnitOfMeasureMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUnitOfMeasureMutation(baseOptions?: Apollo.MutationHookOptions<CreateUnitOfMeasureMutation, CreateUnitOfMeasureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUnitOfMeasureMutation, CreateUnitOfMeasureMutationVariables>(CreateUnitOfMeasureDocument, options);
      }
export type CreateUnitOfMeasureMutationHookResult = ReturnType<typeof useCreateUnitOfMeasureMutation>;
export type CreateUnitOfMeasureMutationResult = Apollo.MutationResult<CreateUnitOfMeasureMutation>;
export type CreateUnitOfMeasureMutationOptions = Apollo.BaseMutationOptions<CreateUnitOfMeasureMutation, CreateUnitOfMeasureMutationVariables>;
export const DeleteUnitOfMeasureDocument = gql`
    mutation deleteUnitOfMeasure($data: UnitOfMeasureDeleteDtoInput!) {
  deleteUnitOfMeasure(data: $data)
}
    `;
export type DeleteUnitOfMeasureMutationFn = Apollo.MutationFunction<DeleteUnitOfMeasureMutation, DeleteUnitOfMeasureMutationVariables>;

/**
 * __useDeleteUnitOfMeasureMutation__
 *
 * To run a mutation, you first call `useDeleteUnitOfMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnitOfMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnitOfMeasureMutation, { data, loading, error }] = useDeleteUnitOfMeasureMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteUnitOfMeasureMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUnitOfMeasureMutation, DeleteUnitOfMeasureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUnitOfMeasureMutation, DeleteUnitOfMeasureMutationVariables>(DeleteUnitOfMeasureDocument, options);
      }
export type DeleteUnitOfMeasureMutationHookResult = ReturnType<typeof useDeleteUnitOfMeasureMutation>;
export type DeleteUnitOfMeasureMutationResult = Apollo.MutationResult<DeleteUnitOfMeasureMutation>;
export type DeleteUnitOfMeasureMutationOptions = Apollo.BaseMutationOptions<DeleteUnitOfMeasureMutation, DeleteUnitOfMeasureMutationVariables>;
export const GetUnitsOfMeasureDocument = gql`
    query getUnitsOfMeasure($where: UnitOfMeasureReturnDtoFilterInput, $take: Int, $skip: Int, $order: [UnitOfMeasureReturnDtoSortInput!]) {
  unitsOfMeasure(where: $where, take: $take, skip: $skip, order: $order) {
    items {
      id
      name
      description
      symbol
      externalId
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetUnitsOfMeasureQuery__
 *
 * To run a query within a React component, call `useGetUnitsOfMeasureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitsOfMeasureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitsOfMeasureQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetUnitsOfMeasureQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitsOfMeasureQuery, GetUnitsOfMeasureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitsOfMeasureQuery, GetUnitsOfMeasureQueryVariables>(GetUnitsOfMeasureDocument, options);
      }
export function useGetUnitsOfMeasureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitsOfMeasureQuery, GetUnitsOfMeasureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitsOfMeasureQuery, GetUnitsOfMeasureQueryVariables>(GetUnitsOfMeasureDocument, options);
        }
export type GetUnitsOfMeasureQueryHookResult = ReturnType<typeof useGetUnitsOfMeasureQuery>;
export type GetUnitsOfMeasureLazyQueryHookResult = ReturnType<typeof useGetUnitsOfMeasureLazyQuery>;
export type GetUnitsOfMeasureQueryResult = Apollo.QueryResult<GetUnitsOfMeasureQuery, GetUnitsOfMeasureQueryVariables>;
export function refetchGetUnitsOfMeasureQuery(variables?: GetUnitsOfMeasureQueryVariables) {
      return { query: GetUnitsOfMeasureDocument, variables: variables }
    }
export const UpdateUnitOfMeasureDocument = gql`
    mutation updateUnitOfMeasure($data: UnitOfMeasureUpdateDtoInput!) {
  updateUnitOfMeasure(data: $data) {
    id
    name
  }
}
    `;
export type UpdateUnitOfMeasureMutationFn = Apollo.MutationFunction<UpdateUnitOfMeasureMutation, UpdateUnitOfMeasureMutationVariables>;

/**
 * __useUpdateUnitOfMeasureMutation__
 *
 * To run a mutation, you first call `useUpdateUnitOfMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitOfMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnitOfMeasureMutation, { data, loading, error }] = useUpdateUnitOfMeasureMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUnitOfMeasureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUnitOfMeasureMutation, UpdateUnitOfMeasureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUnitOfMeasureMutation, UpdateUnitOfMeasureMutationVariables>(UpdateUnitOfMeasureDocument, options);
      }
export type UpdateUnitOfMeasureMutationHookResult = ReturnType<typeof useUpdateUnitOfMeasureMutation>;
export type UpdateUnitOfMeasureMutationResult = Apollo.MutationResult<UpdateUnitOfMeasureMutation>;
export type UpdateUnitOfMeasureMutationOptions = Apollo.BaseMutationOptions<UpdateUnitOfMeasureMutation, UpdateUnitOfMeasureMutationVariables>;
export const UnseenNotificationsCountSubscriptionDocument = gql`
    subscription unseenNotificationsCountSubscription {
  unseenUserNotificationsCountChanged
}
    `;

/**
 * __useUnseenNotificationsCountSubscriptionSubscription__
 *
 * To run a query within a React component, call `useUnseenNotificationsCountSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUnseenNotificationsCountSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnseenNotificationsCountSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUnseenNotificationsCountSubscriptionSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UnseenNotificationsCountSubscriptionSubscription, UnseenNotificationsCountSubscriptionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UnseenNotificationsCountSubscriptionSubscription, UnseenNotificationsCountSubscriptionSubscriptionVariables>(UnseenNotificationsCountSubscriptionDocument, options);
      }
export type UnseenNotificationsCountSubscriptionSubscriptionHookResult = ReturnType<typeof useUnseenNotificationsCountSubscriptionSubscription>;
export type UnseenNotificationsCountSubscriptionSubscriptionResult = Apollo.SubscriptionResult<UnseenNotificationsCountSubscriptionSubscription>;
export const DeleteUserDocument = gql`
    mutation deleteUser($model: AccountDeleteDtoInput!) {
  delete(model: $model)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetFieldWorkersPlainDocument = gql`
    query getFieldWorkersPlain($order: [ApplicationUserSortInput!], $skip: Int, $take: Int, $where: ApplicationUserFilterInput) {
  users(
    order: $order
    skip: $skip
    take: $take
    where: $where
    filters: {role: FIELD_WORKER}
  ) {
    items {
      id
      userName
      email
      firstName
      lastName
      emailConfirmed
      phoneNumber
      companyId
      company {
        id
        name
        taxId
      }
      currentVehicleId
      currentVehicle {
        id
        name
        description
        registrationNumber
      }
      currentOrganizationalEntityId
      currentOrganizationalEntity {
        id
        name
        description
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetFieldWorkersPlainQuery__
 *
 * To run a query within a React component, call `useGetFieldWorkersPlainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFieldWorkersPlainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFieldWorkersPlainQuery({
 *   variables: {
 *      order: // value for 'order'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetFieldWorkersPlainQuery(baseOptions?: Apollo.QueryHookOptions<GetFieldWorkersPlainQuery, GetFieldWorkersPlainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFieldWorkersPlainQuery, GetFieldWorkersPlainQueryVariables>(GetFieldWorkersPlainDocument, options);
      }
export function useGetFieldWorkersPlainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFieldWorkersPlainQuery, GetFieldWorkersPlainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFieldWorkersPlainQuery, GetFieldWorkersPlainQueryVariables>(GetFieldWorkersPlainDocument, options);
        }
export type GetFieldWorkersPlainQueryHookResult = ReturnType<typeof useGetFieldWorkersPlainQuery>;
export type GetFieldWorkersPlainLazyQueryHookResult = ReturnType<typeof useGetFieldWorkersPlainLazyQuery>;
export type GetFieldWorkersPlainQueryResult = Apollo.QueryResult<GetFieldWorkersPlainQuery, GetFieldWorkersPlainQueryVariables>;
export function refetchGetFieldWorkersPlainQuery(variables?: GetFieldWorkersPlainQueryVariables) {
      return { query: GetFieldWorkersPlainDocument, variables: variables }
    }
export const GetUnfilteredUsersDocument = gql`
    query getUnfilteredUsers($order: [ApplicationUserReturnDtoSortInput!], $skip: Int, $take: Int, $where: ApplicationUserReturnDtoFilterInput) {
  usersUnfiltered(order: $order, skip: $skip, take: $take, where: $where) {
    items {
      id
      userName
      email
      firstName
      lastName
      emailConfirmed
      phoneNumber
      companyId
      company {
        id
        name
        taxId
      }
      currentVehicleId
      currentVehicle {
        id
        name
        description
        registrationNumber
      }
      currentOrganizationalEntityId
      currentOrganizationalEntity {
        id
        name
        description
      }
      externalId
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetUnfilteredUsersQuery__
 *
 * To run a query within a React component, call `useGetUnfilteredUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnfilteredUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnfilteredUsersQuery({
 *   variables: {
 *      order: // value for 'order'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUnfilteredUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUnfilteredUsersQuery, GetUnfilteredUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnfilteredUsersQuery, GetUnfilteredUsersQueryVariables>(GetUnfilteredUsersDocument, options);
      }
export function useGetUnfilteredUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnfilteredUsersQuery, GetUnfilteredUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnfilteredUsersQuery, GetUnfilteredUsersQueryVariables>(GetUnfilteredUsersDocument, options);
        }
export type GetUnfilteredUsersQueryHookResult = ReturnType<typeof useGetUnfilteredUsersQuery>;
export type GetUnfilteredUsersLazyQueryHookResult = ReturnType<typeof useGetUnfilteredUsersLazyQuery>;
export type GetUnfilteredUsersQueryResult = Apollo.QueryResult<GetUnfilteredUsersQuery, GetUnfilteredUsersQueryVariables>;
export function refetchGetUnfilteredUsersQuery(variables?: GetUnfilteredUsersQueryVariables) {
      return { query: GetUnfilteredUsersDocument, variables: variables }
    }
export const GetUserDocument = gql`
    query getUser($id: String!) {
  singleUser(where: {id: {eq: $id}}) {
    id
    userName
    companyId
    firstName
    lastName
    emailConfirmed
    email
    phoneNumber
    preferredLanguage
    company {
      id
      name
      taxId
    }
    currentVehicleId
    currentVehicle {
      id
      name
      description
      registrationNumber
    }
    currentOrganizationalEntityId
    currentOrganizationalEntity {
      id
      name
      description
    }
    externalId
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export function refetchGetUserQuery(variables: GetUserQueryVariables) {
      return { query: GetUserDocument, variables: variables }
    }
export const GetUserRolesDocument = gql`
    query getUserRoles($id: String!) {
  userRoles(id: $id)
}
    `;

/**
 * __useGetUserRolesQuery__
 *
 * To run a query within a React component, call `useGetUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRolesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserRolesQuery(baseOptions: Apollo.QueryHookOptions<GetUserRolesQuery, GetUserRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(GetUserRolesDocument, options);
      }
export function useGetUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserRolesQuery, GetUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(GetUserRolesDocument, options);
        }
export type GetUserRolesQueryHookResult = ReturnType<typeof useGetUserRolesQuery>;
export type GetUserRolesLazyQueryHookResult = ReturnType<typeof useGetUserRolesLazyQuery>;
export type GetUserRolesQueryResult = Apollo.QueryResult<GetUserRolesQuery, GetUserRolesQueryVariables>;
export function refetchGetUserRolesQuery(variables: GetUserRolesQueryVariables) {
      return { query: GetUserRolesDocument, variables: variables }
    }
export const GetUsersDocument = gql`
    query getUsers($order: [ApplicationUserSortInput!], $skip: Int, $take: Int, $where: ApplicationUserFilterInput, $filters: ApplicationUserFilterDtoInput!) {
  users(order: $order, skip: $skip, take: $take, where: $where, filters: $filters) {
    items {
      id
      userName
      email
      firstName
      lastName
      emailConfirmed
      phoneNumber
      companyId
      userRoles {
        role {
          name
        }
      }
      company {
        id
        name
        taxId
      }
      currentVehicleId
      currentVehicle {
        id
        name
        description
        registrationNumber
      }
      currentOrganizationalEntityId
      currentOrganizationalEntity {
        id
        name
        description
      }
      externalId
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      order: // value for 'order'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export function refetchGetUsersQuery(variables: GetUsersQueryVariables) {
      return { query: GetUsersDocument, variables: variables }
    }
export const ManuallyConfirmUserEmailDocument = gql`
    mutation manuallyConfirmUserEmail($userId: String!) {
  confirmEmailManually(userId: $userId)
}
    `;
export type ManuallyConfirmUserEmailMutationFn = Apollo.MutationFunction<ManuallyConfirmUserEmailMutation, ManuallyConfirmUserEmailMutationVariables>;

/**
 * __useManuallyConfirmUserEmailMutation__
 *
 * To run a mutation, you first call `useManuallyConfirmUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManuallyConfirmUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manuallyConfirmUserEmailMutation, { data, loading, error }] = useManuallyConfirmUserEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useManuallyConfirmUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<ManuallyConfirmUserEmailMutation, ManuallyConfirmUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManuallyConfirmUserEmailMutation, ManuallyConfirmUserEmailMutationVariables>(ManuallyConfirmUserEmailDocument, options);
      }
export type ManuallyConfirmUserEmailMutationHookResult = ReturnType<typeof useManuallyConfirmUserEmailMutation>;
export type ManuallyConfirmUserEmailMutationResult = Apollo.MutationResult<ManuallyConfirmUserEmailMutation>;
export type ManuallyConfirmUserEmailMutationOptions = Apollo.BaseMutationOptions<ManuallyConfirmUserEmailMutation, ManuallyConfirmUserEmailMutationVariables>;
export const RegisterUserDocument = gql`
    mutation registerUser($model: AccountRegisterExtendedDtoInput!) {
  register(model: $model)
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const RemoveUserRoleDocument = gql`
    mutation removeUserRole($id: String!, $roleId: UserRole!) {
  removeFromRoleById(id: $id, role: $roleId)
}
    `;
export type RemoveUserRoleMutationFn = Apollo.MutationFunction<RemoveUserRoleMutation, RemoveUserRoleMutationVariables>;

/**
 * __useRemoveUserRoleMutation__
 *
 * To run a mutation, you first call `useRemoveUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserRoleMutation, { data, loading, error }] = useRemoveUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useRemoveUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserRoleMutation, RemoveUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserRoleMutation, RemoveUserRoleMutationVariables>(RemoveUserRoleDocument, options);
      }
export type RemoveUserRoleMutationHookResult = ReturnType<typeof useRemoveUserRoleMutation>;
export type RemoveUserRoleMutationResult = Apollo.MutationResult<RemoveUserRoleMutation>;
export type RemoveUserRoleMutationOptions = Apollo.BaseMutationOptions<RemoveUserRoleMutation, RemoveUserRoleMutationVariables>;
export const ResendConfirmationEmailDocument = gql`
    mutation resendConfirmationEmail($model: AccountResendConfirmationEmailDtoInput!) {
  resendConfirmationEmail(model: $model)
}
    `;
export type ResendConfirmationEmailMutationFn = Apollo.MutationFunction<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>;

/**
 * __useResendConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useResendConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationEmailMutation, { data, loading, error }] = useResendConfirmationEmailMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useResendConfirmationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>(ResendConfirmationEmailDocument, options);
      }
export type ResendConfirmationEmailMutationHookResult = ReturnType<typeof useResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationResult = Apollo.MutationResult<ResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationOptions = Apollo.BaseMutationOptions<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>;
export const UpdateApplicationUserDocument = gql`
    mutation updateApplicationUser($input: ApplicationUserUpdateDtoInput!) {
  updateApplicationUser(data: $input) {
    id
    companyId
  }
}
    `;
export type UpdateApplicationUserMutationFn = Apollo.MutationFunction<UpdateApplicationUserMutation, UpdateApplicationUserMutationVariables>;

/**
 * __useUpdateApplicationUserMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationUserMutation, { data, loading, error }] = useUpdateApplicationUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApplicationUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicationUserMutation, UpdateApplicationUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApplicationUserMutation, UpdateApplicationUserMutationVariables>(UpdateApplicationUserDocument, options);
      }
export type UpdateApplicationUserMutationHookResult = ReturnType<typeof useUpdateApplicationUserMutation>;
export type UpdateApplicationUserMutationResult = Apollo.MutationResult<UpdateApplicationUserMutation>;
export type UpdateApplicationUserMutationOptions = Apollo.BaseMutationOptions<UpdateApplicationUserMutation, UpdateApplicationUserMutationVariables>;
export const AddUserRoleDocument = gql`
    mutation addUserRole($id: String!, $roleId: UserRole!) {
  addToRoleById(id: $id, role: $roleId)
}
    `;
export type AddUserRoleMutationFn = Apollo.MutationFunction<AddUserRoleMutation, AddUserRoleMutationVariables>;

/**
 * __useAddUserRoleMutation__
 *
 * To run a mutation, you first call `useAddUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserRoleMutation, { data, loading, error }] = useAddUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useAddUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<AddUserRoleMutation, AddUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserRoleMutation, AddUserRoleMutationVariables>(AddUserRoleDocument, options);
      }
export type AddUserRoleMutationHookResult = ReturnType<typeof useAddUserRoleMutation>;
export type AddUserRoleMutationResult = Apollo.MutationResult<AddUserRoleMutation>;
export type AddUserRoleMutationOptions = Apollo.BaseMutationOptions<AddUserRoleMutation, AddUserRoleMutationVariables>;
export const CreateVatTypeDocument = gql`
    mutation createVATType($data: VATTypeCreateDtoInput!) {
  createVATType(data: $data) {
    id
  }
}
    `;
export type CreateVatTypeMutationFn = Apollo.MutationFunction<CreateVatTypeMutation, CreateVatTypeMutationVariables>;

/**
 * __useCreateVatTypeMutation__
 *
 * To run a mutation, you first call `useCreateVatTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVatTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVatTypeMutation, { data, loading, error }] = useCreateVatTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateVatTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateVatTypeMutation, CreateVatTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVatTypeMutation, CreateVatTypeMutationVariables>(CreateVatTypeDocument, options);
      }
export type CreateVatTypeMutationHookResult = ReturnType<typeof useCreateVatTypeMutation>;
export type CreateVatTypeMutationResult = Apollo.MutationResult<CreateVatTypeMutation>;
export type CreateVatTypeMutationOptions = Apollo.BaseMutationOptions<CreateVatTypeMutation, CreateVatTypeMutationVariables>;
export const DeleteVatTypeDocument = gql`
    mutation deleteVATType($data: VATTypeDeleteDtoInput!) {
  deleteVATType(data: $data)
}
    `;
export type DeleteVatTypeMutationFn = Apollo.MutationFunction<DeleteVatTypeMutation, DeleteVatTypeMutationVariables>;

/**
 * __useDeleteVatTypeMutation__
 *
 * To run a mutation, you first call `useDeleteVatTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVatTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVatTypeMutation, { data, loading, error }] = useDeleteVatTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteVatTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVatTypeMutation, DeleteVatTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVatTypeMutation, DeleteVatTypeMutationVariables>(DeleteVatTypeDocument, options);
      }
export type DeleteVatTypeMutationHookResult = ReturnType<typeof useDeleteVatTypeMutation>;
export type DeleteVatTypeMutationResult = Apollo.MutationResult<DeleteVatTypeMutation>;
export type DeleteVatTypeMutationOptions = Apollo.BaseMutationOptions<DeleteVatTypeMutation, DeleteVatTypeMutationVariables>;
export const GetVatTypesDocument = gql`
    query getVATTypes($where: VATTypeReturnDtoFilterInput, $take: Int, $skip: Int, $order: [VATTypeReturnDtoSortInput!]) {
  vatTypes(where: $where, take: $take, skip: $skip, order: $order) {
    items {
      id
      name
      description
      rate
      externalId
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetVatTypesQuery__
 *
 * To run a query within a React component, call `useGetVatTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVatTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVatTypesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetVatTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetVatTypesQuery, GetVatTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVatTypesQuery, GetVatTypesQueryVariables>(GetVatTypesDocument, options);
      }
export function useGetVatTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVatTypesQuery, GetVatTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVatTypesQuery, GetVatTypesQueryVariables>(GetVatTypesDocument, options);
        }
export type GetVatTypesQueryHookResult = ReturnType<typeof useGetVatTypesQuery>;
export type GetVatTypesLazyQueryHookResult = ReturnType<typeof useGetVatTypesLazyQuery>;
export type GetVatTypesQueryResult = Apollo.QueryResult<GetVatTypesQuery, GetVatTypesQueryVariables>;
export function refetchGetVatTypesQuery(variables?: GetVatTypesQueryVariables) {
      return { query: GetVatTypesDocument, variables: variables }
    }
export const UpdateVatTypeDocument = gql`
    mutation updateVATType($data: VATTypeUpdateDtoInput!) {
  updateVATType(data: $data) {
    id
    name
  }
}
    `;
export type UpdateVatTypeMutationFn = Apollo.MutationFunction<UpdateVatTypeMutation, UpdateVatTypeMutationVariables>;

/**
 * __useUpdateVatTypeMutation__
 *
 * To run a mutation, you first call `useUpdateVatTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVatTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVatTypeMutation, { data, loading, error }] = useUpdateVatTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateVatTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVatTypeMutation, UpdateVatTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVatTypeMutation, UpdateVatTypeMutationVariables>(UpdateVatTypeDocument, options);
      }
export type UpdateVatTypeMutationHookResult = ReturnType<typeof useUpdateVatTypeMutation>;
export type UpdateVatTypeMutationResult = Apollo.MutationResult<UpdateVatTypeMutation>;
export type UpdateVatTypeMutationOptions = Apollo.BaseMutationOptions<UpdateVatTypeMutation, UpdateVatTypeMutationVariables>;
export const CreateVehicleDocument = gql`
    mutation createVehicle($data: VehicleCreateDtoInput!) {
  createVehicle(data: $data) {
    id
  }
}
    `;
export type CreateVehicleMutationFn = Apollo.MutationFunction<CreateVehicleMutation, CreateVehicleMutationVariables>;

/**
 * __useCreateVehicleMutation__
 *
 * To run a mutation, you first call `useCreateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleMutation, { data, loading, error }] = useCreateVehicleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateVehicleMutation(baseOptions?: Apollo.MutationHookOptions<CreateVehicleMutation, CreateVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVehicleMutation, CreateVehicleMutationVariables>(CreateVehicleDocument, options);
      }
export type CreateVehicleMutationHookResult = ReturnType<typeof useCreateVehicleMutation>;
export type CreateVehicleMutationResult = Apollo.MutationResult<CreateVehicleMutation>;
export type CreateVehicleMutationOptions = Apollo.BaseMutationOptions<CreateVehicleMutation, CreateVehicleMutationVariables>;
export const DeleteVehicleDocument = gql`
    mutation deleteVehicle($data: VehicleDeleteDtoInput!) {
  deleteVehicle(data: $data)
}
    `;
export type DeleteVehicleMutationFn = Apollo.MutationFunction<DeleteVehicleMutation, DeleteVehicleMutationVariables>;

/**
 * __useDeleteVehicleMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleMutation, { data, loading, error }] = useDeleteVehicleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteVehicleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVehicleMutation, DeleteVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVehicleMutation, DeleteVehicleMutationVariables>(DeleteVehicleDocument, options);
      }
export type DeleteVehicleMutationHookResult = ReturnType<typeof useDeleteVehicleMutation>;
export type DeleteVehicleMutationResult = Apollo.MutationResult<DeleteVehicleMutation>;
export type DeleteVehicleMutationOptions = Apollo.BaseMutationOptions<DeleteVehicleMutation, DeleteVehicleMutationVariables>;
export const GetVehiclesDocument = gql`
    query getVehicles($where: VehicleReturnDtoFilterInput, $take: Int, $skip: Int, $order: [VehicleReturnDtoSortInput!]) {
  vehicles(where: $where, take: $take, skip: $skip, order: $order) {
    items {
      id
      name
      description
      costLocationId
      costLocation {
        name
        code
      }
      location {
        latitude
        longitude
      }
      registrationNumber
      assignedUser {
        id
        userName
        firstName
        lastName
        fullName
      }
      externalId
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetVehiclesQuery__
 *
 * To run a query within a React component, call `useGetVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehiclesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetVehiclesQuery(baseOptions?: Apollo.QueryHookOptions<GetVehiclesQuery, GetVehiclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehiclesQuery, GetVehiclesQueryVariables>(GetVehiclesDocument, options);
      }
export function useGetVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehiclesQuery, GetVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehiclesQuery, GetVehiclesQueryVariables>(GetVehiclesDocument, options);
        }
export type GetVehiclesQueryHookResult = ReturnType<typeof useGetVehiclesQuery>;
export type GetVehiclesLazyQueryHookResult = ReturnType<typeof useGetVehiclesLazyQuery>;
export type GetVehiclesQueryResult = Apollo.QueryResult<GetVehiclesQuery, GetVehiclesQueryVariables>;
export function refetchGetVehiclesQuery(variables?: GetVehiclesQueryVariables) {
      return { query: GetVehiclesDocument, variables: variables }
    }
export const UpdateVehicleDocument = gql`
    mutation updateVehicle($data: VehicleUpdateDtoInput!) {
  updateVehicle(data: $data) {
    id
    name
  }
}
    `;
export type UpdateVehicleMutationFn = Apollo.MutationFunction<UpdateVehicleMutation, UpdateVehicleMutationVariables>;

/**
 * __useUpdateVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleMutation, { data, loading, error }] = useUpdateVehicleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateVehicleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVehicleMutation, UpdateVehicleMutationVariables>(UpdateVehicleDocument, options);
      }
export type UpdateVehicleMutationHookResult = ReturnType<typeof useUpdateVehicleMutation>;
export type UpdateVehicleMutationResult = Apollo.MutationResult<UpdateVehicleMutation>;
export type UpdateVehicleMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>;
export const CreateWorkOrderItemDocument = gql`
    mutation createWorkOrderItem($data: WorkOrderItemCreateDtoInput!) {
  createWorkOrderItem(data: $data) {
    id
  }
}
    `;
export type CreateWorkOrderItemMutationFn = Apollo.MutationFunction<CreateWorkOrderItemMutation, CreateWorkOrderItemMutationVariables>;

/**
 * __useCreateWorkOrderItemMutation__
 *
 * To run a mutation, you first call `useCreateWorkOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkOrderItemMutation, { data, loading, error }] = useCreateWorkOrderItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWorkOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkOrderItemMutation, CreateWorkOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkOrderItemMutation, CreateWorkOrderItemMutationVariables>(CreateWorkOrderItemDocument, options);
      }
export type CreateWorkOrderItemMutationHookResult = ReturnType<typeof useCreateWorkOrderItemMutation>;
export type CreateWorkOrderItemMutationResult = Apollo.MutationResult<CreateWorkOrderItemMutation>;
export type CreateWorkOrderItemMutationOptions = Apollo.BaseMutationOptions<CreateWorkOrderItemMutation, CreateWorkOrderItemMutationVariables>;
export const DeleteWorkOrderItemDocument = gql`
    mutation deleteWorkOrderItem($data: WorkOrderItemDeleteDtoInput!) {
  deleteWorkOrderItem(data: $data)
}
    `;
export type DeleteWorkOrderItemMutationFn = Apollo.MutationFunction<DeleteWorkOrderItemMutation, DeleteWorkOrderItemMutationVariables>;

/**
 * __useDeleteWorkOrderItemMutation__
 *
 * To run a mutation, you first call `useDeleteWorkOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkOrderItemMutation, { data, loading, error }] = useDeleteWorkOrderItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteWorkOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkOrderItemMutation, DeleteWorkOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkOrderItemMutation, DeleteWorkOrderItemMutationVariables>(DeleteWorkOrderItemDocument, options);
      }
export type DeleteWorkOrderItemMutationHookResult = ReturnType<typeof useDeleteWorkOrderItemMutation>;
export type DeleteWorkOrderItemMutationResult = Apollo.MutationResult<DeleteWorkOrderItemMutation>;
export type DeleteWorkOrderItemMutationOptions = Apollo.BaseMutationOptions<DeleteWorkOrderItemMutation, DeleteWorkOrderItemMutationVariables>;
export const GetWorkOrderItemsDocument = gql`
    query getWorkOrderItems($where: WorkOrderItemReturnDtoFilterInput, $take: Int, $skip: Int, $order: [WorkOrderItemReturnDtoSortInput!]) {
  workOrderItems(where: $where, take: $take, skip: $skip, order: $order) {
    items {
      id
      note
      workOrderId
      files(where: {fileType: {eq: GENERIC}}) {
        id
        workOrderId
        notes
        fileName
        contentType
      }
      quantity
      itemId
      itemName
      itemDescription
      itemUnitsContained
      itemUnitPrice
      unitOfMeasureId
      unitOfMeasureName
      unitOfMeasureSymbol
      vat
      vatBase
      total
      itemVATTypeRate
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetWorkOrderItemsQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetWorkOrderItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkOrderItemsQuery, GetWorkOrderItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrderItemsQuery, GetWorkOrderItemsQueryVariables>(GetWorkOrderItemsDocument, options);
      }
export function useGetWorkOrderItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderItemsQuery, GetWorkOrderItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrderItemsQuery, GetWorkOrderItemsQueryVariables>(GetWorkOrderItemsDocument, options);
        }
export type GetWorkOrderItemsQueryHookResult = ReturnType<typeof useGetWorkOrderItemsQuery>;
export type GetWorkOrderItemsLazyQueryHookResult = ReturnType<typeof useGetWorkOrderItemsLazyQuery>;
export type GetWorkOrderItemsQueryResult = Apollo.QueryResult<GetWorkOrderItemsQuery, GetWorkOrderItemsQueryVariables>;
export function refetchGetWorkOrderItemsQuery(variables?: GetWorkOrderItemsQueryVariables) {
      return { query: GetWorkOrderItemsDocument, variables: variables }
    }
export const UpdateWorkOrderItemDocument = gql`
    mutation updateWorkOrderItem($data: WorkOrderItemUpdateDtoInput!) {
  updateWorkOrderItem(data: $data) {
    id
  }
}
    `;
export type UpdateWorkOrderItemMutationFn = Apollo.MutationFunction<UpdateWorkOrderItemMutation, UpdateWorkOrderItemMutationVariables>;

/**
 * __useUpdateWorkOrderItemMutation__
 *
 * To run a mutation, you first call `useUpdateWorkOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkOrderItemMutation, { data, loading, error }] = useUpdateWorkOrderItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWorkOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkOrderItemMutation, UpdateWorkOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkOrderItemMutation, UpdateWorkOrderItemMutationVariables>(UpdateWorkOrderItemDocument, options);
      }
export type UpdateWorkOrderItemMutationHookResult = ReturnType<typeof useUpdateWorkOrderItemMutation>;
export type UpdateWorkOrderItemMutationResult = Apollo.MutationResult<UpdateWorkOrderItemMutation>;
export type UpdateWorkOrderItemMutationOptions = Apollo.BaseMutationOptions<UpdateWorkOrderItemMutation, UpdateWorkOrderItemMutationVariables>;
export const CreateWorkOrderStatusChangeDocument = gql`
    mutation createWorkOrderStatusChange($data: WorkOrderStatusChangeCreateDtoInput!) {
  createWorkOrderStatusChange(data: $data) {
    id
  }
}
    `;
export type CreateWorkOrderStatusChangeMutationFn = Apollo.MutationFunction<CreateWorkOrderStatusChangeMutation, CreateWorkOrderStatusChangeMutationVariables>;

/**
 * __useCreateWorkOrderStatusChangeMutation__
 *
 * To run a mutation, you first call `useCreateWorkOrderStatusChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkOrderStatusChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkOrderStatusChangeMutation, { data, loading, error }] = useCreateWorkOrderStatusChangeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWorkOrderStatusChangeMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkOrderStatusChangeMutation, CreateWorkOrderStatusChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkOrderStatusChangeMutation, CreateWorkOrderStatusChangeMutationVariables>(CreateWorkOrderStatusChangeDocument, options);
      }
export type CreateWorkOrderStatusChangeMutationHookResult = ReturnType<typeof useCreateWorkOrderStatusChangeMutation>;
export type CreateWorkOrderStatusChangeMutationResult = Apollo.MutationResult<CreateWorkOrderStatusChangeMutation>;
export type CreateWorkOrderStatusChangeMutationOptions = Apollo.BaseMutationOptions<CreateWorkOrderStatusChangeMutation, CreateWorkOrderStatusChangeMutationVariables>;
export const DeleteWorkOrderStatusChangeDocument = gql`
    mutation deleteWorkOrderStatusChange($data: WorkOrderStatusChangeDeleteDtoInput!) {
  deleteWorkOrderStatusChange(data: $data)
}
    `;
export type DeleteWorkOrderStatusChangeMutationFn = Apollo.MutationFunction<DeleteWorkOrderStatusChangeMutation, DeleteWorkOrderStatusChangeMutationVariables>;

/**
 * __useDeleteWorkOrderStatusChangeMutation__
 *
 * To run a mutation, you first call `useDeleteWorkOrderStatusChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkOrderStatusChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkOrderStatusChangeMutation, { data, loading, error }] = useDeleteWorkOrderStatusChangeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteWorkOrderStatusChangeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkOrderStatusChangeMutation, DeleteWorkOrderStatusChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkOrderStatusChangeMutation, DeleteWorkOrderStatusChangeMutationVariables>(DeleteWorkOrderStatusChangeDocument, options);
      }
export type DeleteWorkOrderStatusChangeMutationHookResult = ReturnType<typeof useDeleteWorkOrderStatusChangeMutation>;
export type DeleteWorkOrderStatusChangeMutationResult = Apollo.MutationResult<DeleteWorkOrderStatusChangeMutation>;
export type DeleteWorkOrderStatusChangeMutationOptions = Apollo.BaseMutationOptions<DeleteWorkOrderStatusChangeMutation, DeleteWorkOrderStatusChangeMutationVariables>;
export const GetSingleWorkOrdersStatusChangesDocument = gql`
    query getSingleWorkOrdersStatusChanges($id: Int!) {
  singleWorkOrderAllStatusChanges(workOrderId: $id, order: {createdAt: DESC}) {
    id
    workOrderId
    notes
    status
    initiatingUserId
    initiatingUser {
      id
      userName
    }
    createdAt
  }
}
    `;

/**
 * __useGetSingleWorkOrdersStatusChangesQuery__
 *
 * To run a query within a React component, call `useGetSingleWorkOrdersStatusChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleWorkOrdersStatusChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleWorkOrdersStatusChangesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleWorkOrdersStatusChangesQuery(baseOptions: Apollo.QueryHookOptions<GetSingleWorkOrdersStatusChangesQuery, GetSingleWorkOrdersStatusChangesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleWorkOrdersStatusChangesQuery, GetSingleWorkOrdersStatusChangesQueryVariables>(GetSingleWorkOrdersStatusChangesDocument, options);
      }
export function useGetSingleWorkOrdersStatusChangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleWorkOrdersStatusChangesQuery, GetSingleWorkOrdersStatusChangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleWorkOrdersStatusChangesQuery, GetSingleWorkOrdersStatusChangesQueryVariables>(GetSingleWorkOrdersStatusChangesDocument, options);
        }
export type GetSingleWorkOrdersStatusChangesQueryHookResult = ReturnType<typeof useGetSingleWorkOrdersStatusChangesQuery>;
export type GetSingleWorkOrdersStatusChangesLazyQueryHookResult = ReturnType<typeof useGetSingleWorkOrdersStatusChangesLazyQuery>;
export type GetSingleWorkOrdersStatusChangesQueryResult = Apollo.QueryResult<GetSingleWorkOrdersStatusChangesQuery, GetSingleWorkOrdersStatusChangesQueryVariables>;
export function refetchGetSingleWorkOrdersStatusChangesQuery(variables: GetSingleWorkOrdersStatusChangesQueryVariables) {
      return { query: GetSingleWorkOrdersStatusChangesDocument, variables: variables }
    }
export const GetWorkOrderStatusChangesDocument = gql`
    query getWorkOrderStatusChanges($where: WorkOrderStatusChangeReturnDtoFilterInput, $take: Int, $skip: Int, $order: [WorkOrderStatusChangeReturnDtoSortInput!]) {
  workOrderStatusChanges(where: $where, take: $take, skip: $skip, order: $order) {
    items {
      id
      workOrderId
      workOrder {
        formattedId
      }
      status
      notes
      initiatingUserId
      initiatingUser {
        userName
      }
      createdAt
      location {
        latitude
        longitude
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetWorkOrderStatusChangesQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderStatusChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderStatusChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderStatusChangesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetWorkOrderStatusChangesQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkOrderStatusChangesQuery, GetWorkOrderStatusChangesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrderStatusChangesQuery, GetWorkOrderStatusChangesQueryVariables>(GetWorkOrderStatusChangesDocument, options);
      }
export function useGetWorkOrderStatusChangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderStatusChangesQuery, GetWorkOrderStatusChangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrderStatusChangesQuery, GetWorkOrderStatusChangesQueryVariables>(GetWorkOrderStatusChangesDocument, options);
        }
export type GetWorkOrderStatusChangesQueryHookResult = ReturnType<typeof useGetWorkOrderStatusChangesQuery>;
export type GetWorkOrderStatusChangesLazyQueryHookResult = ReturnType<typeof useGetWorkOrderStatusChangesLazyQuery>;
export type GetWorkOrderStatusChangesQueryResult = Apollo.QueryResult<GetWorkOrderStatusChangesQuery, GetWorkOrderStatusChangesQueryVariables>;
export function refetchGetWorkOrderStatusChangesQuery(variables?: GetWorkOrderStatusChangesQueryVariables) {
      return { query: GetWorkOrderStatusChangesDocument, variables: variables }
    }
export const UpdateWorkOrderStatusChangeDocument = gql`
    mutation updateWorkOrderStatusChange($data: WorkOrderStatusChangeUpdateDtoInput!) {
  updateWorkOrderStatusChange(data: $data) {
    id
  }
}
    `;
export type UpdateWorkOrderStatusChangeMutationFn = Apollo.MutationFunction<UpdateWorkOrderStatusChangeMutation, UpdateWorkOrderStatusChangeMutationVariables>;

/**
 * __useUpdateWorkOrderStatusChangeMutation__
 *
 * To run a mutation, you first call `useUpdateWorkOrderStatusChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkOrderStatusChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkOrderStatusChangeMutation, { data, loading, error }] = useUpdateWorkOrderStatusChangeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWorkOrderStatusChangeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkOrderStatusChangeMutation, UpdateWorkOrderStatusChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkOrderStatusChangeMutation, UpdateWorkOrderStatusChangeMutationVariables>(UpdateWorkOrderStatusChangeDocument, options);
      }
export type UpdateWorkOrderStatusChangeMutationHookResult = ReturnType<typeof useUpdateWorkOrderStatusChangeMutation>;
export type UpdateWorkOrderStatusChangeMutationResult = Apollo.MutationResult<UpdateWorkOrderStatusChangeMutation>;
export type UpdateWorkOrderStatusChangeMutationOptions = Apollo.BaseMutationOptions<UpdateWorkOrderStatusChangeMutation, UpdateWorkOrderStatusChangeMutationVariables>;
export const CreateWorkOrderTemplateItemDocument = gql`
    mutation createWorkOrderTemplateItem($data: WorkOrderTemplateItemCreateDtoInput!) {
  createWorkOrderTemplateItem(data: $data) {
    id
  }
}
    `;
export type CreateWorkOrderTemplateItemMutationFn = Apollo.MutationFunction<CreateWorkOrderTemplateItemMutation, CreateWorkOrderTemplateItemMutationVariables>;

/**
 * __useCreateWorkOrderTemplateItemMutation__
 *
 * To run a mutation, you first call `useCreateWorkOrderTemplateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkOrderTemplateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkOrderTemplateItemMutation, { data, loading, error }] = useCreateWorkOrderTemplateItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWorkOrderTemplateItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkOrderTemplateItemMutation, CreateWorkOrderTemplateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkOrderTemplateItemMutation, CreateWorkOrderTemplateItemMutationVariables>(CreateWorkOrderTemplateItemDocument, options);
      }
export type CreateWorkOrderTemplateItemMutationHookResult = ReturnType<typeof useCreateWorkOrderTemplateItemMutation>;
export type CreateWorkOrderTemplateItemMutationResult = Apollo.MutationResult<CreateWorkOrderTemplateItemMutation>;
export type CreateWorkOrderTemplateItemMutationOptions = Apollo.BaseMutationOptions<CreateWorkOrderTemplateItemMutation, CreateWorkOrderTemplateItemMutationVariables>;
export const DeleteWorkOrderTemplateItemDocument = gql`
    mutation deleteWorkOrderTemplateItem($data: WorkOrderTemplateItemDeleteDtoInput!) {
  deleteWorkOrderTemplateItem(data: $data)
}
    `;
export type DeleteWorkOrderTemplateItemMutationFn = Apollo.MutationFunction<DeleteWorkOrderTemplateItemMutation, DeleteWorkOrderTemplateItemMutationVariables>;

/**
 * __useDeleteWorkOrderTemplateItemMutation__
 *
 * To run a mutation, you first call `useDeleteWorkOrderTemplateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkOrderTemplateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkOrderTemplateItemMutation, { data, loading, error }] = useDeleteWorkOrderTemplateItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteWorkOrderTemplateItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkOrderTemplateItemMutation, DeleteWorkOrderTemplateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkOrderTemplateItemMutation, DeleteWorkOrderTemplateItemMutationVariables>(DeleteWorkOrderTemplateItemDocument, options);
      }
export type DeleteWorkOrderTemplateItemMutationHookResult = ReturnType<typeof useDeleteWorkOrderTemplateItemMutation>;
export type DeleteWorkOrderTemplateItemMutationResult = Apollo.MutationResult<DeleteWorkOrderTemplateItemMutation>;
export type DeleteWorkOrderTemplateItemMutationOptions = Apollo.BaseMutationOptions<DeleteWorkOrderTemplateItemMutation, DeleteWorkOrderTemplateItemMutationVariables>;
export const GetWorkOrderTemplateItemsDocument = gql`
    query getWorkOrderTemplateItems($where: WorkOrderTemplateItemReturnDtoFilterInput, $take: Int, $skip: Int, $order: [WorkOrderTemplateItemReturnDtoSortInput!]) {
  workOrderTemplateItems(where: $where, take: $take, skip: $skip, order: $order) {
    items {
      id
      note
      workOrderTemplateId
      item {
        id
        name
        description
      }
      quantity
      itemId
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetWorkOrderTemplateItemsQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderTemplateItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderTemplateItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderTemplateItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetWorkOrderTemplateItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkOrderTemplateItemsQuery, GetWorkOrderTemplateItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrderTemplateItemsQuery, GetWorkOrderTemplateItemsQueryVariables>(GetWorkOrderTemplateItemsDocument, options);
      }
export function useGetWorkOrderTemplateItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderTemplateItemsQuery, GetWorkOrderTemplateItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrderTemplateItemsQuery, GetWorkOrderTemplateItemsQueryVariables>(GetWorkOrderTemplateItemsDocument, options);
        }
export type GetWorkOrderTemplateItemsQueryHookResult = ReturnType<typeof useGetWorkOrderTemplateItemsQuery>;
export type GetWorkOrderTemplateItemsLazyQueryHookResult = ReturnType<typeof useGetWorkOrderTemplateItemsLazyQuery>;
export type GetWorkOrderTemplateItemsQueryResult = Apollo.QueryResult<GetWorkOrderTemplateItemsQuery, GetWorkOrderTemplateItemsQueryVariables>;
export function refetchGetWorkOrderTemplateItemsQuery(variables?: GetWorkOrderTemplateItemsQueryVariables) {
      return { query: GetWorkOrderTemplateItemsDocument, variables: variables }
    }
export const UpdateWorkOrderTemplateItemDocument = gql`
    mutation updateWorkOrderTemplateItem($data: WorkOrderTemplateItemUpdateDtoInput!) {
  updateWorkOrderTemplateItem(data: $data) {
    id
  }
}
    `;
export type UpdateWorkOrderTemplateItemMutationFn = Apollo.MutationFunction<UpdateWorkOrderTemplateItemMutation, UpdateWorkOrderTemplateItemMutationVariables>;

/**
 * __useUpdateWorkOrderTemplateItemMutation__
 *
 * To run a mutation, you first call `useUpdateWorkOrderTemplateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkOrderTemplateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkOrderTemplateItemMutation, { data, loading, error }] = useUpdateWorkOrderTemplateItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWorkOrderTemplateItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkOrderTemplateItemMutation, UpdateWorkOrderTemplateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkOrderTemplateItemMutation, UpdateWorkOrderTemplateItemMutationVariables>(UpdateWorkOrderTemplateItemDocument, options);
      }
export type UpdateWorkOrderTemplateItemMutationHookResult = ReturnType<typeof useUpdateWorkOrderTemplateItemMutation>;
export type UpdateWorkOrderTemplateItemMutationResult = Apollo.MutationResult<UpdateWorkOrderTemplateItemMutation>;
export type UpdateWorkOrderTemplateItemMutationOptions = Apollo.BaseMutationOptions<UpdateWorkOrderTemplateItemMutation, UpdateWorkOrderTemplateItemMutationVariables>;
export const CreateWorkOrderTemplateDocument = gql`
    mutation createWorkOrderTemplate($data: WorkOrderTemplateCreateDtoInput!) {
  createWorkOrderTemplate(data: $data) {
    id
  }
}
    `;
export type CreateWorkOrderTemplateMutationFn = Apollo.MutationFunction<CreateWorkOrderTemplateMutation, CreateWorkOrderTemplateMutationVariables>;

/**
 * __useCreateWorkOrderTemplateMutation__
 *
 * To run a mutation, you first call `useCreateWorkOrderTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkOrderTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkOrderTemplateMutation, { data, loading, error }] = useCreateWorkOrderTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWorkOrderTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkOrderTemplateMutation, CreateWorkOrderTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkOrderTemplateMutation, CreateWorkOrderTemplateMutationVariables>(CreateWorkOrderTemplateDocument, options);
      }
export type CreateWorkOrderTemplateMutationHookResult = ReturnType<typeof useCreateWorkOrderTemplateMutation>;
export type CreateWorkOrderTemplateMutationResult = Apollo.MutationResult<CreateWorkOrderTemplateMutation>;
export type CreateWorkOrderTemplateMutationOptions = Apollo.BaseMutationOptions<CreateWorkOrderTemplateMutation, CreateWorkOrderTemplateMutationVariables>;
export const DeleteWorkOrderTemplateDocument = gql`
    mutation deleteWorkOrderTemplate($data: WorkOrderTemplateDeleteDtoInput!) {
  deleteWorkOrderTemplate(data: $data)
}
    `;
export type DeleteWorkOrderTemplateMutationFn = Apollo.MutationFunction<DeleteWorkOrderTemplateMutation, DeleteWorkOrderTemplateMutationVariables>;

/**
 * __useDeleteWorkOrderTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteWorkOrderTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkOrderTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkOrderTemplateMutation, { data, loading, error }] = useDeleteWorkOrderTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteWorkOrderTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkOrderTemplateMutation, DeleteWorkOrderTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkOrderTemplateMutation, DeleteWorkOrderTemplateMutationVariables>(DeleteWorkOrderTemplateDocument, options);
      }
export type DeleteWorkOrderTemplateMutationHookResult = ReturnType<typeof useDeleteWorkOrderTemplateMutation>;
export type DeleteWorkOrderTemplateMutationResult = Apollo.MutationResult<DeleteWorkOrderTemplateMutation>;
export type DeleteWorkOrderTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteWorkOrderTemplateMutation, DeleteWorkOrderTemplateMutationVariables>;
export const GenerateWorkOrdersFromTemplatesDocument = gql`
    mutation generateWorkOrdersFromTemplates {
  generateWorkOrdersFromTemplates
}
    `;
export type GenerateWorkOrdersFromTemplatesMutationFn = Apollo.MutationFunction<GenerateWorkOrdersFromTemplatesMutation, GenerateWorkOrdersFromTemplatesMutationVariables>;

/**
 * __useGenerateWorkOrdersFromTemplatesMutation__
 *
 * To run a mutation, you first call `useGenerateWorkOrdersFromTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateWorkOrdersFromTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateWorkOrdersFromTemplatesMutation, { data, loading, error }] = useGenerateWorkOrdersFromTemplatesMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateWorkOrdersFromTemplatesMutation(baseOptions?: Apollo.MutationHookOptions<GenerateWorkOrdersFromTemplatesMutation, GenerateWorkOrdersFromTemplatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateWorkOrdersFromTemplatesMutation, GenerateWorkOrdersFromTemplatesMutationVariables>(GenerateWorkOrdersFromTemplatesDocument, options);
      }
export type GenerateWorkOrdersFromTemplatesMutationHookResult = ReturnType<typeof useGenerateWorkOrdersFromTemplatesMutation>;
export type GenerateWorkOrdersFromTemplatesMutationResult = Apollo.MutationResult<GenerateWorkOrdersFromTemplatesMutation>;
export type GenerateWorkOrdersFromTemplatesMutationOptions = Apollo.BaseMutationOptions<GenerateWorkOrdersFromTemplatesMutation, GenerateWorkOrdersFromTemplatesMutationVariables>;
export const GetWorkOrderTemplateDocument = gql`
    query getWorkOrderTemplate($id: Int!) {
  workOrderTemplate(where: {id: {eq: $id}}) {
    id
    name
    description
    companyLocationId
    companyLocation {
      name
      address
    }
    companyId
    company {
      id
      name
      description
      taxId
    }
    freeformLocation
    deactivated
    executionDay
    executionDayOfMonth
    organizationalEntityId
    organizationalEntity {
      name
      description
    }
    vehicleId
    vehicle {
      name
      registrationNumber
    }
    priority
    items {
      id
      itemId
      quantity
      note
      item {
        id
        name
        description
      }
    }
  }
}
    `;

/**
 * __useGetWorkOrderTemplateQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkOrderTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrderTemplateQuery, GetWorkOrderTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrderTemplateQuery, GetWorkOrderTemplateQueryVariables>(GetWorkOrderTemplateDocument, options);
      }
export function useGetWorkOrderTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderTemplateQuery, GetWorkOrderTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrderTemplateQuery, GetWorkOrderTemplateQueryVariables>(GetWorkOrderTemplateDocument, options);
        }
export type GetWorkOrderTemplateQueryHookResult = ReturnType<typeof useGetWorkOrderTemplateQuery>;
export type GetWorkOrderTemplateLazyQueryHookResult = ReturnType<typeof useGetWorkOrderTemplateLazyQuery>;
export type GetWorkOrderTemplateQueryResult = Apollo.QueryResult<GetWorkOrderTemplateQuery, GetWorkOrderTemplateQueryVariables>;
export function refetchGetWorkOrderTemplateQuery(variables: GetWorkOrderTemplateQueryVariables) {
      return { query: GetWorkOrderTemplateDocument, variables: variables }
    }
export const GetWorkOrderTemplatesDocument = gql`
    query getWorkOrderTemplates($where: WorkOrderTemplateReturnDtoFilterInput, $take: Int, $skip: Int, $order: [WorkOrderTemplateReturnDtoSortInput!]) {
  workOrderTemplates(where: $where, take: $take, skip: $skip, order: $order) {
    items {
      id
      name
      description
      companyLocationId
      companyLocation {
        name
        address
      }
      companyId
      company {
        id
        name
        taxId
        description
      }
      freeformLocation
      deactivated
      executionDay
      executionDayOfMonth
      organizationalEntityId
      organizationalEntity {
        name
        description
      }
      vehicleId
      vehicle {
        name
        registrationNumber
      }
      priority
      items {
        id
        itemId
        quantity
        note
        item {
          id
          name
          description
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetWorkOrderTemplatesQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderTemplatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetWorkOrderTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkOrderTemplatesQuery, GetWorkOrderTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrderTemplatesQuery, GetWorkOrderTemplatesQueryVariables>(GetWorkOrderTemplatesDocument, options);
      }
export function useGetWorkOrderTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderTemplatesQuery, GetWorkOrderTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrderTemplatesQuery, GetWorkOrderTemplatesQueryVariables>(GetWorkOrderTemplatesDocument, options);
        }
export type GetWorkOrderTemplatesQueryHookResult = ReturnType<typeof useGetWorkOrderTemplatesQuery>;
export type GetWorkOrderTemplatesLazyQueryHookResult = ReturnType<typeof useGetWorkOrderTemplatesLazyQuery>;
export type GetWorkOrderTemplatesQueryResult = Apollo.QueryResult<GetWorkOrderTemplatesQuery, GetWorkOrderTemplatesQueryVariables>;
export function refetchGetWorkOrderTemplatesQuery(variables?: GetWorkOrderTemplatesQueryVariables) {
      return { query: GetWorkOrderTemplatesDocument, variables: variables }
    }
export const UpdateWorkOrderTemplateDocument = gql`
    mutation updateWorkOrderTemplate($data: WorkOrderTemplateUpdateDtoInput!) {
  updateWorkOrderTemplate(data: $data) {
    id
  }
}
    `;
export type UpdateWorkOrderTemplateMutationFn = Apollo.MutationFunction<UpdateWorkOrderTemplateMutation, UpdateWorkOrderTemplateMutationVariables>;

/**
 * __useUpdateWorkOrderTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateWorkOrderTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkOrderTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkOrderTemplateMutation, { data, loading, error }] = useUpdateWorkOrderTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWorkOrderTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkOrderTemplateMutation, UpdateWorkOrderTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkOrderTemplateMutation, UpdateWorkOrderTemplateMutationVariables>(UpdateWorkOrderTemplateDocument, options);
      }
export type UpdateWorkOrderTemplateMutationHookResult = ReturnType<typeof useUpdateWorkOrderTemplateMutation>;
export type UpdateWorkOrderTemplateMutationResult = Apollo.MutationResult<UpdateWorkOrderTemplateMutation>;
export type UpdateWorkOrderTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateWorkOrderTemplateMutation, UpdateWorkOrderTemplateMutationVariables>;
export const CreateWorkOrderDocument = gql`
    mutation createWorkOrder($data: WorkOrderCreateDtoInput!) {
  createWorkOrder(data: $data) {
    id
  }
}
    `;
export type CreateWorkOrderMutationFn = Apollo.MutationFunction<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>;

/**
 * __useCreateWorkOrderMutation__
 *
 * To run a mutation, you first call `useCreateWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkOrderMutation, { data, loading, error }] = useCreateWorkOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>(CreateWorkOrderDocument, options);
      }
export type CreateWorkOrderMutationHookResult = ReturnType<typeof useCreateWorkOrderMutation>;
export type CreateWorkOrderMutationResult = Apollo.MutationResult<CreateWorkOrderMutation>;
export type CreateWorkOrderMutationOptions = Apollo.BaseMutationOptions<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>;
export const DeleteWorkOrderDocument = gql`
    mutation deleteWorkOrder($data: WorkOrderDeleteDtoInput!) {
  deleteWorkOrder(data: $data)
}
    `;
export type DeleteWorkOrderMutationFn = Apollo.MutationFunction<DeleteWorkOrderMutation, DeleteWorkOrderMutationVariables>;

/**
 * __useDeleteWorkOrderMutation__
 *
 * To run a mutation, you first call `useDeleteWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkOrderMutation, { data, loading, error }] = useDeleteWorkOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkOrderMutation, DeleteWorkOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkOrderMutation, DeleteWorkOrderMutationVariables>(DeleteWorkOrderDocument, options);
      }
export type DeleteWorkOrderMutationHookResult = ReturnType<typeof useDeleteWorkOrderMutation>;
export type DeleteWorkOrderMutationResult = Apollo.MutationResult<DeleteWorkOrderMutation>;
export type DeleteWorkOrderMutationOptions = Apollo.BaseMutationOptions<DeleteWorkOrderMutation, DeleteWorkOrderMutationVariables>;
export const DuplicateWorkOrderDocument = gql`
    mutation duplicateWorkOrder($workOrderId: Int!) {
  duplicateWorkOrder(dto: {workOrderId: $workOrderId}) {
    id
  }
}
    `;
export type DuplicateWorkOrderMutationFn = Apollo.MutationFunction<DuplicateWorkOrderMutation, DuplicateWorkOrderMutationVariables>;

/**
 * __useDuplicateWorkOrderMutation__
 *
 * To run a mutation, you first call `useDuplicateWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateWorkOrderMutation, { data, loading, error }] = useDuplicateWorkOrderMutation({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *   },
 * });
 */
export function useDuplicateWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateWorkOrderMutation, DuplicateWorkOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateWorkOrderMutation, DuplicateWorkOrderMutationVariables>(DuplicateWorkOrderDocument, options);
      }
export type DuplicateWorkOrderMutationHookResult = ReturnType<typeof useDuplicateWorkOrderMutation>;
export type DuplicateWorkOrderMutationResult = Apollo.MutationResult<DuplicateWorkOrderMutation>;
export type DuplicateWorkOrderMutationOptions = Apollo.BaseMutationOptions<DuplicateWorkOrderMutation, DuplicateWorkOrderMutationVariables>;
export const GetWorkOrderDocument = gql`
    query getWorkOrder($id: Int!) {
  workOrder(workOrderId: $id) {
    id
    formattedId
    vat
    vatBase
    total
    currentStatusId
    currentStatus {
      dateTime
      notes
      status
    }
    scheduledDate
    pickupRequestId
    pickupRequest {
      id
      companyLocationId
      note
      pickupDate
      reportingUserId
      locationNote
      serviceNote
      company {
        id
        name
      }
      companyLocation {
        id
        name
      }
    }
    companyId
    company {
      id
      name
      description
      taxId
    }
    freeformCompany
    freeformLocation
    companyLocationId
    companyLocation {
      id
      name
      description
      address
      isMainLocation
      location {
        latitude
        longitude
      }
    }
    assignedUserId
    assignedUser {
      id
      userName
    }
    startDateTime
    endDateTime
    fieldWorkerNote
    managerNote
    noteForAccounting
    organizationalEntityId
    organizationalEntity {
      id
      description
      name
    }
    duration
    vehicleId
    vehicle {
      id
      name
      costLocationId
      registrationNumber
    }
    items {
      id
      note
      quantity
      files(where: {fileType: {eq: GENERIC}}) {
        id
        notes
        contentType
        fileName
      }
      itemId
      itemName
      itemDescription
      itemUnitPrice
      vat
      vatBase
      total
    }
    priority
    files(where: {fileType: {eq: GENERIC}, workOrderItemId: {eq: null}}) {
      id
      notes
      contentType
      fileName
    }
    signedPDFs: files(where: {fileType: {eq: SIGNED_WORK_ORDER}}) {
      id
      fileType
      userId
      workOrderId
      workOrderItemId
      notes
      contentType
      fileName
    }
  }
}
    `;

/**
 * __useGetWorkOrderQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkOrderQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrderQuery, GetWorkOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrderQuery, GetWorkOrderQueryVariables>(GetWorkOrderDocument, options);
      }
export function useGetWorkOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderQuery, GetWorkOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrderQuery, GetWorkOrderQueryVariables>(GetWorkOrderDocument, options);
        }
export type GetWorkOrderQueryHookResult = ReturnType<typeof useGetWorkOrderQuery>;
export type GetWorkOrderLazyQueryHookResult = ReturnType<typeof useGetWorkOrderLazyQuery>;
export type GetWorkOrderQueryResult = Apollo.QueryResult<GetWorkOrderQuery, GetWorkOrderQueryVariables>;
export function refetchGetWorkOrderQuery(variables: GetWorkOrderQueryVariables) {
      return { query: GetWorkOrderDocument, variables: variables }
    }
export const GetWorkOrderIdsDocument = gql`
    query getWorkOrderIds($workOrderId: Int!) {
  workOrderIds(workOrderId: $workOrderId) {
    id
    formattedId
    number
    year
  }
}
    `;

/**
 * __useGetWorkOrderIdsQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderIdsQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *   },
 * });
 */
export function useGetWorkOrderIdsQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrderIdsQuery, GetWorkOrderIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrderIdsQuery, GetWorkOrderIdsQueryVariables>(GetWorkOrderIdsDocument, options);
      }
export function useGetWorkOrderIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderIdsQuery, GetWorkOrderIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrderIdsQuery, GetWorkOrderIdsQueryVariables>(GetWorkOrderIdsDocument, options);
        }
export type GetWorkOrderIdsQueryHookResult = ReturnType<typeof useGetWorkOrderIdsQuery>;
export type GetWorkOrderIdsLazyQueryHookResult = ReturnType<typeof useGetWorkOrderIdsLazyQuery>;
export type GetWorkOrderIdsQueryResult = Apollo.QueryResult<GetWorkOrderIdsQuery, GetWorkOrderIdsQueryVariables>;
export function refetchGetWorkOrderIdsQuery(variables: GetWorkOrderIdsQueryVariables) {
      return { query: GetWorkOrderIdsDocument, variables: variables }
    }
export const GetWorkOrderTotalsDocument = gql`
    query getWorkOrderTotals($workOrderId: Int!) {
  workOrderTotals(workOrderId: $workOrderId) {
    vatBase
    vat
    total
  }
}
    `;

/**
 * __useGetWorkOrderTotalsQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderTotalsQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *   },
 * });
 */
export function useGetWorkOrderTotalsQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrderTotalsQuery, GetWorkOrderTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrderTotalsQuery, GetWorkOrderTotalsQueryVariables>(GetWorkOrderTotalsDocument, options);
      }
export function useGetWorkOrderTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderTotalsQuery, GetWorkOrderTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrderTotalsQuery, GetWorkOrderTotalsQueryVariables>(GetWorkOrderTotalsDocument, options);
        }
export type GetWorkOrderTotalsQueryHookResult = ReturnType<typeof useGetWorkOrderTotalsQuery>;
export type GetWorkOrderTotalsLazyQueryHookResult = ReturnType<typeof useGetWorkOrderTotalsLazyQuery>;
export type GetWorkOrderTotalsQueryResult = Apollo.QueryResult<GetWorkOrderTotalsQuery, GetWorkOrderTotalsQueryVariables>;
export function refetchGetWorkOrderTotalsQuery(variables: GetWorkOrderTotalsQueryVariables) {
      return { query: GetWorkOrderTotalsDocument, variables: variables }
    }
export const GetWorkOrdersDocument = gql`
    query getWorkOrders($dto: WorkOrderFilterDtoInput!, $where: WorkOrderReturnDtoFilterInput, $take: Int, $skip: Int, $order: [WorkOrderReturnDtoSortInput!]) {
  workOrders(dto: $dto, where: $where, take: $take, skip: $skip, order: $order) {
    items {
      id
      isDeleted
      formattedId
      currentStatusId
      currentStatus {
        dateTime
        notes
        status
      }
      pickupRequestId
      pickupRequest {
        id
        companyLocationId
        note
        pickupDate
        reportingUserId
      }
      company {
        name
        description
        taxId
      }
      freeformCompany
      companyLocationId
      companyLocation {
        name
        address
        isMainLocation
        location {
          latitude
          longitude
        }
      }
      freeformLocation
      scheduledDate
      assignedUserId
      assignedUser {
        id
        userName
      }
      startDateTime
      endDateTime
      fieldWorkerNote
      managerNote
      noteForAccounting
      organizationalEntityId
      organizationalEntity {
        description
        name
      }
      duration
      vehicleId
      vehicle {
        name
        id
        costLocationId
        registrationNumber
      }
      items {
        id
        note
        quantity
        itemId
        itemName
        itemDescription
        itemUnitPrice
        unitOfMeasureSymbol
        itemUnitsContained
      }
      signedPDFs: files(where: {fileType: {eq: SIGNED_WORK_ORDER}}) {
        id
        fileType
        userId
        workOrderId
        workOrderItemId
        notes
        contentType
        fileName
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetWorkOrdersQuery__
 *
 * To run a query within a React component, call `useGetWorkOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrdersQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetWorkOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrdersQuery, GetWorkOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrdersQuery, GetWorkOrdersQueryVariables>(GetWorkOrdersDocument, options);
      }
export function useGetWorkOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrdersQuery, GetWorkOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrdersQuery, GetWorkOrdersQueryVariables>(GetWorkOrdersDocument, options);
        }
export type GetWorkOrdersQueryHookResult = ReturnType<typeof useGetWorkOrdersQuery>;
export type GetWorkOrdersLazyQueryHookResult = ReturnType<typeof useGetWorkOrdersLazyQuery>;
export type GetWorkOrdersQueryResult = Apollo.QueryResult<GetWorkOrdersQuery, GetWorkOrdersQueryVariables>;
export function refetchGetWorkOrdersQuery(variables: GetWorkOrdersQueryVariables) {
      return { query: GetWorkOrdersDocument, variables: variables }
    }
export const GetWorkOrdersPlainDocument = gql`
    query getWorkOrdersPlain($where: WorkOrderReturnDtoFilterInput, $take: Int, $skip: Int, $search: String) {
  workOrders(
    dto: {search: $search}
    where: $where
    take: $take
    skip: $skip
    order: {id: DESC}
  ) {
    items {
      id
      formattedId
      currentStatusId
      currentStatus {
        dateTime
        notes
        status
      }
      pickupRequestId
      pickupRequest {
        id
        companyLocationId
        note
        pickupDate
        reportingUserId
      }
      company {
        name
        description
        taxId
      }
      freeformCompany
      companyLocationId
      companyLocation {
        name
        address
        isMainLocation
        location {
          latitude
          longitude
        }
      }
      freeformLocation
      scheduledDate
      assignedUserId
      assignedUser {
        id
        userName
      }
      startDateTime
      endDateTime
      fieldWorkerNote
      managerNote
      noteForAccounting
      organizationalEntityId
      organizationalEntity {
        description
        name
      }
      duration
      vehicleId
      vehicle {
        name
        id
        costLocationId
        registrationNumber
      }
      items {
        id
        note
        quantity
        itemId
        itemName
        itemDescription
        itemUnitPrice
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetWorkOrdersPlainQuery__
 *
 * To run a query within a React component, call `useGetWorkOrdersPlainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrdersPlainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrdersPlainQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetWorkOrdersPlainQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkOrdersPlainQuery, GetWorkOrdersPlainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrdersPlainQuery, GetWorkOrdersPlainQueryVariables>(GetWorkOrdersPlainDocument, options);
      }
export function useGetWorkOrdersPlainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrdersPlainQuery, GetWorkOrdersPlainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrdersPlainQuery, GetWorkOrdersPlainQueryVariables>(GetWorkOrdersPlainDocument, options);
        }
export type GetWorkOrdersPlainQueryHookResult = ReturnType<typeof useGetWorkOrdersPlainQuery>;
export type GetWorkOrdersPlainLazyQueryHookResult = ReturnType<typeof useGetWorkOrdersPlainLazyQuery>;
export type GetWorkOrdersPlainQueryResult = Apollo.QueryResult<GetWorkOrdersPlainQuery, GetWorkOrdersPlainQueryVariables>;
export function refetchGetWorkOrdersPlainQuery(variables?: GetWorkOrdersPlainQueryVariables) {
      return { query: GetWorkOrdersPlainDocument, variables: variables }
    }
export const MarkWorkOrdersAsInvoicedDocument = gql`
    mutation markWorkOrdersAsInvoiced($workOrderIds: [Int!]!) {
  batchChangeWorkOrdersStatus(workOrderIds: $workOrderIds, status: SENT_TO_ERP)
}
    `;
export type MarkWorkOrdersAsInvoicedMutationFn = Apollo.MutationFunction<MarkWorkOrdersAsInvoicedMutation, MarkWorkOrdersAsInvoicedMutationVariables>;

/**
 * __useMarkWorkOrdersAsInvoicedMutation__
 *
 * To run a mutation, you first call `useMarkWorkOrdersAsInvoicedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkWorkOrdersAsInvoicedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markWorkOrdersAsInvoicedMutation, { data, loading, error }] = useMarkWorkOrdersAsInvoicedMutation({
 *   variables: {
 *      workOrderIds: // value for 'workOrderIds'
 *   },
 * });
 */
export function useMarkWorkOrdersAsInvoicedMutation(baseOptions?: Apollo.MutationHookOptions<MarkWorkOrdersAsInvoicedMutation, MarkWorkOrdersAsInvoicedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkWorkOrdersAsInvoicedMutation, MarkWorkOrdersAsInvoicedMutationVariables>(MarkWorkOrdersAsInvoicedDocument, options);
      }
export type MarkWorkOrdersAsInvoicedMutationHookResult = ReturnType<typeof useMarkWorkOrdersAsInvoicedMutation>;
export type MarkWorkOrdersAsInvoicedMutationResult = Apollo.MutationResult<MarkWorkOrdersAsInvoicedMutation>;
export type MarkWorkOrdersAsInvoicedMutationOptions = Apollo.BaseMutationOptions<MarkWorkOrdersAsInvoicedMutation, MarkWorkOrdersAsInvoicedMutationVariables>;
export const RestoreDeletedWorkOrderDocument = gql`
    mutation restoreDeletedWorkOrder($workOrderId: Int!) {
  restoreDeletedWorkOrder(dto: {workOrderId: $workOrderId}) {
    id
  }
}
    `;
export type RestoreDeletedWorkOrderMutationFn = Apollo.MutationFunction<RestoreDeletedWorkOrderMutation, RestoreDeletedWorkOrderMutationVariables>;

/**
 * __useRestoreDeletedWorkOrderMutation__
 *
 * To run a mutation, you first call `useRestoreDeletedWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreDeletedWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreDeletedWorkOrderMutation, { data, loading, error }] = useRestoreDeletedWorkOrderMutation({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *   },
 * });
 */
export function useRestoreDeletedWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<RestoreDeletedWorkOrderMutation, RestoreDeletedWorkOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreDeletedWorkOrderMutation, RestoreDeletedWorkOrderMutationVariables>(RestoreDeletedWorkOrderDocument, options);
      }
export type RestoreDeletedWorkOrderMutationHookResult = ReturnType<typeof useRestoreDeletedWorkOrderMutation>;
export type RestoreDeletedWorkOrderMutationResult = Apollo.MutationResult<RestoreDeletedWorkOrderMutation>;
export type RestoreDeletedWorkOrderMutationOptions = Apollo.BaseMutationOptions<RestoreDeletedWorkOrderMutation, RestoreDeletedWorkOrderMutationVariables>;
export const SendWorkOrderPdfToPartnerDocument = gql`
    mutation sendWorkOrderPDFToPartner($dto: SendWorkOrderPDFDtoInput!) {
  sendWorkOrderPDFToPartner(dto: $dto)
}
    `;
export type SendWorkOrderPdfToPartnerMutationFn = Apollo.MutationFunction<SendWorkOrderPdfToPartnerMutation, SendWorkOrderPdfToPartnerMutationVariables>;

/**
 * __useSendWorkOrderPdfToPartnerMutation__
 *
 * To run a mutation, you first call `useSendWorkOrderPdfToPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendWorkOrderPdfToPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWorkOrderPdfToPartnerMutation, { data, loading, error }] = useSendWorkOrderPdfToPartnerMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSendWorkOrderPdfToPartnerMutation(baseOptions?: Apollo.MutationHookOptions<SendWorkOrderPdfToPartnerMutation, SendWorkOrderPdfToPartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendWorkOrderPdfToPartnerMutation, SendWorkOrderPdfToPartnerMutationVariables>(SendWorkOrderPdfToPartnerDocument, options);
      }
export type SendWorkOrderPdfToPartnerMutationHookResult = ReturnType<typeof useSendWorkOrderPdfToPartnerMutation>;
export type SendWorkOrderPdfToPartnerMutationResult = Apollo.MutationResult<SendWorkOrderPdfToPartnerMutation>;
export type SendWorkOrderPdfToPartnerMutationOptions = Apollo.BaseMutationOptions<SendWorkOrderPdfToPartnerMutation, SendWorkOrderPdfToPartnerMutationVariables>;
export const SetWorkOrderStatusDocument = gql`
    mutation setWorkOrderStatus($status: WorkOrderStatus!, $workOrderId: Int!) {
  setWorkOrderStatus(status: $status, workOrderId: $workOrderId)
}
    `;
export type SetWorkOrderStatusMutationFn = Apollo.MutationFunction<SetWorkOrderStatusMutation, SetWorkOrderStatusMutationVariables>;

/**
 * __useSetWorkOrderStatusMutation__
 *
 * To run a mutation, you first call `useSetWorkOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWorkOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWorkOrderStatusMutation, { data, loading, error }] = useSetWorkOrderStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *      workOrderId: // value for 'workOrderId'
 *   },
 * });
 */
export function useSetWorkOrderStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetWorkOrderStatusMutation, SetWorkOrderStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetWorkOrderStatusMutation, SetWorkOrderStatusMutationVariables>(SetWorkOrderStatusDocument, options);
      }
export type SetWorkOrderStatusMutationHookResult = ReturnType<typeof useSetWorkOrderStatusMutation>;
export type SetWorkOrderStatusMutationResult = Apollo.MutationResult<SetWorkOrderStatusMutation>;
export type SetWorkOrderStatusMutationOptions = Apollo.BaseMutationOptions<SetWorkOrderStatusMutation, SetWorkOrderStatusMutationVariables>;
export const UpdateWorkOrderDocument = gql`
    mutation updateWorkOrder($data: WorkOrderUpdateDtoInput!) {
  updateWorkOrder(data: $data) {
    id
  }
}
    `;
export type UpdateWorkOrderMutationFn = Apollo.MutationFunction<UpdateWorkOrderMutation, UpdateWorkOrderMutationVariables>;

/**
 * __useUpdateWorkOrderMutation__
 *
 * To run a mutation, you first call `useUpdateWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkOrderMutation, { data, loading, error }] = useUpdateWorkOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkOrderMutation, UpdateWorkOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkOrderMutation, UpdateWorkOrderMutationVariables>(UpdateWorkOrderDocument, options);
      }
export type UpdateWorkOrderMutationHookResult = ReturnType<typeof useUpdateWorkOrderMutation>;
export type UpdateWorkOrderMutationResult = Apollo.MutationResult<UpdateWorkOrderMutation>;
export type UpdateWorkOrderMutationOptions = Apollo.BaseMutationOptions<UpdateWorkOrderMutation, UpdateWorkOrderMutationVariables>;