import React, {FC, ReactElement, useContext, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../Contexts/AuthContext";
import {MenuDefinition, MenuItemType} from "../Static/MenuDefinition";
import {Badge, ButtonProps, Card, CardProps, theme} from "antd";
import {makeStyles} from "@griffel/react";
import {UnassignedPickupRequestCountContext} from "../Contexts/UnassignedPickupRequestCountContext";

const buttonStyle = makeStyles({
    root: {
        width: "300px",
        "> span.fui-CompoundButton__contentContainer": {
            width: "100%",
        }
    },
    icon: {
        "> svg": {
            width: "30px",
            height: "30px"
        }
    }
})

interface IDashboardItemProps {
    title: string;
    description?: string;
    icon?: ReactElement;
    cardProps?: CardProps;
    badge?: number;
    navigateTo: string;
    isDanger?: boolean;
}

const DashboardItem: FC<IDashboardItemProps> = ({
                                                    title,
                                                    description,
                                                    icon,
                                                    cardProps,
                                                    badge,
                                                    navigateTo,
                                                    isDanger
                                                }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const buttonStyles = buttonStyle();

    return <Badge count={badge} offset={[-30, 20]}>
        <Card
            hoverable
            className={buttonStyles.root}
            {...cardProps}
            onClick={() => navigate(navigateTo)}>
            <Card.Meta avatar={<div style={{width: 30, height: 30}} className={buttonStyles.icon}>
                {icon}
            </div>} title={t(title)} description={description}/>
        </Card>
    </Badge>;
}

export const DashboardItems: FC = () => {
    const {auth: {roles}} = useContext(AuthContext);
    const {t} = useTranslation();
    const {token: {colorPrimary}} = theme.useToken();
    const count = useContext(UnassignedPickupRequestCountContext)

    const menu = useMemo(() => MenuDefinition(roles), [roles]);

    return <div style={{padding: 50, gap: 25}}>
        {menu.map(f => <div key={f.translationKey}>
            <h3>{t(f.translationKey)}</h3>
            <div style={{gap: 25, flexWrap: "wrap", display: "flex", flexDirection: "row"}}>
                {f.children?.map(c => {
                    if (c.type === MenuItemType.PickupRequest) {
                        return <DashboardItem title={c.translationKey}
                                              cardProps={{color: colorPrimary}}
                                              badge={count}
                                              icon={c.icon}
                                              navigateTo={c.route ?? ""}/>;
                    }

                    return <DashboardItem key={c.translationKey} title={c.translationKey}
                                          icon={c.icon}
                                          navigateTo={c.route ?? ""}/>;
                })}
            </div>
        </div>)}
    </div>;
}