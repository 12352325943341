import dayjs from "dayjs";
import {useCallback, useContext, useMemo} from "react";
import {AuthContext} from "../Contexts/AuthContext";
import {useTranslation} from "react-i18next";

export const useExport = () => {
    const {auth: {token}} = useContext(AuthContext);
    const {t} = useTranslation();

    const singleSheetXLSX = useCallback(async (data: any[][], fileName: string) => {
        try {
            const fetched = await fetch(`/api/Exports/SingleSheetXLSX?filename=${fileName}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(data),
            });

            const blobbed = await fetched.blob();

            const url = window.URL.createObjectURL(blobbed);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${fileName}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (e) {
            console.error(e);
            alert(t("Failed to export XLSX"));
        }
    }, [t, token]);

    const multiSheetXLSX = useCallback(async (data: {
        [key: string]: any[][]
    }, fileName: string) => {
        try {
            const fetched = await fetch(`/api/Exports/MultiSheetXLSX?filename=${fileName}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(data),
            });

            const blobbed = await fetched.blob();

            const url = window.URL.createObjectURL(blobbed);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${fileName}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (e) {
            console.error(e);
            alert(t("Failed to export XLSX"));
        }
    }, [t, token]);

    const csv = useCallback(async (data: any[][], fileName: string) => {
        try {
            const fetched = await fetch(`/api/Exports/CSV?filename=${fileName}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(data),
            });

            const blobbed = await fetched.blob();

            const url = window.URL.createObjectURL(blobbed);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${fileName}.csv`;
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (e) {
            console.error(e);
            alert(t("Failed to export CSV"));
        }
    }, [t, token]);

    const pdf = useCallback(async (options: PdfExportOptions) => {
        try {
            const fetched = await fetch(`/api/Exports/PDF`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(options),
            });

            const blobbed = await fetched.blob();

            const url = window.URL.createObjectURL(blobbed);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${options.fileName}.pdf`;
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (e) {
            console.error(e);
            alert(t("Failed to export PDF"));
        }
    }, [t, token]);

    return {singleSheetXLSX, multiSheetXLSX, csv, pdf};
}

export interface PdfExportOptions{
    html: string,
    fileName: string,
    footerCenter?: string,
}