import {UserRole} from "../graphql-types";
import {
    BookExclamationMarkRegular,
    BoxMultipleRegular,
    BuildingRegular,
    DataBarVerticalAscendingRegular,
    ClipboardTextLtrRegular,
    DocumentSplitHintRegular,
    FluentIcon,
    GlobeLocationRegular,
    OrganizationRegular,
    PeopleCommunityRegular,
    PersonAccountsRegular,
    PersonCallRegular,
    RulerRegular,
    TagMultipleRegular,
    TaskListRtlRegular,
    VehicleTruckProfileRegular,

    BuildingBank20Regular,
    BookExclamationMark20Regular,
    ClipboardTextLtr20Regular,
    PeopleCommunity20Regular,
    Database20Regular,
    Book20Regular,
    DataBarVerticalAscending20Regular,
    PersonAccounts20Regular,
    PersonCall20Regular,
    Building20Regular,
    GlobeLocation20Regular,
    DocumentSplitHint20Regular,
    TaskListRtl20Regular,
    VehicleTruckProfile20Regular,
    BoxMultiple20Regular,
    Ruler20Regular,
    Organization20Regular,
    TagMultiple20Regular,
    PersonMoneyRegular,
    PersonMoney20Regular,
    NoteRegular,
    Note20Regular,
    PersonWrenchRegular,
    PersonWrench20Regular,
    BriefcaseRegular,
    Briefcase20Regular,
    NumberSymbolSquareRegular,
    NumberSymbolSquare20Regular,
    DocumentPercentRegular, DocumentPercent20Regular
} from "@fluentui/react-icons";
import React from "react";
import {PlusOutlined} from "@ant-design/icons";

export enum MenuItemType {
    PickupRequest,
    Administration
}

type MenuItem = {
    type?: MenuItemType;
    translationKey: string;
    icon?: JSX.Element;
    smallIcon?: JSX.Element;
    children?: MenuItem[];
    route: string;
};

export const MenuDefinition = (roles: UserRole[]): MenuItem[] => {
    const items: MenuItem[] = [];

    if (roles.includes(UserRole.Manager) || roles.includes(UserRole.Accounting)) {
        items.push({
            translationKey: "administration",
            route: "/ManagerAdministration",
            icon: <BuildingBank20Regular/>,
            type: MenuItemType.Administration,
            children: [
                {
                    type: MenuItemType.PickupRequest,
                    translationKey: "pickup-requests",
                    icon: <BookExclamationMarkRegular/>,
                    smallIcon: <BookExclamationMark20Regular/>,
                    route: "/Manager/PickupRequests"
                },
                {
                    translationKey: "work-orders",
                    icon: <ClipboardTextLtrRegular/>,
                    smallIcon: <ClipboardTextLtr20Regular/>,
                    route: "/Manager/WorkOrders"
                },
                {
                    translationKey: "reports",
                    icon: <DataBarVerticalAscendingRegular/>,
                    smallIcon: <DataBarVerticalAscending20Regular/>,
                    route: "/Manager/Reports"
                }
            ]
        }, {
            translationKey: "users",
            route: "/ManagerUsers",
            icon: <PeopleCommunity20Regular/>,
            children: [
                {
                    translationKey: "users",
                    icon: <PeopleCommunityRegular/>,
                    smallIcon: <PeopleCommunity20Regular/>,
                    route: "/Manager/Users"
                },
                {
                    translationKey: "partner-contacts",
                    icon: <PersonCallRegular/>,
                    smallIcon: <PersonCall20Regular/>,
                    route: "/Manager/PartnerContacts"
                },
                {
                    translationKey: "field-workers",
                    icon: <PersonWrenchRegular/>,
                    smallIcon: <PersonWrench20Regular/>,
                    route: "/Manager/FieldWorkers"
                },
                {
                    translationKey: "accounting",
                    icon: <PersonMoneyRegular/>,
                    smallIcon: <PersonMoney20Regular/>,
                    route: "/Manager/Accountants"
                },
                {
                    translationKey: "managers",
                    icon: <PersonAccountsRegular/>,
                    smallIcon: <PersonMoney20Regular/>,
                    route: "/Manager/Managers"
                },
            ]
        }, {
            translationKey: "basic-data",
            route: "/ManagerBasicData",
            icon: <Book20Regular/>,
            children: [
                {
                    translationKey: "companies",
                    icon: <BuildingRegular/>,
                    smallIcon: <Building20Regular/>,
                    route: "/Manager/Companies"
                },
                {
                    translationKey: "company-locations",
                    icon: <GlobeLocationRegular/>,
                    smallIcon: <GlobeLocation20Regular/>,
                    route: "/Manager/CompanyLocations"
                }
            ]
        }, {
            translationKey: "system",
            route: "/ManagerSystem",
            icon: <Database20Regular/>,
            children: [
                {
                    translationKey: "work-order-templates",
                    icon: <DocumentSplitHintRegular/>,
                    smallIcon: <DocumentSplitHint20Regular/>,
                    route: "/Manager/WorkOrderTemplates"
                },
                {
                    translationKey: "work-order-status-changes",
                    icon: <TaskListRtlRegular/>,
                    smallIcon: <TaskListRtl20Regular/>,
                    route: "/Manager/WorkOrderStatusChanges"
                },
                {
                    translationKey: "vehicles",
                    icon: <VehicleTruckProfileRegular/>,
                    smallIcon: <VehicleTruckProfile20Regular/>,
                    route: "/Manager/Vehicles"
                },
                {
                    translationKey: "costLocations",
                    icon: <BriefcaseRegular/>,
                    smallIcon: <Briefcase20Regular/>,
                    route: "/Manager/CostLocations"
                },
                {
                    translationKey: "items",
                    icon: <BoxMultipleRegular/>,
                    smallIcon: <BoxMultiple20Regular/>,
                    route: "/Manager/Items"
                },
                {
                    translationKey: "units-of-measure",
                    icon: <RulerRegular/>,
                    smallIcon: <Ruler20Regular/>,
                    route: "/Manager/UnitsOfMeasure"
                },
                {
                    translationKey: "vat-types",
                    icon: <DocumentPercentRegular/>,
                    smallIcon: <DocumentPercent20Regular/>,
                    route: "/Manager/VATTypes"
                },
                {
                    translationKey: "financial-accounts",
                    icon: <NumberSymbolSquareRegular/>,
                    smallIcon: <NumberSymbolSquare20Regular/>,
                    route: "/Manager/FinancialAccounts"
                },
                {
                    translationKey: "organizational-entities",
                    icon: <OrganizationRegular/>,
                    smallIcon: <Organization20Regular/>,
                    route: "/Manager/OrganizationalEntities"
                }, {
                    translationKey: "partner-portal-notes",
                    icon: <NoteRegular/>,
                    smallIcon: <Note20Regular/>,
                    route: "/Manager/PartnerPortalNotes"
                }
            ]
        });
    } else if (roles.includes(UserRole.FieldWorker)) {

    } else if (roles.includes(UserRole.PartnerContact)) {

    }

    return items;
}