import {useMemo} from "react";
import {ITenantDeleting} from "@ib-tehnologije/react-widgets";
import {DeleteCompanyLocationMutation, useDeleteCompanyLocationMutation} from "../graphql-types";

export const useCompanyLocationDeleting = () => {
    const [deleteCompanyLocation] = useDeleteCompanyLocationMutation();

    return useMemo<ITenantDeleting<DeleteCompanyLocationMutation, any, any, "data">>(() => ({
        modelKey: "data",
        fn: deleteCompanyLocation,
        selectionToDto: (selection: { id: number }) => ({
            id: selection.id!,
        })
    }), []);
}