import React, {FC, useEffect, useState} from "react";
import {
    useGetCompaniesLazyQuery,
    useGetCompaniesQuery
} from "../graphql-types";
import {useTranslation} from "react-i18next";
import {
    FormOrientation,
    RenderAntdGraphQLList, RenderAntdStandaloneForm, TextFieldElement,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {useNavigate} from "react-router-dom";
import {useCompanyCreating} from "../Hooks/useCompanyCreating";
import {useCompanyUpdating} from "../Hooks/useCompanyUpdating";
import {Anchor, Button, Typography} from "antd";
import {RightOutlined, SearchOutlined} from "@ant-design/icons";
import {useCompanyDeleting} from "../Hooks/useCompanyDeleting";
import {useLocalStorage} from "usehooks-ts";

const ManagerCompaniesPage: FC = () => {
    const {t} = useTranslation();
    const [a, q] = useGetCompaniesLazyQuery();
    const [filter, setFilter] = useLocalStorage<any>("companiesfilter", {});
    const [filterLoading, setFilterLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const creating = useCompanyCreating();
    const updating = useCompanyUpdating();
    const deleting = useCompanyDeleting();

    useEffect(() => {
        if (!filterLoading) {
            setFilterLoading(true);
            const refetchFilter = {
                where: filter.search ? {
                    or: [{
                        name: {
                            contains: filter.search
                        },
                    }, {
                        description: {
                            contains: filter.search
                        },
                    }, {
                        taxId: {
                            contains: filter.search
                        }
                    }, {
                        externalId: {
                            contains: filter.search
                        }
                    }]
                } : {}
            };
            console.log("filter", filter, refetchFilter);
            a({variables: refetchFilter}).finally(() => {
                setFilterLoading(false);
            });
        }
    }, [filter]);

    return <TitleAndContentLayout title={"companies"} pad>
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                width: "100%"
            }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: 250,
            }}>
                <RenderAntdStandaloneForm
                    formSettings={{inline: true, orientation: FormOrientation.VERTICAL}}
                    loading={q.loading}
                    baseFormData={filter}
                    formDefinition={{
                        search: {
                            type: new TextFieldElement({})
                        }
                    }}
                    submission={{
                        title: "start-search",
                        onSubmit: (values) => {
                            setFilter(values);
                        }
                    }}
                />
            </div>
            <div style={{flex: 1, width: "100%"}}>
                <RenderAntdGraphQLList
                    tableData={q.data?.companies}
                    deleting={deleting}
                    columns={[{
                        columnId: "id",
                        renderCell: (row) => <div style={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <Typography.Text>{row.id}</Typography.Text>
                            <Typography.Text type={"secondary"}>{row.externalId}</Typography.Text>
                        </div>,
                    }, {
                        columnId: "company-name",
                        renderCell: (row) => <span>{row.name}</span>,
                    }, {
                        columnId: "taxId",
                        renderCell: (row) => <span>{row.taxId}</span>,
                    }, {
                        columnId: "actions",
                        renderCell: (row) => <Button type={"primary"}
                                                     onClick={() => navigate(`/Manager/Companies/${row.id}/CompanyLocations`, {state: row})}>
                            {t("locations")}
                            <RightOutlined/>
                        </Button>,
                    }]}
                    query={q}
                    loading={q.loading}
                    creating={creating}
                    getRowId={g => g.id}
                    updating={updating}
                />
            </div>
        </div>
    </TitleAndContentLayout>;
}

export default ManagerCompaniesPage;