import {createContext, FC, PropsWithChildren, useContext, useMemo} from "react";
import {
    useGetUnassignedPickupRequestsCountQuery,
    useUnassignedPickupRequestCountChangedSubscription
} from "../graphql-types";
import {AuthContext} from "./AuthContext";

export const UnassignedPickupRequestCountContext = createContext<number>(0);

export const UnassignedPickupRequestCountProvider: FC<PropsWithChildren> = ({children}) => {
    const {data: queryData} = useGetUnassignedPickupRequestsCountQuery();
    const {data: subscriptionData} = useUnassignedPickupRequestCountChangedSubscription();

    const count = useMemo(() => {
        if (subscriptionData?.unassignedPickupRequestCountChanged !== undefined) {
            return subscriptionData?.unassignedPickupRequestCountChanged ?? 0;
        }

        if (queryData?.unassignedPickupRequestCount !== undefined) {
            return queryData?.unassignedPickupRequestCount ?? 0;
        }

        return 0;
    }, [
        queryData?.unassignedPickupRequestCount,
        subscriptionData?.unassignedPickupRequestCountChanged,
        queryData,
        subscriptionData,
    ]);

    return <UnassignedPickupRequestCountContext.Provider value={count}>
        {children}
    </UnassignedPickupRequestCountContext.Provider>;
}