import React, {FC, useMemo} from "react";
import {
    ITenantCreating,
    ITenantDeleting,
    ITenantUpdating, MultilineTextElement, RenderAntdGraphQLList, TextFieldElement,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {useTranslation} from "react-i18next";
import {
    CreateUnitOfMeasureMutation,
    DeleteUnitOfMeasureMutation,
    UnitOfMeasureCreateDtoInput,
    UnitOfMeasureDeleteDtoInput,
    UnitOfMeasureUpdateDtoInput,
    UpdateUnitOfMeasureMutation,
    useCreateUnitOfMeasureMutation,
    useDeleteUnitOfMeasureMutation,
    useGetUnitsOfMeasureQuery,
    useUpdateUnitOfMeasureMutation
} from "../graphql-types";
import {ActualUnitOfMeasureReturnDto} from "../ReturnTypes/ActualUnitOfMeasureReturnDto";
import {CheckboxChecked20Filled} from "@fluentui/react-icons";
import {Typography} from "antd";

const ManagerUnitsOfMeasurePage: FC = () => {
    const {t} = useTranslation();
    const q = useGetUnitsOfMeasureQuery();
    const [updateUnitOfMeasure] = useUpdateUnitOfMeasureMutation();
    const [createUnitOfMeasure] = useCreateUnitOfMeasureMutation();
    const [deleteUnitOfMeasure] = useDeleteUnitOfMeasureMutation();
    
    const creating = useMemo<ITenantCreating<CreateUnitOfMeasureMutation, ActualUnitOfMeasureReturnDto, UnitOfMeasureCreateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            name: {
                type: new TextFieldElement({})
            },
            description: {
                type: new MultilineTextElement({rows: 3}),
            },
            symbol: {
                type: new TextFieldElement({})
            },
            externalId: {
                type: new TextFieldElement({})
            },
        },
        selectionToDuplicateDto: (selection) => ({
            name: selection?.name,
            description: selection?.description,
            symbol: selection?.symbol,
            externalId: selection?.externalId,
        }),
        formDataToDto: (formData) => ({
            name: formData.name,
            description: formData.description,
            symbol: formData.symbol,
            externalId: formData.externalId,
        }),
        baseFormData: {},
        fn: createUnitOfMeasure
    }), []);
    
    const updating = useMemo<ITenantUpdating<UpdateUnitOfMeasureMutation, ActualUnitOfMeasureReturnDto, UnitOfMeasureUpdateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            name: {
                type: new TextFieldElement({})
            },
            description: {
                type: new MultilineTextElement({rows: 3}),
            },
            symbol: {
                type: new TextFieldElement({})
            },
            externalId: {
                type: new TextFieldElement({})
            },
        },
        selectionToBaseFormData: (selection) => ({
            id: selection?.id,
            name: selection?.name,
            description: selection?.description,
            symbol: selection?.symbol,
            externalId: selection?.externalId,
        }),
        formDataToDto: (formData) => ({
            id: formData.id,
            name: formData.name,
            description: formData.description,
            symbol: formData.symbol,
            externalId: formData.externalId,
        }),
        fn: updateUnitOfMeasure
    }), []);
    
    const deleting = useMemo<ITenantDeleting<DeleteUnitOfMeasureMutation, ActualUnitOfMeasureReturnDto, UnitOfMeasureDeleteDtoInput, "data">>(() => ({
        modelKey: "data",
        selectionToDto: (selection) => ({
            id: selection?.id,
        }),
        fn: deleteUnitOfMeasure
    }), []);

    return <TitleAndContentLayout title={"units-of-measure"} pad>
        <RenderAntdGraphQLList
            deleting={deleting}
            tableData={q.data?.unitsOfMeasure}
            columns={[{
                columnId: "id",
                renderCell: (row) => <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <Typography.Text>{row.id}</Typography.Text>
                    <Typography.Text type={"secondary"}>{row.externalId}</Typography.Text>
                </div>,
            }, {
                columnId: "name",
                renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                    <Typography.Text>{row.name}</Typography.Text>
                    <Typography.Text type={"secondary"}>{row.description}</Typography.Text>
                </div>,
                width: 200
            }, {
                columnId: "symbol",
                renderCell: (row) => row.symbol,
            }]}
            query={q}
            loading={q.loading}
            creating={creating}
            getRowId={g => g.id}
            updating={updating}
        />
    </TitleAndContentLayout>
}

export default ManagerUnitsOfMeasurePage;