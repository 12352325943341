import {useMemo} from "react";
import {CustomSearchBoxElement} from "@ib-tehnologije/react-widgets";
import {useGetUnitsOfMeasureLazyQuery, useGetUnitsOfMeasureQuery} from "../graphql-types";

export const useUnitOfMeasureIdPicker = () => {
    return useMemo(() => ({
        type: new CustomSearchBoxElement({
            query: useGetUnitsOfMeasureLazyQuery,
            getQueryData: ({data}) => data?.unitsOfMeasure,
            getKey: (g) => g.id,
            makeSubtitle: (g) => g.symbol,
            makeTitle: (g) => g.name,
            makeWhere: (values, search) => ({
                or: [{
                    name: {
                        contains: search ?? ""
                    },
                }, {
                    symbol: {
                        contains: search ?? ""
                    }
                }]
            }),
            valueKey: "unitOfMeasure",
        })
    }), []);
}