import React, {FC, useCallback, useContext, useMemo} from "react";
import {TitleAndContentLayout} from "@ib-tehnologije/react-widgets";
import {Menu, MenuProps} from "antd";
import {MenuInfo} from "rc-menu/lib/interface";
import {useTranslation} from "react-i18next";
import "../Styles/ManagerReportsPage.css";
import {ReportsPageContext, ReportsPageProvider} from "../Contexts/ReportsPageContext";
import '@antv/s2-react/dist/style.min.css';
import {ReportTableView} from "../Components/ReportTableView";
import {TotalWorkOrderCountReportView} from "../Components/TotalWorkOrderCountReportView";

enum reportView {
    TotalWorkOrderCountCalendar = "total_work_order_count_calendar",
    TotalWorkOrderCountGrid = "total_work_order_count_grid"
}

const ManagerReportsPage: FC = () => {
    const {t} = useTranslation();
    const [selectedMenuKey, setSelectedMenuKey] = React.useState<reportView>(reportView.TotalWorkOrderCountCalendar);

    const onMenuClick = useCallback((info: MenuInfo) => {
        setSelectedMenuKey(info.key as reportView);
    }, []);

    const items = useMemo<MenuProps["items"]>(() => [{
        label: t(`enums:reportViews.${reportView.TotalWorkOrderCountCalendar}`),
        key: reportView.TotalWorkOrderCountCalendar
    }, {
        label: t(`enums:reportViews.${reportView.TotalWorkOrderCountGrid}`),
        key: reportView.TotalWorkOrderCountGrid
    }], [t]);

    const selectedKeys = useMemo(() => [selectedMenuKey], [selectedMenuKey]);

    const view = useMemo(() => {
        switch (selectedMenuKey) {
            case reportView.TotalWorkOrderCountCalendar:
                return <TotalWorkOrderCountReportView/>;
            case reportView.TotalWorkOrderCountGrid:
                return <ReportTableView/>;
        }
    }, [selectedMenuKey]);

    return <TitleAndContentLayout title={"reports"} pad>
        <ReportsPageProvider>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Menu onClick={onMenuClick} items={items} selectedKeys={selectedKeys} mode={"horizontal"}/>

                <RenderAdditionalMenuFunctions/>
            </div>
            <div className={"reports-view"}>
                {view}
            </div>
        </ReportsPageProvider>
    </TitleAndContentLayout>;
}

const RenderAdditionalMenuFunctions: FC = () => {
    const {element} = useContext(ReportsPageContext);

    return element;
}

export default ManagerReportsPage;

// TODO Izvještavanje napraviti slično kao listu kod Radni nalozi, lista koja će na kraju dati ukupni iznos gdje je moguće vidjeti koji vozač, koji kamion, koji partner, koja poslovna jedinica je imala koji broj radnih naloga, koji je njihov iznos i slično…. (PUSTIMO OVO ZA ZAVRŠNU FAZU)