export function reorderEnum<T extends Record<string, string | number>>(enumObj: T, order: T[keyof T][]): Record<keyof T, T[keyof T]> {
    const reordered: Partial<Record<keyof T, T[keyof T]>> = {};

    order.forEach(value => {
        const key = Object.keys(enumObj).find(key => enumObj[key] === value) as keyof T | undefined;
        if (key) {
            reordered[key] = value;
        }
    });

    return reordered as Record<keyof T, T[keyof T]>;
}
