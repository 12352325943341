import React, {FC, useMemo} from "react";
import {SortEnumType, useGetPartnerPickupRequestsQuery, useGetPartnerPortalNotesQuery} from "../graphql-types";
import {RenderAntdGraphQLList, TitleAndContentLayout} from "@ib-tehnologije/react-widgets";
import {useTranslation} from "react-i18next";
import {usePartnerPickupRequestCreating} from "../Hooks/usePartnerPickupRequestCreating";
import {Tag, Typography} from "antd";
import dayjs from "dayjs";
import {usePartnerPickupRequestUpdating} from "../Hooks/usePartnerPickupRequestUpdating";
import {usePartnerPickupRequestDeleting} from "../Hooks/usePartnerPickupRequestDeleting";
import {WorkOrderStatusIcon} from "../Components/WorkOrderStatusIcon";

const PartnerContactDashboardPage: FC = () => {
    const query = useGetPartnerPickupRequestsQuery({
        variables: {
            order: {
                id: SortEnumType.Desc
            }
        }
    });
    const partnerNoteQuery = useGetPartnerPortalNotesQuery();
    const {t} = useTranslation();

    const note = useMemo(() => partnerNoteQuery.data?.partnerPortalNotes?.items?.[0], [partnerNoteQuery.data]);

    const creating = usePartnerPickupRequestCreating({
        note: note?.note
    });

    const updating = usePartnerPickupRequestUpdating({
        note: note?.note
    });

    const deleting = usePartnerPickupRequestDeleting({
        note: note?.note
    });

    return <TitleAndContentLayout title={"pickup-requests"} pad>
        {note?.note?.trim() ? <div dangerouslySetInnerHTML={{
            __html: note?.note
                ?? ""
        }}/> : null}
        <RenderAntdGraphQLList
            tableData={query.data?.partnerPickupRequests}
            columns={[{
                columnId: "id",
                renderCell: (row) => row.id,
                width: 50
            }, {
                columnId: "status",
                renderCell: (row) => row.isAssigned ?
                    <WorkOrderStatusIcon status={row?.workOrder?.currentStatus?.status}/> :
                    <Tag color={"warning"} key={"not_reviewed"}>{t("not_reviewed")}</Tag>,
                width: 50
            }, {
                columnId: "requested-by",
                renderCell: (row) => row.reportingUser?.userName,
                width: 140,
            }, {
                columnId: "pickup-date",
                renderCell: (row) => dayjs(row.pickupDate).format('L'),
                width: 140,
            }, {
                columnId: "location",
                renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                    <Typography.Text>{row.companyLocation?.name}</Typography.Text>
                    {row.companyLocation?.address?.trim() ?
                        <Typography.Text type={"secondary"}>{row.companyLocation?.address}</Typography.Text> : null}
                    <Typography.Text>{row.locationNote}</Typography.Text>
                </div>,
                width: 400,
            }, {
                columnId: "requested-service",
                renderCell: (row) => row.serviceNote,
            }]} query={query} loading={query.loading}
            getRowId={f => f.id}
            creating={creating}
            updating={updating}
            deleting={deleting}
        />
    </TitleAndContentLayout>;
}

export default PartnerContactDashboardPage;