import {useMemo} from "react";
import {CustomSearchBoxElement} from "@ib-tehnologije/react-widgets";
import {
    useGetFinancialAccountsLazyQuery
} from "../graphql-types";

export const useFinancialAccountPicker = () => {
    return useMemo(() => ({
        type: new CustomSearchBoxElement({
            query: useGetFinancialAccountsLazyQuery,
            getQueryData: ({data}) => data?.financialAccounts,
            getKey: (f) => f.id,
            makeTitle: (f) => f.name,
            makeWhere: (values, search) => ({
                or: [{
                    name: {
                        contains: search
                    }
                }, {
                    description: {
                        contains: search
                    }
                }, {
                    externalId: {
                        contains: search
                    }
                }, {
                    number: {
                        contains: search
                    }
                }]
            }),
            makeSubtitle: (f) => f.description,
            valueKey: "financialAccount",
        })
    }), []);
}