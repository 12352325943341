import React, {Suspense} from 'react';
import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';
import {AuthContextEntry} from './Contexts/AuthContext';
import {LoginContextEntry} from "./Contexts/LoginContext";
import {LoginFormContextProvider} from "./Providers/LoginFormContextProvider";
import ApolloProviderWrapper from "./Providers/ApolloProviderWrapper";
import BaseRoutes from "./Routes";
import {LeftMainMenuContextWrapper} from "./Contexts/LeftMainMenuContext";
import {Spin} from "antd";
import {GraphQLListProvider} from "@ib-tehnologije/react-widgets/dist/contexts/GraphQLListContext";

const router = createBrowserRouter(
    BaseRoutes
);

export const App: React.FC = () => {
    return <React.StrictMode>
        <Suspense fallback={<Spin/>}>
            <GraphQLListProvider value={{basePagingOffset: 50}}>
                <ApolloProviderWrapper>
                    <LoginFormContextProvider>
                        <AuthContextEntry>
                            <LoginContextEntry>
                                <LeftMainMenuContextWrapper>
                                    <RouterProvider router={router}/>
                                </LeftMainMenuContextWrapper>
                            </LoginContextEntry>
                        </AuthContextEntry>
                    </LoginFormContextProvider>
                </ApolloProviderWrapper>
            </GraphQLListProvider>
        </Suspense>
    </React.StrictMode>;
}

export default App;