import {AntdSignUpForm, ISignUpFormState, SignUpFormDefaultProvider} from "@ib-tehnologije/react-widgets";
import React, {FC, startTransition, useCallback, useContext} from "react";
import {useNavigate} from "react-router-dom";
import {LoginContext} from "../Contexts/LoginContext";
import {useRegisterMeMutation} from "../graphql-types";

const logo = require('../Images/1maj.png');

const SignUpPage: FC = () => {
    const navigate = useNavigate();
    const {setLoginState} = useContext(LoginContext);
    const [register, {data, loading, error}] = useRegisterMeMutation();

    const submitted = useCallback(async (data: ISignUpFormState) => {
        try {
            const signUpResponse = await register({
                variables: {
                    model: {
                        userName: data.username,
                        email: data.email,
                        password: data.password,
                        userRole: undefined as any
                    }
                }
            });

            if (signUpResponse.data) {
                setLoginState({
                    signUpSuccess: "ConfirmYourEmail"
                })
                navigate('/Login', {replace: true});
            }
        } catch (e) {
            console.log(JSON.parse(JSON.stringify(e)));
            throw e;
        }
    }, []);

    const onBackButton = useCallback(() => {
        navigate("/Login");
    }, [navigate]);

    return <SignUpFormDefaultProvider>
        <AntdSignUpForm submitted={submitted} logoSrc={logo} logoFullWidth onBackButton={onBackButton}/>
    </SignUpFormDefaultProvider>;
}

export default SignUpPage;