export const it = {
    "login": "Accesso",
    "register": "Registrazione",
    "username": "Nome utente",
    "password": "Password",
    "sign-up": "Registrati",
    "email": "Email",
    "go-back": "Indietro",
    "EmailConfirmed_PleaseWait": "Email confermata. Attendere prego...",
    "EmailConfirmationErrorPage_Title": "Errore nella conferma dell'email",
    "EmailConfirmationErrorPage_Text": "Si è verificato un errore durante la conferma dell'email. Si prega di riprovare.",
    "EmailConfirmed_Success": "Email confermata con successo.",
    "The Username field is required.": "Il campo del nome utente è obbligatorio.",
    "The Email field is required.": "Il campo dell'email è obbligatorio.",
    "The Password field is required.": "Il campo della password è obbligatorio.",
    "EmailConfirmationErrorPage_BackButton": "Indietro",
    "redirector.unknownRoleForUsingApp": "Non ti è ancora stato assegnato l'accesso all'applicazione.",
    "NotFoundPage_Title": "Pagina non trovata",
    "NotFoundPage_BackButton": "Indietro",
    "NotFoundPage_Text": "La pagina che stai cercando non è stata trovata.",
    "ConfirmYourEmail": "Un link di conferma è stato inviato alla tua email. Si prega di confermare la tua email.",
    "User email not confirmed": "Email non confermata",
    "EmailNotConfirmed": "Email non confermata",
    "ResendConfirmationEmail": "Reinvia il link di conferma",
    "ResendConfirmationEmail_Success": "Il link di conferma è stato inviato con successo.",
    "PasswordTooShort": "La password è troppo corta.",
    "User not found": "Utente non trovato.",
    "back": "Indietro",
    "home": "Home",
    "logout": "Disconnetti",
    "work-orders": "Ordini di lavoro",
    "work-order": "Ordine di lavoro",
    "work-orders-description": "Panoramica degli ordini di lavoro",
    "users": "Utenti",
    "users-description": "Panoramica degli utenti",
    "companies": "Partner",
    "companies-description": "Panoramica dei partner",
    "administration": "Amministrazione",
    "error-loading-items": "Errore nel caricamento degli elementi",
    "retry": "Riprova",
    "search": "Ricerca",
    "Previous": "Precedente",
    "Next": "Successivo",
    "create": "Aggiungi",
    "cancel": "Annulla",
    "save": "Salva",
    "id": "Chiave",
    "emailConfirmed": "Email confermata",
    "are-you-sure-you-want-to-delete-this-item": "Sei sicuro di voler eliminare questo elemento?",
    "delete": "Elimina",
    "page": "Pagina",
    "InvalidEmail": "Inserisci un'email valida",
    "additional": "Aggiuntivo",
    "resendConfirmationEmail": "Reinvia il link di conferma",
    "pickup-requests": "Richieste di raccolta",
    "no-items": "Nessun elemento",
    "pickup-requests-description": "Panoramica delle richieste di raccolta",
    "status": "Stato",
    "date": "Data",
    "roles": "Ruoli",
    "Admin": "Amministratore",
    "Customer": "Cliente",
    "company-name": "Nome dell'azienda",
    "adding-companies": "Aggiunta di aziende",
    "taxId": "Partita IVA",
    "actions": "Azioni",
    "locations": "Ubicazioni",
    "company-locations": "Ubicazioni dell'azienda",
    "address": "Indirizzo",
    "adding-companyLocations": "Aggiunta di ubicazioni aziendali",
    "company-location-name": "Nome della ubicazione",
    "location-name": "Nome della ubicazione",
    "geodecode-address": "Cerca indirizzo sulla mappa",
    "editing-companyLocations": "Modifica delle ubicazioni aziendali",
    "adding-users": "Aggiunta di utenti",
    "adding-pickupRequests": "Aggiunta di richieste di raccolta",
    "notification": "Notifica",
    "successfully-deleted-item": "Elemento eliminato con successo",
    "successfully-deleted-item-with-id": "Elemento con ID eliminato con successo",
    "error-deleting-item-with-id": "Errore nell'eliminazione dell'elemento con ID",
    "successfully-duplicated-item-with-id": "Elemento con ID duplicato con successo",
    "error-duplicating-item-with-id": "Errore nella duplicazione dell'elemento con ID",
    "company": "Partner",
    "company-location": "Ubicazione del partner",
    "InvalidUserName": "Inserisci un nome utente valido",
    "editing-users": "Modifica degli utenti",
    "edit": "Modifica",
    "show-only-unassigned": "Mostra solo non assegnati",
    "settings": "Impostazioni",
    "start-search": "Avvia ricerca",
    "adding-pickupRequestsFiltered": "Aggiunta di richieste di raccolta filtrate",
    "editing-pickupRequestsFiltered": "Modifica delle richieste di raccolta filtrate",
    "isMainLocation": "Ubicazione principale",
    "assign": "Assegna",
    "assigned-user": "Assegnato all'utente",
    "details": "Dettagli",
    "user": "Utente",
    "user-overview": "Panoramica dell'utente",
    "MANAGER": "Manager",
    "PARTNER_CONTACT": "Contatto Partner",
    "FIELD_WORKER": "Lavoratore sul Campo",
    "user-details": "Dettagli Utente",
    "confirmationEmailSent": "Email di conferma inviata",
    "userName": "Nome Utente",
    "firstName": "Nome",
    "lastName": "Cognome",
    "system": "Sistema",
    "field-worker": "Lavoratore sul Campo",
    "field-workers": "Lavoratori sul Campo",
    "field-workers-description": "Panoramica dei lavoratori sul campo",
    "reports": "Rapporti",
    "reports-description": "Rapporti dettagliati",
    "partner-contacts": "Contatti Partner",
    "units-of-measure": "Unità di Misura",
    "vehicles": "Veicoli",
    "organizational-entities": "Entità Organizzative",
    "work-order-status-changes": "Cambiamenti di stato dell'ordine di lavoro",
    "work-order-templates": "Modelli di Ordine di Lavoro",
    "basic-data": "Dati di Base",
    "role": "Ruolo",
    "enums:FieldWorker": "Lavoratore sul Campo",
    "enums:PartnerContact": "Contatto Partner",
    "enums:Manager": "Manager",
    "editing-companies": "Modifica Aziende",
    "adding-partnerPickupRequests": "Aggiunta Richieste di raccolta Partner",
    "languages-full:hr": "Croato",
    "languages-full:en": "Inglese",
    "languages-full:de": "Tedesco",
    "languages-full:it": "Italiano",
    "items": "Servizi e Materiali",
    "cost-locations": "Luoghi di Costo",
    "execution-categories": "Categorie di Esecuzione",
    "duplicate": "Duplica",
    "editing-executionCategories": "Modifica Categorie di Esecuzione",
    "adding-executionCategories": "Aggiunta Categorie di Esecuzione",
    "name": "Nome",
    "description": "Descrizione",
    "unitOfMeasureId": "Unità di Misura",
    "unitOfMeasure": "Unità di Misura",
    "unitPrice": "Prezzo Unitario",
    "unitsContained": "Unità Contenute",
    "nameAndDescription": "Nome e Descrizione",
    "NoResults": "Nessun Risultato",
    "adding-items": "Aggiunta di Servizi e Materiali",
    "editing-items": "Modifica di Servizi e Materiali",
    "symbol": "Simbolo",
    "adding-unitsOfMeasure": "Aggiunta di Unità di Misura",
    "editing-unitsOfMeasure": "Modifica di Unità di Misura",
    "workOrderId": "Ordine di Lavoro",
    "dateTime": "Data e ora",
    "initiatingUser": "Iniziatore",
    "companyLocation": "Località dell'azienda",
    "organizationalEntity": "Entità organizzativa",
    "executionCategory": "Categoria di esecuzione",
    "vehicle": "Veicolo",
    "recurrence": "Ricorrenza",
    "deactivated": "Disattivato",
    "executionDay": "Giorno di esecuzione",
    "executionDayOfMonth": "Giorno del mese",
    "executionCategoryId": "Categoria di esecuzione",
    "organizationalEntityId": "Entità organizzativa",
    "adding-workOrderTemplates": "Aggiunta di modelli di ordine di lavoro",
    "editing-workOrderTemplates": "Modifica dei modelli di ordine di lavoro",
    "adding-vehicles": "Aggiunta di veicoli",
    "editing-vehicles": "Modifica di veicoli",
    "registrationNumber": "Numero di registrazione",
    "costLocation": "Luogo di costo",
    "costLocationId": "ID del luogo di costo",
    "adding-organizationalEntities": "Aggiunta di entità organizzative",
    "editing-organizationalEntities": "Modifica di entità organizzative",
    "parent": "Superiore",
    "parentId": "ID del superiore",
    "Email already exists": "L'email esiste già",
    "unassigned": "Non assegnato",
    "pickup-date": "Data di raccolta",
    "create-work-order": "Crea ordine di lavoro",
    "work-order-from-pickup-request": "Ordine di lavoro dalla richiesta di raccolta",
    "pickup-request-id": "ID della richiesta di raccolta",
    "pickup-request-pickup-date": "Data di raccolta richiesta",
    "note": "Nota",
    "reporting-user": "Segnalato da",
    "company-details": "Dettagli dell'azienda",
    "vehicleId": "Veicolo",
    "work-order-settings": "Impostazioni ordine di lavoro",
    "first-page": "Prima pagina",
    "next-page": "Pagina successiva",
    "previous-page": "Pagina precedente",
    "last-page": "Ultima pagina",
    "pickupDate": "Data di raccolta",
    "drop-pin": "Segna la posizione",
    "company-tax-id": "Partita IVA dell'azienda",
    "company-location-map-preview": "Anteprima della mappa della località dell'azienda",
    "yes": "Sì",
    "no": "No",
    "warning": "Avviso",
    "managerNote": "Nota del manager",
    "company-location-change": "Cambio di località dell'azienda",
    "phoneNumber": "Numero di telefono",
    "preferredLanguage": "Lingua Preferita",
    "enums:WAITING_FOR_ACCEPTANCE": "In Attesa di Accettazione",
    "enums:ACCEPTED": "Accettato",
    "enums:DECLINED": "Declinato",
    "enums:COMPLETED": "Completato",
    "company-and-location": "Azienda e Posizione",
    "work-order-overview": "Panoramica Ordine di Lavoro",
    "enums:CANCELLED": "Annullato",
    "enums:INVOICED": "Fatturato",
    "itemId": "Elemento",
    "quantity": "Quantità",
    "adding-workOrders": "Aggiunta Ordine di Lavoro",
    "item": "Elemento",
    "add-row": "Aggiungi Riga",
    "header": "Intestazione",
    "create-work-order-template": "Crea Modello Ordine di Lavoro",
    "enums:FRIDAY": "Venerdì",
    "enums:MONDAY": "Lunedì",
    "enums:SATURDAY": "Sabato",
    "enums:SUNDAY": "Domenica",
    "enums:THURSDAY": "Giovedì",
    "enums:TUESDAY": "Martedì",
    "enums:WEDNESDAY": "Mercoledì",
    "known-location": "Posizione Conosciuta",
    "unknown-location": "Posizione Sconosciuta",
    "every_week_at": "Ogni Settimana alle",
    "freeformLocation": "Posizione Libera",
    "basics": "Principi",
    "executionFrequency": "Frequenza di Esecuzione",
    "companyAndLocation": "Azienda e Posizione",
    "executionResources": "Risorse di Esecuzione",
    "itemsTitle": "Elementi",
    "every_month": "Ogni Mese",
    "th-extension": "og",
    "every": "Ogni",
    "genitive_every": "Ogni",
    "day_of_month": "giorno del mese",
    "enums:High": "Alto",
    "enums:Low": "Basso",
    "enums:Medium": "Medio",
    "priority": "Priorità",
    "Company location is required when freeform location is empty": "La Posizione Aziendale è Richiesta Quando la Posizione Libera è Vuota",
    "Either Company location or freeform location is required, but not both": "È Richiesta la Posizione Aziendale o la Posizione Libera, ma non entrambe",
    "Execution day or execution day of month is required": "Il giorno di esecuzione o il giorno del mese di esecuzione è richiesto",
    "Company or Location not found, or not associated with company.": "Azienda o Posizione non trovata o non associata all'azienda.",
    "every_monday": "Ogni Lunedì",
    "every_tuesday": "Ogni Martedì",
    "every_wednesday": "Ogni Mercoledì",
    "every_thursday": "Ogni Giovedì",
    "every_friday": "Ogni Venerdì",
    "every_saturday": "Ogni Sabato",
    "every_sunday": "Ogni Domenica",
    "updating-work-order-template": "Aggiornamento Modello Ordine di Lavoro",
    "Company location or freeform location is required": "La Posizione Aziendale o la Posizione Libera sono Obbligatorie",
    "freeformCompany": "Azienda Libera",
    "Either Company or freeform company is required": "Azienda o Azienda Libera sono Obbligatorie",
    "scheduledDate": "Data Pianificata",
    "Freeform location is required when company is empty": "La Posizione Libera è Obbligatoria Quando l'Azienda è Vuota",
    "Freeform company is required when company is empty": "Azienda Libera è Obbligatoria Quando l'Azienda è Vuota",
    "enums:WAITING_FOR_ACCEPTANCE_BY_FIELD_WORKER": "In Attesa di Accettazione da Parte del Lavoratore sul Campo",
    "enums:ACCEPTED_BY_FIELD_WORKER": "Accettato dal Lavoratore sul Campo",
    "enums:DECLINED_BY_FIELD_WORKER": "Declinato dal Lavoratore sul Campo",
    "enums:COMPLETED_BY_FIELD_WORKER": "Completato dal Lavoratore sul Campo",
    "enums:CONFIRMED_BY_MANAGER": "Confermato dal Manager",
    "enums:WITHOUT_ASSIGNMENT": "Senza Assegnazione",
    "locationNote": "Nota sulla Posizione",
    "serviceNote": "Nota sul Servizio",
    "ACCOUNTING": "Contabilità",
    "BackGoToLogin": "Torna al Login",
    "close": "Chiudi",
    "enums:Accounting": "Contabilità",
    "companyName": "Nome Azienda",
    "companyLocationName": "Nome della Posizione Aziendale",
    "workOrderCurrentStatusStatus": "Stato Attuale dell'Ordine di Lavoro",
    "notes": "Note",
    "noteForAccounting": "Nota per la Contabilità",
    "organizational-entities-structure": "Struttura delle Entità Organizzative",
    "files": "File",
    "filesTitle": "File",
    "file-selection": "Selezione File",
    "pickupRequestId": "ID Richiesta di Ritiro",
    "pickupLocationNote": "Nota sulla Posizione di Ritiro",
    "pickupRequestServiceNote": "Nota sul Servizio di Ritiro",
    "fillFromPickupRequest": "Riempi dal Richiesta di Ritiro",
    "fillFromPickupRequestContent": "Vuoi riempire l'ordine di lavoro dalla richiesta di raccolta?",
    "work-order-updated": "Ordine di Lavoro Aggiornato",
    "createdAt": "Data di Creazione",
    "customActionsTitle": "Azioni Personalizzate",
    "Cannot set status to a lower value than the current one!": "Impossibile impostare lo stato su un valore inferiore a quello attuale!",
    "Only accountants can set status to this value!": "Solo i contabili possono impostare lo stato su questo valore!",
    "Only managers can set status to this value!": "Solo i manager possono impostare lo stato su questo valore!",
    "Only managers or field workers can set status to this value!": "Solo i manager o i lavoratori sul campo possono impostare lo stato su questo valore!",
    "Work order must be assigned to a vehicle or organizational entity to set status to this value!": "L'ordine di lavoro deve essere assegnato a un veicolo o a un'entità organizzativa per impostare lo stato su questo valore!",
    "Only field workers can set status to this value!": "Solo i lavoratori sul campo possono impostare lo stato su questo valore!",
    "work-order-items": "Elementi dell'Ordine di Lavoro",
    "adding-workOrderTemplateItems": "Aggiunta Elementi del Modello di Ordine di Lavoro",
    "editing-workOrderTemplateItems": "Modifica Elementi del Modello di Ordine di Lavoro",
    "adding-workOrderItems": "Aggiunta Elementi dell'Ordine di Lavoro",
    "editing-workOrderItems": "Modifica Elementi dell'Ordine di Lavoro",
    "form.uploadFailed": "Caricamento Fallito",
    "form.uploadFailedDescription": "Ci sono file che non sono stati caricati correttamente. Si prega di controllare.",
    "form.uploading": "Caricamento in corso",
    "form.uploadingDescription": "Il caricamento dei file è in corso. Si prega di attendere.",
    "cancel-work-order": "Annulla ordine di lavoro",
    "are-you-sure-you-want-to-cancel-this-work-order": "Sei sicuro di voler annullare questo ordine di lavoro?",
    "create-pdf": "Crea PDF",
    "enums:CONFIRMED_BY_ACCOUNTING": "Confermato dalla Contabilità",
    "enums:SENT_TO_ERP": "Inviato a ERP",
    "error-occurred": "Si è verificato un errore",
    "reload": "Ricarica",
    "enums:SENDING_TO_ERP_FAILED": "Invio a ERP fallito",
    "Cannot cancel work order that has been sent to ERP!": "Impossibile annullare l'ordine di lavoro inviato a ERP!",
    "detailed-work-order-status-info": "Informazioni dettagliate sullo stato dell'ordine di lavoro",
    "consent-details": "Dettagli del consenso",
    "isEmailConsentGiven": "Consenso email",
    "emailConsentSource": "Origine del consenso email",
    "emailConsentGivenOn": "Consenso email dato il",
    "consent-not-given": "Consenso non dato",
    "user-updated-successfully": "Utente aggiornato con successo",
    "consent-updated-successfully": "Consenso aggiornato con successo",
    "profile-updated": "Profilo aggiornato",
    "userRole": "Ruolo utente",
    "CompanyLocationId or LocationNote is required": "L'ID della posizione aziendale o la nota sulla posizione sono obbligatori",
    "location": "Posizione",
    "requested-service": "Servizio richiesto",
    "requested-by": "Richiesto da",
    "view-work-order": "Visualizza ordine di lavoro",
    "enums:AcceptedByFieldWorker": "Accettato dal Lavoratore sul Campo",
    "enums:DeclinedByFieldWorker": "Declinato dal Lavoratore sul Campo",
    "enums:CompletedByFieldWorker": "Completato dal Lavoratore sul Campo",
    "enums:Cancelled": "Annullato",
    "enums:ConfirmedByManager": "Confermato dal Manager",
    "enums:ConfirmedByAccounting": "Confermato dalla Contabilità",
    "enums:SentToErp": "Inviato a ERP",
    "enums:SendingToErpFailed": "Invio a ERP fallito",
    "enums:WaitingForAcceptanceByFieldWorker": "In attesa di accettazione da parte del Lavoratore sul Campo",
    "enums:WithoutAssignment": "Senza assegnazione",
    "assignedUserId": "ID Utente Assegnato",
    "scheduledDateFrom": "Data Pianificata Da",
    "scheduledDateTo": "Data Pianificata A",
    "no-linked-work-order": "Nessun ordine di lavoro collegato",
    "accountants": "Contabili",
    "accounting": "Contabilità",
    "enums:reportViews.total_work_order_count": "Numero totale di ordini di lavoro",
    "work_order_count_short": "Numero ordini",
    "work_order_count": "Numero di ordini di lavoro",
    "startDate": "Data di Inizio",
    "endDate": "Data di Fine",
    "assigneeId": "ID Assegnatario",
    "count": "Conteggio",
    "xlsx_export": "Esporta in Excel",
    "csv_export": "Esporta in CSV",
    "pdf_export": "Esporta in PDF",
    "current-page": "Pagina Corrente",
    "page-of": "di",
    "work_order_count_per_day": "Numero di ordini di lavoro al giorno",
    "enums:reportViews.total_work_order_count_calendar": "Numero totale di ordini di lavoro: calendario",
    "reportWorkOrderid": "ID dell'ordine di lavoro",
    "reportWorkOrderpickupRequestId": "ID della richiesta di raccolta",
    "reportWorkOrderpickupRequestPickupDate": "Data di raccolta richiesta",
    "reportWorkOrderpickupRequestReportingUserUserName": "Segnalato da",
    "reportWorkOrderpickupRequestCompanyName": "Nome dell'azienda partner",
    "reportWorkOrderpickupRequestCompanyTaxId": "Partita IVA dell'azienda partner",
    "reportWorkOrderpickupRequestCompanyLocationName": "Nome della sede aziendale partner",
    "reportWorkOrderpickupRequestCompanyLocationAddress": "Indirizzo della sede aziendale partner",
    "reportWorkOrderscheduledDate": "Data pianificata",
    "reportWorkOrderstartDateTime": "Ora di inizio",
    "reportWorkOrderendDateTime": "Ora di fine",
    "reportWorkOrderduration": "Durata",
    "reportWorkOrderassignedByManagerUserUserName": "Assegnato da un manager",
    "reportWorkOrderassignedUserUserName": "Assegnato all'utente",
    "reportWorkOrdervehicleName": "Nome del veicolo",
    "reportWorkOrdervehicleRegistrationNumber": "Numero di registrazione del veicolo",
    "reportWorkOrdervehicleCostLocationName": "Nome del luogo di costo del veicolo",
    "reportWorkOrdervehicleCostLocationCode": "Codice del luogo di costo del veicolo",
    "reportWorkOrderorganizationalEntityName": "Nome dell'entità organizzativa",
    "reportWorkOrderexecutionCategoryName": "Nome della categoria di esecuzione",
    "reportWorkOrdercurrentStatusStatus": "Stato corrente dell'ordine di lavoro",
    "reportWorkOrderitemsTotal": "Totale degli elementi",
    "currentVehicleId": "ID del veicolo attuale",
    "currentOrganizationalEntityId": "ID dell'entità organizzativa attuale",
    "Cannot reopen a completed work order!": "Impossibile riaprire un ordine di lavoro completato!",
    "open-pdf": "Apri PDF",
    "no-pdf-available": "Nessun PDF disponibile",
    "send-pdf": "Invia PDF",
    "send-pdf-to": "Invia PDF a",
    "contactId": "ID del contatto",
    "directToEmail": "Direttamente via email",
    "ContactId or directToEmail must be provided": "ID del contatto o direttamente via email devono essere forniti",
    "pdf-sent": "PDF inviato",
    "User has not given consent to use email.": "L'utente non ha dato il consenso all'uso dell'email.",
    "value": "Valore",
    "Login": "Accesso",
    "generate-work-orders": "Genera ordini di lavoro",
    "Work orders generated successfully": "Ordini di lavoro generati con successo",
    "partner-portal-notes": "Note del portale partner",
    "Work order must be assigned to a vehicle to set status to this value!": "L'ordine di lavoro deve essere assegnato a un veicolo per impostare lo stato su questo valore!",
    "change-password": "Cambia password",
    "oldPassword": "Vecchia password",
    "newPassword": "Nuova password",
    "password-changed": "Password cambiata",
    "password-reset": "Reimposta password",
    "password-reset-successful": "Reimpostazione password riuscita",
    "Cannot delete pickup request with associated work order.": "Impossibile eliminare la richiesta di ritiro con l'ordine di lavoro associato.",
    "Cannot update pickup request with associated work order." : "Impossibile aggiornare la richiesta di ritiro con l'ordine di lavoro associato.",
    "editing-partnerPickupRequests": "Modifica delle richieste di ritiro partner",
    "requestedService": "Servizio richiesto",
    "service-note": "Nota sul servizio",
    "pickupServiceNote": "Nota sul servizio di ritiro",
    "not_reviewed": "Non revisionato",
    "no-data": "Nessun dato",
}