import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import {hr} from "./i18n/hr";
import {en} from "./i18n/en";
import {de} from "./i18n/de";
import {it} from "./i18n/it";

export const locales: {
    [key: string]: string;
} = {
    hr: "hr-HR",
    en: "en-US",
    de: "de-DE",
    it: "it-IT",
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: "hr",
        debug: false,

        keySeparator: false,

        interpolation: {
            escapeValue: false,
        },
        resources: {
            hr: {translation: hr},
            en: {translation: en},
            de: {translation: de},
            it: {translation: it},
        },
        react: {
            useSuspense: true,
        },
        nsSeparator: false,
        contextSeparator: undefined,
        pluralSeparator: undefined,
        fallbackLng: "hr",
        supportedLngs: ["hr", "en", "de", "it"],
    });

export default i18n;