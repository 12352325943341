import {ITenantDeleting} from "@ib-tehnologije/react-widgets";
import {
    DeleteWorkOrderTemplateItemMutation,
    UpdateWorkOrderTemplateItemMutation, useDeleteWorkOrderTemplateItemMutation,
    WorkOrderTemplateItemDeleteDtoInput,
    WorkOrderTemplateItemUpdateDtoInput
} from "../graphql-types";
import {ActualWorkOrderTemplateItemReturnDto} from "../ReturnTypes/ActualWorkOrderTemplateItemReturnDto";
import {useMemo} from "react";

export const useWorkOrderTemplateItemDeleting = (): ITenantDeleting<DeleteWorkOrderTemplateItemMutation, ActualWorkOrderTemplateItemReturnDto, WorkOrderTemplateItemDeleteDtoInput, "data"> => {
    const [deleting] = useDeleteWorkOrderTemplateItemMutation();
    
    return useMemo(() => ({
        modelKey: "data",
        selectionToDto: selection => ({
            id: selection?.id
        }),
        fn: deleting
    }), [deleting]);
}