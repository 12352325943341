import React, {FC, useCallback, useMemo, useState} from "react";
import {
    AlertApiError, CheckboxElement, CustomRenderElement,
    ExtractLocalisedApiError, FormCustomElement,
    FormOrientation, ITenantUpdating,
    RenderAntdGraphQLForm, TextFieldElement,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {
    AccountConsentUpdateDtoInput, AccountResetPasswordDtoInput, ResetMyPasswordMutation,
    SetConsentMutation,
    useGetConsentQuery,
    useGetUserQuery,
    useResendConfirmationEmailMutation,
    useResetMyPasswordMutation,
    UserRole
} from "../graphql-types";
import {UserRoles} from "../Components/UserRoles";
import {useUserUpdating} from "../Hooks/useUserUpdating";
import {Empty, notification, Spin, Tag, Typography} from "antd";
import {useUserProfileUpdating} from "../Hooks/useUserProfileUpdating";
import {useUserConsentUpdating} from "../Hooks/useUserConsentUpdating";
import {ActualAccountConsentSingleUserReturnDto} from "../ReturnTypes/ActualAccountConsentReturnDto";
import dayjs from "dayjs";


const ManagerUserPage: FC = () => {
    const [currentUserRoles, setCurrentUserRoles] = useState<UserRole[]>([]);
    const onRolesChanged = useCallback((roles: UserRole[]) => {
        setCurrentUserRoles(roles);
    }, []);
    const routeParams = useParams<{ id: string }>();
    const userId = useMemo(() => routeParams.id!, [routeParams.id!]);
    const q = useGetUserQuery({
        variables: {
            id: userId
        }
    });
    const [api, contextHolder] = notification.useNotification();
    const {t} = useTranslation();
    const updatingUserProfileConfig = useUserProfileUpdating(currentUserRoles, q.refetch);
    const updatingConsentConfig = useUserConsentUpdating();
    const [resetPassword, resetMutation] = useResetMyPasswordMutation();
    const consentQ = useGetConsentQuery({
        variables: {
            userId: userId
        }
    });

    const userUpdatedSuccess = useCallback(() => {
        api.success({
            message: t("user-updated-successfully")
        });
    }, [api]);

    const userConsentUpdatedSuccess = useCallback(async () => {
        api.success({
            message: t("consent-updated-successfully")
        });
        await consentQ.refetch();
    }, [api, consentQ]);

    const passwordReset = useMemo<ITenantUpdating<ResetMyPasswordMutation, AccountResetPasswordDtoInput, AccountResetPasswordDtoInput, "model">>(() => ({
        modelKey: "model",
        selectionToBaseFormData: (data) => ({}),
        formDefinition: {
            hidden: {
                type: new CustomRenderElement({
                    renderer: () => <div style={{width: 450}}/>
                })
            },
            newPassword: {
                type: new TextFieldElement({
                    inputProps: {
                        type: "password"
                    },
                })
            }
        },
        baseFormDto: {
            newPassword: ""
        },
        formDataToDto: (data) => ({
            username: q.data?.singleUser?.userName ?? "",
            newPassword: data?.newPassword,
        }),
        fn: resetPassword
    }), [resetPassword, q.data?.singleUser?.userName]);

    return <TitleAndContentLayout error={ExtractLocalisedApiError(q.error)} title={t("user-overview")}
                                  titleAddon={q.data?.singleUser?.userName} pad>
        {contextHolder}
        <Spin spinning={q.loading}>
            {q.data?.singleUser ? <div style={{
                display: "flex",
                flexDirection: "row",
                gap: 50,
                flexWrap: "wrap"
            }}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <h3>{t("user-details")}</h3>
                    <RenderAntdGraphQLForm getId={data => data?.id}
                                           editingTitle={""}
                                           addingTitle={""}
                                           refetch={q.refetch}
                                           loading={q.loading}
                                           onSuccessfulSubmission={userUpdatedSuccess}
                                           formSettings={{inline: true, orientation: FormOrientation.VERTICAL}}
                                           updating={updatingUserProfileConfig}
                                           doNotResetAfterSuccessfulSubmission={true}
                                           selectedItem={q.data.singleUser}/>
                </div>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <h3>{t("consent-details")}</h3>
                    <RenderAntdGraphQLForm
                        key={`${consentQ.data?.accountConsent?.id}-${consentQ.loading}`}
                        getId={f => f.id}
                        editingTitle={""}
                        addingTitle={""}
                        refetch={consentQ.refetch}
                        loading={consentQ.loading}
                        formSettings={{inline: true, orientation: FormOrientation.HORIZONTAL}}
                        updating={updatingConsentConfig}
                        onSuccessfulSubmission={userConsentUpdatedSuccess}
                        doNotResetAfterSuccessfulSubmission={true}
                        selectedItem={consentQ.data?.accountConsent}/>
                </div>
                <UserRoles userId={userId} onRolesChanged={onRolesChanged}/>
            </div> : <Empty/>}
            <div style={{display: "flex", flexDirection: "row"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <h3>{t("password-reset")}</h3>
                    <RenderAntdGraphQLForm
                        key={`${q.data?.singleUser?.id}`}
                        getId={f => undefined}
                        editingTitle={""}
                        addingTitle={""}
                        refetch={q.refetch}
                        loading={resetMutation.loading}
                        formSettings={{inline: true, orientation: FormOrientation.VERTICAL}}
                        updating={passwordReset}
                        onSuccessfulSubmission={() => {
                            api.success({
                                message: t("password-reset-successful")
                            });
                        }}/>
                </div>
            </div>
        </Spin>
    </TitleAndContentLayout>;
}

export default ManagerUserPage;