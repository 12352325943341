import React, {FC, useMemo} from "react";
import {
    ITenantCreating,
    ITenantDeleting,
    ITenantUpdating, MultilineTextElement, NumericFieldElement, RenderAntdGraphQLList, TextFieldElement,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {useTranslation} from "react-i18next";
import {
    CreateVatTypeMutation,
    DeleteVatTypeMutation,
    UpdateVatTypeMutation,
    useCreateVatTypeMutation,
    useDeleteVatTypeMutation,
    useGetVatTypesQuery,
    useUpdateVatTypeMutation,
    VatTypeCreateDtoInput,
    VatTypeDeleteDtoInput,
    VatTypeUpdateDtoInput
} from "../graphql-types";
import {ActualVatTypeReturnDto} from "../ReturnTypes/ActualVATTypeReturnDto";
import {CheckboxChecked20Filled} from "@fluentui/react-icons";
import {nFormat} from "../index";
import {Typography} from "antd";

const ManagerVatTypesPage: FC = () => {
    const {t} = useTranslation();
    const q = useGetVatTypesQuery();
    const [updateVatType] = useUpdateVatTypeMutation();
    const [createVatType] = useCreateVatTypeMutation();
    const [deleteVatType] = useDeleteVatTypeMutation();

    const creating = useMemo<ITenantCreating<CreateVatTypeMutation, ActualVatTypeReturnDto, VatTypeCreateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            name: {
                type: new TextFieldElement({})
            },
            description: {
                type: new MultilineTextElement({
                    rows: 3
                })
            },
            rate: {
                type: new NumericFieldElement({})
            },
            externalId: {
                type: new TextFieldElement({})
            }
        },
        selectionToDuplicateDto: (selection) => ({
            name: selection?.name,
            description: selection?.description,
            rate: selection?.rate,
            externalId: selection?.externalId
        }),
        formDataToDto: (formData) => ({
            name: formData.name,
            description: formData.description,
            rate: formData.rate,
            externalId: formData.externalId
        }),
        baseFormData: {
            name: "",
            description: "",
            rate: 0,
            externalId: ""
        },
        fn: createVatType
    }), []);


    const updating = useMemo<ITenantUpdating<UpdateVatTypeMutation, ActualVatTypeReturnDto, VatTypeUpdateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            name: {
                type: new TextFieldElement({})
            },
            description: {
                type: new MultilineTextElement({
                    rows: 3
                })
            },
            rate: {
                type: new NumericFieldElement({})
            },
            externalId: {
                type: new TextFieldElement({})
            }
        },
        selectionToBaseFormData: (selection) => ({
            id: selection?.id,
            name: selection?.name,
            description: selection?.description,
            rate: selection?.rate,
            externalId: selection?.externalId
        }),
        formDataToDto: (formData) => ({
            id: formData.id,
            name: formData.name,
            description: formData.description,
            rate: formData.rate,
            externalId: formData.externalId
        }),
        fn: updateVatType
    }), []);

    const deleting = useMemo<ITenantDeleting<DeleteVatTypeMutation, ActualVatTypeReturnDto, VatTypeDeleteDtoInput, "data">>(() => ({
        modelKey: "data",
        selectionToDto: (selection) => ({
            id: selection?.id
        }),
        fn: deleteVatType
    }), []);

    return <TitleAndContentLayout title={"vat-types"} pad>
        <RenderAntdGraphQLList
            deleting={deleting}
            tableData={q.data?.vatTypes}
            columns={[{
                columnId: "id",
                renderCell: (row) => <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <Typography.Text>{row.id}</Typography.Text>
                    <Typography.Text type={"secondary"}>{row.externalId}</Typography.Text>
                </div>,
            }, {
                columnId: "name",
                renderCell: (row) => row.name,
            }, {
                columnId: "description",
                renderCell: (row) => row.description,
            }, {
                columnId: "rate",
                renderCell: (row) => nFormat.format(row.rate),
            }]}
            query={q}
            loading={q.loading}
            creating={creating}
            getRowId={g => g.id}
            updating={updating}
        />
    </TitleAndContentLayout>
}

export default ManagerVatTypesPage;