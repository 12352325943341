import {useMemo} from "react";
import {
    CustomRenderElement,
    CustomSearchBoxElement,
    DateElement,
    ITenantCreating,
    MultilineTextElement
} from "@ib-tehnologije/react-widgets";
import {
    CreatePartnerPickupRequestMutation,
    PartnerPickupRequestCreateDtoInput, useCreatePartnerPickupRequestMutation,
    useGetCompanyLocationsQuery
} from "../graphql-types";
import {ActualPartnerPickupRequestsReturnDto} from "../ReturnTypes/ActualPartnerPickupRequestReturnDto";
import {useCompanyLocationPicker} from "../Pickers/useCompanyLocationPicker";
import dayjs from "dayjs";

export const usePartnerPickupRequestCreating = ({note}: { note?: string }) => {
    const [createPickupRequest] = useCreatePartnerPickupRequestMutation();
    const companyLocationPicker = useCompanyLocationPicker();

    const firstAvailableDate = useMemo(() => {
        // min two days from now and skip sunday
        const minDays = dayjs().add(2, "day").startOf("day").toDate();
        const skipSunday = dayjs(minDays).weekday() === 6 ? dayjs(minDays).add(1, "day").startOf("day").toDate() : minDays;
        return skipSunday;
    }, []);

    return useMemo<ITenantCreating<CreatePartnerPickupRequestMutation, ActualPartnerPickupRequestsReturnDto, PartnerPickupRequestCreateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            note: {
                type: new CustomRenderElement({
                    renderer: () => <div dangerouslySetInnerHTML={{__html: note ?? ""}}/>
                })
            },
            pickupDate: {
                type: new DateElement({
                    minDate: firstAvailableDate,
                    disabledDate: (current) => dayjs(current).weekday() === 6
                }),
            },
            companyLocationId: companyLocationPicker,
            locationNote: {
                type: new MultilineTextElement({rows: 5})
            },
            serviceNote: {
                type: new MultilineTextElement({rows: 5})
            },
        },
        baseFormData: {
            pickupDate: firstAvailableDate,
        },
        formDataToDto: (formData) => ({
            companyLocationId: formData.companyLocationId,
            pickupDate: formData.pickupDate,
            serviceNote: formData.serviceNote,
            locationNote: formData.locationNote,
        }),
        fn: createPickupRequest
    }), [note, firstAvailableDate]);
}