import React, {FC, useCallback, useMemo} from "react";
import {
    AfterListEvent, CheckboxElement,
    ITenantCreating,
    ITenantDeleting,
    ITenantUpdating, MultilineTextElement, RenderAntdGraphQLList, TextFieldElement,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {useTranslation} from "react-i18next";
import {
    CreateOrganizationalEntityMutation,
    DeleteOrganizationalEntityMutation,
    OrganizationalEntityCreateDtoInput,
    OrganizationalEntityDeleteDtoInput,
    OrganizationalEntityUpdateDtoInput,
    UpdateOrganizationalEntityMutation,
    useCreateOrganizationalEntityMutation,
    useDeleteOrganizationalEntityMutation, useGetAllOrganizationalEntitiesQuery,
    useGetOrganizationalEntitiesQuery,
    useUpdateOrganizationalEntityMutation
} from "../graphql-types";
import {ActualOrganizationalEntityReturnDto} from "../ReturnTypes/ActualOrganizationalEntityReturnDto";
import {useOrganizationalEntityPicker} from "../Pickers/useOrganizationalEntityPicker";
import {Checkbox, Spin, Tree, TreeDataNode, TreeNodeProps, Typography} from "antd";

const ManagerOrganizationalEntitiesPage: FC = () => {
    const {t} = useTranslation();
    const q = useGetOrganizationalEntitiesQuery();
    const [updateOrganizationalEntity] = useUpdateOrganizationalEntityMutation();
    const [createOrganizationalEntity] = useCreateOrganizationalEntityMutation();
    const [deleteOrganizationalEntity] = useDeleteOrganizationalEntityMutation();
    const organizationalEntityPicker = useOrganizationalEntityPicker({
        type: {
            valueKey: "parent",
        }
    });

    const creating = useMemo<ITenantCreating<CreateOrganizationalEntityMutation, ActualOrganizationalEntityReturnDto, OrganizationalEntityCreateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            name: {
                type: new TextFieldElement({})
            },
            isDefault: {
                type: new CheckboxElement({})
            },
            description: {
                type: new MultilineTextElement({rows: 3})
            },
            parentId: organizationalEntityPicker,
        },
        selectionToDuplicateDto: (selection) => ({
            name: selection?.name,
            isDefault: selection?.isDefault,
            description: selection?.description,
            parentId: selection?.parentId,
            parent: selection?.parent
        }),
        formDataToDto: (formData) => ({
            name: formData.name,
            isDefault: formData.isDefault,
            description: formData.description,
            parentId: formData.parentId
        }),
        baseFormData: {},
        fn: createOrganizationalEntity
    }), []);

    const updating = useMemo<ITenantUpdating<UpdateOrganizationalEntityMutation, ActualOrganizationalEntityReturnDto, OrganizationalEntityUpdateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            name: {
                type: new TextFieldElement({})
            },
            isDefault: {
                type: new CheckboxElement({})
            },
            description: {
                type: new MultilineTextElement({rows: 3})
            },
            parentId: organizationalEntityPicker,
        },
        selectionToBaseFormData: (selection) => ({
            id: selection?.id,
            name: selection?.name,
            isDefault: selection?.isDefault,
            description: selection?.description,
            parentId: selection?.parentId,
            parent: selection?.parent
        }),
        formDataToDto: (formData) => ({
            id: formData.id,
            name: formData.name,
            isDefault: formData.isDefault,
            description: formData.description,
            parentId: formData.parentId
        }),
        fn: updateOrganizationalEntity
    }), []);

    const deleting = useMemo<ITenantDeleting<DeleteOrganizationalEntityMutation, ActualOrganizationalEntityReturnDto, OrganizationalEntityDeleteDtoInput, "data">>(() => ({
        modelKey: "data",
        selectionToDto: (selection) => ({
            id: selection?.id
        }),
        fn: deleteOrganizationalEntity
    }), []);

    const onDragEnter = useCallback((info: TreeNodeProps) => {
        console.log(info);
    }, []);

    const onDrop = useCallback((info: TreeNodeProps) => {
        console.log(info);
    }, []);

    const treeq = useGetAllOrganizationalEntitiesQuery();

    const afterListEvent = useCallback((event: AfterListEvent<any, any, any, any>) => {
        if (event.eventType === "refresh" && event.complete) {
            treeq.refetch();
        }
    }, []);

    const treeDataRecursive = (data: ActualOrganizationalEntityReturnDto[], allData: ActualOrganizationalEntityReturnDto[]): TreeDataNode[] => {
        const nodes: TreeDataNode[] = [];
        for (const d of data) {
            nodes.push({
                key: d.id,
                title: d.name,
                children: treeDataRecursive(allData.filter(x => x.parentId === d.id), allData)
            });
        }
        return nodes;
    }

    const treeData = useMemo<TreeDataNode[]>(() => {
        const nodes: TreeDataNode[] = [];
        const allData = treeq.data?.wholeOrganizationalEntitiesTree;
        if (allData) {
            for (const d of allData.filter(x => x.parentId === null)) {
                nodes.push({
                    key: d.id,
                    title: d.name,
                    children: treeDataRecursive(allData.filter(x => x.parentId === d.id), allData)
                });
            }
        }
        return nodes;
    }, [treeq.data?.wholeOrganizationalEntitiesTree]);

    return <TitleAndContentLayout title={"organizational-entities"} pad>
        <div style={{display: "flex", flexDirection: "row", gap: 10}}>
            <div style={{flex: 1, width: "100%"}}>
                <RenderAntdGraphQLList
                    deleting={deleting}
                    style={{flex: 1, width: "100%"}}
                    afterListEvent={afterListEvent}
                    tableData={q.data?.organizationalEntities}
                    columns={[{
                        columnId: "nameAndDescription",
                        renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                            <Typography.Text>{row.name}</Typography.Text>
                            <Typography.Text type={"secondary"}>{row.description}</Typography.Text>
                        </div>,
                    }, {
                        columnId: "isDefault",
                        renderCell: (row) => row.isDefault ? <Checkbox checked={true}/> : null,
                    }, {
                        columnId: "parent",
                        renderCell: (row) => row.parent?.name,
                    }]}
                    query={q}
                    loading={q.loading}
                    creating={creating}
                    getRowId={g => g.id}
                    updating={updating}
                />
            </div>
            <div style={{width: 350}}>
                <Spin spinning={treeq.loading}>
                    <Tree treeData={treeData}
                          draggable

                          blockNode
                          expandedKeys={treeq.data?.wholeOrganizationalEntitiesTree?.map(x => x.id)}
                          onDoubleClick={console.log}
                          showIcon
                          defaultExpandAll={true}
                          defaultExpandParent={true}
                          onDragEnter={onDragEnter}
                          onDrop={onDrop}
                    />
                </Spin>
            </div>
        </div>
    </TitleAndContentLayout>
}

export default ManagerOrganizationalEntitiesPage

