import React from "react";
import {RouteObject} from "react-router-dom";
import {DefaultLayout} from "./Components/DefaultLayout";
import {Redirector} from "./Pages/Redirector";
import {PrivateRoute} from "./Guards/PrivateRouteGuard";
import {UserRole} from "./graphql-types";
import ManagerUsersPage from "./Pages/ManagerUsersPage";
import ManagerDashboardPage from "./Pages/ManagerDashboardPage";
import ManagerSettingsPage from "./Pages/ManagerSettingsPage";
import ManagerUserPage from "./Pages/ManagerUserPage";
import ManagerPickupRequestsPage from "./Pages/ManagerPickupRequestsPage";
import ManagerCompaniesPage from "./Pages/ManagerCompaniesPage";
import ManagerCompanyCompanyLocationsPage from "./Pages/ManagerCompanyCompanyLocationsPage";
import ManagerFieldWorkersPage from "./Pages/ManagerFieldWorkersPage";
import ManagerPartnerContactsPage from "./Pages/ManagerPartnerContactsPage";
import ManagerWorkOrdersPage from "./Pages/ManagerWorkOrdersPage";
import ManagerWorkOrderPage from "./Pages/ManagerWorkOrderPage";
import ManagerCompanyLocationsPage from "./Pages/ManagerCompanyLocationsPage";
import ManagerWorkOrderTemplatesPage from "./Pages/ManagerWorkOrderTemplatesPage";
import ManagerWorkOrderTemplatePage from "./Pages/ManagerWorkOrderTemplatePage";
import ManagerWorkOrderStatusChangesPage from "./Pages/ManagerWorkOrderStatusChangesPage";
import ManagerVehiclesPage from "./Pages/ManagerVehiclesPage";
import ManagerUnitsOfMeasurePage from "./Pages/ManagerUnitsOfMeasurePage";
import ManagerItemsPage from "./Pages/ManagerItemsPage";
import ManagerOrganizationalEntitiesPage from "./Pages/ManagerOrganizationalEntitiesPage";
import ManagerCostLocationsPage from "./Pages/ManagerCostLocationsPage";
import ManagerReportsPage from "./Pages/ManagerReportsPage";
import PartnerContactDashboardPage from "./Pages/PartnerContactDashboardPage";
import UserSettingsPage from "./Pages/UserSettingsPage";
import EmailConfirmedPage from "./Pages/EmailConfirmedPage";
import EmailConfirmationErrorPage from "./Pages/EmailConfirmationErrorPage";
import LoginPage from "./Pages/LoginPage";
import SignUpPage from "./Pages/SignUpPage";
import NotFoundPage from "./Pages/NotFoundPage";
import ManagerWorkOrderCreatePage from "./Pages/ManagerWorkOrderCreatePage";
import ManagerWorkOrderTemplateCreatePage from "./Pages/ManagerWorkOrderTemplateCreatePage";
import ManagerAccountantsPage from "./Pages/ManagerAccountantsPage";
import ManagerPartnerPortalNotesPage from "./Pages/ManagerPartnerPortalNotesPage";
import ManagerManagersPage from "./Pages/ManagerManagersPage";
import ManagerVATTypesPage from "./Pages/ManagerVATTypesPage";
import ManagerFinancialAccountsPage from "./Pages/ManagerFinancialAccountsPage";

// Separate function for Manager Routes
const ManagerRoutes: RouteObject[] = [
    {
        path: '',
        element: <ManagerDashboardPage/>,
    },
    {
        path: 'Settings',
        element: <ManagerSettingsPage/>,
    },
    {
        path: 'Users',
        element: <ManagerUsersPage/>,
    }, {
        path: "User/:id",
        element: <ManagerUserPage/>,
    },
    {
        path: 'PickupRequests',
        element: <ManagerPickupRequestsPage/>,
    },
    {
        path: 'Companies',
        element: <ManagerCompaniesPage/>,
    },
    {
        path: 'Companies/:id/CompanyLocations',
        element: <ManagerCompanyCompanyLocationsPage/>,
    },
    {
        path: 'FieldWorkers',
        element: <ManagerFieldWorkersPage/>,
    },
    {
        path: 'PartnerContacts',
        element: <ManagerPartnerContactsPage/>,
    },
    {
        path: 'Accountants',
        element: <ManagerAccountantsPage/>,
    },
    {
        path: 'Managers',
        element: <ManagerManagersPage/>,
    },
    {
        path: 'WorkOrders',
        element: <ManagerWorkOrdersPage/>,
    },
    {
        path: "WorkOrder/:id",
        element: <ManagerWorkOrderPage/>,
    },
    {
        path: "WorkOrders/Create",
        element: <ManagerWorkOrderCreatePage/>,
    },
    {
        path: "CompanyLocations",
        element: <ManagerCompanyLocationsPage/>,
    },
    {
        path: "WorkOrderTemplates",
        element: <ManagerWorkOrderTemplatesPage/>,
    },
    {
        path: "WorkOrderTemplate/:id",
        element: <ManagerWorkOrderTemplatePage/>,
    },
    {
        path: "WorkOrderTemplates/Create",
        element: <ManagerWorkOrderTemplateCreatePage/>,
    },
    {
        path: "WorkOrderStatusChanges",
        element: <ManagerWorkOrderStatusChangesPage/>,
    },
    {
        path: "Vehicles",
        element: <ManagerVehiclesPage/>,
    },
    {
        path: "UnitsOfMeasure",
        element: <ManagerUnitsOfMeasurePage/>,
    },
    {
        path: "Items",
        element: <ManagerItemsPage/>,
    },
    {
        path: "VATTypes",
        element: <ManagerVATTypesPage/>,
    },
    {
        path: "FinancialAccounts",
        element: <ManagerFinancialAccountsPage/>,
    },
    {
        path: "OrganizationalEntities",
        element: <ManagerOrganizationalEntitiesPage/>,
    },
    {
        path: 'CostLocations',
        element: <ManagerCostLocationsPage/>,
    }, 
    {
        path: "Reports",
        element: <ManagerReportsPage/>,
    },
    {
        path: 'PartnerPortalNotes',
        element: <ManagerPartnerPortalNotesPage/>,
    },
];

// Separate function for Customer Routes
const PartnerRoutes: RouteObject[] = [
    {
        path: '',
        element: <DefaultLayout>
            <PartnerContactDashboardPage/>
        </DefaultLayout>,
    },
];

const UserSettingsRoutes: RouteObject[] = [
    {
        path: '',
        element: <UserSettingsPage/>,
    }
];

const BaseRoutes: RouteObject[] = [
    {
        path: '/',
        element: <Redirector/>,
    },
    {
        path: "/EmailConfirmed",
        element: <EmailConfirmedPage/>,
    },
    {
        path: "/EmailConfirmationError",
        element: <EmailConfirmationErrorPage/>,
    },
    {
        path: '/Login',
        element: <LoginPage/>,
    },
    {
        path: '/SignUp',
        element: <SignUpPage/>,
    },
    {
        path: "/UserSettings",
        element: <DefaultLayout>
            <PrivateRoute roles={[UserRole.FieldWorker, UserRole.PartnerContact, UserRole.Manager, UserRole.Accounting]}/>
        </DefaultLayout>,
        children: UserSettingsRoutes,
    },
    {
        path: '/Manager/*',
        element: <DefaultLayout>
            <PrivateRoute roles={[UserRole.Manager, UserRole.Accounting]}/>
        </DefaultLayout>,
        children: ManagerRoutes
    },
    {
        path: '/Partner/*',
        element: <PrivateRoute roles={[UserRole.PartnerContact]}/>,
        children: PartnerRoutes,
    },
    {
        path: '*',
        element: <NotFoundPage/>,
    },
];

export default BaseRoutes;