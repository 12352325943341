import {useMemo} from "react";
import {CustomSearchBoxElement, ITenantFormLayoutDefinition, RecursivePartial} from "@ib-tehnologije/react-widgets";
import {useGetVehiclesLazyQuery, useGetVehiclesQuery} from "../graphql-types";

export function useVehiclePicker<TDto>(overrides?: RecursivePartial<ITenantFormLayoutDefinition<TDto, CustomSearchBoxElement<any, any, any, any>>>, deps: any[] = []) {
    return useMemo(() => ({
        ...(overrides as any),
        type: new CustomSearchBoxElement<any, TDto, any, any>({
            query: useGetVehiclesLazyQuery,
            getQueryData: ({data}) => data?.vehicles,
            getKey: (item) => item.id,
            makeTitle: (item) => item.name,
            makeSubtitle: (item) => item.registrationNumber,
            makeWhere: (values, search) => ({
                or: [{
                    name: {
                        contains: search
                    }
                }, {
                    registrationNumber: {
                        contains: search
                    }
                }, {
                    description: {
                        contains: search
                    }
                }]
            }),
            valueKey: "vehicle",
            ...(overrides?.type as any)
        }),
    }), [overrides, ...deps]);
}