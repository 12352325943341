import {FileUploadElement} from "@ib-tehnologije/react-widgets";
import {useContext, useMemo} from "react";
import {AuthContext} from "../Contexts/AuthContext";

export const useFilePicker = () => {
    const {auth: {token}} = useContext(AuthContext);

    return useMemo(() => ({
        type: new FileUploadElement({
            action: "/api/File/Upload",
            uploadProps: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            },
            makeDownloadUrl: item => {
                if (item?.response?.id) {
                    return `/api/File/Download/${item.response.id}?access_token=${token}`;
                }
            }
        })
    }), [token]);
}