import {useMemo} from "react";
import {CustomSearchBoxElement} from "@ib-tehnologije/react-widgets";
import {useGetUnfilteredUsersLazyQuery, useGetUsersLazyQuery} from "../graphql-types";

export const useCompanyUserPicker = (companyId: number, showEmail: boolean = false) => {
    return useMemo(() => ({
        type: new CustomSearchBoxElement({
            query: useGetUnfilteredUsersLazyQuery,
            getQueryData: (data) => data.data?.usersUnfiltered,
            valueKey: "user",
            getKey: (user) => user.id,
            makeTitle: (user) => user.userName,
            makeSubtitle: (user) => `${user.firstName} ${user.lastName}${showEmail ? ` (${user.email})` : ""}`,
            makeWhere: (values, search) => ({
                and: [{
                    companyId: {
                        eq: companyId
                    }
                }, {
                    or: [{
                        userName: {
                            contains: search
                        },
                    }, {
                        email: {
                            contains: search
                        },
                    }, {
                        firstName: {
                            contains: search
                        },
                    }, {
                        lastName: {
                            contains: search
                        },
                    }]
                }]
            }),
        })
    }), [companyId]);
}