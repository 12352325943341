import {useMemo} from "react";
import {
    CheckboxElement, GroupElement, ITenantUpdating,
    MultilineTextElement, NumericFieldElement,
    TextFieldElement, TitleElement, VerticalGroupElement
} from "@ib-tehnologije/react-widgets";
import {
    UpdateWorkOrderTemplateMutation,
    useUpdateWorkOrderTemplateMutation, WorkOrderTemplateUpdateDtoInput
} from "../graphql-types";
import {
    ActualWorkOrderTemplateReturnSingleDto
} from "../ReturnTypes/ActualWorkOrderTemplateReturnDto";
import {useTranslation} from "react-i18next";
import {useCompanyPicker} from "../Pickers/useCompanyPicker";
import {useCompanyLocationPicker} from "../Pickers/useCompanyLocationPicker";
import {useVehiclePicker} from "../Pickers/useVehiclePicker";
import {useOrganizationalEntityPicker} from "../Pickers/useOrganizationalEntityPicker";
import {useItemPicker} from "../Pickers/useItemPicker";
import {useExecutionDayPicker} from "../Pickers/useExecutionDayPicker";
import {useWorkOrderPriorityPicker} from "../Pickers/useWorkOrderPriorityPicker";

export const useWorkOrderTemplateUpdating = (): ITenantUpdating<UpdateWorkOrderTemplateMutation, ActualWorkOrderTemplateReturnSingleDto, WorkOrderTemplateUpdateDtoInput, "data"> => {
    const [updateWorkOrderTemplate] = useUpdateWorkOrderTemplateMutation();
    const {t} = useTranslation();
    const companyIdPicker = useCompanyPicker();
    const companyLocationIdPicker = useCompanyLocationPicker();
    const vehicleIdPicker = useVehiclePicker();
    const organizationalEntityIdPicker = useOrganizationalEntityPicker();
    const itemIdPicker = useItemPicker();
    const executionDayPicker = useExecutionDayPicker();
    const workOrderPriorityPicker = useWorkOrderPriorityPicker();

    return useMemo<ITenantUpdating<UpdateWorkOrderTemplateMutation, ActualWorkOrderTemplateReturnSingleDto, WorkOrderTemplateUpdateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            headAndItems: {
                type: new GroupElement({
                    head: {
                        type: new VerticalGroupElement({
                            basics: {
                                type: new TitleElement({})
                            },
                            nameDeactivatedAndPriority: {
                                type: new VerticalGroupElement({
                                    name: {
                                        type: new TextFieldElement({})
                                    },
                                    deactivated: {
                                        type: new CheckboxElement({})
                                    },
                                    priority: workOrderPriorityPicker,
                                })
                            },
                            description: {
                                type: new MultilineTextElement({rows: 3})
                            },
                            executionFrequency: {
                                type: new TitleElement({})
                            },
                            executionDay: executionDayPicker,
                            executionDayOfMonth: {
                                type: new NumericFieldElement({})
                            },
                            companyAndLocation: {
                                type: new TitleElement({})
                            },
                            companyId: companyIdPicker,
                            companyLocationId: companyLocationIdPicker,
                            freeformLocation: {
                                type: new TextFieldElement({})
                            },
                            executionResources: {
                                type: new TitleElement({})
                            },
                            vehicleId: vehicleIdPicker,
                            organizationalEntityId: organizationalEntityIdPicker,
                        })
                    },
                })
            }
        },
        formDataToDto: (formData) => ({
            id: formData.id,
            name: formData.name,
            description: formData.description,
            deactivated: formData.deactivated,
            executionDay: formData.executionDay,
            executionDayOfMonth: formData.executionDayOfMonth ? +formData.executionDayOfMonth : undefined,
            organizationalEntityId: formData.organizationalEntityId,
            companyId: formData.companyId,
            companyLocationId: formData.companyLocationId,
            vehicleId: formData.vehicleId,
            freeformLocation: formData.freeformLocation,
            priority: formData.priority,
        }),
        selectionToBaseFormData: (selection) => ({
            id: selection?.id,
            name: selection?.name,
            description: selection?.description,
            deactivated: selection?.deactivated,
            executionDay: selection?.executionDay,
            executionDayOfMonth: selection?.executionDayOfMonth,
            organizationalEntityId: selection?.organizationalEntityId,
            organizationalEntity: selection?.organizationalEntity,
            companyLocationId: selection?.companyLocationId,
            companyLocation: selection?.companyLocation,
            vehicleId: selection?.vehicleId,
            vehicle: selection?.vehicle,
            items: selection?.items,
            companyId: selection?.companyId,
            company: selection?.company,
            freeformLocation: selection?.freeformLocation,
            priority: selection?.priority,
        }),
        fn: updateWorkOrderTemplate
    }), []);
}