import {useMemo} from "react";
import {
    CustomRenderElement,
    DateElement, FormEvent, FormTextElement,
    GroupElement, ITenantCreating, MultilineTextElement, TextFieldElement, TitleElement, VerticalGroupElement
} from "@ib-tehnologije/react-widgets";
import {
    CreateWorkOrderMutation,
    useCreateWorkOrderMutation,
    WorkOrderCreateDtoInput,
    WorkOrderPriority
} from "../graphql-types";
import {ActualWorkOrderReturnDto} from "../ReturnTypes/ActualWorkOrderReturnDto";
import {useCompanyPicker} from "../Pickers/useCompanyPicker";
import {useCompanyLocationPicker} from "../Pickers/useCompanyLocationPicker";
import {useVehiclePicker} from "../Pickers/useVehiclePicker";
import {useOrganizationalEntityPicker} from "../Pickers/useOrganizationalEntityPicker";
import {useItemPicker} from "../Pickers/useItemPicker";
import {useTranslation} from "react-i18next";
import {ActualPickupRequestFilteredReturnDto} from "../ReturnTypes/ActualPickupRequestFilteredReturnDto";
import {usePickupRequestIdPicker} from "../Pickers/usePickupRequestIdPicker";
import {Modal} from "antd";
import {useFilePicker} from "../Pickers/useFilePicker";
import {useWorkOrderPriorityPicker} from "../Pickers/useWorkOrderPriorityPicker";
import dayjs from "dayjs";
import {ActualOrganizationalEntityReturnDto} from "../ReturnTypes/ActualOrganizationalEntityReturnDto";

interface IFromPickupRequest {
    pickupRequest?: ActualPickupRequestFilteredReturnDto
}

export const useWorkOrderCreating = (fillFrom?: IFromPickupRequest, defaultOE?: ActualOrganizationalEntityReturnDto): ITenantCreating<CreateWorkOrderMutation, ActualWorkOrderReturnDto, WorkOrderCreateDtoInput, "data"> => {
    const [create] = useCreateWorkOrderMutation();
    const companyIdPicker = useCompanyPicker();
    const companyLocationIdPicker = useCompanyLocationPicker();
    const vehicleIdPicker = useVehiclePicker();
    const organizationalEntityIdPicker = useOrganizationalEntityPicker();
    const pickupRequestIdPicker = usePickupRequestIdPicker();
    const {t} = useTranslation();
    const [modal, contextHolder] = Modal.useModal();
    const filePicker = useFilePicker();
    const workOrderPriorityPicker = useWorkOrderPriorityPicker();

    return useMemo<ITenantCreating<CreateWorkOrderMutation, ActualWorkOrderReturnDto, WorkOrderCreateDtoInput, "data">>(() => ({
        modelKey: "data",
        baseFormData: {
            pickupRequestId: fillFrom?.pickupRequest?.id,
            scheduledDate: fillFrom?.pickupRequest?.pickupDate ?? dayjs().startOf("day").toDate(),
            companyId: fillFrom?.pickupRequest?.companyId,
            company: fillFrom?.pickupRequest?.company,
            companyLocationId: fillFrom?.pickupRequest?.companyLocationId,
            companyLocation: fillFrom?.pickupRequest?.companyLocation,
            pickupRequest: fillFrom?.pickupRequest,
            organizationalEntityId: defaultOE?.id,
            organizationalEntity: defaultOE,
            // organizationalEntityId: 
            priority: WorkOrderPriority.Medium,
        },
        formDataToDto: (formData) => ({
            companyLocationId: formData.companyLocationId,
            description: formData.description,
            managerNote: formData.managerNote,
            organizationalEntityId: formData.organizationalEntityId,
            vehicleId: formData.vehicleId,
            pickupRequestId: formData.pickupRequestId,
            priority: formData.priority,
            companyId: formData.companyId,
            freeformLocation: formData.freeformLocation,
            freeformCompany: formData.freeformCompany,
            noteForAccounting: formData.noteForAccounting,
            scheduledDate: formData.scheduledDate,
            id: formData.id,
            files: (formData.files ?? []).map((file: any) => ({id: file.response.id})),
        }),
        formDefinition: {
            globalHandlers: {
                type: new CustomRenderElement({
                    renderer: formikProps => contextHolder
                }),
                dependsOn: [{
                    key: "pickupRequestId",
                    onChanged: async (data, value, initialized) => {
                        if (value) {
                            console.log("pickupRequestId", data, value, initialized);

                            const updateFromPickupRequest = await new Promise((resolve, reject) => {
                                modal.confirm({
                                    title: t("fillFromPickupRequest"),
                                    content: t("fillFromPickupRequestContent"),
                                    onOk: () => resolve(true),
                                    onCancel: () => resolve(false),
                                });
                            });

                            if (updateFromPickupRequest) {
                                return [{
                                    eventType: FormEvent.UpdateValueAndStorage,
                                    payload: {
                                        formKey: "scheduledDate",
                                        value: data?.pickupRequest?.pickupDate,
                                        storage: data?.pickupRequest?.pickupDate,
                                        stopPropagation: true
                                    }
                                }, {
                                    eventType: FormEvent.UpdateValueAndStorage,
                                    payload: {
                                        formKey: "companyId",
                                        value: data?.pickupRequest?.companyId,
                                        storage: data?.pickupRequest?.company,
                                        stopPropagation: true
                                    }
                                }, {
                                    eventType: FormEvent.UpdateValueAndStorage,
                                    payload: {
                                        formKey: "companyLocationId",
                                        value: data?.pickupRequest?.companyLocationId,
                                        storage: data?.pickupRequest?.companyLocation,
                                        stopPropagation: true
                                    }
                                }];
                            }
                        }

                        return undefined;
                    }
                }]
            },
            headAndItems: {
                type: new GroupElement({
                    head: {
                        style: {
                            maxWidth: 600
                        },
                        type: new VerticalGroupElement({
                            basics: {
                                type: new TitleElement({})
                            },
                            pickupRequestId: pickupRequestIdPicker,
                            pickupLocationNote: {
                                type: new FormTextElement({
                                    titleFormatter: (values: any) => {
                                        return values?.pickupRequest?.locationNote;
                                    }
                                })
                            },
                            pickupServiceNote: {
                                type: new FormTextElement({
                                    titleFormatter: (values: any) => {
                                        return values?.pickupRequest?.serviceNote;
                                    }
                                })
                            },
                            scheduledDate: {
                                type: new DateElement({})
                            },
                            priority: workOrderPriorityPicker,
                            companyAndLocation: {
                                type: new TitleElement({})
                            },
                            companyAndLocationEntry: {
                                type: new VerticalGroupElement({
                                    companyId: companyIdPicker,
                                    freeformCompany: {
                                        type: new TextFieldElement({})
                                    },
                                    companyLocationId: companyLocationIdPicker,
                                    freeformLocation: {
                                        type: new TextFieldElement({})
                                    },
                                })
                            },
                            executionResources: {
                                type: new TitleElement({})
                            },
                            vehicleAndOrOrganizationalEntity: {
                                type: new VerticalGroupElement({
                                    vehicleId: vehicleIdPicker,
                                    organizationalEntityId: organizationalEntityIdPicker,
                                })
                            },
                            notes: {
                                type: new TitleElement({})
                            },
                            managerNotes: {
                                type: new VerticalGroupElement({
                                    managerNote: {
                                        type: new MultilineTextElement({
                                            rows: 5
                                        })
                                    },
                                    noteForAccounting: {
                                        type: new MultilineTextElement({
                                            rows: 5
                                        })
                                    }
                                })
                            },
                            filesSection: {
                                type: new VerticalGroupElement({
                                    filesTitle: {
                                        type: new TitleElement({})
                                    },
                                    files: filePicker
                                })
                            }
                        })
                    },
                })
            },
        },
        fn: create,
    }), [create, fillFrom?.pickupRequest, filePicker]);
}