import {useMemo} from "react";
import {CustomSearchBoxElement, FormEvent} from "@ib-tehnologije/react-widgets";
import {useGetCompanyLocationsLazyQuery, useGetCompanyLocationsQuery} from "../graphql-types";

export const useCompanyLocationPicker = () => {
    return useMemo(() => ({
        type: new CustomSearchBoxElement({
            getQueryData: ({data}) => data?.companyLocations,
            query: useGetCompanyLocationsLazyQuery,
            getKey: (f) => f.id,
            makeTitle: (f) => f.name,
            makeWhere: (values, search) => ({
                and: [{
                    ...(values?.companyId ? {
                        companyId: {
                            eq: values.companyId
                        }
                    } : {}),
                    or: [{
                        name: {
                            contains: search
                        }
                    }, {
                        address: {
                            contains: search
                        }
                    }]
                }]
            }),
            makeSubtitle: (f) => f.address,
            valueKey: "companyLocation",
        }),
        translationKey: "company-location",
        dependsOn: [{
            key: "companyId",
            onChanged: async (data: any, value: any) => {
                return [{
                    eventType: FormEvent.UpdateValueAndStorage,
                    payload: {value: undefined, storage: undefined, formKey: "companyLocationId"}
                }]
            }
        }]
    }), []);
}