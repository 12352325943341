import {createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState} from "react";
import {AuthContext} from "./AuthContext";
import {UserRole} from "../graphql-types";

type LeftMainMenuContextType = {
    openMenu?: () => void;
    isMenuOpen: boolean;
    closeMenu?: () => void;
    setMenuOpen: (isOpen: boolean) => void;
}

const LeftMainMenuContext = createContext<LeftMainMenuContextType>({
    isMenuOpen: false,
    setMenuOpen: () => {}
});

export default LeftMainMenuContext;

export const LeftMainMenuContextWrapper: FC<PropsWithChildren> = ({children}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {auth: {roles}} = useContext(AuthContext);

    const isManager = useMemo(() => roles.includes(UserRole.Manager), [roles]);

    const openMenu = useCallback(() => {
        if (isManager) {
            setIsMenuOpen(true);
        }
    }, [isManager]);
    
    const closeMenu = useCallback(() => {
        setIsMenuOpen(false);
    }, []);
    
    const setMenuOpen = useCallback((isOpen: boolean) => {
        if (isManager) {
            setIsMenuOpen(isOpen);
        }
    }, [isManager]);

    return <LeftMainMenuContext.Provider value={{setMenuOpen, openMenu: isManager ? openMenu : undefined, isMenuOpen, closeMenu}}>
        {children}
    </LeftMainMenuContext.Provider>
}