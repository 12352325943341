import {FC} from "react";
import {useGetSingleWorkOrdersStatusChangesQuery, WorkOrderStatus} from "../graphql-types";
import {Steps, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {isErrorWorkOrderStatus} from "../Helpers/isErrorWorkOrderStatus";
import dayjs from 'dayjs';

interface IWorkOrderStatusesVerticalProps {
    statusQuery: ReturnType<typeof useGetSingleWorkOrdersStatusChangesQuery>;
}

export const WorkOrderStatusesVertical: FC<IWorkOrderStatusesVerticalProps> = ({statusQuery}) => {
    const {t} = useTranslation()

    return (
        <Steps
            direction={"vertical"}
            current={statusQuery.data?.singleWorkOrderAllStatusChanges.length}>
            {statusQuery.data?.singleWorkOrderAllStatusChanges.map((statusChange, index) => {
                return (
                    <Steps.Step
                        key={index}
                        title={t(`enums:${statusChange.status}`)}
                        status={isErrorWorkOrderStatus(statusChange.status!) ? "error" : undefined}
                        description={<div style={{display: "flex", flexDirection: "column"}}>
                            <Typography.Text>{t(statusChange.notes ?? "")}</Typography.Text>
                            <Typography.Text type={"secondary"}>
                                {dayjs(statusChange.createdAt).format('L LT')} ({statusChange.initiatingUser?.userName ?? t("system")})
                            </Typography.Text>
                        </div>}
                    />
                );
            })}
        </Steps>
    );
}