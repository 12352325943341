import React, {FC, useEffect, useState} from "react";
import {
    CheckboxElement,
    FormOrientation,
    RenderAntdGraphQLList,
    RenderAntdStandaloneForm, TextFieldElement,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {useGetCompanyLocationsLazyQuery, useGetCompanyLocationsQuery} from "../graphql-types";
import {CheckboxChecked20Filled, CheckmarkCircleRegular} from "@fluentui/react-icons";
import {useCompanyLocationCreating} from "../Hooks/useCompanyLocationCreating";
import {useCompanyLocationUpdating} from "../Hooks/useCompanyLocationUpdating";
import {useCompanyLocationDeleting} from "../Hooks/useCompanyLocationDeleting";
import {useTranslation} from "react-i18next";
import {CheckOutlined} from "@ant-design/icons";
import {Typography} from "antd";
import {useLocalStorage} from "usehooks-ts";

const ManagerCompanyLocationsPage: FC = () => {
    const [a, q] = useGetCompanyLocationsLazyQuery();
    const creating = useCompanyLocationCreating();
    const updating = useCompanyLocationUpdating();
    const deleting = useCompanyLocationDeleting();
    const {t} = useTranslation();
    const [filterLoading, setFilterLoading] = useState<boolean>(false);
    const [filter, setFilter] = useLocalStorage<any>("companylocationsfilter", {});

    useEffect(() => {
        if (!filterLoading) {
            setFilterLoading(true);
            const refetchFilter = {
                where: filter.search ? {
                    or: [{
                        name: {
                            contains: filter.search
                        },
                    }, {
                        description: {
                            contains: filter.search
                        },
                    }, {
                        taxId: {
                            contains: filter.search
                        }
                    }, {
                        externalId: {
                            contains: filter.search
                        }
                    }]
                } : {}
            };
            console.log("filter", filter, refetchFilter);

            a({
                variables: {
                    where: {
                        and: [filter.search ? {
                            or: [{
                                name: {
                                    contains: filter.search
                                },
                            }, {
                                description: {
                                    contains: filter.search
                                },
                            }, {
                                address: {
                                    contains: filter.search
                                }
                            }, {
                                externalId: {
                                    contains: filter.search
                                }
                            }, {
                                company: {
                                    name: {
                                        contains: filter.search
                                    }
                                }
                            }, {
                                company: {
                                    description: {
                                        contains: filter.search
                                    }
                                }
                            }, {
                                company: {
                                    taxId: {
                                        contains: filter.search
                                    }
                                }
                            }]
                        } : {},
                            filter.isMainLocation ? {
                                isMainLocation: {
                                    eq: filter.isMainLocation
                                }
                            } : {}]
                    }
                }
            }).finally(() => {
                setFilterLoading(false);
            });
        }
    }, [filter]);

    return <TitleAndContentLayout title={"company-locations"} pad>
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                width: "100%"
            }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: 250,
            }}>
                <RenderAntdStandaloneForm
                    formSettings={{inline: true, orientation: FormOrientation.VERTICAL}}
                    loading={q.loading}
                    baseFormData={filter}
                    formDefinition={{
                        search: {
                            type: new TextFieldElement({})
                        },
                        isMainLocation: {
                            type: new CheckboxElement({})
                        }
                    }}
                    submission={{
                        title: "start-search",
                        onSubmit: (values) => {
                            setFilter(values);
                        }
                    }}
                />
            </div>
            <div style={{flex: 1, width: "100%"}}>
                <RenderAntdGraphQLList
                    deleting={deleting}
                    tableData={q.data?.companyLocations}
                    columns={[{
                        columnId: "id",
                        renderCell: (row) => <div style={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <Typography.Text>{row.id}</Typography.Text>
                            <Typography.Text type={"secondary"}>{row.externalId}</Typography.Text>
                        </div>,
                    }, {
                        columnId: "isMainLocation",
                        renderCell: (row) => row.isMainLocation ? <CheckOutlined/> : null,
                    }, {
                        columnId: "company",
                        renderCell: (row) => <span>{row.company?.name}</span>,
                    }, {
                        columnId: "company-location-name",
                        renderCell: (row) => <span>{row.name}</span>,
                    }, {
                        columnId: "address",
                        renderCell: (row) => <span>{row.address}</span>,
                    }]}
                    query={q}
                    loading={q.loading}
                    creating={creating}
                    getRowId={g => g.id}
                    updating={updating}
                />
            </div>
        </div>
    </TitleAndContentLayout>;
}

export default ManagerCompanyLocationsPage;