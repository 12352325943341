import React, {FC, useMemo, useState} from "react";
import {
    DateElement,
    FormOrientation, MapPreviewElement,
    RenderAntdGraphQLList, RenderAntdStandaloneForm,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {useTranslation} from "react-i18next";
import {
    SortEnumType,
    useGetWorkOrderStatusChangesQuery, WorkOrderStatusChangeReturnDtoFilterInput
} from "../graphql-types";
import {Button, Typography} from "antd";
import {WorkOrderStatusIcon} from "../Components/WorkOrderStatusIcon";
import dayjs from "dayjs";
import {useWorkOrderIdPicker} from "../Hooks/useWorkOrderIdPicker";
import {useLocation} from "react-router-dom";
import {useWorkOrderStatusPicker} from "../Hooks/useWorkOrderStatusPicker";
import {RightOutlined, SearchOutlined} from "@ant-design/icons";

const ManagerWorkOrderStatusChangesPage: FC = () => {
    const loc = useLocation();
    const {t} = useTranslation();
    const initialWorkOrder = useMemo(() => {
        return loc?.state?.workOrder;
    }, [loc?.state?.workOrder]);
    const [filter, setFilter] = useState<WorkOrderStatusChangeReturnDtoFilterInput>({
        ...(initialWorkOrder ? {
            workOrderId: {
                eq: initialWorkOrder.id
            }
        } : {})
    });

    const q = useGetWorkOrderStatusChangesQuery({
        variables: {
            order: {
                id: SortEnumType.Desc
            },
            where: filter
        }
    });
    const workOrderIdPicker = useWorkOrderIdPicker();
    const statusPicker = useWorkOrderStatusPicker();
    const [showLocation, setShowLocation] = useState<{ latitude: number, longitude: number } | undefined>(undefined);

    const initialFormData = useMemo(() => ({
        workOrder: initialWorkOrder,
        ...filter,
    }), [initialWorkOrder]);

    return <TitleAndContentLayout title={"work-order-status-changes"} pad>
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                width: "100%"
            }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: 250,
            }}>
                <RenderAntdStandaloneForm
                    formSettings={{inline: true, orientation: FormOrientation.VERTICAL}}
                    loading={q.loading}
                    baseFormData={initialFormData}
                    formDefinition={{
                        workOrderId: workOrderIdPicker,
                        status: statusPicker,
                        dateFrom: {
                            type: new DateElement({})
                        },
                        dateTo: {
                            type: new DateElement({})
                        },
                    }}
                    submission={{
                        title: "start-search",
                        onSubmit: (values) => {
                            q.refetch({
                                ...q.variables,
                                where: {
                                    and: [
                                        values.workOrderId ? {
                                            workOrderId: {
                                                eq: values.workOrderId
                                            },
                                        } : {},
                                        values.status !== undefined ? {
                                            status: {
                                                eq: values.status
                                            }
                                        } : {},
                                        values.dateFrom !== undefined ? {
                                            createdAt: {
                                                gte: values.dateFrom
                                            }
                                        } : {},
                                        values.dateTo !== undefined ? {
                                            createdAt: {
                                                lte: values.dateTo
                                            }
                                        } : {}
                                    ]
                                }
                            });
                        }
                    }}
                />
            </div>
            <div style={{flex: 1, width: "100%"}}>
                <RenderAntdGraphQLList
                    // deleting={deleting}
                    tableData={q.data?.workOrderStatusChanges}
                    columns={[{
                        columnId: "workOrderId",
                        renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                            <Typography.Text>{`${row.workOrder?.formattedId}`}</Typography.Text>
                            <Typography.Text type={"secondary"}>{row.notes}</Typography.Text>
                        </div>,
                        sorter: true
                    }, {
                        columnId: "status",
                        renderCell: (row) => <div style={{
                            display: "flex",
                            flexDirection: "row",
                            verticalAlign: "center",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <WorkOrderStatusIcon status={row?.status}/>
                            <div style={{
                                display: "flex",
                                verticalAlign: "center",
                                flexDirection: "column",
                                alignItems: "flex-start",
                            }}>
                                <Typography.Text>{t(`enums:${row?.status}`)}</Typography.Text>
                                <Typography.Text type={"secondary"}>{row?.notes}</Typography.Text>
                            </div>
                        </div>,
                    }, {
                        columnId: "createdAt",
                        renderCell: (row) =>
                            <span>{row.createdAt ? dayjs(row.createdAt).format('L LT') : null}</span>,
                        sorter: true
                    }, {
                        columnId: "initiatingUser",
                        renderCell: (row) => <span>{row.initiatingUser?.userName}</span>,
                    }, {
                        columnId: "location",
                        renderCell: (row) => row.location ? <Button
                            type={"primary"}
                            onClick={() => setShowLocation(row.location)}>
                            {t("location")}
                            <RightOutlined/>
                        </Button> : null,
                    }]}
                    query={q}
                    loading={q.loading}
                    // creating={creating}
                    getRowId={g => g.id}
                    // updating={updating}
                />
            </div>
        </div>
        {showLocation ? <RenderAntdStandaloneForm loading={false} formSettings={{
            isOpen: true,
            orientation: FormOrientation.VERTICAL,
            setIsOpen: (open) => !open ? setShowLocation(undefined) : undefined,
            trigger: (onClick) => <div/>
        }} submission={{
            title: "close",
            onSubmit: () => {
            }
        }} formDefinition={{
            map: {
                type: new MapPreviewElement({
                    center: showLocation,
                })
            }
        }}/> : null}
    </TitleAndContentLayout>
}

export default ManagerWorkOrderStatusChangesPage