// PrivateRoute.tsx
import React, {FC, PropsWithChildren, ReactNode, useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {AuthContext, AuthState} from '../Contexts/AuthContext';
import {UserRole} from "../graphql-types";

type PrivateRouteGuardProps = {
    roles: UserRole[];
};

export const PrivateRoute: FC<PropsWithChildren<PrivateRouteGuardProps>> = ({roles}) => {
    const navigate = useNavigate();
    const {auth} = React.useContext(AuthContext);

    useEffect(() => {
        const isAuthorized = roles.some(role => auth?.roles.includes(role));
        if (!isAuthorized) {
            navigate('/login');
        }
    }, [auth, navigate, roles]);

    return <Outlet/>;
}
