import React, {FC, PropsWithChildren} from 'react';
import {ApolloClient, InMemoryCache, ApolloProvider, HttpLink, split} from '@apollo/client';
import {setContext} from "@apollo/client/link/context";
import {WebSocketLink} from "@apollo/client/link/ws";
import {getMainDefinition} from "@apollo/client/utilities";

const httpLink = new HttpLink({uri: '/graphql'});

const authLink = setContext((_, {headers}) => {
    // get the authentication token from local storage if it exists
    const tokenObj = localStorage.getItem('authState');
    let token: string | null = null;
    if (tokenObj) {
        token = JSON.parse(tokenObj).token;
    }
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_WS_GRAPHQL_ENDPOINT!,
    options: {
        reconnect: true,
        connectionParams: {
            authToken: localStorage.getItem('authState') ? JSON.parse(localStorage.getItem('authState') as string).token : null,
        },
    },
});

// Initialize Apollo Client
export const gqlClient = new ApolloClient({
    link: split(
        (s) => {
            const query = s.query;
            const def = getMainDefinition(query);
            return def.kind === 'OperationDefinition' && (def as any).operation === 'subscription';
        },
        wsLink,
        authLink.concat(httpLink)
    ),
    cache: new InMemoryCache(),
    defaultOptions: {
        query: {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "network-only",
        },
        mutate: {
            fetchPolicy: "network-only",
        },
        watchQuery: {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "network-only",
        }
    }
});

const ApolloProviderWrapper: FC<PropsWithChildren> = ({children}) => {
    return <ApolloProvider client={gqlClient}>
        {children}
    </ApolloProvider>;
}

export default ApolloProviderWrapper;