import {ITenantDeleting} from "@ib-tehnologije/react-widgets";
import {
    DeleteWorkOrderItemMutation, GetWorkOrderTotalsDocument,
    UpdateWorkOrderItemMutation, useDeleteWorkOrderItemMutation,
    WorkOrderItemDeleteDtoInput,
    WorkOrderItemUpdateDtoInput
} from "../graphql-types";
import {ActualWorkOrderItemReturnDto} from "../ReturnTypes/ActualWorkOrderItemReturnDto";
import {useMemo} from "react";

export const useWorkOrderItemDeleting = (forWorkOrderId: number): ITenantDeleting<DeleteWorkOrderItemMutation, ActualWorkOrderItemReturnDto, WorkOrderItemDeleteDtoInput, "data"> => {
    const [deleting] = useDeleteWorkOrderItemMutation({
        refetchQueries: [{
            query: GetWorkOrderTotalsDocument,
            variables: {workOrderId: forWorkOrderId},
        }]
    });
    
    return useMemo(() => ({
        modelKey: "data",
        selectionToDto: selection => ({
            id: selection?.id
        }),
        fn: deleting
    }), [deleting]);
}