import React, {FC, useEffect, useMemo, useState} from "react";
import {
    CheckboxElement,
    FormOrientation,
    ITenantCreating,
    ITenantDeleting,
    ITenantUpdating, MultilineTextElement, RenderAntdGraphQLList, RenderAntdStandaloneForm, TextFieldElement,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {useTranslation} from "react-i18next";
import {
    CreateVehicleMutation, DeleteVehicleMutation, VehicleCreateDtoInput, VehicleDeleteDtoInput,
    VehicleUpdateDtoInput,
    UpdateVehicleMutation,
    useCreateVehicleMutation, useDeleteVehicleMutation,
    useUpdateVehicleMutation, useGetVehiclesQuery, useGetVehiclesLazyQuery
} from "../graphql-types";
import {ActualVehicleReturnDto} from "../ReturnTypes/ActualVehicleReturnDto";
import {CheckboxChecked20Filled} from "@fluentui/react-icons";
import {useCostLocationPicker} from "../Pickers/useCostLocationPicker";
import {Typography} from "antd";
import {useUserPicker} from "../Pickers/useUserPicker";
import {useFieldWorkerPicker} from "../Pickers/useFieldWorkerPicker";
import {useLocalStorage} from "usehooks-ts";

const ManagerVehiclesPage: FC = () => {
    const {t} = useTranslation();
    const [a, q] = useGetVehiclesLazyQuery();
    const [updateVehicle] = useUpdateVehicleMutation();
    const [createVehicle] = useCreateVehicleMutation();
    const [deleteVehicle] = useDeleteVehicleMutation();
    const costLocationIdPicker = useCostLocationPicker();
    const userIdPicker = useFieldWorkerPicker();
    const [filter, setFilter] = useLocalStorage<any>("vehiclesfilter", {});
    const [filterLoading, setFilterLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!filterLoading) {
            setFilterLoading(true);
            const refetchFilter = {
                ...q.variables,
                where: {
                    and: [filter.search ? {
                        or: [{
                            name: {
                                contains: filter.search
                            },
                        }, {
                            description: {
                                contains: filter.search
                            },
                        }, {
                            externalId: {
                                contains: filter.search
                            },
                        }, {
                            costLocation: {
                                name: {
                                    contains: filter.search
                                }
                            }
                        }, {
                            costLocation: {
                                code: {
                                    eq: +filter.search
                                }
                            }
                        }, {
                            registrationNumber: {
                                contains: filter.search
                            }
                        }]
                    } : {},
                        filter.currentDriverId !== undefined ? {
                            assignedUser: {
                                id: {
                                    eq: filter.currentDriverId
                                }
                            }
                        } : {},
                        filter.costLocationId !== undefined ? {
                            costLocationId: {
                                eq: filter.costLocationId
                            }
                        } : {}
                    ]
                }
            };
            console.log("filter", filter, refetchFilter);
            a({variables: refetchFilter}).finally(() => {
                setFilterLoading(false);
            });
        }
    }, [filter]);

    const creating = useMemo<ITenantCreating<CreateVehicleMutation, ActualVehicleReturnDto, VehicleCreateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            name: {
                type: new TextFieldElement({})
            },
            description: {
                type: new MultilineTextElement({
                    rows: 3
                })
            },
            registrationNumber: {
                type: new TextFieldElement({})
            },
            costLocationId: costLocationIdPicker,
            externalId: {
                type: new TextFieldElement({})
            },
        },
        selectionToDuplicateDto: (selection) => ({
            name: selection?.name,
            registrationNumber: selection?.registrationNumber,
            costLocationId: selection?.costLocationId,
            description: selection?.description,
            externalId: selection?.externalId
        }),
        formDataToDto: (formData) => ({
            name: formData?.name,
            registrationNumber: formData?.registrationNumber,
            costLocationId: formData?.costLocationId,
            externalId: formData?.externalId,
            description: formData?.description
        }),
        baseFormData: {},
        fn: createVehicle
    }), []);

    const updating = useMemo<ITenantUpdating<UpdateVehicleMutation, ActualVehicleReturnDto, VehicleUpdateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            name: {
                type: new TextFieldElement({})
            },
            description: {
                type: new MultilineTextElement({
                    rows: 3
                })
            },
            registrationNumber: {
                type: new TextFieldElement({})
            },
            costLocationId: costLocationIdPicker,
            externalId: {
                type: new TextFieldElement({})
            },
        },
        selectionToBaseFormData: (selection) => ({
            id: selection?.id,
            name: selection?.name,
            registrationNumber: selection?.registrationNumber,
            costLocationId: selection?.costLocationId,
            costLocation: selection?.costLocation,
            externalId: selection?.externalId,
            description: selection?.description
        }),
        formDataToDto: (formData) => ({
            id: formData?.id,
            name: formData?.name,
            registrationNumber: formData?.registrationNumber,
            costLocationId: formData?.costLocationId,
            externalId: formData?.externalId,
            description: formData?.description
        }),
        fn: updateVehicle
    }), []);

    const deleting = useMemo<ITenantDeleting<DeleteVehicleMutation, ActualVehicleReturnDto, VehicleDeleteDtoInput, "data">>(() => ({
        modelKey: "data",
        selectionToDto: (selection) => ({
            id: selection?.id
        }),
        fn: deleteVehicle
    }), []);

    return <TitleAndContentLayout title={"vehicles"} pad>
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                width: "100%"
            }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: 250,
            }}>
                <RenderAntdStandaloneForm
                    formSettings={{inline: true, orientation: FormOrientation.VERTICAL}}
                    loading={q.loading}
                    baseFormData={filter}
                    formDefinition={{
                        search: {
                            type: new TextFieldElement({})
                        },
                        currentDriverId: userIdPicker,
                        costLocationId: costLocationIdPicker
                    }}
                    submission={{
                        title: "start-search",
                        onSubmit: (values) => {
                            setFilter(values);
                        }
                    }}
                />
            </div>
            <div style={{flex: 1, width: "100%"}}>
                <RenderAntdGraphQLList
                    deleting={deleting}
                    tableData={q.data?.vehicles}
                    columns={[{
                        columnId: "id",
                        renderCell: (row) => <div style={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <Typography.Text>{row.id}</Typography.Text>
                            <Typography.Text type={"secondary"}>{row.externalId}</Typography.Text>
                        </div>,
                    }, {
                        columnId: "name",
                        renderCell: (row) => row.name,
                    }, {
                        columnId: "registrationNumber",
                        renderCell: (row) => row.registrationNumber,
                    }, {
                        columnId: "costLocation",
                        renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                            <Typography.Text>{row.costLocation?.name}</Typography.Text>
                            <Typography.Text type={"secondary"}>{row.costLocation?.code}</Typography.Text>
                        </div>,
                    }, {
                        columnId: "currentDriver",
                        renderCell: (row) => row.assignedUser?.fullName,
                    }]}
                    query={q}
                    loading={q.loading}
                    creating={creating}
                    getRowId={g => g.id}
                    updating={updating}
                />
            </div>
        </div>
    </TitleAndContentLayout>
}

export default ManagerVehiclesPage