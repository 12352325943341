import {useMemo} from "react";
import {ITenantCreating, MultilineTextElement, NumericFieldElement} from "@ib-tehnologije/react-widgets";
import {
    CreateWorkOrderTemplateItemMutation,
    CreateWorkOrderMutation, useCreateWorkOrderTemplateItemMutation,
    WorkOrderCreateDtoInput,
    WorkOrderTemplateItemCreateDtoInput
} from "../graphql-types";
import {ActualWorkOrderReturnDto} from "../ReturnTypes/ActualWorkOrderReturnDto";
import {ActualWorkOrderTemplateItemReturnDto} from "../ReturnTypes/ActualWorkOrderTemplateItemReturnDto";
import {useItemPicker} from "../Pickers/useItemPicker";
import {useFilePicker} from "../Pickers/useFilePicker";

export const useWorkOrderTemplateItemCreating = (forWorkOrderTemplateId: number): ITenantCreating<CreateWorkOrderTemplateItemMutation, ActualWorkOrderTemplateItemReturnDto, WorkOrderTemplateItemCreateDtoInput, "data"> => {
    const [create] = useCreateWorkOrderTemplateItemMutation();
    const itemIdPicker = useItemPicker({
        showDeleted: false,
    });
    const filePicker = useFilePicker();

    return useMemo<ITenantCreating<CreateWorkOrderTemplateItemMutation, ActualWorkOrderTemplateItemReturnDto, WorkOrderTemplateItemCreateDtoInput, "data">>(() => ({
        modelKey: "data",
        baseFormData: {
            workOrderTemplateId: forWorkOrderTemplateId
        },
        formDataToDto: formData => ({
            itemId: formData.itemId,
            note: formData.note,
            quantity: formData.quantity,
            workOrderTemplateId: forWorkOrderTemplateId
        }),
        formDefinition: {
            itemId: itemIdPicker,
            note: {
                type: new MultilineTextElement({rows: 3}),
            },
            quantity: {
                type: new NumericFieldElement({}),
            },
        },
        selectionToDuplicateDto: selection => ({
            itemId: selection.itemId,
            item: selection.item,
            note: selection.note,
            quantity: selection.quantity,
            workOrderTemplateId: forWorkOrderTemplateId
        }),
        fn: create
    }), [create, forWorkOrderTemplateId]);
}