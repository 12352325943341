import {useCallback, useMemo, useState} from "react";
import {
    AlertApiError,
    ButtonElement,
    CustomRenderElement, ExtractLocalisedApiError,
    GroupElement,
    ITenantUpdating,
    TextFieldElement
} from "@ib-tehnologije/react-widgets";
import {
    ApplicationUserUpdateDtoInput, GetUserQuery, GetUserQueryVariables,
    UpdateApplicationUserMutation, useManuallyConfirmUserEmailMutation, useResendConfirmationEmailMutation,
    UserRole,
    useUpdateApplicationUserMutation
} from "../graphql-types";
import {ActualApplicationSingleUserReturnDto} from "../ReturnTypes/ActualApplicationUserReturnDto";
import {useCompanyPicker} from "../Pickers/useCompanyPicker";
import {useVehiclePicker} from "../Pickers/useVehiclePicker";
import {useOrganizationalEntityPicker} from "../Pickers/useOrganizationalEntityPicker";
import {FormikProps, FormikValues} from "formik";
import {useTranslation} from "react-i18next";
import {notification} from "antd";
import {ApolloQueryResult} from "@apollo/client";

export const useUserProfileUpdating = (currentUserRoles: UserRole[], refetch: (variables?: Partial<GetUserQueryVariables>) => Promise<ApolloQueryResult<GetUserQuery>>): ITenantUpdating<UpdateApplicationUserMutation, ActualApplicationSingleUserReturnDto, ApplicationUserUpdateDtoInput, "input"> => {
    const [updateUser] = useUpdateApplicationUserMutation();
    const companyIdPicker = useCompanyPicker({
        disabledIf: ({values}: FormikProps<FormikValues>) => !currentUserRoles.includes(UserRole.PartnerContact),
    }, [currentUserRoles]);
    const vehicleIdPicker = useVehiclePicker({
        disabledIf: ({values}: FormikProps<FormikValues>) => !currentUserRoles.includes(UserRole.FieldWorker),
        type: {
            valueKey: "currentVehicle"
        }
    }, [currentUserRoles]);
    const organizationalEntityIdPicker = useOrganizationalEntityPicker({
        disabledIf: ({values}: FormikProps<FormikValues>) => !currentUserRoles.includes(UserRole.FieldWorker),
        type: {
            valueKey: "currentOrganizationalEntity"
        }
    }, [currentUserRoles]);
    const [resendConfirmationEmail] = useResendConfirmationEmailMutation();
    const [sendingConfirmationEmail, setSendingConfirmationEmail] = useState(false);
    const {t} = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [confirmEmailManually] = useManuallyConfirmUserEmailMutation()
    const [confirmingUserEmailManually, setConfirmingUserEmailManually] = useState(false);
    const resendConfirmationEmailClicked = useCallback(async (userName: string) => {
        setSendingConfirmationEmail(true);
        resendConfirmationEmail({
            variables: {
                model: {
                    username: userName
                }
            }
        }).then(() => {
            api.success({
                message: t("notification"),
                description: t("confirmationEmailSent")
            });

            refetch();
        }).catch((e) => api.error({
            message: t("error"),
            description: ExtractLocalisedApiError(e)
        })).finally(() => setSendingConfirmationEmail(false));
    }, [refetch, resendConfirmationEmail, api, t]);

    const confirmUserEmail = useCallback(async (id: string) => {
        setConfirmingUserEmailManually(true);
        confirmEmailManually({
            variables: {
                userId: id
            }
        }).then(() => {
            api.success({
                message: t("notification"),
                description: t("emailConfirmed")
            });

            refetch();
        }).catch((e) => api.error({
            message: t("error"),
            description: ExtractLocalisedApiError(e)
        })).finally(() => setConfirmingUserEmailManually(false));
    }, [confirmEmailManually, api, t, refetch]);

    return useMemo<ITenantUpdating<UpdateApplicationUserMutation, ActualApplicationSingleUserReturnDto, ApplicationUserUpdateDtoInput, "input">>(() => ({
        modelKey: "input",
        formDefinition: {
            context: {
                type: new CustomRenderElement({
                    renderer: ({values}) => contextHolder
                })
            },
            resendOrConfirmManually: {
                type: new GroupElement({
                    resendConfirmationEmail: {
                        type: new ButtonElement({
                            props: {
                                loading: sendingConfirmationEmail
                            },
                            onClick: (data) => resendConfirmationEmailClicked(data.values.userName),
                        }),
                        disabledIf: ({values}) => values.emailConfirmed,
                    },
                    confirmManually: {
                        type: new ButtonElement({
                            props: {
                                style: {
                                    width: "100%"
                                },
                                danger: true,
                                loading: confirmingUserEmailManually
                            },
                            onClick: (data) => confirmUserEmail(data.values.id),
                        }),
                        disabledIf: ({values}) => values.emailConfirmed,
                    },
                })
            },
            userName: {
                type: new TextFieldElement({})
            },
            nameAndSurname: {
                type: new GroupElement({
                    firstName: {
                        type: new TextFieldElement({})
                    },
                    lastName: {
                        type: new TextFieldElement({})
                    },
                }),
            },
            phoneAndEmail: {
                type: new GroupElement({
                    phoneNumber: {
                        type: new TextFieldElement({})
                    },
                    email: {
                        type: new TextFieldElement({})
                    },
                }),
            },
            companyId: companyIdPicker,
            vehicleAndOrganizationalEntity: {
                type: new GroupElement({
                    currentVehicleId: vehicleIdPicker,
                    currentOrganizationalEntityId: organizationalEntityIdPicker,
                }),
            },
            externalId: {
                type: new TextFieldElement({})
            }
        },
        formDataToDto: (formData) => ({
            id: formData.id,
            userName: formData.userName,
            companyId: formData.companyId,
            firstName: formData.firstName,
            lastName: formData.lastName,
            phoneNumber: formData.phoneNumber,
            email: formData.email,
            currentVehicleId: formData.currentVehicleId,
            currentOrganizationalEntityId: formData.currentOrganizationalEntityId,
            externalId: formData.externalId,
        }),
        selectionToBaseFormData: (selection) => ({
            id: selection?.id,
            companyId: selection?.companyId,
            firstName: selection?.firstName,
            lastName: selection?.lastName,
            company: selection?.company,
            phoneNumber: selection?.phoneNumber,
            userName: selection?.userName,
            email: selection?.email,
            emailConfirmed: selection?.emailConfirmed,
            currentVehicleId: selection?.currentVehicleId,
            currentVehicle: selection?.currentVehicle,
            currentOrganizationalEntityId: selection?.currentOrganizationalEntityId,
            currentOrganizationalEntity: selection?.currentOrganizationalEntity,
            externalId: selection?.externalId,
        }),
        fn: updateUser,
    }), [updateUser, companyIdPicker, vehicleIdPicker, organizationalEntityIdPicker, resendConfirmationEmailClicked, contextHolder, sendingConfirmationEmail, confirmingUserEmailManually, confirmUserEmail]);
}