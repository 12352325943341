import React, {FC, useCallback, useContext, useMemo} from "react";
import {
    AlertApiError,
    ExtractLocalisedApiError,
    FormOrientation,
    RenderAntdGraphQLForm,
    RenderAntdGraphQLList,
    RenderAntdStandaloneForm,
    TextFieldElement,
    TitleAndContentLayout, TitleElement
} from "@ib-tehnologije/react-widgets";
import {
    useCreateWorkOrderPdfMutation, useDeleteFileMutation, useDuplicateWorkOrderMutation,
    useGetSignedWorkOrderPdFsLazyQuery,
    useGetSingleWorkOrdersStatusChangesQuery, useGetUnsignedWorkOrderPdFsQuery, useGetWorkOrderIdsQuery,
    useGetWorkOrderItemsQuery,
    useGetWorkOrderQuery, useGetWorkOrderTotalsQuery, useSendPdfFileMutation,
    useSendWorkOrderPdfToPartnerMutation,
    useSetWorkOrderStatusMutation,
    WorkOrderStatus
} from "../graphql-types";
import {useNavigate, useParams} from "react-router-dom";
import {useWorkOrderUpdating} from "../Hooks/useWorkOrderUpdating";
import {
    Badge,
    Button, Descriptions, Divider,
    Dropdown,
    DropdownProps,
    MenuProps,
    Modal,
    notification,
    Popconfirm,
    Tag,
    theme,
    Typography
} from "antd";
import {useTranslation} from "react-i18next";
import {WorkOrderStatusStepper} from "../Components/WorkOrderStatusStepper";
import {useWorkOrderItemCreating} from "../Hooks/useWorkOrderItemCreating";
import {useWorkOrderItemUpdating} from "../Hooks/useWorkOrderItemUpdating";
import {useWorkOrderItemDeleting} from "../Hooks/useWorkOrderItemDeleting";
import {ncFormat, nFormat} from "../index";
import {
    DeleteOutlined,
    FileAddOutlined,
    FilePdfOutlined,
    PlusOutlined,
    UnorderedListOutlined,
    DownloadOutlined,
    SendOutlined,
    FolderOpenOutlined, CopyOutlined
} from "@ant-design/icons";
import {WorkOrderStatusesVertical} from "../Components/WorkOrderStatusesVertical";
import {AuthContext} from "../Contexts/AuthContext";
import {useCompanyUserPicker} from "../Hooks/useCompanyUserPicker";
import {SignatureRegular} from "@fluentui/react-icons";
import dayjs from "dayjs";
import {WorkOrderPDFAttachmentsView} from "../Components/WorkOrderPDFAttachmentsView";
import {ActualWorkOrderReturnDto} from "../ReturnTypes/ActualWorkOrderReturnDto";

const ManagerWorkOrderPage: FC = () => {
    const {id} = useParams();
    const updatingWorkOrder = useWorkOrderUpdating();
    const updatingWorkOrderItem = useWorkOrderItemUpdating(+id!);
    const creatingWorkOrderItem = useWorkOrderItemCreating(+id!);
    const deletingWorkOrderItem = useWorkOrderItemDeleting(+id!);
    const [api, apiContextHolder] = notification.useNotification();
    const [modal, modalContextHolder] = Modal.useModal();
    const {t} = useTranslation();
    const [createWorkOrderPDF,] = useCreateWorkOrderPdfMutation();
    const [duplicateWorkOrder, duplicationData] = useDuplicateWorkOrderMutation();
    const [q, v] = useGetSignedWorkOrderPdFsLazyQuery({
        variables: {
            workOrderId: +id!
        }
    });
    const workOrderQuery = useGetWorkOrderQuery({
        variables: {
            id: +id!
        }
    });
    const workOrderItemQuery = useGetWorkOrderItemsQuery({
        variables: {
            where: {
                workOrderId: {
                    eq: +id!
                }
            }
        }
    });
    const statusQuery = useGetSingleWorkOrdersStatusChangesQuery({
        variables: {
            id: +id!,
        }
    });
    const [setWorkOrderStatus, statusData] = useSetWorkOrderStatusMutation({});
    const {data: workOrderIds, refetch: refetchWorkOrderIds} = useGetWorkOrderIdsQuery({
        variables: {
            workOrderId: +id!
        }
    });
    const changeStatus = useCallback(async (toStatus: WorkOrderStatus): Promise<void> => {
        await setWorkOrderStatus({
            variables: {
                workOrderId: +id!,
                status: toStatus
            }
        }).then(({data}) => {
            statusQuery.refetch();
            if (toStatus === WorkOrderStatus.ConfirmedByManager) {
                if (!workOrderIds?.workOrderIds.formattedId.includes("/")) {
                    refetchWorkOrderIds();
                }
            }
        }).catch((e) => {
            api.error({
                message: ExtractLocalisedApiError(e)
            });
        });
    }, [setWorkOrderStatus, id, statusQuery, workOrderQuery.data?.workOrder, workOrderQuery.refetch, api, refetchWorkOrderIds]);
    const cancelWorkOrder = useCallback((): Promise<void> => changeStatus(WorkOrderStatus.Cancelled), [changeStatus]);
    const {token: themeToken} = theme.useToken();
    const {auth: {token}} = useContext(AuthContext);
    const companyUserIdPicker = useCompanyUserPicker(workOrderQuery.data?.workOrder.companyId!, true);
    const [sendFilesToEmail] = useSendPdfFileMutation({});
    const [deleteFile] = useDeleteFileMutation({});
    const navigate = useNavigate();
    const [showingSendPDF, setShowingSendPDFFile] = React.useState<number | undefined>(undefined);
    const {
        token: {
            colorErrorText,
            colorTextDisabled,
            colorSuccessText,
            colorWarning,
            blue,
            purple,
            cyan,
            yellow6,
            green7
        },
    } = theme.useToken();
    const {
        data: unsignedPDFs,
        refetch: refetchUnsignedPDFs,
        loading: unsignedPDFsLoading
    } = useGetUnsignedWorkOrderPdFsQuery({
        variables: {
            workOrderId: +id!
        }
    });
    const {data: workOrderTotals} = useGetWorkOrderTotalsQuery({
        variables: {
            workOrderId: +id!
        }
    });

    const contentStyle: React.CSSProperties = {
        backgroundColor: themeToken.colorBgElevated,
        borderRadius: themeToken.borderRadiusLG,
        boxShadow: themeToken.boxShadowSecondary,
        padding: "10px 10px 0px 10px"
    };

    const currentStatus = useMemo(() => {
        return statusQuery.data?.singleWorkOrderAllStatusChanges?.[0]?.status;
    }, [statusQuery.data?.singleWorkOrderAllStatusChanges]);

    const onOpenPDFClicked = useCallback(() => {
        q().then((r) => {
            if (r.data?.file) {
                const url = `/api/File/Download/${r.data.file.id}?access_token=${token}`;
                window.open(url, "_blank");
            } else {
                api.error({message: t("no-pdf-available")});
            }
        });
    }, [q, api, t]);

    const onSendPDFToContactOrEmail = useCallback(async (data: any, pdfId: number): Promise<boolean> => {
        if (workOrderQuery.data?.workOrder.id) {
            return sendFilesToEmail({
                variables: {
                    dto: {
                        directToEmail: data.directToEmail,
                        contactId: data.contactId,
                        fileIds: [pdfId]
                    }
                }
            }).then(() => {
                api.success({message: t("pdf-sent")});
                return true;
            }).catch((e) => {
                api.error({message: ExtractLocalisedApiError(e)});
                return false;
            });
        } else {
            api.error({message: t("work-order-not-found")});
            return false;
        }
    }, [workOrderQuery.data?.workOrder.id, api, t, sendFilesToEmail]);

    const hasSignedPDF = useMemo(() => !!workOrderQuery.data?.workOrder.signedPDFs.length, [workOrderQuery.data?.workOrder.signedPDFs.length]);
    const [creatingPDF, setCreatingPDF] = React.useState(false);
    const [showingPDFFiles, setShowingPDFFiles] = React.useState(false);

    const pdfMenu = useMemo<DropdownProps["menu"]>(() => ({
        items: [{
            key: "signed-pdf",
            label: t("signed-pdf"),
            disabled: !hasSignedPDF,
            children: [{
                icon: <FilePdfOutlined/>,
                key: "open-signed-pdf",
                label: t("open-signed-pdf"),
                onClick: () => {
                    onOpenPDFClicked();
                },
            }, {
                icon: <FilePdfOutlined/>,
                key: "send-signed-pdf",
                label: t("send-signed-pdf"),
                disabled: !hasSignedPDF,
                onClick: () => {
                    setShowingSendPDFFile(workOrderQuery.data?.workOrder.signedPDFs?.[0]?.id);
                },
            }]
        }, {
            key: "unsigned-pdf",
            label: t("unsigned-pdf"),
            children: [{
                icon: <PlusOutlined/>,
                key: "create-pdf",
                label: t("create-pdf"),
                onClick: () => {
                    setCreatingPDF(true);
                    createWorkOrderPDF({
                        variables: {
                            workOrderId: +id!
                        }
                    }).then((res) => {
                        if (res.data?.createWorkOrderPDF) {
                            api.success({message: t("pdf-created")});
                            const url = `/api/File/Download/${res.data?.createWorkOrderPDF}?access_token=${token}`;
                            window.open(url, "_blank");
                            refetchUnsignedPDFs();
                        }
                    }).catch(AlertApiError)
                        .finally(() => setCreatingPDF(false));
                },
            }, ...(unsignedPDFs?.files?.items?.map((f) => ({
                icon: <FilePdfOutlined/>,
                key: f.id,
                label: dayjs(f.createdAt).local().format("YYYY-MM-DD HH:mm"),
                children: [{
                    icon: <DownloadOutlined/>,
                    key: `open-${f.id}`,
                    label: t("open"),
                    onClick: () => {
                        const url = `/api/File/Download/${f.id}?access_token=${token}`;
                        window.open(url, "_blank");
                    }
                }, {
                    icon: <SendOutlined/>,
                    key: `send-to-${f.id}`,
                    label: `${t("send-to")}...`,
                    onClick: () => {
                        setShowingSendPDFFile(f.id);
                    }
                }, {
                    icon: <DeleteOutlined/>,
                    key: `delete-${f.id}`,
                    label: t("delete"),
                    danger: true,
                    onClick: () => {
                        modal.confirm({
                            title: t("warning"),
                            okType: "danger",
                            content: t("are-you-sure-you-want-to-delete-this-pdf"),
                            onOk: () => {
                                deleteFile({
                                    variables: {
                                        data: {
                                            id: f.id
                                        }
                                    }
                                }).then(() => {
                                    refetchUnsignedPDFs().then(() => {
                                        api.success({message: t("pdf-deleted")});
                                    });
                                });
                            }
                        });
                    }
                }]
            })) ?? [])]
        }, {
            key: "attachments",
            label: t("attachments"),
            icon: <FolderOpenOutlined/>,
            onClick: () => setShowingPDFFiles(true)
        }]
    }), [hasSignedPDF, t, onOpenPDFClicked, id, setShowingSendPDFFile, workOrderQuery.data?.workOrder.signedPDFs, setCreatingPDF, createWorkOrderPDF, api, refetchUnsignedPDFs, unsignedPDFs?.files?.items]);

    const onDuplicateClicked = useCallback(async () => {
        try {
            const duplicationResult = await duplicateWorkOrder({
                variables: {
                    workOrderId: +id!
                }
            });

            if (duplicationResult.data?.duplicateWorkOrder) {
                api.success({message: t("work-order-duplicated")});

                navigate(`/Manager/WorkOrder/${duplicationResult.data.duplicateWorkOrder[0].id}`);
            }
        } catch (e: any) {
            AlertApiError(e);
        }
    }, [duplicateWorkOrder]);


    return <TitleAndContentLayout pad title={"work-order-overview"}
                                  titleAddon={workOrderIds?.workOrderIds?.formattedId}>
        {apiContextHolder}
        {modalContextHolder}
        {<Modal afterClose={() => setShowingPDFFiles(false)}
                width={1200}
                open={showingPDFFiles}
                destroyOnClose={true}
                title={t("files-that-will-be-attached-to-the-pdf")}
                onCancel={() => setShowingPDFFiles(false)}
                onOk={() => setShowingPDFFiles(false)}
                afterOpenChange={open => {
                    if (open) {
                        setShowingPDFFiles(open)
                    } else {
                        setShowingPDFFiles(false);
                    }
                }}>
            <WorkOrderPDFAttachmentsView workOrderId={+id!}/>
        </Modal>}
        <Modal onCancel={() => setShowingSendPDFFile(undefined)}
               destroyOnClose={true}
               open={!!showingSendPDF}
               afterClose={() => setShowingSendPDFFile(undefined)} footer={null}
               afterOpenChange={(open) => {
                   if (open) {
                       setShowingSendPDFFile(showingSendPDF);
                   } else {
                       setShowingSendPDFFile(undefined);
                   }
               }}>
            <RenderAntdStandaloneForm
                loading={false}
                formSettings={{
                    inline: true,
                    orientation: FormOrientation.VERTICAL,
                }}
                title={t("send-pdf-to")}
                formDefinition={{
                    "send-pdf-to": {
                        type: new TitleElement({})
                    },
                    contactId: companyUserIdPicker,
                    directToEmail: {
                        type: new TextFieldElement({})
                    }
                }}
                submission={{
                    title: t("send-pdf"),
                    onSubmit: (data) => onSendPDFToContactOrEmail(data, showingSendPDF!)
                        .then((success) => {
                            if (success) {
                                setShowingSendPDFFile(undefined)
                            }
                        })
                }}
                baseFormData={{}}
            />
            <div style={{height: 10}}/>
        </Modal>
        <WorkOrderStatusStepper statusQuery={statusQuery} id={+id!} changeStatus={changeStatus}/>
        <div style={{display: "flex", flexDirection: "row", gap: 10, flexWrap: "wrap"}}>
            <Dropdown
                dropdownRender={(menu) => (
                    <div style={contentStyle}>
                        <WorkOrderStatusesVertical statusQuery={statusQuery}/>
                    </div>
                )}>
                <Button loading={statusQuery.loading} onClick={() => navigate("/Manager/WorkOrderStatusChanges", {
                    state: {
                        workOrderId: id,
                        workOrder: workOrderQuery.data?.workOrder
                    }
                })}>
                    {t("detailed-work-order-status-info")}
                </Button>
            </Dropdown>
            <Dropdown menu={pdfMenu}>
                <Button icon={<FilePdfOutlined/>}
                        loading={creatingPDF || workOrderQuery.loading || statusQuery.loading || unsignedPDFsLoading}>
                    {t("pdf")}
                </Button>
            </Dropdown>
            {currentStatus === WorkOrderStatus.Cancelled ? null :
                <Popconfirm okType={"danger"}
                            okButtonProps={{
                                type: "primary"
                            }}
                            title={t("warning")}
                            description={t("are-you-sure-you-want-to-cancel-this-work-order")}
                            onConfirm={cancelWorkOrder}>
                    <Button loading={statusQuery.loading} type={"primary"} danger>
                        {t("cancel-work-order")}
                    </Button>
                </Popconfirm>}
            <Button loading={workOrderQuery.loading || duplicationData.loading} onClick={onDuplicateClicked}
                    icon={<CopyOutlined/>}>
                {t("duplicate")}
            </Button>
            <div>
                {(currentStatus && workOrderQuery.data?.workOrder) && !workOrderQuery.data?.workOrder.signedPDFs.length ?
                    <div style={{verticalAlign: "middle", display: "flex", flexDirection: "row"}}>
                        <Tag color={"error"} style={{
                            verticalAlign: "middle"
                        }}>
                            <div style={{
                                display: "flex",
                                verticalAlign: "middle",
                                alignContent: "stretch",
                                alignItems: "stretch"
                            }}>
                                <SignatureRegular fontSize={30}
                                                  color={colorErrorText} style={{padding: 5}}/>
                                <div style={{flex: 1, alignSelf: "center"}}>
                                    <span>{t("no-signed-pdf")}</span>
                                </div>
                            </div>
                        </Tag>
                    </div> : null}
            </div>
        </div>
        <div style={{display: "flex", flexDirection: "row", gap: 10, flexWrap: "wrap"}}>
            <div style={{width: 600}}>
                <RenderAntdGraphQLForm
                    updating={updatingWorkOrder}
                    selectedItem={workOrderQuery.data?.workOrder}
                    getId={data => data.id}
                    editingTitle={"work-order-editing"}
                    addingTitle={""}
                    doNotResetAfterSuccessfulSubmission={true}
                    loading={workOrderQuery.loading}
                    onSuccessfulSubmission={() => api.success({message: t("work-order-updated")})}
                    formSettings={{inline: true}}/>
            </div>
            <div style={{flex: 1}}>
                <Typography.Title level={4}>{t("work-order-items")}</Typography.Title>
                <RenderAntdGraphQLList
                    style={{flex: 1}}
                    creating={creatingWorkOrderItem}
                    updating={updatingWorkOrderItem}
                    deleting={deletingWorkOrderItem}
                    tableData={workOrderItemQuery.data?.workOrderItems}
                    columns={[{
                        columnId: "itemId",
                        renderCell: (row) => <Badge count={row.files?.length}>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <Typography.Text>{row.itemName}</Typography.Text>
                                {row.itemDescription &&
                                    <Typography.Text type={"secondary"}>{row.itemDescription}</Typography.Text>}
                                {row.note &&
                                    <Typography.Text>{row.note}</Typography.Text>}
                            </div>
                        </Badge>,
                        width: 200
                    }, {
                        columnId: "quantity",
                        width: 30,
                        renderCell: (row) =>
                            <span>{nFormat.format(row.quantity)} x {nFormat.format(row.itemUnitsContained)} {row.unitOfMeasureSymbol ?? ""}</span>,
                    }, {
                        columnId: "price/vatBase",
                        width: 30,
                        renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                            <Typography.Text type={"secondary"}>{ncFormat.format(row.itemUnitPrice)}</Typography.Text>
                            <Typography.Text>{ncFormat.format(row.vatBase)}</Typography.Text>
                        </div>,
                    }, {
                        columnId: "vatRate/vat",
                        width: 30,
                        renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                            <Typography.Text type={"secondary"}>{nFormat.format(row.itemVATTypeRate)} %</Typography.Text>
                            <Typography.Text>{ncFormat.format(row.vat)}</Typography.Text>
                        </div>
                    }, {
                        columnId: "total",
                        width: 30,
                        renderCell: (row) => <span>{ncFormat.format(row.total)}</span>,
                    }]}
                    query={workOrderItemQuery}
                    loading={workOrderItemQuery.loading}
                    footer={() => <div
                        style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                        <Descriptions bordered size={"small"} column={1}>
                            <Descriptions.Item
                                style={{textAlign: "end"}}
                                label={t("vat_base")}>{ncFormat.format(workOrderTotals?.workOrderTotals.vatBase ?? 0)}</Descriptions.Item>
                            <Descriptions.Item
                                style={{textAlign: "end"}}
                                label={t("vat")}>{ncFormat.format(workOrderTotals?.workOrderTotals.vat ?? 0)}</Descriptions.Item>
                            <Descriptions.Item
                                style={{textAlign: "end"}}
                                contentStyle={{fontWeight: "bold"}}
                                label={t("total")}>{ncFormat.format(workOrderTotals?.workOrderTotals.total ?? 0)}</Descriptions.Item>
                        </Descriptions>
                    </div>}
                    getRowId={v => v.id}/>
            </div>
        </div>
    </TitleAndContentLayout>;
}

export default ManagerWorkOrderPage;