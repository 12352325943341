import React, {FC, useMemo} from "react";
import {useWorkOrderTemplateUpdating} from "../Hooks/useWorkOrderTemplateUpdating";
import {useWorkOrderTemplateCreating} from "../Hooks/useWorkOrderTemplateCreating";
import {useNavigate, useParams} from "react-router-dom";
import {RenderAntdGraphQLForm, RenderAntdGraphQLList, TitleAndContentLayout} from "@ib-tehnologije/react-widgets";
import {
    useGetWorkOrderItemsQuery,
    useGetWorkOrderTemplateItemsQuery,
    useGetWorkOrderTemplateQuery
} from "../graphql-types";
import {ButtonProps, Typography} from "antd";
import {nFormat} from "../index";
import {useTranslation} from "react-i18next";
import {useWorkOrderItemUpdating} from "../Hooks/useWorkOrderItemUpdating";
import {useWorkOrderItemCreating} from "../Hooks/useWorkOrderItemCreating";
import {useWorkOrderTemplateItemUpdating} from "../Hooks/useWorkOrderTemplateItemUpdating";
import {useWorkOrderTemplateItemCreating} from "../Hooks/useWorkOrderTemplateItemCreating";
import {useWorkOrderTemplateItemDeleting} from "../Hooks/useWorkOrderTemplateItemDeleting";

const ManagerWorkOrderTemplatePage: FC = () => {
    const {id} = useParams();
    const {t} = useTranslation();
    const updatingTemplate = useWorkOrderTemplateUpdating();
    const navigate = useNavigate();
    const updatingWorkOrderTemplateItem = useWorkOrderTemplateItemUpdating(+id!);
    const creatingWorkOrderTemplateItem = useWorkOrderTemplateItemCreating(+id!);
    const deletingWorkOrderTemplateItem = useWorkOrderTemplateItemDeleting();

    const workOrderTemplateQuery = useGetWorkOrderTemplateQuery({
        variables: {
            id: +id!
        }
    });
    const workOrderTemplateItemQuery = useGetWorkOrderTemplateItemsQuery({
        variables: {
            where: {
                workOrderTemplateId: {
                    eq: +id!
                }
            }
        }
    });
    
    return <TitleAndContentLayout title="updating-work-order-template"
                                  titleAddon={workOrderTemplateQuery.data?.workOrderTemplate?.id?.toString()}
                                  pad>
        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10}}>
            <div style={{width: 600}}>
                <RenderAntdGraphQLForm
                    updating={updatingTemplate}
                    selectedItem={workOrderTemplateQuery.data?.workOrderTemplate}
                    addingTitle=""
                    editingTitle=""
                    formSettings={{inline: true}}
                    doNotResetAfterSuccessfulSubmission
                    getId={data => data.id}
                    loading={workOrderTemplateQuery.loading}
                />
            </div>
            <div style={{flex: 1}}>
                <Typography.Title level={4}>{t("work-order-items")}</Typography.Title>
                <RenderAntdGraphQLList
                    style={{flex: 1}}
                    creating={creatingWorkOrderTemplateItem}
                    updating={updatingWorkOrderTemplateItem}
                    deleting={deletingWorkOrderTemplateItem}
                    tableData={workOrderTemplateItemQuery.data?.workOrderTemplateItems}
                    columns={[{
                        columnId: "itemId",
                        renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                            <Typography.Text>{row.item?.name}</Typography.Text>
                            {row.item?.description && <Typography.Text type={"secondary"}>{row.item?.description}</Typography.Text>}
                        </div>,
                        width: 200
                    }, {
                        columnId: "note",
                        renderCell: (row) => row.note,
                    }, {
                        columnId: "quantity",
                        renderCell: (row) => nFormat.format(row.quantity),
                    }]}
                    query={workOrderTemplateItemQuery}
                    loading={workOrderTemplateItemQuery.loading}
                    getRowId={v => v.id}/>
            </div>
        </div>
    </TitleAndContentLayout>;
}

export default ManagerWorkOrderTemplatePage;