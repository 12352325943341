import {useMemo} from "react";
import {DateElement, ITenantUpdating, MultilineTextElement} from "@ib-tehnologije/react-widgets";
import {
    PickupRequestUpdateDtoInput,
    UpdatePickupRequestMutation, useUpdatePickupRequestMutation
} from "../graphql-types";
import {ActualPickupRequestFilteredReturnDto} from "../ReturnTypes/ActualPickupRequestFilteredReturnDto";
import {useCompanyPicker} from "../Pickers/useCompanyPicker";
import {useCompanyLocationPicker} from "../Pickers/useCompanyLocationPicker";
import dayjs from "dayjs";

export const usePickupRequestUpdating = () => {
    const [updatePickupRequest] = useUpdatePickupRequestMutation();
    const companyIdPicker = useCompanyPicker();
    const companyLocationIdPicker = useCompanyLocationPicker();

    return useMemo<ITenantUpdating<UpdatePickupRequestMutation, ActualPickupRequestFilteredReturnDto, PickupRequestUpdateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            companyId: companyIdPicker,
            companyLocationId: companyLocationIdPicker,
            locationNote: {
                type: new MultilineTextElement({rows: 3}),
            },
            pickupDate: {
                type: new DateElement({}),
            },
            serviceNote: {
                type: new MultilineTextElement({rows: 3}),
            },
        },
        baseFormData: {},
        formDataToDto: (formData) => ({
            id: formData.id,
            companyId: formData.companyId,
            companyLocationId: formData.companyLocationId,
            pickupDate: formData.pickupDate ? dayjs(formData.pickupDate).utc().toISOString() : undefined,
            locationNote: formData.locationNote,
            serviceNote: formData.serviceNote,
        }),
        fn: updatePickupRequest,
        selectionToBaseFormData: (selection) => ({
            company: selection?.company,
            companyId: selection?.companyId,
            companyLocation: selection?.companyLocation,
            companyLocationId: selection?.companyLocationId,
            id: selection?.id,
            pickupDate: selection?.pickupDate ? dayjs(selection.pickupDate).toDate() : undefined,
            locationNote: selection?.locationNote,
            serviceNote: selection?.serviceNote,
        })
    }), []);
}