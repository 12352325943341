import {useMemo} from "react";
import {
    ButtonElement,
    CheckboxElement, CustomSearchBoxElement,
    ITenantUpdating,
    MapPreviewElement, MultilineTextElement,
    TextFieldElement
} from "@ib-tehnologije/react-widgets";
import {
    CompanyLocationUpdateDtoInput,
    GeoJsonGeometryType,
    UpdateCompanyLocationMutation, useGetCompaniesLazyQuery, useGetCompaniesQuery,
    useUpdateCompanyLocationMutation
} from "../graphql-types";
import {ActualCompanyLocationsReturnDto} from "../ReturnTypes/ActualCompanyLocationReturnDto";
import {useApolloClient} from "@apollo/client";
import {makeGeoCenterFromValues} from "../Helpers/makeGeoCenterFromValues";
import {geocodeAddress} from "../Helpers/geocodeAddress";
import {useCompanyPicker} from "../Pickers/useCompanyPicker";

export const useCompanyLocationUpdating = (companyId: number | undefined = undefined) => {
    const [updateCompanyLocation] = useUpdateCompanyLocationMutation();
    const apolloClient = useApolloClient();
    const companyIdPicker = useCompanyPicker();

    return useMemo<ITenantUpdating<UpdateCompanyLocationMutation, ActualCompanyLocationsReturnDto, CompanyLocationUpdateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            ...(companyId ? {} : {
                companyId: companyIdPicker
            }),
            name: {
                type: new TextFieldElement({}),
                translationKey: "location-name"
            },
            description: {
                type: new MultilineTextElement({rows: 3}),
            },
            isMainLocation: {
                type: new CheckboxElement({}),
            },
            address: {
                type: new TextFieldElement({})
            },
            "geodecode-address": {
                type: new ButtonElement({
                    onClick: (values) => {
                        console.log(values);
                        return geocodeAddress(apolloClient, values);
                    },
                    props: {
                        type: "primary",
                        style: {
                            width: "100%",
                            marginBottom: 10
                        }
                    }
                }),
                disabledIf: (values) => !values.values.address,
            },
            location: {
                type: new MapPreviewElement({
                    makeCenter: makeGeoCenterFromValues,
                    fullscreenSettings: {
                        separateDialogTitle: "company-location-map-preview",
                    }
                })
            },
            externalId: {
                type: new TextFieldElement({})
            }
        },
        selectionToBaseFormData: (selection) => ({
            id: selection?.id,
            name: selection?.name,
            description: selection?.description,
            address: selection?.address,
            location: selection?.location ? {
                latitude: selection.location.latitude,
                longitude: selection.location.longitude,
            } : undefined,
            companyId: selection?.companyId ?? companyId,
            isMainLocation: selection?.isMainLocation,
            company: selection?.company,
            externalId: selection?.externalId
        }),
        formDataToDto: (formData) => {
            console.log("formData", formData);
            return ({
                id: formData.id,
                name: formData.name,
                description: formData.description,
                address: formData.address,
                location: formData.location ? {
                    latitude: formData.location.latitude,
                    longitude: formData.location.longitude,
                } : undefined,
                companyId: formData.companyId ?? companyId,
                isMainLocation: formData.isMainLocation,
                externalId: formData.externalId
            });
        },
        fn: updateCompanyLocation
    }), [companyId]);
}