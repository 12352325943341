import {useCallback, useMemo, useState} from "react";
import {
    ButtonElement, CustomRenderElement,
    CustomSearchBoxElement, ExtractLocalisedApiError,
    GroupElement, HeaderElement,
    ITenantUpdating,
    TextFieldElement
} from "@ib-tehnologije/react-widgets";
import {
    ApplicationUserUpdateDtoInput,
    UpdateApplicationUserMutation, useGetCompaniesLazyQuery,
    useGetCompaniesQuery, useResendConfirmationEmailMutation, UserRole,
    useUpdateApplicationUserMutation
} from "../graphql-types";
import {
    ActualApplicationSingleUserReturnDto,
    ActualApplicationUsersReturnDto
} from "../ReturnTypes/ActualApplicationUserReturnDto";
import {useCompanyPicker} from "../Pickers/useCompanyPicker";
import {FormikProps, FormikValues} from "formik";
import {useTranslation} from "react-i18next";
import {notification} from "antd";

export const useUserUpdating = (): ITenantUpdating<UpdateApplicationUserMutation, ActualApplicationUsersReturnDto, ApplicationUserUpdateDtoInput, "input"> => {
    const [updateUser] = useUpdateApplicationUserMutation();
    const {t} = useTranslation();
    const companyIdPicker = useCompanyPicker({
        disabledIf: (formikProps: FormikProps<any>) => {
            const roles = formikProps.values?.userRoles?.map((f: any) => f.role.name.replace("_", "").toLowerCase()) ?? [];
            const pc = UserRole.PartnerContact.replaceAll("_", "").toLowerCase();
            return roles.every((f: any) => f !== pc);
        }
    });
    const [sendingConfirmationEmail, setSendingConfirmationEmail] = useState(false);
    const [resendConfirmationEmail] = useResendConfirmationEmailMutation();
    const [api, contextHolder] = notification.useNotification();
    const resendConfirmationEmailClicked = useCallback(async (userName: string) => {
        setSendingConfirmationEmail(true);
        resendConfirmationEmail({
            variables: {
                model: {
                    username: userName
                }
            }
        }).then(() => api.success({
            message: t("notification"),
            description: t("confirmationEmailSent")
        })).catch((e) => api.error({
            message: t("error"),
            description: ExtractLocalisedApiError(e)
        })).finally(() => setSendingConfirmationEmail(false));
    }, []);

    return useMemo<ITenantUpdating<UpdateApplicationUserMutation, ActualApplicationUsersReturnDto, ApplicationUserUpdateDtoInput, "input">>(() => ({
        modelKey: "input",
        formDefinition: {
            context: {
                type: new CustomRenderElement({
                    renderer: ({values}) => contextHolder
                })
            },
            resendConfirmationEmail: {
                type: new ButtonElement({
                    props: {
                        style: {
                            width: "100%"
                        }
                    },
                    onClick: (data) => {
                        resendConfirmationEmailClicked(data.values.userName);
                    },
                }),
                disabledIf: ({values}) => values.emailConfirmed,
            },
            nameAndSurname: {
                type: new GroupElement({
                    firstName: {
                        type: new TextFieldElement({})
                    },
                    lastName: {
                        type: new TextFieldElement({})
                    },
                }),
            },
            phoneAndEmail: {
                type: new GroupElement({
                    phoneNumber: {
                        type: new TextFieldElement({})
                    },
                    email: {
                        type: new TextFieldElement({})
                    },
                }),
            },
            companyId: companyIdPicker,
            externalId: {
                type: new TextFieldElement({})
            }
        },
        formDataToDto: (formData) => ({
            id: formData.id,
            companyId: formData.companyId,
            firstName: formData.firstName,
            lastName: formData.lastName,
            phoneNumber: formData.phoneNumber,
            email: formData.email,
            userName: formData.userName,
            externalId: formData.externalId,
        }),
        selectionToBaseFormData: (selection) => ({
            id: selection?.id,
            companyId: selection?.companyId,
            firstName: selection?.firstName,
            lastName: selection?.lastName,
            company: selection?.company,
            userName: selection?.userName,
            phoneNumber: selection?.phoneNumber,
            email: selection?.email,
            emailConfirmed: selection?.emailConfirmed,
            userRoles: selection?.userRoles,
            externalId: selection?.externalId,
        }),
        fn: updateUser,
    }), [updateUser, t, companyIdPicker, resendConfirmationEmail, contextHolder, api, resendConfirmationEmailClicked]);
}