export const de = {
    "login": "Anmeldung",
    "register": "Registrierung",
    "username": "Benutzername",
    "password": "Passwort",
    "sign-up": "Registrieren",
    "email": "E-Mail",
    "go-back": "Zurück",
    "EmailConfirmed_PleaseWait": "E-Mail wurde bestätigt. Bitte warten...",
    "EmailConfirmationErrorPage_Title": "Fehler bei der E-Mail-Bestätigung",
    "EmailConfirmationErrorPage_Text": "Es gab einen Fehler bei der E-Mail-Bestätigung. Bitte versuchen Sie es erneut.",
    "EmailConfirmed_Success": "E-Mail erfolgreich bestätigt.",
    "The Username field is required.": "Das Feld Benutzername ist erforderlich.",
    "The Email field is required.": "Das Feld E-Mail ist erforderlich.",
    "The Password field is required.": "Das Feld Passwort ist erforderlich.",
    "EmailConfirmationErrorPage_BackButton": "Zurück",
    "redirector.unknownRoleForUsingApp": "Sie haben noch keinen Zugriff auf die Anwendung zugewiesen bekommen.",
    "NotFoundPage_Title": "Seite nicht gefunden",
    "NotFoundPage_BackButton": "Zurück",
    "NotFoundPage_Text": "Die gesuchte Seite wurde nicht gefunden.",
    "ConfirmYourEmail": "Ein Bestätigungslink wurde an Ihre E-Mail gesendet. Bitte bestätigen Sie Ihre E-Mail.",
    "User email not confirmed": "E-Mail nicht bestätigt",
    "EmailNotConfirmed": "E-Mail nicht bestätigt",
    "ResendConfirmationEmail": "Bestätigungslink erneut senden",
    "ResendConfirmationEmail_Success": "Bestätigungslink erfolgreich gesendet.",
    "PasswordTooShort": "Passwort ist zu kurz.",
    "User not found": "Benutzer nicht gefunden.",
    "back": "Zurück",
    "home": "Startseite",
    "logout": "Abmelden",
    "work-orders": "Arbeitsaufträge",
    "work-order": "Arbeitsauftrag",
    "work-orders-description": "Übersicht der Arbeitsaufträge",
    "users": "Benutzer",
    "users-description": "Übersicht der Benutzer",
    "companies": "Partner",
    "companies-description": "Übersicht der Partner",
    "administration": "Verwaltung",
    "error-loading-items": "Fehler beim Laden der Artikel",
    "retry": "Wiederholen",
    "search": "Suche",
    "Previous": "Vorherige",
    "Next": "Nächste",
    "create": "Hinzufügen",
    "cancel": "Abbrechen",
    "save": "Speichern",
    "id": "Schlüssel",
    "emailConfirmed": "E-Mail bestätigt",
    "are-you-sure-you-want-to-delete-this-item": "Sind Sie sicher, dass Sie diesen Artikel löschen möchten?",
    "delete": "Löschen",
    "page": "Seite",
    "InvalidEmail": "Geben Sie eine gültige E-Mail ein",
    "additional": "Zusätzlich",
    "resendConfirmationEmail": "Bestätigungslink erneut senden",
    "pickup-requests": "Abholanfragen",
    "no-items": "Keine Artikel",
    "pickup-requests-description": "Übersicht der Abholanfragen",
    "status": "Status",
    "date": "Datum",
    "roles": "Rollen",
    "Admin": "Administrator",
    "Customer": "Kunde",
    "company-name": "Partnername",
    "adding-companies": "Partner hinzufügen",
    "taxId": "Steuernummer",
    "actions": "Aktionen",
    "locations": "Standorte",
    "company-locations": "Partnerstandorte",
    "address": "Adresse",
    "adding-companyLocations": "Partnerstandort hinzufügen",
    "company-location-name": "Standortname",
    "location-name": "Standortname",
    "geodecode-address": "Adresse auf Karte suchen",
    "editing-companyLocations": "Partnerstandort bearbeiten",
    "adding-users": "Benutzer hinzufügen",
    "adding-pickupRequests": "Abholanfrage hinzufügen",
    "notification": "Benachrichtigung",
    "successfully-deleted-item": "Artikel erfolgreich gelöscht",
    "successfully-deleted-item-with-id": "Artikel mit ID erfolgreich gelöscht",
    "error-deleting-item-with-id": "Fehler beim Löschen des Elements mit ID",
    "successfully-duplicated-item-with-id": "Element mit ID erfolgreich dupliziert",
    "error-duplicating-item-with-id": "Fehler beim Duplizieren des Elements mit ID",
    "company": "Partner",
    "company-location": "Partnerstandort",
    "InvalidUserName": "Geben Sie einen gültigen Benutzernamen ein",
    "editing-users": "Benutzer bearbeiten",
    "edit": "Bearbeiten",
    "show-only-unassigned": "Nur nicht zugewiesene anzeigen",
    "settings": "Einstellungen",
    "start-search": "Suche starten",
    "adding-pickupRequestsFiltered": "Abholaufträge hinzufügen",
    "editing-pickupRequestsFiltered": "Abholaufträge bearbeiten",
    "isMainLocation": "Hauptstandort",
    "assign": "Zuweisen",
    "assigned-user": "Dem Benutzer zugewiesen",
    "details": "Details",
    "user": "Benutzer",
    "user-overview": "Benutzerübersicht",
    "MANAGER": "Manager",
    "PARTNER_CONTACT": "Partnerkontakt",
    "FIELD_WORKER": "Feldarbeiter",
    "user-details": "Benutzerdetails",
    "confirmationEmailSent": "Bestätigungs-E-Mail gesendet",
    "userName": "Benutzername",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "system": "System",
    "field-worker": "Feldarbeiter",
    "field-workers": "Feldarbeiter",
    "field-workers-description": "Übersicht der Feldarbeiter",
    "reports": "Berichte",
    "reports-description": "Detaillierte Berichte",
    "partner-contacts": "Partnerkontakte",
    "units-of-measure": "Maßeinheiten",
    "vehicles": "Fahrzeuge",
    "organizational-entities": "Organisationseinheiten",
    "work-order-status-changes": "Statusänderungen von Arbeitsaufträgen",
    "work-order-templates": "Arbeitsauftragsvorlagen",
    "basic-data": "Grunddaten",
    "role": "Rolle",
    "enums:FieldWorker": "Feldarbeiter",
    "enums:PartnerContact": "Partnerkontakt",
    "enums:Manager": "Manager",
    "editing-companies": "Unternehmen bearbeiten",
    "adding-partnerPickupRequests": "Abholaufträge für Partner hinzufügen",
    "languages-full:hr": "Kroatisch",
    "languages-full:en": "Englisch",
    "languages-full:de": "Deutsch",
    "languages-full:it": "Italienisch",
    "items": "Dienstleistungen und Materialien",
    "cost-locations": "Kostenstellen",
    "execution-categories": "Ausführungskategorien",
    "duplicate": "Duplizieren",
    "editing-executionCategories": "Ausführungskategorien bearbeiten",
    "adding-executionCategories": "Ausführungskategorien hinzufügen",
    "name": "Name",
    "description": "Beschreibung",
    "unitOfMeasureId": "Maßeinheit",
    "unitOfMeasure": "Maßeinheit",
    "unitPrice": "Preis pro Einheit",
    "unitsContained": "Anzahl der Einheiten",
    "nameAndDescription": "Name und Beschreibung",
    "NoResults": "Keine Ergebnisse",
    "adding-items": "Dienstleistungen und Materialien hinzufügen",
    "editing-items": "Dienstleistungen und Materialien bearbeiten",
    "symbol": "Symbol",
    "adding-unitsOfMeasure": "Maßeinheiten hinzufügen",
    "editing-unitsOfMeasure": "Maßeinheiten bearbeiten",
    "workOrderId": "Arbeitsauftragsnummer",
    "dateTime": "Datum und Uhrzeit",
    "initiatingUser": "Initiator",
    "companyLocation": "Standort des Unternehmens",
    "organizationalEntity": "Organisationseinheit",
    "executionCategory": "Ausführungskategorie",
    "vehicle": "Fahrzeug",
    "recurrence": "Wiederholung",
    "deactivated": "Deaktiviert",
    "executionDay": "Ausführungstag",
    "executionDayOfMonth": "Tag des Monats",
    "executionCategoryId": "Kategorie der Ausführung",
    "organizationalEntityId": "Organisationseinheit",
    "adding-workOrderTemplates": "Arbeitsauftragsvorlagen hinzufügen",
    "editing-workOrderTemplates": "Arbeitsauftragsvorlagen bearbeiten",
    "adding-vehicles": "Fahrzeuge hinzufügen",
    "editing-vehicles": "Fahrzeuge bearbeiten",
    "registrationNumber": "Registrierungsnummer",
    "costLocation": "Kostenstelle",
    "costLocationId": "Kostenstellen-ID",
    "adding-organizationalEntities": "Organisationseinheiten hinzufügen",
    "editing-organizationalEntities": "Organisationseinheiten bearbeiten",
    "parent": "Übergeordnet",
    "parentId": "Übergeordnete ID",
    "Email already exists": "E-Mail existiert bereits",
    "unassigned": "Nicht zugewiesen",
    "pickup-date": "Abholdatum",
    "create-work-order": "Arbeitsauftrag erstellen",
    "work-order-from-pickup-request": "Arbeitsauftrag aus Abholanfrage",
    "pickup-request-id": "Abholanfragen-ID",
    "pickup-request-pickup-date": "Gewünschtes Abholdatum",
    "note": "Notiz",
    "reporting-user": "Gemeldet von",
    "company-details": "Partnerdetails",
    "vehicleId": "Fahrzeug",
    "work-order-settings": "Einstellungen des Arbeitsauftrags",
    "first-page": "Erste Seite",
    "next-page": "Nächste Seite",
    "previous-page": "Vorherige Seite",
    "last-page": "Letzte Seite",
    "pickupDate": "Abholdatum",
    "drop-pin": "Standort markieren",
    "company-tax-id": "Steuernummer des Partners",
    "company-location-map-preview": "Vorschau des Standorts auf der Karte",
    "yes": "Ja",
    "no": "Nein",
    "warning": "Warnung",
    "managerNote": "Notiz des Managers",
    "company-location-change": "Standortänderung des Partners",
    "phoneNumber": "Telefonnummer",
    "preferredLanguage": "Bevorzugte Sprache",
    "enums:WAITING_FOR_ACCEPTANCE": "Warten auf Annahme",
    "enums:ACCEPTED": "Angenommen",
    "enums:DECLINED": "Abgelehnt",
    "enums:COMPLETED": "Erledigt",
    "company-and-location": "Partner und Standort",
    "work-order-overview": "Überblick über Arbeitsaufträge",
    "enums:CANCELLED": "Abgesagt",
    "enums:INVOICED": "Fakturiert",
    "itemId": "Dienstleistung oder Material",
    "quantity": "Menge",
    "adding-workOrders": "Arbeitsaufträge hinzufügen",
    "item": "Dienstleistung oder Material",
    "add-row": "Zeile hinzufügen",
    "header": "Überschrift",
    "create-work-order-template": "Arbeitsauftragsvorlage erstellen",
    "enums:FRIDAY": "Freitag",
    "enums:MONDAY": "Montag",
    "enums:SATURDAY": "Samstag",
    "enums:SUNDAY": "Sonntag",
    "enums:THURSDAY": "Donnerstag",
    "enums:TUESDAY": "Dienstag",
    "enums:WEDNESDAY": "Mittwoch",
    "known-location": "Bekannter Standort",
    "unknown-location": "Unbekannter Standort",
    "every_week_at": "Jede Woche um",
    "freeformLocation": "Freiform Standort",
    "basics": "Grundlagen",
    "executionFrequency": "Ausführungshäufigkeit",
    "companyAndLocation": "Partner und Standort",
    "executionResources": "Ausführungsressourcen",
    "itemsTitle": "Dienstleistungen und Materialien",
    "every_month": "Jeden Monat",
    "th-extension": "-te",
    "every": "Jeden",
    "genitive_every": "Jedes",
    "day_of_month": "Tag des Monats",
    "enums:High": "Hoch",
    "enums:Low": "Niedrig",
    "enums:Medium": "Mittel",
    "priority": "Priorität",
    "Company location is required when freeform location is empty": "Standort des Unternehmens ist erforderlich, wenn der Freiform-Standort leer ist",
    "Either Company location or freeform location is required, but not both": "Entweder Standort des Unternehmens oder Freiform-Standort ist erforderlich, aber nicht beides",
    "Execution day or execution day of month is required": "Ausführungstag oder Tag des Monats ist erforderlich",
    "Company or Location not found, or not associated with company.": "Unternehmen oder Standort nicht gefunden oder nicht mit dem Unternehmen verbunden.",
    "every_monday": "Jeden Montag",
    "every_tuesday": "Jeden Dienstag",
    "every_wednesday": "Jeden Mittwoch",
    "every_thursday": "Jeden Donnerstag",
    "every_friday": "Jeden Freitag",
    "every_saturday": "Jeden Samstag",
    "every_sunday": "Jeden Sonntag",
    "updating-work-order-template": "Arbeitsauftragsvorlage aktualisieren",
    "Company location or freeform location is required": "Unternehmensstandort oder freiform Standort ist erforderlich",
    "freeformCompany": "Freiform-Unternehmen",
    "Either Company or freeform company is required": "Entweder Unternehmen oder Freiform-Unternehmen ist erforderlich",
    "scheduledDate": "Geplantes Datum",
    "Freeform location is required when company is empty": "Freiform Standort ist erforderlich, wenn das Unternehmen leer ist",
    "Freeform company is required when company is empty": "Freiform-Unternehmen ist erforderlich, wenn das Unternehmen leer ist",
    "enums:WAITING_FOR_ACCEPTANCE_BY_FIELD_WORKER": "Warten auf Annahme durch den Feldarbeiter",
    "enums:ACCEPTED_BY_FIELD_WORKER": "Vom Feldarbeiter angenommen",
    "enums:DECLINED_BY_FIELD_WORKER": "Vom Feldarbeiter abgelehnt",
    "enums:COMPLETED_BY_FIELD_WORKER": "Vom Feldarbeiter abgeschlossen",
    "enums:CONFIRMED_BY_MANAGER": "Vom Manager bestätigt",
    "enums:WITHOUT_ASSIGNMENT": "Ohne Zuweisung",
    "locationNote": "Standortnotiz",
    "serviceNote": "Dienstleistungsnotiz",
    "ACCOUNTING": "Buchhaltung",
    "BackGoToLogin": "Zurück zum Login",
    "close": "Schließen",
    "enums:Accounting": "Buchhaltung",
    "companyName": "Firmenname",
    "companyLocationName": "Standortname des Unternehmens",
    "workOrderCurrentStatusStatus": "Aktueller Status des Arbeitsauftrags",
    "notes": "Notizen",
    "noteForAccounting": "Notiz für die Buchhaltung",
    "organizational-entities-structure": "Struktur der Organisationseinheiten",
    "files": "Dateien",
    "filesTitle": "Dateien",
    "file-selection": "Dateiauswahl",
    "pickupRequestId": "Abholauftrags-ID",
    "pickupLocationNote": "Abholstandort Notiz",
    "pickupRequestServiceNote": "Abholauftragsdienst Notiz",
    "fillFromPickupRequest": "Aus Abholauftrag ausfüllen",
    "fillFromPickupRequestContent": "Möchten Sie den Arbeitsauftrag aus dem Abholauftrag ausfüllen?",
    "work-order-updated": "Arbeitsauftrag aktualisiert",
    "createdAt": "Erstellungsdatum und -zeit",
    "customActionsTitle": "Benutzerdefinierte Aktionen",
    "Cannot set status to a lower value than the current one!": "Status kann nicht niedriger als der aktuelle Wert gesetzt werden!",
    "Only accountants can set status to this value!": "Nur Buchhalter können den Status auf diesen Wert setzen!",
    "Only managers can set status to this value!": "Nur Manager können den Status auf diesen Wert setzen!",
    "Only managers or field workers can set status to this value!": "Nur Manager oder Feldarbeiter können den Status auf diesen Wert setzen!",
    "Work order must be assigned to a vehicle or organizational entity to set status to this value!": "Arbeitsauftrag muss einem Fahrzeug oder einer Organisationseinheit zugewiesen werden, um den Status auf diesen Wert zu setzen!",
    "Only field workers can set status to this value!": "Nur Feldarbeiter können den Status auf diesen Wert setzen!",
    "work-order-items": "Arbeitsauftragsartikel",
    "adding-workOrderTemplateItems": "Hinzufügen von Dienstleistungen und Materialien zu Arbeitsauftragsvorlagen",
    "editing-workOrderTemplateItems": "Bearbeiten von Dienstleistungen und Materialien in Arbeitsauftragsvorlagen",
    "adding-workOrderItems": "Hinzufügen von Dienstleistungen und Materialien zu Arbeitsaufträgen",
    "editing-workOrderItems": "Bearbeiten von Dienstleistungen und Materialien in Arbeitsaufträgen",
    "form.uploadFailed": "Fehler",
    "form.uploadFailedDescription": "Es gibt Dateien, die nicht erfolgreich übertragen wurden. Bitte überprüfen.",
    "form.uploading": "Warnung",
    "form.uploadingDescription": "Dateiübertragung läuft. Bitte warten.",
    "cancel-work-order": "Arbeitsauftrag stornieren",
    "are-you-sure-you-want-to-cancel-this-work-order": "Sind Sie sicher, dass Sie diesen Arbeitsauftrag stornieren möchten?",
    "create-pdf": "PDF erstellen",
    "enums:CONFIRMED_BY_ACCOUNTING": "Von der Buchhaltung bestätigt",
    "enums:SENT_TO_ERP": "An ERP gesendet",
    "error-occurred": "Es ist ein Fehler aufgetreten",
    "reload": "Neu laden",
    "enums:SENDING_TO_ERP_FAILED": "Fehler beim Senden an ERP",
    "Cannot cancel work order that has been sent to ERP!": "Ein an ERP gesendeter Arbeitsauftrag kann nicht storniert werden!",
    "detailed-work-order-status-info": "Detaillierte Statusinformationen zum Arbeitsauftrag",
    "consent-details": "Zustimmungsdetails",
    "isEmailConsentGiven": "E-Mail-Zustimmung erteilt",
    "emailConsentSource": "Quelle der E-Mail-Zustimmung",
    "emailConsentGivenOn": "Zustimmung erteilt am",
    "consent-not-given": "Zustimmung nicht erteilt",
    "user-updated-successfully": "Benutzer erfolgreich aktualisiert",
    "consent-updated-successfully": "Zustimmung erfolgreich aktualisiert",
    "profile-updated": "Profil aktualisiert",
    "userRole": "Benutzerrolle",
    "CompanyLocationId or LocationNote is required": "Standort-ID des Unternehmens oder Standortnotiz ist erforderlich",
    "location": "Standort",
    "requested-service": "Angeforderter Dienst",
    "requested-by": "Angefordert von",
    "view-work-order": "Arbeitsauftrag ansehen",
    "enums:AcceptedByFieldWorker": "Vom Feldarbeiter akzeptiert",
    "enums:DeclinedByFieldWorker": "Vom Feldarbeiter abgelehnt",
    "enums:CompletedByFieldWorker": "Vom Feldarbeiter abgeschlossen",
    "enums:Cancelled": "Storniert",
    "enums:ConfirmedByManager": "Vom Manager bestätigt",
    "enums:ConfirmedByAccounting": "Von der Buchhaltung bestätigt",
    "enums:SentToErp": "An ERP gesendet",
    "enums:SendingToErpFailed": "Senden an ERP fehlgeschlagen",
    "enums:WaitingForAcceptanceByFieldWorker": "Warten auf Annahme durch den Feldarbeiter",
    "enums:WithoutAssignment": "Ohne Zuweisung",
    "assignedUserId": "Dem Benutzer zugewiesen",
    "scheduledDateFrom": "Geplanter Startdatum",
    "scheduledDateTo": "Geplantes Enddatum",
    "no-linked-work-order": "Kein verbundener Arbeitsauftrag",
    "accountants": "Buchhalter",
    "accounting": "Buchhaltung",
    "enums:reportViews.total_work_order_count": "Gesamtanzahl der Arbeitsaufträge",
    "work_order_count_short": "Anzahl der Aufträge",
    "work_order_count": "Anzahl der Arbeitsaufträge",
    "startDate": "Startdatum",
    "endDate": "Enddatum",
    "assigneeId": "Ausführender",
    "count": "Anzahl",
    "xlsx_export": "Export in Excel",
    "csv_export": "Export in CSV",
    "pdf_export": "Export in PDF",
    "current-page": "Seite",
    "page-of": "von",
    "work_order_count_per_day": "Anzahl der Arbeitsaufträge pro Tag",
    "enums:reportViews.total_work_order_count_calendar": "Gesamtanzahl der Arbeitsaufträge: Kalender",
    "reportWorkOrderid": "Arbeitsauftrags-Schlüssel",
    "reportWorkOrderpickupRequestId": "Abholauftragscode",
    "reportWorkOrderpickupRequestPickupDate": "Gewünschtes Abholdatum",
    "reportWorkOrderpickupRequestReportingUserUserName": "Gemeldet von",
    "reportWorkOrderpickupRequestCompanyName": "Partnername",
    "reportWorkOrderpickupRequestCompanyTaxId": "Steuernummer des Partners",
    "reportWorkOrderpickupRequestCompanyLocationName": "Standortname des Partners",
    "reportWorkOrderpickupRequestCompanyLocationAddress": "Adresse des Partnerstandorts",
    "reportWorkOrderscheduledDate": "Geplantes Datum",
    "reportWorkOrderstartDateTime": "Startzeit",
    "reportWorkOrderendDateTime": "Endzeit",
    "reportWorkOrderduration": "Dauer",
    "reportWorkOrderassignedByManagerUserUserName": "Vom Manager zugewiesen",
    "reportWorkOrderassignedUserUserName": "Dem Benutzer zugewiesen",
    "reportWorkOrdervehicleName": "Fahrzeugname",
    "reportWorkOrdervehicleRegistrationNumber": "Fahrzeugregistrierungsnummer",
    "reportWorkOrdervehicleCostLocationName": "Kostenstelle des Fahrzeugs",
    "reportWorkOrdervehicleCostLocationCode": "Kostenstellen-Code des Fahrzeugs",
    "reportWorkOrderorganizationalEntityName": "Name der Organisationseinheit",
    "reportWorkOrderexecutionCategoryName": "Name der Ausführungskategorie",
    "reportWorkOrdercurrentStatusStatus": "Aktueller Status des Arbeitsauftrags",
    "reportWorkOrderitemsTotal": "Gesamtzahl von Dienstleistungen und Materialien",
    "currentVehicleId": "Fahrzeug",
    "currentOrganizationalEntityId": "Organisationseinheit",
    "Cannot reopen a completed work order!": "Ein abgeschlossener Arbeitsauftrag kann nicht wieder geöffnet werden!",
    "open-pdf": "PDF öffnen",
    "no-pdf-available": "Kein PDF verfügbar",
    "send-pdf": "PDF senden",
    "send-pdf-to": "PDF senden an",
    "contactId": "Kontakt",
    "directToEmail": "Direkt an E-Mail",
    "ContactId or directToEmail must be provided": "Kontakt oder E-Mail müssen angegeben werden",
    "pdf-sent": "PDF wurde gesendet",
    "User has not given consent to use email.": "Der Benutzer hat keine Zustimmung zur Verwendung von E-Mail gegeben.",
    "value": "Wert",
    "Login": "Anmeldung",
    "generate-work-orders": "Arbeitsaufträge generieren",
    "Work orders generated successfully": "Arbeitsaufträge erfolgreich generiert",
    "partner-portal-notes": "Partnerportal-Notizen",
    "Work order must be assigned to a vehicle to set status to this value!": "Arbeitsauftrag muss einem Fahrzeug zugewiesen werden, um den Status auf diesen Wert zu setzen!",
    "change-password": "Passwort ändern",
    "oldPassword": "Altes Passwort",
    "newPassword": "Neues Passwort",
    "password-changed": "Passwort geändert",
    "password-reset": "Passwort zurücksetzen",
    "password-reset-successful": "Passwort erfolgreich zurückgesetzt",
    "Cannot delete pickup request with associated work order.": "Abholanfrage mit zugehörigem Arbeitsauftrag kann nicht gelöscht werden.",
    "Cannot update pickup request with associated work order." : "Abholanfrage mit zugehörigem Arbeitsauftrag kann nicht aktualisiert werden.",
    "editing-partnerPickupRequests": "Bearbeiten von Abholaufträgen für Partner",
    "requestedService": "Angeforderter Dienst",
    "service-note": "Dienstleistungsnotiz",
    "pickupServiceNote": "Abholauftragsdienst Notiz",
    "not_reviewed": "Nicht überprüft",
    "no-data": "Keine Daten",
}