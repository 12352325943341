import React, {FC, useCallback, useMemo} from "react";
import {
    AlertApiError,
    CheckboxElement,
    ExtractLocalisedApiError,
    FormOrientation,
    ITenantDeleting,
    NumericFieldElement,
    RenderAntdGraphQLList,
    RenderAntdStandaloneForm,
    TextFieldElement,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {
    DayOfWeek,
    DeleteWorkOrderTemplateMutation,
    useDeleteWorkOrderTemplateMutation, useGenerateWorkOrdersFromTemplatesMutation,
    useGetWorkOrderTemplatesQuery,
    WorkOrderTemplateDeleteDtoInput
} from "../graphql-types";
import {useTranslation} from "react-i18next";
import {ActualWorkOrderTemplateReturnDto} from "../ReturnTypes/ActualWorkOrderTemplateReturnDto";
import {useNavigate} from "react-router-dom";
import {ButtonProps, notification, theme, Typography} from "antd";
import dayjs from "dayjs";
import {PersonRegular} from "@fluentui/react-icons";
import {CheckOutlined, CloseCircleOutlined, FileAddOutlined} from "@ant-design/icons";
import {useCompanyPicker} from "../Pickers/useCompanyPicker";
import {useVehiclePicker} from "../Pickers/useVehiclePicker";
import {useOrganizationalEntityPicker} from "../Pickers/useOrganizationalEntityPicker";
import {useCompanyLocationPicker} from "../Pickers/useCompanyLocationPicker";
import {useWorkOrderPriorityPicker} from "../Pickers/useWorkOrderPriorityPicker";
import {useExecutionDayPicker} from "../Pickers/useExecutionDayPicker";
import useTheme from "antd/es/config-provider/hooks/useTheme";

const ManagerWorkOrderTemplatesPage: FC = () => {
    const {t} = useTranslation();
    const q = useGetWorkOrderTemplatesQuery();
    const [deleteWorkOrderTemplate] = useDeleteWorkOrderTemplateMutation();
    const navigate = useNavigate();
    const [generatingWorkOrders, setGeneratingWorkOrders] = React.useState(false);
    const [generateWorkOrders] = useGenerateWorkOrdersFromTemplatesMutation();
    const [api, contextHolder] = notification.useNotification();
    const companyIdPicker = useCompanyPicker();
    const companyLocationIdPicker = useCompanyLocationPicker();
    const vehicleIdPicker = useVehiclePicker();
    const organizationalEntityIdPicker = useOrganizationalEntityPicker();
    const priorityPicker = useWorkOrderPriorityPicker();
    const executionDayPicker = useExecutionDayPicker();

    const deleting = useMemo<ITenantDeleting<DeleteWorkOrderTemplateMutation, ActualWorkOrderTemplateReturnDto, WorkOrderTemplateDeleteDtoInput, "data">>(() => ({
        modelKey: "data",
        selectionToDto: (selection) => ({
            id: selection.id
        }),
        fn: deleteWorkOrderTemplate
    }), []);

    const now = useMemo(() => dayjs(), []);
    const {token: themeToken} = theme.useToken();

    const getNextExecutionDay = useCallback((dayOfWeek: DayOfWeek) => {
        const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        const dayIndex = daysOfWeek.indexOf(dayOfWeek.toLowerCase());
        const difference = (dayIndex - now.weekday() + 7) % 7;
        if (difference === 0) {
            return now.startOf('day');
        }
        return now.add(difference, 'day').startOf('day');
    }, [now]);

    const getNextExecutionDayOfMonth = useCallback((dayOfMonth: number) => {
        let nextExecution = now.date(dayOfMonth).startOf('day');
        if (nextExecution.isBefore(now)) {
            nextExecution = nextExecution.add(1, 'month');
        }
        return nextExecution;
    }, [now]);

    const additionalInlineButtons = useMemo<ButtonProps[]>(() => [{
        onClick: async () => {
            setGeneratingWorkOrders(true);
            try {
                await generateWorkOrders();
                api.success({
                    message: t("Work orders generated successfully")
                });
            } catch (e) {
                console.error(e);
                api.error({
                    message: ExtractLocalisedApiError(e as any)
                });
            } finally {
                setGeneratingWorkOrders(false);
            }
        },
        children: t("generate-work-orders"),
        icon: <FileAddOutlined/>,
        loading: generatingWorkOrders
    }], [generatingWorkOrders, generateWorkOrders, t, api]);

    return <TitleAndContentLayout title={"work-order-templates"} pad>
        {contextHolder}
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                width: "100%"
            }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: 250,
            }}>
                <RenderAntdStandaloneForm
                    formSettings={{inline: true, orientation: FormOrientation.VERTICAL}}
                    loading={q.loading}
                    formDefinition={{
                        search: {
                            type: new TextFieldElement({})
                        },
                        deactivated: {
                            type: new CheckboxElement({
                                tristate: true
                            })
                        },
                        companyId: companyIdPicker,
                        companyLocationId: companyLocationIdPicker,
                        vehicleId: vehicleIdPicker,
                        priority: priorityPicker,
                        organizationalEntityId: organizationalEntityIdPicker,
                        executionDay: executionDayPicker,
                        executionDayOfMonth: {
                            type: new NumericFieldElement({})
                        },
                    }}
                    submission={{
                        title: "start-search",
                        onSubmit: (values) => {
                            console.log(values);
                            q.refetch({
                                ...q.variables,
                                where: {
                                    and: [values.search ? {
                                        or: [{
                                            name: {
                                                contains: values.search
                                            },
                                        }, {
                                            description: {
                                                contains: values.search
                                            },
                                        }, {
                                            company: {
                                                name: {
                                                    contains: values.search
                                                }
                                            }
                                        }, {
                                            company: {
                                                description: {
                                                    contains: values.search
                                                }
                                            }
                                        }, {
                                            company: {
                                                taxId: {
                                                    contains: values.search
                                                }
                                            }
                                        }, {
                                            companyLocation: {
                                                name: {
                                                    contains: values.search
                                                }

                                            }
                                        }, {
                                            companyLocation: {
                                                address: {
                                                    contains: values.search
                                                }
                                            }
                                        }, {
                                            freeformLocation: {
                                                contains: values.search
                                            }
                                        }, {
                                            organizationalEntity: {
                                                name: {
                                                    contains: values.search
                                                }
                                            }
                                        }, {
                                            organizationalEntity: {
                                                description: {
                                                    contains: values.search
                                                }
                                            }
                                        }, {
                                            vehicle: {
                                                name: {
                                                    contains: values.search
                                                }
                                            }
                                        }, {
                                            vehicle: {
                                                registrationNumber: {
                                                    contains: values.search
                                                }
                                            }
                                        }, {
                                            vehicle: {
                                                costLocation: {
                                                    name: {
                                                        contains: values.search
                                                    }
                                                }
                                            }
                                        }]
                                    } : {},
                                        values.companyId ? {
                                            companyId: {
                                                eq: values.companyId
                                            }
                                        } : {},
                                        values.companyLocationId ? {
                                            companyLocationId: {
                                                eq: values.companyLocationId
                                            }
                                        } : {},
                                        values.vehicleId ? {
                                            vehicleId: {
                                                eq: values.vehicleId
                                            }
                                        } : {},
                                        values.priority !== undefined ? {
                                            priority: {
                                                eq: values.priority
                                            }
                                        } : {},
                                        values.organizationalEntityId ? {
                                            organizationalEntityId: {
                                                eq: values.organizationalEntityId
                                            }
                                        } : {},
                                        values.priority !== undefined ? {
                                            priority: {
                                                eq: values.priority
                                            }
                                        } : {},
                                        values.executionDay !== undefined ? {
                                            executionDay: {
                                                eq: values.executionDay
                                            }
                                        } : {},
                                        values.executionDayOfMonth !== undefined ? {
                                            executionDayOfMonth: {
                                                eq: values.executionDayOfMonth
                                            }
                                        } : {},
                                        values.deactivated !== undefined ? {
                                            deactivated: {
                                                eq: values.deactivated
                                            }
                                        } : {}
                                    ]
                                }
                            });
                        }
                    }}
                />
            </div>
            <div style={{flex: 1, width: "100%"}}>
                <RenderAntdGraphQLList
                    additionalInlineButtons={additionalInlineButtons}
                    onAddClicked={() => navigate("/Manager/WorkOrderTemplates/Create")}
                    onEditClicked={(item) => navigate(`/Manager/WorkOrderTemplate/${item.id}`)}
                    deleting={deleting}
                    tableData={q.data?.workOrderTemplates}
                    columns={[{
                        columnId: "nameAndDescription",
                        renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                            <Typography.Text>{row.name}</Typography.Text>
                            <Typography.Text type={"secondary"}>{row.description}</Typography.Text>
                        </div>,
                    }, {
                        columnId: "active",
                        renderCell: (row) => !row.deactivated ?
                            <CheckOutlined style={{color: themeToken?.colorSuccess}}/> :
                            <CloseCircleOutlined style={{color: themeToken?.colorError}}/>,
                    }, {
                        columnId: "companyAndLocation",
                        renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                            <Typography.Text>{row.company?.name}</Typography.Text>
                            <Typography.Text
                                type={"secondary"}>{row.companyLocation?.name ?? row.freeformLocation}</Typography.Text>
                        </div>,
                    }, {
                        columnId: "vehicle",
                        renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                            <Typography.Text>{row.vehicle?.name}</Typography.Text>
                            <Typography.Text type={"secondary"}>{row.vehicle?.registrationNumber}</Typography.Text>
                        </div>,
                    }, {
                        columnId: "organizationalEntity",
                        renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                            <Typography.Text>{row.organizationalEntity?.name}</Typography.Text>
                            <Typography.Text
                                type={"secondary"}>{row.organizationalEntity?.description}</Typography.Text>
                        </div>,
                    }, {
                        columnId: "recurrence",
                        renderCell: (row) => {
                            if (row.executionDay != undefined) {
                                const nextExecutionDay = getNextExecutionDay(row.executionDay);
                                const humanized = nextExecutionDay.fromNow();

                                return <div style={{display: "flex", flexDirection: "column"}}>
                                    <Typography.Text>{t(`every_${row.executionDay.toLowerCase()}`)}</Typography.Text>
                                    <Typography.Text
                                        type={"secondary"}>{humanized} - {nextExecutionDay.format('LL')}</Typography.Text>
                                </div>;
                            } else if (row.executionDayOfMonth != undefined) {
                                const nextExecutionDay = getNextExecutionDayOfMonth(row.executionDayOfMonth);
                                const humanized = nextExecutionDay.fromNow();

                                return <div style={{display: "flex", flexDirection: "column"}}>
                                    <Typography.Text>{t("genitive_every")} {row.executionDayOfMonth}. {t("day_of_month")}</Typography.Text>
                                    <Typography.Text type={"secondary"}>{humanized}</Typography.Text>
                                </div>
                            }
                            return <span>{t("no_recurrence")}</span>
                        },
                    }]}
                    query={q}
                    loading={q.loading}
                    getRowId={g => g.id}
                />
            </div>
        </div>
    </TitleAndContentLayout>
}

export default ManagerWorkOrderTemplatesPage;