import {useMemo} from "react";
import {ITenantCreating, ITenantDeleting, MultilineTextElement, TextFieldElement} from "@ib-tehnologije/react-widgets";
import {
    CompanyDeleteDtoInput,
    DeleteCompanyMutation,useDeleteCompanyMutation
} from "../graphql-types";
import {ActualCompanyReturnDto} from "../ReturnTypes/ActualCompanyReturnDto";

export const useCompanyDeleting = (): ITenantDeleting<DeleteCompanyMutation, ActualCompanyReturnDto, CompanyDeleteDtoInput, "data"> => {
    const [deleteCompany] = useDeleteCompanyMutation();

    return useMemo<ITenantDeleting<DeleteCompanyMutation, ActualCompanyReturnDto, CompanyDeleteDtoInput, "data">>(() => ({
        modelKey: "data",
        selectionToDto: (selection) => ({
            id: selection.id
        }), 
        fn: deleteCompany
    }), []);
}