import {FormikProps, FormikValues} from "formik";
import {GeocodeAddressDocument, GeocodingResponseDto, GeoJsonGeometryType} from "../graphql-types";
import {ApolloClient} from "@apollo/client";
import {AlertApiError} from "@ib-tehnologije/react-widgets";


export const geocodeAddress = async (apolloClient: ApolloClient<any>, formik: FormikProps<FormikValues>) => {
    const address = formik.values.address;

    if (address?.trim()) {
        try{
            const decoded = await apolloClient.query({
                query: GeocodeAddressDocument,
                variables: {address}
            });

            const responseDto = decoded.data.geocodeAddress as GeocodingResponseDto;

            await formik.setValues({
                ...formik.values,
                geoDecodedAddress: responseDto,
                location: responseDto ? {
                    latitude: responseDto.lat,
                    longitude: responseDto.lng
                } : undefined
            });
        }catch (e){
            console.error(e);
            AlertApiError(e as any);
        }
    }
};