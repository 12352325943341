import {FC, useCallback, useContext, useMemo} from "react";
import {ITableColumnDefinition, RenderAntdGraphQLList} from "@ib-tehnologije/react-widgets";
import {useGetWorkOrderRelatedFilesQuery, useToggleFileIncludeInPdfMutation} from "../graphql-types";
import {ActualFileReturnDto} from "../ReturnTypes/ActualFileReturnDto";
import {useTranslation} from "react-i18next";
import {Button, Checkbox} from "antd";
import {mimeTypeToText} from "../Helpers/mimeTypeToText";
import {AuthContext} from "../Contexts/AuthContext";

interface IWorkOrderPDFAttachmentsView {
    workOrderId: number;

}

export const WorkOrderPDFAttachmentsView: FC<IWorkOrderPDFAttachmentsView> = ({workOrderId}) => {
    const {t} = useTranslation();
    const query = useGetWorkOrderRelatedFilesQuery({
        variables: {
            workOrderId
        }
    });
    const [toggleFile, toggling] = useToggleFileIncludeInPdfMutation()
    const {auth: {token}} = useContext(AuthContext);

    const onIncludePDFChanged = useCallback((file: ActualFileReturnDto) => {
        toggleFile({
            variables: {
                fileId: file.id
            }
        }).then(r => query.refetch());
    }, [toggleFile, query]);

    const filesColumns = useMemo<ITableColumnDefinition<ActualFileReturnDto>[]>(() => [{
        columnId: 'fileName',
        renderCell: item => item?.fileName,
        width: 200
    }, {
        columnId: 'preview',
        renderCell: item => <img
            src={`/api/File/Download/${item.id}?access_token=${token}`}
            style={{maxWidth: 100, maxHeight: 100}}
            alt={item?.fileName}/>,
    }, {
        columnId: 'contentType',
        renderCell: item => mimeTypeToText(item?.contentType),
    }, {
        columnId: 'includeInPDF',
        renderCell: item => <Checkbox onChange={() => onIncludePDFChanged(item)} checked={item?.includeInPDF}/>,
    }, {
        columnId: 'actions',
        renderCell: item => <Button type={"primary"} onClick={() => {
            window.open(`/api/File/Download/${item.id}?access_token=${token}`);
        }}>
            {t('Download')}
        </Button>
    }], [t, onIncludePDFChanged]);

    return <RenderAntdGraphQLList
        disableRowSelection={true}
        tableData={query.data?.workOrderRelatedFiles}
        columns={filesColumns}
        query={query}
        loading={query.loading || toggling.loading}
        getRowId={item => item?.id}/>;
}