import {useMemo} from "react";
import {
    CustomSearchBoxElement,
    ICustomSearchBoxElementProps,
    IModifiableCustomSearchBoxElementProps, ITenantFormLayoutDefinition, RecursivePartial
} from "@ib-tehnologije/react-widgets";
import {
    GetOrganizationalEntitiesQuery,
    GetOrganizationalEntitiesQueryVariables, OrganizationalEntityReturnDtoFilterInput,
    useGetOrganizationalEntitiesLazyQuery,
    useGetOrganizationalEntitiesQuery
} from "../graphql-types";
import {ActualOrganizationalEntityReturnDto} from "../ReturnTypes/ActualOrganizationalEntityReturnDto";
import {OperationVariables} from "@apollo/client";

export function useOrganizationalEntityPicker<TDto>(overrides?: RecursivePartial<ITenantFormLayoutDefinition<TDto, CustomSearchBoxElement<any, any, any, any>>>, deps: any[] = []) {
    return useMemo(() => ({
        ...(overrides as any),
        type: new CustomSearchBoxElement<any, any, any, any>({
            query: useGetOrganizationalEntitiesLazyQuery,
            getQueryData: ({data}) => data?.organizationalEntities,
            getKey: (item) => item.id,
            makeTitle: (item) => item.name,
            makeSubtitle: (item) => item.description,
            makeWhere: (values, search) => ({
                or: [{
                    name: {
                        contains: search
                    }
                }, {
                    description: {
                        contains: search
                    }
                }]
            }),
            valueKey: "organizationalEntity",
            ...(overrides?.type as any)
        }),
    }), [overrides, ...deps]);
}