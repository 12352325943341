import React, {FC, useCallback, useMemo} from "react";
import {notification, Spin, StepProps, Steps} from "antd";
import {
    useGetSingleWorkOrdersStatusChangesQuery,
    useSetWorkOrderStatusMutation,
    WorkOrderStatus
} from "../graphql-types";
import {useTranslation} from "react-i18next";
import {isErrorWorkOrderStatus} from "../Helpers/isErrorWorkOrderStatus";

interface IWorkOrderStatusStepperProps {
    id: number;
    statusQuery: ReturnType<typeof useGetSingleWorkOrdersStatusChangesQuery>;
    changeStatus: (status: WorkOrderStatus) => Promise<void>;
}

export const WorkOrderStatusStepper: FC<IWorkOrderStatusStepperProps> = ({id, statusQuery, changeStatus}) => {
    const {t} = useTranslation();
    const [setWorkOrderStatus, statusData] = useSetWorkOrderStatusMutation();
    const [api, contextHolder] = notification.useNotification();

    const visualStatusGroups = useMemo<Array<Array<WorkOrderStatus>>>(() => ([
        [WorkOrderStatus.WithoutAssignment, WorkOrderStatus.Cancelled],
        [WorkOrderStatus.WaitingForAcceptanceByFieldWorker],
        [WorkOrderStatus.AcceptedByFieldWorker, WorkOrderStatus.DeclinedByFieldWorker],
        [WorkOrderStatus.CompletedByFieldWorker, WorkOrderStatus.PausedUntilContainerPickup, WorkOrderStatus.ResumedContainerPickup],
        [WorkOrderStatus.ConfirmedByManager],
        [WorkOrderStatus.ConfirmedByAccounting, WorkOrderStatus.SentToErp, WorkOrderStatus.SendingToErpFailed],
    ]), []);

    const findStatusIndex = useCallback((status?: WorkOrderStatus) => {
        if (status) {
            for (let i = 0; i < visualStatusGroups.length; i++) {
                if (visualStatusGroups[i].includes(status)) {
                    return i;
                }
            }
        }

        return 0;
    }, [visualStatusGroups]);
    
    const actualStatuses = useMemo(() => {
        return statusQuery.data?.singleWorkOrderAllStatusChanges;
    }, [statusQuery.data]);

    const actualStatusesFromNewest = useMemo(() => {
        return actualStatuses?.map(item => item.status);
    }, [actualStatuses]);
    
    const groupUniqueActualStatuses = useMemo(() => {
        // from newest to oldest, take only one of each status in groups as defined in visualStatusGroups
        const actualStatuses: WorkOrderStatus[] = [];
        if(actualStatusesFromNewest) {
            for (let statusGroup of visualStatusGroups) {
                const actualGroupStatuses = actualStatusesFromNewest.filter(status => statusGroup.includes(status!));
                
                if(actualGroupStatuses[0]) actualStatuses.push(actualGroupStatuses[0]);
            }
        }
        return actualStatuses;
    }, [actualStatusesFromNewest, visualStatusGroups]);
    
    const statusItems = useMemo<StepProps[]>(() => {
        return visualStatusGroups.map(statusGroup => {
            for (const status of statusGroup) {
                if (groupUniqueActualStatuses?.includes(status)) {
                    return {
                        title: <br/>,
                        description: t(`enums:${status.toString()}`)
                    };
                }
            }

            return ({
                title: <br/>,
                description: t(`enums:${statusGroup[0].toString()}`),
            });
        });
    }, [visualStatusGroups, t, groupUniqueActualStatuses]);

    const currentStatus = useMemo(() => {
        return actualStatusesFromNewest?.[0];
    }, [actualStatusesFromNewest]);

    const stepStatus = useMemo(() => {
        if (currentStatus) {
            if (isErrorWorkOrderStatus(currentStatus)) {
                return "error";
            } else if(currentStatus === WorkOrderStatus.SentToErp) {
                return "finish";
            }
        }
    }, [currentStatus]);

    const changeStatusNumeric = useCallback(async (toStatus: number): Promise<void> => {
        await changeStatus(visualStatusGroups[toStatus][0]);
    }, [visualStatusGroups, changeStatus]);

    return <Spin spinning={statusQuery.loading || statusData.loading}>
        {contextHolder}
        <Steps
            current={findStatusIndex(currentStatus)}
            items={statusItems}
            size={"small"}
            style={{paddingBottom: 10}}
            status={stepStatus}
            onChange={changeStatusNumeric}
        />
    </Spin>;
}