import React, {FC} from "react";
import {RenderAntdGraphQLForm, TitleAndContentLayout} from "@ib-tehnologije/react-widgets";
import {useWorkOrderCreating} from "../Hooks/useWorkOrderCreating";
import {useLocation, useNavigate} from "react-router-dom";
import {useGetDefaultOrganizationalEntityQuery} from "../graphql-types";
import {Spin} from "antd";
import {ActualOrganizationalEntityReturnDto} from "../ReturnTypes/ActualOrganizationalEntityReturnDto";

const ManagerWorkOrderCreatePage: FC = () => {
    const {state} = useLocation();
    const {data, loading} = useGetDefaultOrganizationalEntityQuery();

    return <TitleAndContentLayout pad title={"create-work-order"}>
        {loading ? <Spin spinning={true}/> :
            <WrappedManagerWorkOrderCreatePage state={state} defaultOE={data?.defaultOrganizationalEntity}/>}
    </TitleAndContentLayout>;
}

const WrappedManagerWorkOrderCreatePage: FC<{
    defaultOE?: ActualOrganizationalEntityReturnDto,
    state: any
}> = ({defaultOE, state}) => {

    const creating = useWorkOrderCreating(
        state?.pickupRequest ? {pickupRequest: state?.pickupRequest} : undefined,
        defaultOE
    );
    const navigate = useNavigate();

    return <div style={{width: 600}}>
        <RenderAntdGraphQLForm
            creating={creating}
            getId={data => data.id}
            editingTitle={""}
            addingTitle={"adding-workOrder"}
            loading={false}
            onSuccessfulSubmission={(response: any) => {
                const createdWorkOrderResponse = response?.createWorkOrder?.[0];
                if (createdWorkOrderResponse) {
                    navigate(`/Manager/WorkOrder/${createdWorkOrderResponse?.id}`);
                } else {
                    navigate(-1);
                }
            }}
            doNotResetAfterSuccessfulSubmission={true}
            formSettings={{inline: true}}/>
    </div>;
}

export default ManagerWorkOrderCreatePage;