import {FormikValues} from "formik";
import {PointDto} from "../graphql-types";

export const makeGeoCenterFromValues = (values: FormikValues): PointDto | undefined => {
    if (values.location) {
        return values.location ? {
            latitude: values.location.latitude,
            longitude: values.location.longitude,
        } : undefined;
    }

    return undefined;
}