import React, {FC, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ChevronLeftRegular} from "@fluentui/react-icons";
import {Button, Result} from "antd";

const NotFoundPage: FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const onBackButtonClicked = useCallback(() => {
        navigate("/");
    }, [navigate]);

    return <center>
        <Result
            status="404"
            title="404"
            subTitle={t("NotFoundPage_Title")}
            extra={<Button type="primary"
                           onClick={onBackButtonClicked}>{t("NotFoundPage_BackButton")}</Button>}
        />
    </center>;
}

export default NotFoundPage;