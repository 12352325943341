import {useMemo} from "react";
import {CustomSearchBoxElement} from "@ib-tehnologije/react-widgets";
import {useGetCostLocationsLazyQuery, useGetCostLocationsQuery} from "../graphql-types";

export const useCostLocationPicker = () => {
    return useMemo(() => ({
        type: new CustomSearchBoxElement({
            query: useGetCostLocationsLazyQuery,
            getQueryData: ({data}) => data?.costLocations,
            getKey: (g) => g.id,
            makeTitle: (g) => g.name,
            valueKey: "costLocation",
            makeSubtitle: (g) => g.code?.toString(),
            makeWhere: (values, search) => ({
                or: [{
                    name: {
                        contains: search ?? ""
                    },
                }, {
                    code: {
                        eq: +(!Number.isNaN(parseInt(search)) ? parseInt(search) : -1)
                    }
                }]
            }),
        })
    }), []);
}